import { CreateProps, SimpleForm } from "react-admin";
import { AnyObject } from "react-final-form";
import { DefaultCreate } from "../../../components/crud/DefaultCreate";
import { MediaFileSource, transformAndUploadS3Files } from "../../utilities/media";
import { UserForm } from "./UserForm";

export const validatePasswords = ({
  password,
  confirm_password,
}: AnyObject) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.users.validation.password_mismatch"];
  }

  return errors;
};

const UserCreate= (props: CreateProps) => {
    const mediaFileSources: MediaFileSource[] = [{
      source: 'files',
      subFolder: 'users',
    }];
  return (
    <DefaultCreate {...props}transform={transformAndUploadS3Files(mediaFileSources)}>
      <SimpleForm validate={validatePasswords} autocomplete="off">
        <UserForm />
      </SimpleForm>
    </DefaultCreate>
  );
};

export { UserCreate };
