import { ReactElement } from "react";
import {
  ListRowActionButtonsActions,
  ListRowActionButtonsActionTypes,
  ListRowActionButtonsState,
} from "./types";

export const prepareListRowActionButtonsInitialState = (
  listRowActions?: Array<ReactElement>
) => {
  const newListRowActions = listRowActions
    ? Array.from(listRowActions)
    : undefined;
  const state = {
    listRowActions: newListRowActions,
    isSpeedDialOpen: false,
    isConfirmOpen: false,
    confirm: undefined,
  };
  return state;
};

export const listRowActionButtonsReducer = (
  state: ListRowActionButtonsState,
  action: ListRowActionButtonsActions
): ListRowActionButtonsState => {
  switch (action.type) {
    case ListRowActionButtonsActionTypes.INIT: {
      const newState = prepareListRowActionButtonsInitialState();

      return newState;
    }

    case ListRowActionButtonsActionTypes.OPEN_SPEED_DIAL: {
      const newState = {
        ...state,
        isSpeedDialOpen: true,
      };

      return newState;
    }

    case ListRowActionButtonsActionTypes.CLOSE_SPEED_DIAL: {
      const newState = {
        ...state,
        isSpeedDialOpen: false,
      };

      return newState;
    }

    case ListRowActionButtonsActionTypes.OPEN_CONFIRM: {
      const confirm = action?.payload?.confirm;
      const newState = {
        ...state,
        isConfirmOpen: true,
        isSpeedDialOpen: false,
        confirm,
      };

      return newState;
    }

    case ListRowActionButtonsActionTypes.CLOSE_CONFIRM: {
      const newState = {
        ...state,
        isConfirmOpen: false,
        confirm: undefined,
      };

      return newState;
    }

    case ListRowActionButtonsActionTypes.HANDLE_CONFIRM_ACTION: {
      const onConfirm = state?.confirm?.onConfirm;
      const event = action?.payload?.event;

      if (onConfirm) {
        onConfirm(event);
      }

      const newState = {
        ...state,
        isConfirmOpen: false,
        confirm: undefined,
      };

      return newState;
    }

    case ListRowActionButtonsActionTypes.SET_LIST_ROW_ACTIONS: {
      const listRowActions = action?.payload?.listRowActions;
      const newState = {
        ...state,
        listRowActions,
      };

      return newState;
    }
  }
};
