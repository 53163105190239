import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  RichTextField,
  ReferenceField,
} from "react-admin";
import { DefaultShow, UploadedFilesField } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { RESOURCE_NAME_VENDOR_GROUP } from "./constants";

const VendorGroupShow=(props:ShowProps)=>{
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="number" />
        <TextField source="name" />
        <RichTextField source="description" />
        <ReferenceField source="parentId" reference={RESOURCE_NAME_VENDOR_GROUP}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <UploadedFilesField canDelete={false} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { VendorGroupShow };
