import { useContext } from "react";
import {
  makeStyles,
  Theme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  createStyles,
} from "@material-ui/core";
import { format } from "date-fns";
import { useTranslate } from "react-admin";
import grey from "@material-ui/core/colors/grey";
import { SectionTitleSubtitle } from "../../../lib/components";
import { BidComparisonCalculationContext } from "./context";
import { DATE_FORMAT_STANDARD_DATE_TIME, DATE_FORMAT_STANDARD_DAY } from "../../../lib/core";

const COLOR_HEADER_CELL = grey[300];
const COLOR_TABLE_BORDER = grey[400];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: "95vw",
      border: "1px solid",
      borderColor: COLOR_TABLE_BORDER,
      borderSpacing: 1,
      marginTop: 10,
      marginBottom: 15,
      marginLeft: theme.spacing(2),
    },
    header: {
      width: "25%",
      backgroundColor: COLOR_HEADER_CELL,
      fontWeight: 500,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
    },
    data: {
      width: "75%",
      textAlign: "left",
      borderColor: COLOR_TABLE_BORDER,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
    },
    headerFourCol: {
      width: "25%",
      backgroundColor: COLOR_HEADER_CELL,
      fontWeight: 500,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
    },
    dataFourCol: {
      width: "25%",
      textAlign: "left",
      borderColor: COLOR_TABLE_BORDER,
      paddingTop: 15,
      paddingBottom: 15,
      paddingLeft: 5,
    },
  })
);

const BidsComparisonHeader= () => {
  const translate = useTranslate();
  const classes = useStyles();
  const { state } = useContext(BidComparisonCalculationContext);

  const enquiry = state?.bidsComparisonCalc?.enquiry;

  const preparedDate = enquiry?.preparedDate
    ? format(new Date(enquiry?.preparedDate), DATE_FORMAT_STANDARD_DATE_TIME)
    : null;
  const bidStartDate = enquiry?.bidDates?.start
    ? format(new Date(enquiry?.bidDates?.start), DATE_FORMAT_STANDARD_DAY)
    : null;
  const bidEndDate = enquiry?.bidDates?.end
    ? format(new Date(enquiry?.bidDates?.end), DATE_FORMAT_STANDARD_DAY)
    : null;
  const preferredDeliveryDate = enquiry?.bidDates?.preferredDeliveryDate
    ? format(new Date(enquiry?.bidDates?.preferredDeliveryDate), DATE_FORMAT_STANDARD_DAY)
    : null;
  const bidDates = `${bidStartDate} - ${bidEndDate}`;

  return (
    <Box display="flex" flexDirection="column" marginBottom={2} >
      <SectionTitleSubtitle
        title="resources.bidsComparison.sections.enquiryDetail.title"
        subTitle="resources.bidsComparison.sections.enquiryDetail.subTitle"
        marginLeft={2}
      />

      <Table className={classes.table}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.enquiryNum")}
            </TableCell>
            <TableCell className={classes.data}>{enquiry?.id}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.enquiryCode")}
            </TableCell>
            <TableCell className={classes.data}>{enquiry?.code}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.requisitionNum")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.requisition?.id}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.businessFunctionNumber")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.requisition?.businessFunction?.number}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.businessFunctionName")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.requisition?.businessFunction?.name}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.requisitionName")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.requisition?.code}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.preparedBy")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.preparedBy?.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.preparedDate")}
            </TableCell>
            <TableCell className={classes.data}>{preparedDate}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.currency")}
            </TableCell>
            <TableCell className={classes.data}>
              {enquiry?.currency?.name}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate("resources.bidsComparison.fields.bidDuration")}
            </TableCell>
            <TableCell className={classes.data}>{bidDates}</TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.header}>
              {translate(
                "resources.bidsComparison.fields.preferredDeliveryDate"
              )}
            </TableCell>
            <TableCell className={classes.data}>
              {preferredDeliveryDate}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};

export { BidsComparisonHeader };
