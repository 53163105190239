import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";
import { RESOURCE_NAME_USER } from "../../../lib/modules";

const ProcureOwnerUserListFilter = ({
  source ="procureOwnerUserId", 
  label = "common.fields.procureOwnerUserId"
}: {
  source?: string; 
  label?: string
}) => {
  return (
    <ReferenceArrayInput
        source={source}
        reference={RESOURCE_NAME_USER}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "firstName", order: "ASC" }}
        key="procureOwnerUserId"
        label="common.fields.procureOwnerUserId"
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceArrayInput>
  );
};

export { ProcureOwnerUserListFilter };
