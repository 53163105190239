import {
  GatedSubMenu,
  GatedMenuItemLink,
  MenuProps,
} from "../../lib/components/menu";
import { businessFunctionResource } from "./business-functions";
import { currencyResource } from "./currencies";
import {
  itemResource,
  itemsClassResource,
  itemsGroupResource,
  itemsSegmentResource,
} from "./items";

import { systemDataResource } from "./system-data";
import { uomResource } from "./unit-of-measure";
import { vendorResource } from "./vendors";
import { locationResource } from "./locations";
import { purchaseGroupResource } from "./purchase-groups";
import { categoryResource } from "./category";
import { vendorGroupResource } from "./vendor-group";
import { orgStructureResource } from "./org-structure/org-structure";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import OrgIcon from "@material-ui/icons/LocationCity";
import { OrgStructureTypesResource } from "./org-structure/org-structure-types";
import { OrgUserGroupsResource } from "./org-structure/org-user-groups";
import GroupWorkIcon from '@material-ui/icons/GroupWork';
const DatasetupMenu= ({
  isSidebarOpen,
  onMenuClick,
  dense,
}:MenuProps) => {
  return (
    <GatedSubMenu
      name={`resources.dataSetup.name`}
      icon={<systemDataResource.icon />}
      isSidebarOpen={isSidebarOpen}
      dense={dense}
      allowedPermissionName="view-datasetup-management-menu"
    >
      <GatedMenuItemLink
        to={{
          pathname: `/${businessFunctionResource.name}`,
          state: { _scrollToTop: true },
        }}
        name="resources.businessFunction.name"
        leftIcon={<businessFunctionResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          businessFunctionResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
      <GatedMenuItemLink
        to={`/${categoryResource.name}`}
        name="resources.category.name"
        leftIcon={<categoryResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={categoryResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${currencyResource.name}`}
        name="resources.currencies.name"
        leftIcon={<currencyResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={currencyResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedSubMenu
        name={`resources.items.name`}
        icon={<itemResource.icon />}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
        allowedPermissionName="view-items-management-menu"
      >
        <GatedMenuItemLink
          to={`/${itemResource.name}`}
          name="resources.items.name"
          leftIcon={<itemResource.icon />}
          onClick={() => onMenuClick}
          allowedPermissionName={itemResource.options.allowedPermissions.menu}
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />

        <GatedMenuItemLink
          to={`/${itemsSegmentResource.name}`}
          name="resources.itemSegments.name"
          leftIcon={<itemsSegmentResource.icon />}
          onClick={() => onMenuClick}
          allowedPermissionName={
            itemsSegmentResource.options.allowedPermissions.menu
          }
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />
        <GatedMenuItemLink
          to={`/${itemsClassResource.name}`}
          name="resources.itemClasses.name"
          leftIcon={<itemsClassResource.icon />}
          onClick={() => onMenuClick}
          allowedPermissionName={
            itemsClassResource.options.allowedPermissions.menu
          }
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />
      </GatedSubMenu>
      <GatedMenuItemLink
        to={`/${itemsGroupResource.name}`}
        name="resources.itemGroups.name"
        leftIcon={<itemsGroupResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          itemsGroupResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
      <GatedMenuItemLink
        to={`/${locationResource.name}`}
        name="resources.location.name"
        leftIcon={<locationResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={locationResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedSubMenu
        name={`resources.orgStructures.name`}
        icon={<OrgIcon />}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
        allowedPermissionName={
          orgStructureResource.options.allowedPermissions.menu
        }
      >
        <GatedMenuItemLink
          to={`/${orgStructureResource.name}`}
          name="resources.orgStructures.name"
          leftIcon={<orgStructureResource.icon />}
          onClick={() => onMenuClick}
          allowedPermissionName={
            orgStructureResource.options.allowedPermissions.menu
          }
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />

        <GatedMenuItemLink
          to={`/${OrgStructureTypesResource.name}`}
          name="resources.OrgStructureTypes.name"
          leftIcon={<MergeTypeIcon />}
          onClick={() => onMenuClick}
          allowedPermissionName={
            OrgStructureTypesResource.options.allowedPermissions.menu
          }
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />
        <GatedMenuItemLink
          to={`/${OrgUserGroupsResource.name}`}
          name="resources.OrgUserGroups.name"
          leftIcon={<GroupWorkIcon />}
          onClick={() => onMenuClick}
          allowedPermissionName={
            OrgUserGroupsResource.options.allowedPermissions.menu
          }
          isSidebarOpen={isSidebarOpen}
          dense={dense}
        />
      </GatedSubMenu>

      <GatedMenuItemLink
        to={`/${purchaseGroupResource.name}`}
        name="resources.purchaseGroups.name"
        leftIcon={<purchaseGroupResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          purchaseGroupResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${systemDataResource.name}`}
        name="resources.systemData.name"
        leftIcon={<systemDataResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          systemDataResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${uomResource.name}`}
        name="resources.uom.name"
        leftIcon={<uomResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={uomResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${vendorResource.name}`}
        name="resources.vendors.name"
        leftIcon={<vendorResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={vendorResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${vendorGroupResource.name}`}
        name="resources.vendorGroup.name"
        leftIcon={<vendorGroupResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          vendorGroupResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
    </GatedSubMenu>
  );
};

export { DatasetupMenu };
