import { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import {
  CommentOwnerEntity,
  CommentType,
  Comment,
  RESOURCE_NAME_COMMENT,
} from "../constants";
import ContentAdd from "@material-ui/icons/Add";
import {
  Create,
  SimpleForm,
  useNotify,
  useListContext,
  useTranslate,
  required,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { CommentToolbar } from "./CommentToolbar";

const CommentCreateButton = ({
  ownerEntityType,
  ownerEntityId,
}: CommentOwnerEntity) => {
  const translate = useTranslate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const commentType = CommentType.INTERNAL;

  const notify = useNotify();

  const { refetch } = useListContext<Comment>();

  const onSuccess = () => {
    setIsDialogOpen(false);
    notify("resources.comments.messages.notification.created", {type: "success"});
    refetch();
  };

  const handleCancelCreate = () => {
    setIsDialogOpen(false);
  };

  const initialValues = {
    commentType,
    ownerEntityType,
    ownerEntityId,
  };

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => setIsDialogOpen(true)}
        startIcon={<ContentAdd />}
      >
        {translate("resources.comments.actions.create")}
      </Button>
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>
          {translate("resources.comments.messages.create.title")}
        </DialogTitle>
        <DialogContent>
          <Create
            basePath="/"
            resource={RESOURCE_NAME_COMMENT}
            onSuccess={onSuccess}
            >
            <SimpleForm 
              initialValues={initialValues} 
              toolbar={<CommentToolbar onCancel={handleCancelCreate}/>}
              mutationMode="undoable"
            >
              <RichTextInput
                source="comment"
                helperText="resources.comments.help.comment"
                label={false}
                validate={[required()]}
                isRequired
                autofocus
              />
            </SimpleForm>
          </Create>
        </DialogContent>
      </Dialog>
    </>
  );
};

export { CommentCreateButton };
