import React, { ReactElement } from "react";
import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  ReferenceField,
  DateField,
  BooleanField,
  RichTextField,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts/accounts/constants";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../lib/components";
import { DEFAULT_PER_PAGE, IdFilter, SearchFilter } from "../../../lib/common";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
  </Filter>
);
const defaultColumns = ["name", "type", "description"];
const SystemDataList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="name" />
        <TextField source="type" />
        <RichTextField source="description" />
        <TextField source="sortOrder" />
        <TextField source="value" />
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { SystemDataList };
