import { useTranslate } from "react-admin";

const FilenameField = (props) => {
  const translate = useTranslate();
  const apiUrl = process.env["REACT_APP_SERVER_ENDPOINT"];
  const downloadUrl = `${apiUrl}/media/download/${props?.record?.id}`;

  return (
    <a
      href={downloadUrl}
      download
      title={translate(
        "components.controls.media.tooltip.uploadedFiles"
      )}
    >
      <span>{props?.record?.originalFileName}</span>
    </a>
  );
};

FilenameField.defaultProps = {
  label: "components.controls.media.fields.originalFileName",
};

export { FilenameField };
