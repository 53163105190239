export const RESOURCE_ACCOUNT_CODE = "accountCode";
export const RESOURCE_NAME_VENDOR_GROUP = "vendorGroup";

export enum PermissionVendorGroup {
  MENU_VENDOR_GROUP = "view-vendor-group-menu",
  LIST_VENDOR_GROUPS = "list-vendor-groups",
  SHOW_VENDOR_GROUP = "show-vendor-group",
  CREATE_VENDOR_GROUP = "create-vendor-group",
  EDIT_VENDOR_GROUP = "update-vendor-group",
  DELETE_VENDOR_GROUP = "delete-vendor-group",
}
