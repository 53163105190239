import { useState } from "react";
import RichTextInput from "ra-input-rich-text";
import {
  Edit,
  SimpleForm,
  DateField,
  useDelete,
  useNotify,
  RichTextField,
  useListContext,
  useTranslate,
  required,
} from "react-admin";
import {
  Typography,
  Tooltip,
  IconButton,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import TrashIcon from "@material-ui/icons/Delete";
import {
  Comment,
  RESOURCE_NAME_COMMENT,
  PermissionComments,
} from "../constants";
import { usePermissionsCheck } from "../../../lib/core";
import { CommentToolbar } from "./CommentToolbar";
import { UserNameEmailField } from "../../../common";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  metadata: {
    color: theme.palette.text.secondary,
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
  content: {
    borderRadius: 10,
    display: "flex",
    alignItems: "stretch",
  },
  text: {
    flex: 1,
  },
  paragraph: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: 1.3,
    marginBottom: theme.spacing(2.4),
  },
  toolbar: {
    marginLeft: theme.spacing(2),
    visibility: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
}));

export const SingleComment = ({
  comment,
  isViewOnly,
}: {
  comment: Comment;
  isViewOnly: boolean;
}) => {
  const translate = useTranslate();
  const { hasOwnOrAccountAccess, permissionsLoaded } = usePermissionsCheck();

  const [isHover, setHover] = useState(false);
  const [isEditing, setEditing] = useState(false);

  const notify = useNotify();
  const classes = useStyles();
  const { refetch } = useListContext<Comment>();

  const hasEditCommentAccess = hasOwnOrAccountAccess(
    PermissionComments.EDIT_ANY_COMMENT,
    PermissionComments.EDIT_OWN_COMMENT,
    comment?.accountId,
    comment?.ownerId
  );

  const hasDeleteCommentAccess = permissionsLoaded && hasOwnOrAccountAccess(
    PermissionComments.DELETE_ANY_COMMENT,
    PermissionComments.DELETE_OWN_COMMENT,
    comment?.accountId,
    comment?.ownerId
  );

  const [handleDelete] = useDelete(RESOURCE_NAME_COMMENT, comment.id, comment, {
    mutationMode: "pessimistic",
    onSuccess: () => {
      refetch();
      notify("resources.comments.messages.notification.deleted", {type: "success"});
    },
  });

  const handleEnterEditMode = () => {
    setEditing(true);
  };

  const handleCancelEdit = () => {
    setEditing(false);
    setHover(false);
  };

  const onSuccess = () => {
    setEditing(false);
    setHover(false);
    refetch();
    notify("resources.comments.messages.notification.edited", {type: "success"});
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classes.metadata}>
        <UserNameEmailField record={comment} source="ownerId" label="resources.comments.fields.fullName"/>{" "}
        <Typography component="span" variant="body2">
          {translate("resources.comments.help.commentedOn")}
        </Typography>
        <DateField
          source="updatedAt"
          label="resources.comments.fields.updatedAt"
          record={comment}
          variant="body2"
          showTime
          locales="en"
          options={{
            dateStyle: "full",
            timeStyle: "short",
          }}
        />
      </div>
      {(isEditing && !isViewOnly) ? (
        <Edit
          basePath="/"
          resource={RESOURCE_NAME_COMMENT}
          id={comment?.id?.toString()}
          onSuccess={onSuccess}
          actions={false}
          mutationMode="optimistic"
        >
          <SimpleForm
            toolbar={<CommentToolbar onCancel={handleCancelEdit}/>}
          >
          <RichTextInput
            source="comment"
            label={false}
            helperText="resources.comments.help.commentEdit"
            
            autofocus
            validate={[required()]}
            isRequired
          />
          </SimpleForm>
        </Edit>
      ) : (
        <Box className={classes.content}>
          <div className={classes.text}>
            <RichTextField
              source="comment"
              label="resources.comments.fields.comment"
              record={comment}
            />
          </div>
          {!isViewOnly && (hasEditCommentAccess || hasDeleteCommentAccess) && (
            <div
              className={classes.toolbar}
              style={{ visibility: isHover ? "visible" : "hidden" }}
            >
              {hasEditCommentAccess && (
                <Tooltip title="Edit Comment">
                  <IconButton size="small" onClick={handleEnterEditMode}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {hasDeleteCommentAccess && (
                <Tooltip title="Delete Comment">
                  <IconButton size="small" onClick={handleDelete}>
                    <TrashIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          )}
        </Box>
      )}
    </div>
  );
};
