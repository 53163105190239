import { useEffect, useState } from "react";
import { SortButton, TopToolbar } from "react-admin";
import { RefetchIconButton } from "../../../lib/components";
import { usePermissionsCheck } from "../../../lib/core";
import { CommentOwnerEntity, PermissionComments } from "../constants";
import { CommentCreateButton } from "./CommentCreateButton";

const CommentListActions = ({
  ownerEntityType,
  ownerEntityId,
  isViewOnly,
}: CommentListActionsProps) => {
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const [hasCreateAccess, setHasCreateAccess] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasCreateAccess = hasPermissionAccess(
        PermissionComments.CREATE_COMMENT
      );
      setHasCreateAccess(hasCreateAccess);
    }
  }, [hasPermissionAccess, permissionsLoaded]);

  return (
    <TopToolbar>
      <SortButton fields={["updatedAt"]} />
      <RefetchIconButton />
      {!isViewOnly && hasCreateAccess && (
        <CommentCreateButton
          ownerEntityType={ownerEntityType}
          ownerEntityId={ownerEntityId}
        />
      )}
    </TopToolbar>
  );
};

interface CommentListActionsProps extends CommentOwnerEntity {
  isViewOnly: boolean;
}

export { CommentListActions };
