import {
    EditProps,
} from 'react-admin';
import { DefaultEdit } from '../../../lib/components';
import { MediaFileSource, transformAndUploadS3Files } from '../../../lib/modules';
import { VendorEditContainer } from './components/VendorEditContainer';


const VendorEdit= (props:EditProps) => {
    const mediaFileSources: MediaFileSource[] = [
        {
          source: "files",
          subFolder: "vendors",
        },
      ];
    return (
        <DefaultEdit
            {...props}
            mutationMode = "pessimistic"
            transform={transformAndUploadS3Files(mediaFileSources)}
        >
                <VendorEditContainer isEdit={true}/>
        </DefaultEdit>
    );
};

export { VendorEdit }