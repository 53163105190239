import { useCallback } from "react";
import {
  useInput,
  useTranslate,
  FieldTitle,
  InputHelperText,
} from "react-admin";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { dateTimeFormatter, dateTimeParser } from "./utils";

const Picker = ({ PickerComponent, ...fieldProps }) => {
  const {
    defaultValue,
    format,
    initialValue,
    label,
    helperText,
    margin,
    options,
    source,
    resource,
    parse,
    validate,
    variant,
    className,
    isRequired,
    providerOptions,
    fullWidth,
    cancelLabel,
    clearLabel,
    pickerVariant,
    stringFormat,
    ...rest
  } = fieldProps;

  const translate = useTranslate();

  const { id, input, meta } = useInput({
    defaultValue,
    format,
    initialValue,
    parse,
    resource,
    source,
    validate,
  });
  const { touched, submitError, error } = meta;

  const handleChange = useCallback(
    (value) => {
      Date.parse(value)
        ? input.onChange(
            stringFormat === "ISO" ? value?.toISOString() : value?.toString()
          )
        : input.onChange(null);
    },
    [input]
  );

  return (
    <div className="picker">
      <MuiPickersUtilsProvider {...providerOptions}>
        <PickerComponent
          id={id}
          InputLabelProps={{
            shrink: true,
          }}
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          variant={pickerVariant}
          inputVariant={variant}
          margin={margin}
          error={!!(touched && (error || submitError))}
          helperText={
            <InputHelperText
              touched={touched ?? false}
              error={error || submitError}
              helperText={helperText}
            />
          }
          className={className}
          value={input.value ? new Date(input.value) : null}
          cancelLabel={translate(cancelLabel)}
          clearLabel={translate(clearLabel)}
          onChange={(date) => handleChange(date)}
          onBlur={() =>
            input.onBlur(
              //@ts-ignore
              input.value
                ? stringFormat === "ISO"
                  ? new Date(input.value).toISOString()
                  : new Date(input.value).toString()
                : null
            )
          }
          {...options}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

const sanitizeRestProps = ({
  allowEmpty,
  alwaysOn,
  basePath,
  component,
  defaultValue,
  format,
  formClassName,
  initialValue,
  initializeForm,
  input,
  isRequired,
  label,
  limitChoicesToValue,
  locale,
  meta,
  options,
  optionText,
  optionValue,
  parse,
  record,
  resource,
  source,
  textAlign,
  translate,
  translateChoice,
  labelTime,
  ...rest
}) => rest;

Picker.defaultProps = {
  input: {},
  isRequired: false,
  meta: { touched: false, error: false },
  options: {},
  cancelLabel: "ra.action.clear_input_value",
  clearLabel: "ra.action.cancel",
  margin: "dense",
  variant: "filled",
  pickerVariant: "dialog",
  stringFormat: "ISO",
  format: dateTimeFormatter,
  parse: dateTimeParser,
  providerOptions: {
    utils: DateFnsUtils,
    locale: undefined,
  },
};

export default Picker;
