export const RESOURCE_NAME_CURRENCIES = "currencies";

export enum PermissionCurrencies {
  CURRENCIES_MENU = "view-currencies-menu",
  LIST_CURRENCIES = "list-currencies",
  SHOW_CURRENCY = "show-currency",
  CREATE_CURRENCY = "create-currency",
  EDIT_CURRENCY = "update-currency",
  DELETE_CURRENCY = "delete-currency",
}
