import {
  TextInput,
  required,
  ReferenceInput,
  useTranslate,
  SimpleForm,
  AutocompleteInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { RESOURCE_NAME_ITEM_SEGMENTS } from "./constants";
import { Typography } from "@material-ui/core";
import { MediaInputField } from "../../../../lib/components";
import { MAX_RECORDS_PER_PAGE } from "../../../../lib/common";
import { RESOURCE_NAME_ITEM_CLASS } from "../itemsClass";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  number: { display: "block" },
  description: { display: "block" },
  parent: { display: "block" },
  class: { display: "block" },
};

const useStyles = makeStyles(styles);

const ItemSegmentForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm warnWhenUnsavedChanges {...rest}>
      <SectionTitle label="resources.itemSegments.fieldGroups.itemSegmentDetails" />
      <TextInput
        autoFocus
        source="number"
        label="resources.itemSegments.fields.number"
        helperText="resources.itemSegments.help.number"
        formClassName={classes.number}
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="name"
        label="resources.itemSegments.fields.name"
        helperText="resources.itemSegments.help.name"
        formClassName={classes.name}
        validate={[required()]}
        fullWidth
      />

      <RichTextInput
        source="description"
        label="resources.itemSegments.fields.description"
        helperText="resources.itemSegments.help.description"
        formClassName={classes.description}
      />

      <ReferenceInput
        source="parentId"
        label="resources.items.fields.parentId"
        reference={RESOURCE_NAME_ITEM_SEGMENTS}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
        formClassName={classes.parent}
        key="parentId"
      >
        <AutocompleteInput
          optionText="displayLabel"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceInput>
      <ReferenceInput
        source="itemClassId"
        label="resources.itemSegments.fields.itemClassId"
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        reference={RESOURCE_NAME_ITEM_CLASS}
        formClassName={classes.group}
        key="itemClassId"
      >
        <AutocompleteInput
          optionText="displayLabel"
          helperText="resources.itemSegments.help.itemClassId"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceInput>

      <MediaInputField showUploadedFiles={isEdit} />
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { ItemSegmentForm };
