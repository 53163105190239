import { Record, Identifier } from "react-admin";

export const RESOURCE_NAME_COMMENT = "comments";

export interface CommentOwnerEntity {
  ownerEntityType: OwnerEntityTypes;
  ownerEntityId: Identifier;
}

export enum PermissionComments {
  LIST_COMMENTS = "list-comments",
  SHOW_COMMENT = "show-comment",
  CREATE_COMMENT = "create-comment",
  EDIT_ANY_COMMENT = "update-any-comment",
  EDIT_OWN_COMMENT = "update-own-comment",
  DELETE_ANY_COMMENT = "delete-any-comment",
  DELETE_OWN_COMMENT = "delete-own-comment",
}

export enum OwnerEntityTypes {
  REQUISITION = 1,
  ENQUIRY = 2,
  BID = 3,
}

export enum CommentType {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export interface Comment extends Record, CommentOwnerEntity {
  comment: string;
  commentType: CommentType;
  parentCommentId?: Identifier;
}
