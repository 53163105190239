import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { AccountCreate } from './AccountCreate';

import { AccountList } from './AccountList';
import { AccountEdit } from './AccountEdit';
import { accountsPermissions, RESOURCE_NAME_ACCOUNT } from './constants';
import { AccountShow } from './AccountShow';

const accountResource = {
    name: RESOURCE_NAME_ACCOUNT,
    list: AccountList,
    create: AccountCreate,
    edit: AccountEdit,
    show: AccountShow,
    icon: AccountBoxIcon,
    options: { 
        label: 'resources.accounts.name',
        allowedPermissions: accountsPermissions 
    },
}

export * from './constants';
export { accountResource }