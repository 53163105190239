export const RESOURCE_NAME_ITEM_GROUPS = "itemGroups";

export enum PermissionItemGroups {
  MENU_ITEM_GROUPS = "view-item-groups-menu",
  LIST_ITEM_GROUPS = "list-item-groups",
  SHOW_ITEM_GROUP = "show-item-group",
  CREATE_ITEM_GROUP = "create-item-group",
  EDIT_ITEM_GROUP = "update-item-group",
  DELETE_ITEM_GROUP = "delete-item-group",
  IMPORT_ITEM_GROUPS = "import-item-groups",
}
