import { useContext } from "react";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import ManagePriceComponentsIcon from "@material-ui/icons/ControlCamera";
import EmptyPriceComponentIcon from "@material-ui/icons/MoneyOff";
import { BidComparisonCalculationActionTypes } from "./types";
import { ManagePriceComponents } from "./ManagePriceComponents";
import { EmptyState, SectionTitleSubtitle } from "../../../lib/components";
import { DisplayPriceComponentsTable } from "./DisplayPriceComponentsTable";
import { useTranslate } from "react-admin";
import { BidComparisonCalculationContext } from "./context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(5),
      marginLeft: theme.spacing(2),
    },
    titleContainer: {
      display: "grid",
      gridAutoFlow: "row",
      alignItems: "start",
      justifyContent: "start",
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up("sm")]: {
        gridAutoFlow: "column",
        gridColumnGap: 100,
        alignItems: "center",
        marginBottom: 0,
      },
    },
    title: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    titleBody: {
      marginTop: theme.spacing(2),
    },
    emptyState: {},
    manageButton: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up("sm")]: {
        marginTop: 0,
        marginLeft: theme.spacing(3),
      },
    },
  })
);

interface DisplayPriceComponentsProps {
  canManage: boolean;
}

const DisplayPriceComponents= (props:DisplayPriceComponentsProps,
  canManage,
) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { state, dispatch } = useContext(BidComparisonCalculationContext);

  const ManagePriceComponentButton = () => (
    <Button
      startIcon={<ManagePriceComponentsIcon />}
      color="primary"
      variant="outlined"
      size="medium"
      className={classes.manageButton}
      onClick={() => {
        if (dispatch) {
          dispatch({
            type: BidComparisonCalculationActionTypes.OPEN_MANAGE_PRICE_COMPONENTS_DIALOG,
          });
        }
      }}
    >
      {translate(
        "resources.bidsComparison.components.ManagePriceComponents.labels.title"
      )}
    </Button>
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <SectionTitleSubtitle
          title="resources.bidsComparison.sections.priceComponents.title"
          subTitle="resources.bidsComparison.sections.priceComponents.subTitle"
        />
        {state?.bidsComparisonCalc?.priceComponents &&
          state?.bidsComparisonCalc?.priceComponents?.length > 0 && (
            <ManagePriceComponentButton />
          )}
      </Box>

      {!state?.bidsComparisonCalc?.priceComponents?.length && (
        <EmptyState
          actionButton={<ManagePriceComponentButton/>}
          Icon={EmptyPriceComponentIcon}
          title="resources.bidsComparison.components.DisplayPriceComponents.messages.empty.title"
          subTitle="resources.bidsComparison.components.DisplayPriceComponents.messages.empty.subTitle"
        />
      )}

      {state?.bidsComparisonCalc?.priceComponents &&
        state?.bidsComparisonCalc?.priceComponents?.length > 0 && (
          <DisplayPriceComponentsTable />
        )}

      {canManage && <ManagePriceComponents />}
    </Box>
  );
};

export { DisplayPriceComponents };
