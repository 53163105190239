import { Theme, useMediaQuery } from "@material-ui/core";
import {
  Filter,
  FilterProps,
  BooleanInput,
  SelectArrayInput,
  SelectInput,
} from "react-admin";
import { BusinessFunctionsListFilter, ItemsListFilter, ProcureOwnerUserListFilter, PROCURE_APPROVAL_STATUS_CHOICES, PROC_ENTITY_RECORD_STATUS_CHOICES } from "../../../common";
import { IdFilter, SearchFilter } from "../../../lib/common";

const RequisitionListFilters = (props: Omit<FilterProps, "children">) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Filter {...props}>

      {isSmall && <SearchFilter alwaysOn />}
      <IdFilter label="components.controls.filters.idFilter.label" source="id" key="id" />
      {isSmall && (
        <SelectArrayInput 
          source="status"
          label="common.fields.status" 
          choices={PROC_ENTITY_RECORD_STATUS_CHOICES} 
        />
      )}

      {isSmall && (
        <SelectInput source="procureApprovalStatus"
          label="common.fields.approvalStatus"
          choices={PROCURE_APPROVAL_STATUS_CHOICES}
          allowEmpty={false}
          resettable={true}
          key="approvalStatus" />
      )}
      <BusinessFunctionsListFilter source="businessFunctionId"/>
      <ItemsListFilter source="itemId"/>
      {isSmall && <BooleanInput source="isOpen" />}
      {isSmall && <BooleanInput source="needsUserApproval" />}
      {isSmall && <BooleanInput source="canPerformAction" />}
      <ProcureOwnerUserListFilter source="procureOwnerUserId" label="common.fields.procureOwnerUserId"/>
    </Filter>
  );
};

export { RequisitionListFilters };
