import { Theme, useMediaQuery } from "@material-ui/core";
import {
  Filter,
  FilterProps,
  SelectInput,
} from "react-admin";
import { SearchFilter } from "../../../../common/ui/filters/SearchFilter";
import { fetchMediaOwnerTypeChoices } from "../constants";
import { IdFilter } from "../../../../common";
import { DateInput } from "../../../../components";

const MediaListFilters = (props: Omit<FilterProps, "children">) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  return (
    <Filter {...props}>
      {isSmall && <SearchFilter alwaysOn />}
      
      {isSmall && <SelectInput alwaysOn={false} source="ownerType" choices={fetchMediaOwnerTypeChoices()} /> }
      
       {isSmall && <DateInput source="updated_since_gte" />}
      <IdFilter label="components.controls.filters.idFilter.label" source="id" key="id" />
      {/* <ReferenceArrayInput 
        source="accountId" 
        reference={RESOURCE_NAME_ACCOUNT}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        key="accountId"
      >
        <AutocompleteInput 
          optionText="name" 
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceArrayInput> */}
    </Filter>
  );
};

export { MediaListFilters };
