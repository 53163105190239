import CategoryIcon from "@material-ui/icons/Category";
import { CategoryCreate } from "./CategoryCreate";
import { CategoryEdit } from "./CategoryEdit";
import { CategoryList } from "./CategoryList";
import { CategoryShow } from "./CategoryShow";
import { PermissionCategory, RESOURCE_NAME_CATEGORY } from "./constants";



const CategoryPermissions = {
    menu: PermissionCategory.MENU_CATEGORY,
    list: PermissionCategory.LIST_CATEGORIES,
    create: PermissionCategory.CREATE_CATEGORY,
    update: PermissionCategory.EDIT_CATEGORY,
    show: PermissionCategory.SHOW_CATEGORY,
    delete: PermissionCategory.DELETE_CATEGORY,
};


const categoryResource = {
    name: RESOURCE_NAME_CATEGORY,
    list: CategoryList,
    create: CategoryCreate,
    edit: CategoryEdit,
    show: CategoryShow,
    icon: CategoryIcon,
    options: { label: 'resources.category.name', allowedPermissions: CategoryPermissions },

}


export * from './constants'
export { CategoryPermissions, categoryResource }

