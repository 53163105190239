import { Box, makeStyles } from "@material-ui/core";
import {
    NumberField, 
    ReferenceField, 
    TextField, 
    Labeled,
    Record,
} from "react-admin";
import { RESOURCE_NAME_UOM } from "../../../modules/datasetup/unit-of-measure";

const useStyles = makeStyles((theme) => ({
  quantity: {
    paddingLeft: 10,
    paddingRight: 50,
  },
  uomId: {
    marginLeft: 5,
  },
}));

const QuantityField = ({
  source = "quantity",
  uomSource = "uomId",
  label = "common.fields.quantity",
  useLabel = true,
  record,
  className,
}: {
  source?: string;
  uomSource?: string;
  label?: string;
  useLabel?: boolean;
  record?: Record;
  className?: string;
}) => {
  const classes = useStyles();
  if (useLabel) {
    return (
      <Labeled
        id={source}
        label={label}
        source={source}
        className={className}
      >
        <Box>
          <NumberField source={source} record={record}/>
          <ReferenceField
            source={uomSource}
            reference={RESOURCE_NAME_UOM}
            link={false}
            record={record}
          >
            <TextField 
                source="displayLabel" 
                className={classes.uomId} 
            />
          </ReferenceField>
        </Box>
      </Labeled>
    );
  } else {
    return (
      <Box>
        <NumberField source={source} />
        <ReferenceField
          source={uomSource}
          reference={RESOURCE_NAME_UOM}
          link={false}
        >
          <TextField 
            source="displayLabel" 
            className={classes.uomId} 
        />
        </ReferenceField>
      </Box>
    );
  }
};

export { QuantityField };
