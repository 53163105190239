import { TextField, useTranslate, Loading, SimpleShowLayout } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ProfileActions from "./ProfileActions";
import { styles } from "./style";
import { DefaultShow } from "../../components";
import { IUser, RESOURCE_NAME_USER } from "../accounts";

const useStyles = makeStyles(styles);

const UserProfileShowTitle = () => {
  const translate = useTranslate();

  return <span>{translate("resources.users.profile.showPagetitle")}</span>;
};

const ProfileShow = (props) => {
  const classes = useStyles();
  let userId: string | undefined = undefined;
  let user: IUser | undefined = undefined;

  const localUserStr = localStorage.getItem("user");
  if (localUserStr) {
    user = JSON.parse(localUserStr);
    userId = user?.id;
  }

  const { staticContext, ...restProps } = props;

  if (!userId) {
    return <Loading />;
  }

  return (
    <DefaultShow
      actions={
        <ProfileActions
          href="/my-profile/edit"
          buttonTitle="ra.action.edit"
        />
      }
      title={<UserProfileShowTitle />}
      id={userId}
      resource={RESOURCE_NAME_USER}
      basePath="/my-profile"
      className={classes.marginTop20}
      {...restProps}
    >
      <SimpleShowLayout>
        <TextField
          source="firstName"
          className={classes.inlineGridWith50PercentWidth}
        />
        <TextField
          source="lastName"
          className={classes.inlineGridWith50PercentWidth}
        />
        <TextField
          source="email"
          className={classes.inlineGridWith50PercentWidth}
        />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { ProfileShow };
