import { useState } from "react";
import { Form, Field } from "react-final-form";
import { Link, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslate } from "ra-core";
import Typography from "@material-ui/core/Typography";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { SecondaryActionLinkButton } from "../../components";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(Dashboard_cover.jpeg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: 300,
    marginTop: "6em",
  },
  avatar: {
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    textAlign: "center",
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 0,
    marginTop: "20px",
  },
}));

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Typography>
        Your password has been changed successfully.
        <br />
        Please login with your changed password. <br />
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={"/login"}
      >
        Login
      </Button>
    </div>
  );
};

const InputForm = ({ handleSubmit, loading }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            name="password"
            // @ts-ignore
            component={renderInput}
            label="Password"
            type="password"
            disabled={loading}
          />
        </div>
        <div className={classes.input}>
          <Field
            name="confirmPassword"
            // @ts-ignore
            component={renderInput}
            label="Confirm Password"
            type="password"
            disabled={loading}
          />
        </div>
      </div>
      <CardActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          fullWidth
        >
          {loading && <CircularProgress size={25} thickness={2} />}
          Reset My Password
        </Button>

        <SecondaryActionLinkButton
          title="Forgot Password ?"
          url="/auth/forgot-password"
        />

        <SecondaryActionLinkButton title="Back to Login" url="/login" />
      </CardActions>
      {/* <Notification /> */}
    </form>
  );
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input: { ...inputProps },
  ...props
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const [isResponse, setIsResponse] = useState(false);
  const { token } = useParams();
  const [open, isOpen] = useState(false);
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    isOpen(false);
  };

  const handleSubmit = async (values) => {
    // setLoading(true);
    if (values.password !== values.confirmPassword) {
      setSeverity("error");
      setMessage("Password and confirm password do not match");

      isOpen(true);
    } else {
      const requestUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/forgot-password/reset`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          newPassword: values?.password,
          token: token,
        }),
      };

      fetch(requestUrl, requestOptions)
        .then((res) => res.json())
        .then(
          (result) => {
            setLoading(false);
            setIsResponse(true);
            setSeverity("success");
            setMessage("Password Changed Successfully");
            isOpen(true);
          },
          (error) => {
            setSeverity("error");
            setMessage("An error occured. Please request a new password.");
            isOpen(true);
          }
        );
    }
  };

  const validate = (values) => {
    let errors = {};

    if (!values.password) {
      errors["password"] = translate("ra.validation.required");
    }
    if (!values.confirmPassword) {
      errors["confirmPassword"] = translate("ra.validation.required");
    }

    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <UnauthenticatedLayout
          title="Reset Password"
          titleMessage="Please reset your password"
        >
          <div>
            {isResponse ? (
              <SuccessMessage />
            ) : (
              <InputForm
                handleSubmit={handleSubmit}
                loading={loading}
                // setIsResponse={setIsResponse}
              />
            )}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
              <Alert onClose={handleClose} severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </UnauthenticatedLayout>
      )}
    />
  );
};

export { ResetPasswordPage };
