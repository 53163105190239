import { Labeled, ReferenceField, TextField } from "react-admin";
import { usePermissionsCheck } from "../../../lib/core";
import { accountResource, RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts";

const ProcureAccountField = ({
  label = "common.fields.procureAccountId",
  useLabel = false,
}: {
  label?: string;
  useLabel?: boolean;
}) => {
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  let showLink: boolean | string = false;
  
  if(permissionsLoaded && (hasPermissionAccess(accountResource?.options?.allowedPermissions?.show))){
    showLink = 'show';
  }

  if (useLabel) {
    return (
      <Labeled
        id="procureAccountId"
        source="procureAccountId"
        label={label}
      >
        <ReferenceField
          source="procureAccountId"
          reference={RESOURCE_NAME_ACCOUNT}
          link={showLink}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
      </Labeled>
    );
  } else {
    return (
      <ReferenceField
        source="procureAccountId"
        reference={RESOURCE_NAME_ACCOUNT}
        link={showLink}
        sortable={false}
      >
        <TextField source="displayLabel" />
      </ReferenceField>
    );
  }
};

export { ProcureAccountField };
