import {  useEffect, useCallback, useContext } from "react";
import { EditProps, Identifier, Loading } from "react-admin";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { httpClient, Options } from "../../../lib/core/providers/dataProvider";
import {
  BidComparisonCalculationActionTypes,
  BidsComparisonCalc,
  PriceComponent,
} from "./types";
import { BidsComparisonTable } from "./BidsComparisonTable";
import { BidsComparisonHeader } from "./BidsComparisonHeader";
import { DisplayPriceComponents } from "./DisplayPriceComponents";
import { useForm } from "react-final-form";
import CompareIcon from "@material-ui/icons/Compare";
import { EmptyState } from "../../../lib/components";
import { BidComparisonCalculationContext } from "./context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bidCalcsContainer: {
      width: "100%",
      paddingLeft: 5,
    },
    loading: {
      width: "100%",
    },
  })
);

const fetchBidCalculationData = async (
  enquiryId: Identifier | undefined,
  excludedItemIds: Array<Identifier>,
  excludedVendorAccountIds: Array<Identifier>,
  priceComponents: Array<PriceComponent>
): Promise<BidsComparisonCalc> => {
  const bidComparisonCalculationUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/bids-comparison/calculate`;

  const body = JSON.stringify({
    enquiryId,
    excludedItemIds,
    excludedVendorAccountIds,
    priceComponents,
  });

  const options: Options = {
    method: "POST",
    body,
  };

  const bidCalculationResponse = await httpClient(
    bidComparisonCalculationUrl,
    options
  );
  return bidCalculationResponse?.json as Promise<BidsComparisonCalc>;
};

const BidsComparisonContainer= (props:EditProps) => {
  const id = props?.id;
  const classes = useStyles();
  const form = useForm();
  const { state, dispatch } = useContext(BidComparisonCalculationContext);

  const refetchBidComparisonCalcs = useCallback(async () => {
    if (!dispatch) {
      return;
    }
    const excludedItemIdsList = state?.excludedItemIds
      ? Array.from(state?.excludedItemIds)
      : [];
    const excludedVendorAccountIdsList = state?.excludedVendorAccountIds
      ? Array.from(state?.excludedVendorAccountIds)
      : [];
    const priceComponentsList = state?.priceComponents ?? [];

    dispatch({
      type: BidComparisonCalculationActionTypes.LOADING_BID_CALCULATIONS,
    });

    const responseBidsComparisonCalc = await fetchBidCalculationData(
      id,
      excludedItemIdsList,
      excludedVendorAccountIdsList,
      priceComponentsList
    );
    dispatch({
      type: BidComparisonCalculationActionTypes.BID_COM_DATA_POPULATE,
      payload: {
        bidsComparisonCalc: responseBidsComparisonCalc,
      },
    });
    form.change("enquiryId", id);
    form.change("excludedItemIds", excludedItemIdsList);
    form.change("excludedVendorAccountIds", excludedVendorAccountIdsList);
    form.change("priceComponents", priceComponentsList);
    // eslint-disable-next-line
  }, [
    dispatch,
    id,
    state?.excludedItemIds,
    state?.excludedVendorAccountIds,
    state?.priceComponents,
  ]);

  useEffect(() => {
    if (state?.shouldRecalculate) {
      refetchBidComparisonCalcs();
    }
    // eslint-disable-next-line
  }, [state?.shouldRecalculate]);

  if (state?.isLoading) {
    return (
      <Loading
        className={classes.loading}
        loadingPrimary="resources.bidsComparison.messages.loading.primary"
        loadingSecondary="resources.bidsComparison.messages.loading.secondary"
      />
    );
  }

  if (!state?.isLoading && !state?.hasData) {
    return (
      <EmptyState
        Icon={CompareIcon}
        title="resources.bidsComparison.components.bidComparisonTable.messages.empty.title"
        subTitle="resources.bidsComparison.components.bidComparisonTable.messages.empty.subTitle"
      />
    );
  }

  if (!state?.isLoading && state?.hasData && state.bidsComparisonCalc) {
    return (
      <Box>
        <BidsComparisonHeader />

        <Box className={classes.bidCalcsContainer}>
          <BidsComparisonTable />
        </Box>

        <Box className={classes.bidCalcsContainer}>
          <DisplayPriceComponents canManage={true} />
        </Box>
      </Box>
    );
  }

  return null;
};

export { BidsComparisonContainer };
