import { Labeled, ReferenceField, TextField } from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";

const CustomerNameField = ({
  source = "procureAccountId",
  label = "common.fields.customerName",
  useLabel = true,
}: {
  source?: string;
  label?: string;
  useLabel?: boolean;
}) => {
  if (useLabel) {
    return (
      <Labeled id={label} source={source} label={label}>
        <ReferenceField
          label={label}
          source={source}
          reference={RESOURCE_NAME_ACCOUNT}
          link={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </Labeled>
    );
  } else {
    return (
      <ReferenceField
        label={label}
        source={source}
        reference={RESOURCE_NAME_ACCOUNT}
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
    );
  }
};

export { CustomerNameField };
