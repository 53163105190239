import StatusIcon from "@material-ui/icons/Traffic";
import { StatusFilterList } from "../../../lib/common/ui/filters";
import { procEntityRecordFiltersList } from "../../constants";

const ProcEntityRecordStatusFilterList = () => {

  return (
    <StatusFilterList
      statusFieldName="procureStatus"
      label="common.fields.status"
      icon={<StatusIcon />}
      statusList={procEntityRecordFiltersList}
      width="160px"
    />
  )
};

export { ProcEntityRecordStatusFilterList };
