import {
  GatedMenuItemLink,
  GatedSubMenu,
  MenuProps,
} from "../../../lib/components";
import {
  bidResource,
} from "../../../modules/bids";
import {
  enquiryResource,
} from "../../../modules/enquiries";
import { requisitionResource } from "../../../modules/requisitions";
import BusinessIcon from "@material-ui/icons/Business";
import { FILTER_DIRECTION } from "../../constants";

const ProcurementMenu = ({
  isSidebarOpen,
  onMenuClick,
  dense,
}: MenuProps) => {
  return (
    <GatedSubMenu
      name={`resources.dataSetup.procurement`}
      icon={<BusinessIcon />}
      isSidebarOpen={isSidebarOpen}
      dense={dense}
      allowedPermissionName="view-procurement-management-menu"
    >
      <GatedMenuItemLink
        to={`/${requisitionResource.name}`}
        name="resources.requisitions.name"
        leftIcon={<requisitionResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          requisitionResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
      <GatedMenuItemLink
        to={{
          pathname: `/${enquiryResource.name}`,
          search: `filter=${JSON.stringify({
            direction: FILTER_DIRECTION.SENT,
          })}`,
        }}
        name="resources.enquiries.name"
        leftIcon={<enquiryResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={enquiryResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
      <GatedMenuItemLink
        to={{
          pathname: `/${bidResource.name}`,
          search: `filter=${JSON.stringify({
            direction: FILTER_DIRECTION.RECEIVED,
          })}`,
        }}
        name="resources.bids.name"
        leftIcon={<bidResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={bidResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
    </GatedSubMenu>
  );
};

export { ProcurementMenu };
