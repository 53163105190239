import { EditProps, SimpleForm } from "react-admin";
import { DefaultEdit } from "../../../components/crud/DefaultEdit";
import { MediaFileSource } from "../../utilities/media/constants";
import { transformAndUploadS3Files } from "../../utilities/media/utils/transformAndUploadS3Files";

import { validatePasswords } from "./UserCreate";
import { UserForm } from "./UserForm";

const UserEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "users",
    },
  ];
  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <SimpleForm validate={validatePasswords} autocomplete="off">
        <UserForm isEdit={true} />
      </SimpleForm>
    </DefaultEdit>
  );
};

export { UserEdit };
