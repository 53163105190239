import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { BusinessFunctionEdit } from './BusinessFunctionEdit'
import { BusinessFunctionList } from './BusinessFunctionList'
import { BusinessFunctionShow } from './BusinessFunctionShow'
import { BusinessFunctionCreate } from './BusinessFunctionsCreate'
import { PermissionBusinessFunctions, RESOURCE_NAME_BUSINESS_FUNCTIONS } from './constants'

const businessFunctionPermissions = {
    menu: PermissionBusinessFunctions.MENU_BUSINESS_FUNCTION,
    list: PermissionBusinessFunctions.LIST_BUSINESS_FUNCTIONS,
    create: PermissionBusinessFunctions.CREATE_BUSINESS_FUNCTION,
    update: PermissionBusinessFunctions.EDIT_BUSINESS_FUNCTION,
    show: PermissionBusinessFunctions.SHOW_BUSINESS_FUNCTION,
    delete: PermissionBusinessFunctions.DELETE_BUSINESS_FUNCTION,

};

const businessFunctionResource = {
    name: RESOURCE_NAME_BUSINESS_FUNCTIONS,
    list: BusinessFunctionList,
    create: BusinessFunctionCreate,
    edit: BusinessFunctionEdit,
    show: BusinessFunctionShow,
    icon: BusinessCenterIcon,    
    options: { label: 'resources.businessFunction.name', allowedPermissions: businessFunctionPermissions },
}


export * from './constants';
export { businessFunctionPermissions, businessFunctionResource }
