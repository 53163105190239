import { forwardRef, useMemo, useContext } from "react";
import { FieldTitle } from "react-admin";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Dialog from "@material-ui/core/Dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import IconClose from "@material-ui/icons/Close";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import Button from "@material-ui/core/Button";
import { useTranslate, useRefresh } from "react-admin";
import { ReactSortable } from "react-sortablejs";
import { makeStyles } from "@material-ui/core/styles";
import {
  CustomizableListActionTypes,
  CustomizableSelectionDialogProps,
  DatagridFieldProps,
} from "./types";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import { CustomizableListContext } from "./CustomizableListContext";

const useStyles = makeStyles((theme) => ({
  donotdrag: {
    color: "gray",
    cursor: "no-drop",
  },
  drag: {
    cursor: "drag",
  },
}));

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomizableSelectionDialog= ({
  //isOpen,
  selectionLabel,
  selectionText,
 // onClose,
}:CustomizableSelectionDialogProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const refresh = useRefresh();
 
  const selectionLabelString = translate(selectionLabel!);
  const selectionTextString = translate(selectionText!);

  const { state, dispatch } = useContext(CustomizableListContext);
  const resource = state?.resource;
  const columns = state?.columns;

  const CustomFormGroup = useMemo(
    () => forwardRef((props, ref) => <FormGroup ref={ref} {...props} />),
    []
  );

  const handleClose = (event) => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.SAVE_COLUMNS_STATE,
      });

      dispatch({
        type: CustomizableListActionTypes.CLOSE_DIALOG,
      });
    }
    refresh();
  };

  const handleColumnToggled = ({ target: { value: columnName } }) => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.COLUMN_TOGGLED,
        payload: { columnName },
      });
    }
  };

  const handleReset = (event) => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.RESET_ALL,
      });
    }
  };

  const setColumns = (columns: DatagridFieldProps[]) => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.SET_COLUMNS,
        payload: { columns },
      });
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      maxWidth="xs"
      aria-labelledby="ra-columns-dialog-title"
      onClose={handleClose}
      open={state?.isOpen || false}
    >
      <DialogTitle id="ra-columns-dialog-title">
        {selectionLabelString}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{selectionTextString}</DialogContentText>
        {columns && Array.isArray(columns) && (
          <ReactSortable
            tag={CustomFormGroup}
            list={columns}
            setList={setColumns}
            filter={`.${classes.donotdrag}`}
          >
            {columns.map((column) => {
              const source = column?.source;
              const label = column?.label;
              //const dataIsDisplayed = get(column, ["props", "dataisdisplayed"]);
              const dataIsDisplayed = column?.isDisplayed;
              const childClassName = !dataIsDisplayed
                ? classes.donotdrag
                : classes.drag;

              if (!source) {
                return null;
              }

              return (
                <FormControlLabel
                  key={source}
                  className={childClassName}
                  control={
                    <Checkbox
                      checked={dataIsDisplayed}
                      onChange={handleColumnToggled}
                      value={source}
                    />
                  }
                  label={
                    <FieldTitle
                      label={label}
                      source={source}
                      key={source}
                      resource={resource}
                    />
                  }
                />
              );
            })}
          </ReactSortable>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReset} color="primary" size="small">
          <RotateLeftIcon />
          Reset
        </Button>
        <Button onClick={handleClose} color="primary" size="small">
          <IconClose />
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomizableSelectionDialog.displayName = "CustomizableSelectionDialog";

CustomizableSelectionDialog.defaultProps = {
  selectionLabel: "components.lists.customizableList.selectionLabel",
  selectionText: "components.lists.customizableList.selectionText",
};
export { CustomizableSelectionDialog };
