import { required } from "react-admin";
import { isDateNotLessThanToday } from "../../../lib/components/validations/dateValidations";

const estimatedDeliveryDate = [
    required(),
    isDateNotLessThanToday()
];

export const validations = {
    estimatedDeliveryDate,
}