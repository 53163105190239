import { Box, createStyles, makeStyles } from "@material-ui/core";
import {
  Loading,
  ReferenceField,
  TextField,
  NumberField,
  useRecordContext,
  RichTextField,
  Labeled,
  ReferenceManyField,
  Datagrid,
  ArrayField,
  usePermissions,
} from "react-admin";
import {
  RESOURCE_NAME_ENQUIRY_DETAILS,
  RESOURCE_NAME_ENQUIRY_VENDORS,
} from "../constants";
import {
  AccordionField,
  DateCountdownField,
  SectionTitleSubtitle,
  TruncatedTextField,
  UploadedFilesField,
} from "../../../lib/components";
import { RESOURCE_NAME_ITEM } from "../../datasetup/items";
import { RESOURCE_NAME_UOM } from "../../datasetup/unit-of-measure";
import { RESOURCE_NAME_VENDOR } from "../../datasetup/vendors";
import { RequisitionSummaryContainer } from "./RequisitionSummaryContainer";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import { COMMON_DATE_TIME_FORMAT, hasAccess } from "../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { requisitionPermissions } from "../../requisitions";
import {
  ApprovalStatusField,
  isMatchingAccount,
  ProcureAccountField,
  UserNameEmailField,
  RecordStatusField,
} from "../../../common";
import { CommentsAccordion, OwnerEntityTypes } from "../../comments";
import { SingleItemShowContainer } from "../../datasetup/items/items";
import { SingleVendorShowContainer } from "../../datasetup/vendors/components";
import { DEFAULT_PER_PAGE } from "../../../lib/common";

const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      width: "100%",
      display: "grid",
      marginLeft: 15,
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 25,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    itemsGrid: {
      width: "90vw",
      whiteSpace: "nowrap",
      overflowX: "auto",
    },
  })
);

const EnquiryShowContainer = (props) => {
  const classes = useStyles();
  const record = useRecordContext();
  const { loading: permissionsLoading, permissions } = usePermissions();

  if (!record || permissionsLoading) return <Loading />;

  const isProcureAccount = isMatchingAccount(record?.procureAccountId);
  const hasRequisitionShowAccess = hasAccess(
    permissions,
    requisitionPermissions.show
  );

  return (
    <>
      {isProcureAccount && hasRequisitionShowAccess && (
        <RequisitionSummaryContainer />
      )}
      <SectionTitleSubtitle
        title="resources.enquiries.sections.enquiry.field.title"
        subTitle="resources.enquiries.sections.enquiry.field.subTitle"
      />
      <Box className={classes.layout}>
        <Labeled id="code" source="code">
          <TextField source="code" />
        </Labeled>

        <Labeled id="currencyId" source="currencyId">
          <ReferenceField
            label="resources.enquiries.fields.currencyId"
            source="currencyId"
            reference={RESOURCE_NAME_CURRENCIES}
            link="show"
            fullWidth={true}
          >
            <TextField source="name" />
          </ReferenceField>
        </Labeled>

        {isProcureAccount && (
          <RecordStatusField label="common.fields.status" useLabel={true} />
        )}

        {isProcureAccount && (
          <ApprovalStatusField
            label="common.fields.approvalStatus"
            useLabel={true}
          />
        )}

        {!isProcureAccount && (
          <ProcureAccountField
            label="resources.enquiries.fields.customerName"
            useLabel={true}
          />
        )}

        {!isProcureAccount && (
          <UserNameEmailField
            source="procureOwnerUserId"
            label="resources.enquiries.fields.customerContact"
            useLabel={true}
          />
        )}

        <Labeled
          id="bidStartDate"
          source="bidStartDate"
          label="resources.enquiries.fields.bidStartDate"
        >
          <DateCountdownField
            source="bidStartDate"
            options={COMMON_DATE_TIME_FORMAT}
          />
        </Labeled>

        <Labeled
          id="bidEndDate"
          source="bidEndDate"
          label="resources.enquiries.fields.bidEndDate"
        >
          <DateCountdownField
            source="bidEndDate"
            options={COMMON_DATE_TIME_FORMAT}
          />
        </Labeled>
        <Labeled
          id="preferredDeliveryDate"
          source="preferredDeliveryDate"
          label="resources.enquiries.fields.preferredDeliveryDate"
        >
          <DateCountdownField
            source="preferredDeliveryDate"
            options={COMMON_DATE_TIME_FORMAT}
          />
        </Labeled>

        {isProcureAccount && (
          <ProcureAccountField
            label="common.fields.procureAccountId"
            useLabel={true}
          />
        )}

        {isProcureAccount && (
          <UserNameEmailField
            source="procureOwnerUserId"
            label="common.fields.procureOwnerUserId"
            useLabel={true}
          />
        )}
      </Box>

      <SectionTitleSubtitle
        title="resources.enquiries.sections.items.field.title"
        subTitle="resources.enquiries.sections.items.field.subTitle"
      />
      <Box className={classes.itemsGrid}>
        <ReferenceManyField
          addLabel={false}
          reference={RESOURCE_NAME_ENQUIRY_DETAILS}
          target="enquiryId"
          perPage={DEFAULT_PER_PAGE}
          sort={{ field: "sortOrder", order: "ASC" }}
        >
          <Datagrid
            optimized
            expand={
              <SingleItemShowContainer
                procureAccountId={record?.procureAccountId}
              />
            }
            {...props}
          >
            <TextField
              source="sortOrder"
              label="resources.enquiries.fields.serialNum"
            />
            <ReferenceField
              label="resources.enquiries.fields.itemId"
              source="itemId"
              reference={RESOURCE_NAME_ITEM}
              link={false}
            >
              <TextField source="displayLabel" />
            </ReferenceField>
            <ReferenceField
              label="resources.enquiries.fields.number"
              source="itemId"
              reference={RESOURCE_NAME_ITEM}
              link={false}
            >
              <TruncatedTextField source="number" truncateLength={25} />
            </ReferenceField>
            <NumberField
              source="quantity"
              label="resources.enquiries.fields.quantity"
            />
            <ReferenceField
              label="resources.enquiries.fields.uomId"
              source="uomId"
              reference={RESOURCE_NAME_UOM}
              link={false}
            >
              <TextField source="displayLabel" />
            </ReferenceField>

            {record?.showUnitPrice2 && <NumberField source="quantity2" />}

            {record?.showUnitPrice2 && (
              <ReferenceField
                label="resources.enquiries.fields.uom2Id"
                source="uom2Id"
                reference={RESOURCE_NAME_UOM}
                link={false}
              >
                <TextField source="displayLabel" />
              </ReferenceField>
            )}
            <TruncatedTextField
              source="requisitionDetail.make"
              label="resources.enquiries.fields.make"
              truncateLength={25}
            />
            <TruncatedTextField
              source="requisitionDetail.size"
              label="resources.enquiries.fields.size"
              truncateLength={25}
            />
            <RichTextField
              source="requisitionDetail.specifications"
              label="resources.enquiries.fields.specifications"
            />
            <TruncatedTextField
              source="requisitionDetail.comments"
              label="resources.enquiries.fields.comments"
              truncateLength={25}
            />
          </Datagrid>
        </ReferenceManyField>
      </Box>
      {isProcureAccount && (
        <>
          <SectionTitleSubtitle
            title="resources.enquiries.sections.vendors.field.title"
            subTitle="resources.enquiries.sections.vendors.field.subTitle"
          />
          <ReferenceManyField
            addLabel={false}
            reference={RESOURCE_NAME_ENQUIRY_VENDORS}
            target="enquiryId"
            perPage={DEFAULT_PER_PAGE}
            sort={{ field: "id", order: "ASC" }}
          >
            <Datagrid
              optimized
              expand={<SingleVendorShowContainer />}
              {...props}
            >
              <ReferenceField
                label="resources.enquiries.fields.vendorAccountName"
                source="vendorAccountId"
                reference={RESOURCE_NAME_ACCOUNT}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceField
                label="resources.enquiries.fields.vendorPrimaryUserName"
                source="vendorId"
                reference={RESOURCE_NAME_VENDOR}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>

              <ReferenceField
                label="resources.enquiries.fields.vendorPrimaryUserEmail"
                source="vendorId"
                reference={RESOURCE_NAME_VENDOR}
                link="show"
              >
                <TextField source="vendorAccount.primaryUser.email" />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </>
      )}

      {record?.conditions && (
        <AccordionField
          headerlabel="resources.enquiries.sections.conditions.field.title"
          headerTooltip="resources.enquiries.sections.conditions.field.tooltip"
        >
          <Box>
            <SectionTitleSubtitle title="resources.enquiries.sections.conditions.field.heading" />

            <ArrayField source="conditions">
              <Datagrid optimized>
                <TextField source="conditionType" />
                <TextField source="condition" />
              </Datagrid>
            </ArrayField>
          </Box>
        </AccordionField>
      )}

      <Box py={2}>
        {record?.remarks && (
          <AccordionField
            headerlabel="resources.enquiries.sections.remarks.field.title"
            headerTooltip="resources.enquiries.sections.remarks.field.tooltip"
          >
            <RichTextField source="remarks" />
          </AccordionField>
        )}
        <AccordionField
          headerlabel="resources.enquiries.sections.fileUpload.input.title"
          headerTooltip="resources.enquiries.sections.fileUpload.input.tooltip"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <UploadedFilesField canDelete={false} />
          </Box>
        </AccordionField>
      </Box>

      <Box py={2}>
        <CommentsAccordion
          ownerEntityType={OwnerEntityTypes.ENQUIRY}
          ownerEntityId={record?.id}
          isViewOnly={false}
        />
      </Box>
    </>
  );
};

export { EnquiryShowContainer };
