import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  DateField,
  BooleanField,
  RichTextField,
} from "react-admin";
import { DefaultShow } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts/accounts/constants";

const SystemDataShow= (props:ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="type" />
        <TextField source="value" />
        <RichTextField source="description" />
        <TextField source="sortOrder" />
        <ArrayField label="Accounts" source={RESOURCE_NAME_ACCOUNT}>
          <Datagrid optimized>
            <TextField source="displayLabel" />
          </Datagrid>
        </ArrayField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { SystemDataShow };
