import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../components";
import { MediaFileSource, transformAndUploadS3Files } from "../../utilities";

import { AccountForm } from "./AccountForm";

const AccountEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "accounts",
    },
  ];
  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <AccountForm isEdit={true} />
    </DefaultEdit>
  );
};

export { AccountEdit };
