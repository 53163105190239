import { InputAdornment } from "@material-ui/core";
import { TextInput, useTranslate } from "react-admin";
import SearchIcon from '@material-ui/icons/Search';

const SearchFilter= (props:{ source?: string; alwaysOn?: boolean }) => {
  const { source = "q", alwaysOn = true, ...rest } = props;
  const translate = useTranslate();
  return (
    <TextInput
      resettable
      helperText={false}
      source={source}
      alwaysOn={alwaysOn}
      label={translate("ra.action.search")}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon color="disabled" />
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export { SearchFilter }