export const RESOURCE_NAME_REQUISITION = "requisitions";
export const RESOURCE_NAME_REQUISITION_DETAILS = "requisition/detail";

export enum PermissionRequisitions {
  REQUISITIONS_MANAGEMENT_MENU = "view-requisitions-management-menu",
  REQUISITION_MENU = "view-requisitions-menu",
  LIST_REQUISITIONS = "list-requisitions",
  SHOW_OWN_REQUISITION = "show-own-requisition",
  SHOW_ANY_REQUISITION = "show-any-requisition",
  EDIT_OWN_REQUISITION = "update-own-requisition",
  EDIT_ANY_REQUISITION = "update-any-requisition",
  DELETE_OWN_REQUISITION = "delete-own-requisition",
  DELETE_ANY_REQUISITION = "delete-any-requisition",
  CREATE_REQUISITION = "create-requisition",
  IMPORT_REQUISITIONS = "import-requisitions",
}
