import EqualizerIcon from "@material-ui/icons/Equalizer";
import { PermissionRequisitions, RESOURCE_NAME_REQUISITION } from "./constants";
import { RequisitionCreate } from "./RequisitionCreate";
import { RequisitionEdit } from "./RequisitionEdit";
import { RequisitionList } from "./RequisitionList";
import { RequisitionShow } from "./RequisitionShow";

const requisitionPermissions = {
  menu: PermissionRequisitions.REQUISITION_MENU,
  list: PermissionRequisitions.LIST_REQUISITIONS,
  create: PermissionRequisitions.CREATE_REQUISITION,
  update: [
    PermissionRequisitions.EDIT_OWN_REQUISITION,
    PermissionRequisitions.EDIT_ANY_REQUISITION,
  ],
  show: [
    PermissionRequisitions.SHOW_OWN_REQUISITION,
    PermissionRequisitions.SHOW_ANY_REQUISITION,
  ],
  delete: [
    PermissionRequisitions.DELETE_OWN_REQUISITION,
    PermissionRequisitions.DELETE_ANY_REQUISITION,
  ],
  import: PermissionRequisitions.IMPORT_REQUISITIONS,
};

const requisitionResource = {
  name: RESOURCE_NAME_REQUISITION,
  list: RequisitionList,
  create: RequisitionCreate,
  edit: RequisitionEdit,
  show: RequisitionShow,
  icon: EqualizerIcon,
  options: {
    label: "resources.requisitions.name",
    allowedPermissions: requisitionPermissions,
  },
};

export * from "./constants";
export { requisitionPermissions, requisitionResource };
