import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import BackButtonIcon from "@material-ui/icons/KeyboardBackspace";

const HistoryBackButton = () => {
  const history = useHistory();
  return (
    <Button variant="contained" onClick={() => history.goBack()}>
      <BackButtonIcon style={{ paddingRight: "5px", fontSize: "20px" }} />
      Back
    </Button>
  );
};

export { HistoryBackButton };
