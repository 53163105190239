import { FunctionComponent, ReactElement } from "react";
import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import classnames from 'classnames';
import DefaultEmptyStateIcon from "@material-ui/icons/LocalGasStation";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyStateContainer: {
      width: "50%",
      textAlign: "center",
      opacity: theme.palette.type === "light" ? 0.5 : 0.8,
      margin: "0 1em",
      color:
        theme.palette.type === "light" ? "inherit" : theme.palette.text.primary,
    },
    icon: {
      width: "4em",
      height: "4em",
    },
  })
);


interface EmptyStateProps {
  Icon?: FunctionComponent<any>;
  className?: string;
  iconClassName?: string;
  subTitle?: string | null;
  title?: string;
  actionButton?: ReactElement<any>;
}

const EmptyState= ({
  Icon,
  className,
  iconClassName,
  actionButton,
  title,
  subTitle,
}:EmptyStateProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Box className={classnames(className, classes.emptyStateContainer)}>
      {Icon ? (
        <Icon className={iconClassName ?? classes.icon} />
      ) : (
        <DefaultEmptyStateIcon className={iconClassName ?? classes.icon} />
      )}
      {title && (
        <Typography variant="h5" paragraph>
          {translate(title)}
        </Typography>
      )}
      {subTitle && (
        <Typography variant="body2" paragraph>
          {translate(subTitle)}
        </Typography>
      )}
      {actionButton && actionButton}
    </Box>
  );
};

EmptyState.defaultProps = {
  title: "components.ui.emptyState.title",
  subTitle: "components.ui.emptyState.subTitle",
};

export { EmptyState };
