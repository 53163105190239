import { useEffect } from "react";
import {
  setSidebarVisibility,
  ShowProps,
  TabbedShowLayout,
  Tab,
  useRecordContext,
  SimpleShowLayout,
} from "react-admin";
import { useDispatch } from "react-redux";
import { FILTER_DIRECTION, isMatchingAccount, PermissionBehavior } from "../../common";
import { DefaultShow } from "../../lib/components";
import { usePermissionsCheck } from "../../lib/core";
import { bidResource } from "../bids";
import { bidsComparisonResource } from "../bidsComparison";
import {
  EnquiryShowBids,
  EnquiryShowContainer,
  EnquiryShowBidEnquiry,
  EnquiryCrudShowActions,
} from "./components";

const EnquiryShowLayout = (props) => {
  const record = useRecordContext();
  const isProcureAccount = isMatchingAccount(record?.procureAccountId);
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const canViewBidComparison = hasPermissionAccess(
    bidsComparisonResource?.options?.allowedPermissions?.show
  );

  const canViewBids = hasPermissionAccess(
    bidResource?.options?.allowedPermissions?.show
  );

  if (isProcureAccount) {
    return (
      <TabbedShowLayout>
        <Tab label="resources.enquiries.fieldGroups.enquiry">
          <EnquiryShowContainer />
        </Tab>
        {canViewBids && (
          <Tab label="resources.enquiries.fieldGroups.bids">
            <EnquiryShowBids />
          </Tab>
        )}
        {canViewBidComparison && (
          <Tab label="resources.enquiries.fieldGroups.bidsComparison">
            <EnquiryShowBidEnquiry {...props} />
          </Tab>
        )}
      </TabbedShowLayout>
    );
  } else {
    return (
      <SimpleShowLayout>
        <EnquiryShowContainer />
      </SimpleShowLayout>
    );
  }
};

const EnquiryShow = (props: ShowProps) => {
  const dispatch = useDispatch();
  const { hasPermissionAccess} = usePermissionsCheck();
  const canProcure = hasPermissionAccess(PermissionBehavior.CAN_PROCURE);
  const direction = canProcure?FILTER_DIRECTION.SENT:FILTER_DIRECTION.RECEIVED;
  
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultShow {...props}
      actions={<EnquiryCrudShowActions/>}
      basePath={`/enquiries?filter={"direction":"${direction}"}`}
    >
      <EnquiryShowLayout {...props} />
    </DefaultShow>
  );
};

export { EnquiryShow };
