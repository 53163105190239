import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import { OrgStructureTypesForm } from "./OrgStructureTypesForm";

const OrgStructureTypesEdit = (props: EditProps) => {
  return (
    <DefaultEdit 
      {...props}
      mutationMode="pessimistic"
    >
      <OrgStructureTypesForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { OrgStructureTypesEdit };
