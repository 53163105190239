import { Filter, FilterProps } from "react-admin";
import { SearchFilter } from "../../../lib/common";

const ItemListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn={true} key="search" />
  </Filter>
);

const VendorListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn={true} key="search" />
  </Filter>
);

export { ItemListFilters, VendorListFilters };
