import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  EditProps,
  setSidebarVisibility,
  useNotify,
  useRefresh,
  useRedirect,
  useEditController,
  EditContextProvider,
} from "react-admin";
import {
  CrudActionBreadcrumb,
} from "../../lib/components";
import { Box } from "@material-ui/core";
import { enquiryResource, RESOURCE_NAME_ENQUIRY } from "../enquiries";
import { bidsComparisonResource } from ".";
import { BidsComparisonView } from "./components/BidsComparisonView";

const BidsComparisonEdit = (props: EditProps) => {
  const appStateDispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    appStateDispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  const onSuccess = (response?: any) => {
    notify("resources.bidsComparison.messages.edited", { type: "success" });
    redirect("edit", props.basePath);
    refresh();
  };

  const onFailure = (error?: any) => {
    if (error.code === 123) {
      notify("Could not save changes: concurrent edition in progress", {
        type: "error",
      });
    } else {
      notify("ra.notification.http_error", { type: "error" });
    }
  };

  const controllerProps = useEditController({
    ...props,
    onSuccess,
    onFailure,
    mutationMode: "pessimistic",
  });

  const { loaded, record } = controllerProps;

  const {excludedItemIds, excludedVendorAccountIds} = useMemo(() => {
    const excludedItemIds = record?.excluded?.items?.map((item) => item?.id);
    const excludedVendorAccountIds = record?.excluded?.vendorAccounts?.map((vendorAccount) => vendorAccount?.id);
    return { excludedItemIds, excludedVendorAccountIds}
  }, [record?.excluded?.items, record?.excluded?.vendorAccounts])
  return (
    <Box>
      <Box pt={1}>
        <CrudActionBreadcrumb
          resourceLabel="resources.enquiries.name"
          listPath={`/${RESOURCE_NAME_ENQUIRY}?filter={"direction":"sent"}`}
          Icon={enquiryResource.icon}
          actionType="resources.bidsComparison.title"
          actionIcon={bidsComparisonResource.icon}
        />
        <EditContextProvider value={controllerProps}>
          <BidsComparisonView
            id={props?.id}
            loaded={loaded}
            isReadOnly={false}
            excludedItemIds={excludedItemIds}
            excludedVendorAccountIds={excludedVendorAccountIds}
            priceComponents={record?.priceComponents}
          />
        </EditContextProvider>
      </Box>
    </Box>
  );
};

export { BidsComparisonEdit };
