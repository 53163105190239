import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ArrayField,
  Datagrid,
  DateField,
  Labeled,
  Loading,
  NumberField,
  ReferenceField,
  RichTextField,
  setSidebarVisibility,
  TextField,
  useRecordContext,
} from "react-admin";
import { EnquirySummaryContainer } from "./EnquirySummaryContainer";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import {
  AccordionField,
  SectionTitleSubtitle,
  UploadedFilesField,
  DateCountdownField,
  TruncatedTextField,
} from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { CommentsAccordion, OwnerEntityTypes } from "../../comments";
import {
  CustomerNameField,
  VendorNameField,
  AmountField,
  ApprovalStatusField,
  isMatchingAccount,
  RecordStatusField,
  QuantityField,
} from "../../../common";
import { RESOURCE_NAME_ITEM } from "../../datasetup/items/items/constants";
import { RESOURCE_NAME_UOM } from "../../datasetup/unit-of-measure/constants";
import { SingleItemShowContainer } from "../../datasetup/items";
const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      width: "100%",
      display: "grid",
      marginLeft: 15,
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 25,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    itemsGrid: {
      width: "95vw",
      whiteSpace: "nowrap",
      overflowX: "auto",
    },
    remarksAccordion: {
      marginTop: 25,
    },
    amount:{
      backgroundColor: '#6bd280',
      textAlign: 'center',
    },
    totalContainer: {
      paddingLeft: 8,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
    },
    totalAmountLabel: {
      fontWeight: 500,
      alignSelf: "center",
      paddingRight: 25,
      fontStyle: "italic",
      [theme.breakpoints.down("sm")]: {
        justifySelf: "start",
        paddingBottom: 0,
      },
    },
    amountsContainer: {
      backgroundColor: '#6bd280',
      "& label": {
        fontWeight: 600,
        fontStyle: "italic",
        transformOrigin: "top",
      },
      "& div": {
        textAlign: "center",
      },
      "& div span": {
        fontWeight: 600,
        fontStyle: "italic",
      },
    },
  })
);

const BidShowContent = (props) => {
  const record = useRecordContext(props);
  const classes = useStyles();
  const dispatch = useDispatch();
  const isProcureAccount = isMatchingAccount(record?.procureAccountId);
  const formClasses = useStyles(props);
  const showUnitPrice2 = record?.showUnitPrice2;
  const linkType = isProcureAccount ? "show" : false;
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  if (!record) return <Loading />;

  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.bids.sections.bid.field.title"
        subTitle="resources.bids.sections.bid.field.subTitle"
      />
      {props?.showEnquiryDetail && <EnquirySummaryContainer />}
      <Box className={classes.layout}>
        {!isProcureAccount && <CustomerNameField />}

        {isProcureAccount && <VendorNameField />}

        {isProcureAccount && (
          <RecordStatusField label="common.fields.status" useLabel={true} />
        )}

        {isProcureAccount && (
          <ApprovalStatusField
            label="common.fields.approvalStatus"
            useLabel={true}
          />
        )}

        <Labeled
          id="estimatedDeliveryDate"
          source="estimatedDeliveryDate"
          label="resources.bids.fields.estimatedDeliveryDate"
        >
          <DateCountdownField
            source="estimatedDeliveryDate"
            options={COMMON_DATE_TIME_FORMAT}
          />
        </Labeled>
        <AmountField
          amountSource="totalAmount"
          currencySoure="currencyId"
          label="resources.bids.fields.totalAmount"
          useLabel={true}
        />

        {showUnitPrice2 && (
          <AmountField
            amountSource="totalAmount2"
            currencySoure="currencyId"
            label="resources.bids.fields.totalAmount2"
            useLabel={true}
          />
        )}

        <Labeled
          id="createdAt"
          source="createdAt"
          label="resources.bids.fields.createdAt"
        >
          <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        </Labeled>

        <Labeled
          id="updatedAt"
          source="updatedAt"
          label="resources.bids.fields.updatedAt"
        >
          <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        </Labeled>
      </Box>
      <Box>
        <SectionTitleSubtitle
          title="resources.bids.sections.items.field.title"
          subTitle="resources.bids.sections.items.field.subTitle"
        />
        <Box className={classes.itemsGrid}>
          <ArrayField source="bidDetails" fullWidth>
            <Datagrid
              optimized
              expand={
                <SingleItemShowContainer
                  procureAccountId={record?.procureAccountId}
                  {...props}
                />
              }
            >
              <TextField
                source="sortOrder"
                label="resources.bids.fields.serialNum"
              />
              <ReferenceField
                source="itemId"
                label="resources.bids.fields.itemId"
                reference={RESOURCE_NAME_ITEM}
                linkType={linkType}
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <QuantityField
                source="quantity"
                label="common.fields.quantity"
                useLabel={false}
              />
              <ReferenceField
                source="currencyId"
                reference={RESOURCE_NAME_CURRENCIES}
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField source="unitPrice" cellClassName={classes.amount}/>
              <NumberField source="amount" />

              {showUnitPrice2 && (
                <QuantityField
                  source="quantity2"
                  uomSource="uom2Id"
                  label="common.fields.quantity2"
                  useLabel={false}
                />
              )}
              {showUnitPrice2 && <NumberField source="unitPrice2" cellClassName={classes.amount}/>}
              {showUnitPrice2 && <NumberField source="amount2" />}
              <TruncatedTextField
                source="requisitionDetail.make"
                label="resources.bids.fields.make"
                truncateLength={50}
              />
              <TruncatedTextField
                source="requisitionDetail.size"
                label="resources.bids.fields.size"
                truncateLength={25}
              />
              <RichTextField
                source="requisitionDetail.specifications"
                label="resources.bids.fields.specifications"
              />
              <TruncatedTextField
                source="requisitionDetail.comments"
                label="resources.bids.fields.comments"
                truncateLength={100}
              />
            </Datagrid>
          </ArrayField>
          <Box className={formClasses.totalContainer}>
            <Typography className={formClasses.totalAmountLabel}>
              Totals
            </Typography>
            <Labeled
              id="totalAmount"
              source="totalAmount"
              label="resources.bids.fields.totalAmount"
              className={formClasses.amountsContainer}
            >
              <NumberField
                source="totalAmount"
                label="resources.bids.fields.totalAmount"
              />
            </Labeled>

            {showUnitPrice2 && (
              <Labeled
                id="totalAmount2"
                source="totalAmount2"
                label="resources.bids.fields.totalAmount2"
                className={formClasses.amountsContainer}
              >
                <NumberField
                  source="totalAmount2"
                  label="resources.bids.fields.totalAmount2"
                />
              </Labeled>
            )}
          </Box>
        </Box>
      </Box>
      {record?.conditions && (
        <Box>
          <AccordionField
            headerlabel="resources.bids.sections.conditions.field.title"
            headerTooltip="resources.bids.sections.conditions.field.tooltip"
          >
            <Box>
              <SectionTitleSubtitle title="resources.bids.sections.conditions.field.heading" />

              <ArrayField source="conditions">
                <Datagrid optimized>
                  <TextField source="conditionType" />
                  <TextField source="condition" />
                </Datagrid>
              </ArrayField>
            </Box>
          </AccordionField>
        </Box>
      )}
      {record?.remarks && (
        <Box py={2}>
          <AccordionField
            headerlabel="resources.bids.sections.remarks.field.title"
            headerTooltip="resources.bids.sections.remarks.field.tooltip"
            className={classes.remarksAccordion}
          >
            <RichTextField source="remarks" />
          </AccordionField>
        </Box>
      )}
      <Box py={2}>
        <AccordionField
          headerlabel="resources.bids.sections.fileUpload.show.title"
          headerTooltip="resources.bids.sections.fileUpload.show.tooltip"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <UploadedFilesField canDelete={false} />
          </Box>
        </AccordionField>
      </Box>

      <Box py={2}>
        <CommentsAccordion
          ownerEntityType={OwnerEntityTypes.BID}
          ownerEntityId={record?.id}
          isViewOnly={false}
        />
      </Box>
    </Box>
  );
};

BidShowContent.defaultProps = {
  showEnquiryDetail: true,
};

export { BidShowContent };
