import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { BidList } from './BidList';
import { BidCreate } from './BidCreate';
import { PermissionBids, RESOURCE_NAME_BID } from "./constants";
import { BidShow } from './BidShow';
import { BidEdit } from './BidEdit';

const bidsPermissions = {
    menu: PermissionBids.MENU_BIDS,
    list: PermissionBids.LIST_BIDS,
    create: PermissionBids.CREATE_BID,
    update: [
        PermissionBids.EDIT_ANY_BID,
        PermissionBids.EDIT_OWN_BID,
    ],
    show: [
        PermissionBids.SHOW_ANY_BID,
        PermissionBids.SHOW_OWN_BID,
    ],
    delete: [
        PermissionBids.DELETE_ANY_BID,
        PermissionBids.DELETE_OWN_BID,
    ],
    import:PermissionBids.IMPORT_BIDS
};

const bidResource = {
    name: RESOURCE_NAME_BID,
    list: BidList,
    create: BidCreate,
    edit: BidEdit,
    show: BidShow,
    icon: LocalAtmIcon,
    options: {label: 'resources.bids.name', allowedPermissions: bidsPermissions },
}

export * from './constants';
export * from './components';
export { bidsPermissions, bidResource }
