import { Theme, useMediaQuery } from "@material-ui/core";
import {
  AutocompleteArrayInput,
  Filter,
  FilterProps,
  ReferenceArrayInput,
  SelectArrayInput,
  useListContext,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { RESOURCE_NAME_ENQUIRY } from "../../enquiries";
import {
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../lib/common";
import { DateInput } from "../../../lib/components";
import {
  BusinessFunctionsListFilter,
  FILTER_DIRECTION,
  ItemsListFilter,
  PROCURE_APPROVAL_STATUS_CHOICES,
  PROC_ENTITY_RECORD_STATUS_CHOICES,
} from "../../../common";

const BidListFilters = (props: Omit<FilterProps, "children">) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { filterValues } = useListContext(props);
  const isDirectionReceived =
    filterValues["direction"] === FILTER_DIRECTION.RECEIVED;

  return (
    <Filter {...props}>
      {isSmall && <SearchFilter alwaysOn key="Search" />}
      <IdFilter
        label="components.controls.filters.idFilter.label"
        source="id"
        key="id"
      />
      {isSmall && isDirectionReceived && (
        <SelectArrayInput
          source="procureStatus"
          choices={PROC_ENTITY_RECORD_STATUS_CHOICES}
          allowEmpty={false}
          resettable={true}
          key="procureStatus"
        />
      )}
      {isSmall && (
        <DateInput source="updated_since_gte" key="updated_since_gte" />
      )}
      {isSmall && isDirectionReceived && (
        <SelectArrayInput
          source="procureApprovalStatus"
          label="common.fields.approvalStatus"
          choices={PROCURE_APPROVAL_STATUS_CHOICES}
          allowEmpty={false}
          resettable={true}
          key="procureApprovalStatus"
        />
      )}

      <ReferenceArrayInput
        source="enquiryId"
        reference={RESOURCE_NAME_ENQUIRY}
        key="enquiryId"
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "code", order: "ASC" }}
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          allowEmpty={false}
          resettable={true}
        />
      </ReferenceArrayInput>

      {isDirectionReceived && <BusinessFunctionsListFilter source="businessFunctionId"/> }
      {isDirectionReceived && <ItemsListFilter source="itemId"/> }
      
      {/* {!isDirectionReceived && (
        <ReferenceArrayInput
          source="procureAccountId"
          label = "common.fields.customerName"
          reference={RESOURCE_NAME_ACCOUNT}
          key="procureAccountId"
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          link={false}
        >
          <AutocompleteArrayInput
            optionText="name"
            allowEmpty={false}
            resettable={true}
          />
        </ReferenceArrayInput>
      )} */}
      {isDirectionReceived && (
        <ReferenceArrayInput
          source="vendorAccountId"
          label = "common.fields.vendorName"
          reference={RESOURCE_NAME_ACCOUNT}
          key="vendorAccountId"
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            allowEmpty={false}
            resettable={true}
          />
        </ReferenceArrayInput>
      )}
    </Filter>
  );
};

export { BidListFilters };
