import { Route } from "react-router-dom";
import { ForgotPasswordPage, ResetPasswordPage } from "./lib/core/auth";
import { ProfileShow, ProfileEdit, UserPreferences } from "./lib/modules/profile";

const routes = [
  <Route
    exact
    path="/auth/forgot-password"
    component={ForgotPasswordPage}
    noLayout
    key="forgotpassword"
  />,
  <Route
    exact
    path="/auth/reset-password/:token"
    component={ResetPasswordPage}
    noLayout
    key="resetpassword"
  />,
  <Route exact path="/my-profile" render={() => <ProfileShow />} />,
  <Route path="/my-profile/edit" render={() => <ProfileEdit />} />,
  <Route
    exact
    path="/my-profile/userpreferences"
    render={() => <UserPreferences />}
  />,
];

export { routes };
