import { usePermissions } from "react-admin";
import { hasAccess } from "../../core/helpers";
import { GatedSubMenuProps } from "./menu.interface";
import { SubMenu } from "./SubMenu";

const GatedSubMenu= ({
  name,
  icon,
  isSidebarOpen,
  dense,
  allowedPermissionName,
  children,
}:GatedSubMenuProps) => {
  const { loaded, permissions } = usePermissions();

  if (loaded && hasAccess(permissions, allowedPermissionName)) {
    return (
      <SubMenu
        isSidebarOpen={isSidebarOpen}
        name={name}
        icon={icon}
        dense={dense}
      >
        {children}
      </SubMenu>
    );
  } else {
    return null
  }
};

export { GatedSubMenu };
