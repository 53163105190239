import {
  TextInput,
  required,
  ReferenceInput,
  useTranslate,
  SimpleForm,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { RESOURCE_NAME_ITEM_GROUPS } from "./constants";
import { Typography } from "@material-ui/core";
import { MediaInputField } from "../../../../lib/components";
import { MAX_RECORDS_PER_PAGE } from "../../../../lib/common";
import { RESOURCE_NAME_PURCHASE_GROUPS } from "../../purchase-groups";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  number: { display: "block" },
  description: { display: "block" },
  parent: { display: "block" },
  class: { display: "block" },
};

const useStyles = makeStyles(styles);

const ItemGroupForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm {...rest} warnWhenUnsavedChanges>
      <SectionTitle label="resources.itemGroups.fieldGroups.itemGroupDetails" />
      <TextInput
        autoFocus
        source="number"
        label="resources.itemGroups.fields.number"
        helperText="resources.itemGroups.help.number"
        formClassName={classes.number}
        validate={[required()]}
        fullWidth
      />
      <TextInput
        source="name"
        label="resources.itemGroups.fields.name"
        helperText="resources.itemGroups.help.name"
        formClassName={classes.name}
        validate={[required()]}
        fullWidth
      />
      <RichTextInput
        source="description"
        label="resources.itemGroups.fields.description"
        helperText="resources.itemGroups.help.description"
        formClassName={classes.description}
      />
      <ReferenceInput
        source="parentId"
        label="resources.itemGroups.fields.parentId"
        reference={RESOURCE_NAME_ITEM_GROUPS}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
        formClassName={classes.parent}
        key="parentId"
      >
        <AutocompleteInput
          optionText="displayLabel"
          resettable={true}
          allowEmpty={false}
          helperText="resources.itemGroups.help.parentId"
        />
      </ReferenceInput>

      <ReferenceArrayInput
        source="purchaseGroupIds"
        label="resources.itemGroups.fields.purchaseGroupIds"
        reference={RESOURCE_NAME_PURCHASE_GROUPS}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        fullWidth
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          helperText="resources.itemGroups.help.purchaseGroupIds"
        />
      </ReferenceArrayInput>

      <MediaInputField showUploadedFiles={isEdit} />
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { ItemGroupForm };
