import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarVisibility,
  EditProps,
  SimpleForm,
  useGetList,
  Record,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { DefaultEdit } from "../../lib/components";
import { RequisitionForm } from "./RequisitionForm";
import { MediaFileSource, transformAndUploadS3Files } from "../../lib/modules";
import { RESOURCE_NAME_REQUISITION_DETAILS } from "./constants";
import { MAX_RECORDS_PER_PAGE } from "../../lib/common";
import { ORG_STRUCTURE_USER_GROUP_TYPE } from "../datasetup/org-structure";

const RequisitionEdit = (props: EditProps) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { data: allRequisitionDetails, ids: allRequisitionDetailIds } =
    useGetList(
      RESOURCE_NAME_REQUISITION_DETAILS,
      { page: 1, perPage: MAX_RECORDS_PER_PAGE },
      { field: "sortOrder", order: "ASC" },
      {
        requisitionId: props?.id,
        orgStructureType: ORG_STRUCTURE_USER_GROUP_TYPE.REQUISITIONS,
      }
    );

  const onSuccess = () => {
    notify("resources.requisitions.messages.edited", { type: "success" });
    refresh(true);
    redirect(`/requisitions`);
  };

  const onFailure = (error) => {
    if (error.code === 123) {
      notify("Could not save changes: concurrent edition in progress", {
        type: "error",
      });
    } else {
      const errorMessage = `An error occured and your changes were not saved. \n ${
        error?.message || "ra.notification.http_error"
      }`;
      notify(errorMessage, { type: "error", multiLine: true });
    }
    //redirect("list", props.basePath);
  };

  const { requisitionDetails, savedItemIds } = useMemo(() => {
    const requisitionDetails: Array<Record> = [];
    const savedItemIds: Array<string> = [];

    for (let index = 0; index < allRequisitionDetailIds.length; index++) {
      const requisitionDetailId = allRequisitionDetailIds[index];
      const requisition = allRequisitionDetails[requisitionDetailId];
      const itemId: string = requisition?.itemId;
      if (itemId) {
        savedItemIds.push(itemId);
      }
      requisitionDetails.push(requisition);
    }
    return { requisitionDetails, savedItemIds };
  }, [allRequisitionDetailIds, allRequisitionDetails]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "requisitions",
    },
  ];
  return (
    <DefaultEdit
    {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onFailure}
    >
      <SimpleForm
        warnWhenUnsavedChanges
        initialValues={{ requisitionDetails: requisitionDetails }}
      >
        <RequisitionForm {...props} isEdit={true} savedItemIds={savedItemIds}/>
      </SimpleForm>
    </DefaultEdit>
  );
};

export { RequisitionEdit };
