import { required, minLength, maxLength } from 'react-admin';

const validateFirstNameMessage =
  'resources.User.validations.firstName.required';
const validateLastNameMessage = 'resources.User.validations.lastName.required';

const validateFirstName = [
  required(validateFirstNameMessage),
  minLength(2),
  maxLength(25)
];
const validateLastName = [
  required(validateLastNameMessage),
  minLength(2),
  maxLength(25)
];

const validatePassword = [
  minLength(6),
];

const matchPassword = (value, allValues) =>
  (value || allValues.password) && value !== allValues.password
    ? `Please confirm your password`
    : undefined;

const validate = {
  validateFirstName,
  validateLastName,
  validatePassword,
  matchPassword
};

export default validate;
