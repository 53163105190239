import {
  TextField,
  ReferenceField,
  DateField,
  RichTextField,
  TabbedForm,
  FormTab,
  SelectField,
  NumberField,
} from "react-admin";
import { isMatchingAccount } from "../../../../../common";
import { SectionTitleSubtitle, UploadedFilesField } from "../../../../../lib/components";
import { RESOURCE_NAME_CATEGORY } from "../../../category";
import { RESOURCE_NAME_LOCATION } from "../../../locations";
import { RESOURCE_NAME_UOM } from "../../../unit-of-measure";
import { RESOURCE_NAME_ITEM_GROUPS } from "../../itemsGroup";
import { RESOURCE_NAME_ITEM_SEGMENTS } from "../../itemsSegment";
import { ABC_INDICATOR_CHOICES, RESOURCE_NAME_ITEM } from "../constants";
import { useFormStyles } from "../styles";

const ItemShowContainer = (props) => {
  const classes = useFormStyles();
  const shouldSyncWithLocation = props?.shouldNotSyncWithLocation ? false : true;

  const procureAccountId = props?.procureAccountId;
  const isProcureAccount = procureAccountId ? isMatchingAccount(props?.procureAccountId) : procureAccountId ?? true;
  const linkType = isProcureAccount ? 'show' : false;

  return (
    <TabbedForm toolbar={<div />} syncWithLocation={shouldSyncWithLocation}>
      <FormTab
        label="resources.items.tabs.general"
        contentClassName={classes.generalTab}
      >
        <SectionTitleSubtitle
          title="resources.items.fieldGroups.itemDetails"
          formClassName="ra-input-itemDetailsLabel"
        />
        <TextField source="number" />

        <TextField source="name" />
        <RichTextField source="description" />
        <ReferenceField source="parentId" reference={RESOURCE_NAME_ITEM} linkType={linkType}>
          <TextField source="displayLabel" />
        </ReferenceField>
        {isProcureAccount && (
          <ReferenceField
            source="itemSegmentId"
            reference={RESOURCE_NAME_ITEM_SEGMENTS}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
        )}

        {isProcureAccount && (
          <ReferenceField
            source="itemCategoryId"
            reference={RESOURCE_NAME_CATEGORY}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
        )}
        {isProcureAccount && (<ReferenceField
          source="itemGroupId"
          reference={RESOURCE_NAME_ITEM_GROUPS}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        )}
        <ReferenceField source="uomId"
          reference={RESOURCE_NAME_UOM}
          linkType={linkType}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <TextField source="size" />
        <RichTextField source="specifications" />

        <NumberField source="weight" />

        <ReferenceField
          source="weightUomId"
          reference={RESOURCE_NAME_UOM}
          linkType={linkType}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <NumberField source="volume" />
        <ReferenceField
          source="volumeUomId"
          reference={RESOURCE_NAME_UOM}
          linkType={linkType}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
      </FormTab>
      {isProcureAccount && (
        <FormTab label="resources.items.tabs.procurement"
          contentClassName={classes.procurementTab}>
          <SectionTitleSubtitle
            title="resources.items.fieldGroups.procurementDetails"
            formClassName="ra-input-procurementDetailsLabel"
          />
          <NumberField source="leadTimeInDays" />
          <ReferenceField
            source="storageLocationId"
            reference={RESOURCE_NAME_LOCATION}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
          <SelectField
            source="abcIndicator"
            choices={ABC_INDICATOR_CHOICES}
          />
          <NumberField source="moq" />
          <ReferenceField source="moqUomId" reference={RESOURCE_NAME_UOM}>
            <TextField source="displayLabel" />
          </ReferenceField>

          <NumberField source="safetyStock" />
          <ReferenceField
            source="safetyStockUomId"
            reference={RESOURCE_NAME_UOM}
          >
            <TextField source="displayLabel" />
          </ReferenceField>

          <TextField source="description" />
          <TextField source="leadTimeInDays" />

          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <TextField source="version" />
        </FormTab>

      )}
      <FormTab label="resources.items.tabs.attachments">
        <UploadedFilesField canDelete={false} />
      </FormTab>

    </TabbedForm>
  )
}

export { ItemShowContainer }