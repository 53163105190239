import MoneyIcon from '@material-ui/icons/Money';
import { PermissionCurrencies, RESOURCE_NAME_CURRENCIES } from './constants';
import { CurrencyCreate } from './CurrencyCreate';
import { CurrencyEdit } from './CurrencyEdit';
import { CurrencyList } from './CurrencyList';
import { CurrencyShow } from './CurrencyShow';

const allowedPermissions = {
    menu: PermissionCurrencies.CURRENCIES_MENU,
    list: PermissionCurrencies.LIST_CURRENCIES,
    create: PermissionCurrencies.CREATE_CURRENCY,
    update: PermissionCurrencies.EDIT_CURRENCY,
    show: PermissionCurrencies.SHOW_CURRENCY,
    delete: PermissionCurrencies.DELETE_CURRENCY,
};

const currencyResource = {
    name: RESOURCE_NAME_CURRENCIES,
    list: CurrencyList,
    create: CurrencyCreate,
    edit: CurrencyEdit,
    show: CurrencyShow, 
    icon: MoneyIcon,
    options: { label: 'resources.currencies.name', allowedPermissions },
}

export * from './constants';
export { currencyResource }