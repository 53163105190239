import { memo } from "react";
import {
  Accordion,
  AccordionSummary,
  Tooltip,
  AccordionDetails,
  Typography,
  withStyles,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { TextFieldProps, useTranslate } from "react-admin";

const StyledAccordionSummary = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[300],
  },
}))(AccordionSummary);

export interface AccordionFieldProps extends TextFieldProps {
  headerTooltip: string;
  headerlabel: string;
  detailsClassName?: string;
  defaultExpanded?: boolean;
}
const AccordionField = memo<AccordionFieldProps>(
  (props) => {
    const {
      headerTooltip,
      headerlabel,
      defaultExpanded = true,
      className,
      detailsClassName,
      children,
    } = props;
    const translate = useTranslate();

    return (
      <Tooltip title={translate(headerTooltip)}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          defaultExpanded={defaultExpanded}
          className={className}
        >
          <StyledAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            aria-controls="additional-actions1-content"
          >
            <Typography component="span" variant="body2">
              {translate(headerlabel)}
            </Typography>
          </StyledAccordionSummary>
          <AccordionDetails className={detailsClassName}>
            {children}
          </AccordionDetails>
        </Accordion>
      </Tooltip>
    );
  }
);

AccordionField.displayName = "AccordionField";


export { AccordionField };
