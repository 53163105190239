import { IUser } from "../../modules/accounts";

export class LocalUser {
  protected localUser: IUser | undefined;

  constructor() {
    this.localUser = this.readLocalUser();
  }

  public getUserId = () => {
    return this.localUser?.id;
  };

  public getLocalUser = (): IUser | undefined => {
    return this.localUser;
  };

  public readLocalUser = (): IUser | undefined => {
    let user: IUser | undefined = undefined;
    const localUserStr = localStorage.getItem("user");
    if (localUserStr) {
      user = JSON.parse(localUserStr);
    }
    return user;
  };

  public hasDarkTheme = (): boolean => {
    if (this.localUser?.settings?.theme === "dark") {
      return true;
    }
    return false;
  };

  public changeLocalTheme = (isDarkTheme: boolean) => {
    let localUser = this.getLocalUser();

    const themeName = isDarkTheme ? "dark" : "light";

    if (localUser && localUser.settings) {
      localUser.settings.theme = themeName;
      this.localUser = localUser;
      this.saveLocalUser();
    }
  };

  public saveName = (firstName: string, lastName: string) => {
    let localUser = this.getLocalUser();
    if (localUser) {
      localUser.firstName = firstName;
      localUser.lastName = lastName;
      this.localUser = localUser;
      this.saveLocalUser();
    }
  };

  public saveLocalUser = () => {
    const user = this.localUser;
    const localUserStr = JSON.stringify(user);
    localStorage.setItem("user", localUserStr);
    this.localUser = this.readLocalUser();
  };
}
