import {
  ShowProps,
  SimpleShowLayout,
} from "react-admin";
import { DefaultShow } from "../../../../lib/components";
import { ItemShowContainer } from './components/ItemShowContainer';

const ItemShow= (props:ShowProps) => {

  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <ItemShowContainer/>
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { ItemShow };
