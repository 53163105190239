import { Children, cloneElement, isValidElement, ReactNode } from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import get from "lodash/get";

import { LocalStorage, CUSTOMIZABLE_LIST_STORAGE_KEY } from "./LocalStorage";
import { CustomizableListProps, CustomizableListState, DatagridFieldProps, DatagridStorage } from "./types";
import { DefaultCrudListRowActions } from "../listActions/actions/DefaultCrudListRowActions";

const prepareColumnKey = (columnKey, isDisplayed = 1) => {
  return `${columnKey}-${isDisplayed}`;
}

const arrayToSelection = (defaultColumns?: string[]) => defaultColumns?.map( (column) => `${column}-1`);

const getColumnNames = (children) => {
  return filter(
    Children.map(children, (field) => get(field, ["props", "source"]))
  );
};

export const getInitialCustomizableListSelection = (
  state: CustomizableListState,
  children: ReactNode | ReactNode[],
) => {
  const resource = state?.resource;
  const defaultColumns = state?.defaultColumns;
  const storage = state?.storage;

  if (resource && storage) {
    const previousSelection = storage.get(CUSTOMIZABLE_LIST_STORAGE_KEY, resource);

    if (!isEmpty(previousSelection)) {
      // if we have a previously stored value, let's return it
      return previousSelection;
    } else if (!isEmpty(defaultColumns)) {
      // if defaultColumns are set let's return them
      return arrayToSelection(defaultColumns);
    } else {
      // otherwise we fallback on the default behaviour : display all columns
      return arrayToSelection(getColumnNames(children));
    }
  }
};

export const recalculateFields = (targetSelection, fieldsList, sortChildren = true) => {
  let sortedNodeChildren: any[] = [];
  const childrenArr = Children.toArray(fieldsList);

   childrenArr.forEach( (child) => {
     const childKey = get(child, ["props", "source"])
     const displayedColumnKey = prepareColumnKey(childKey,1);
     const hiddenColumnKey = prepareColumnKey(childKey,0);
     const oldDataColumnPosition = get(child, ["props", "datacolumnposition"]);
     let shouldColumnBeDisplayed = 0;
     let columnPosition = -1;

     const displayedColumnPosition = targetSelection?.lastIndexOf(displayedColumnKey);
     const hiddenColumnPosition = targetSelection?.lastIndexOf(hiddenColumnKey);

     if(displayedColumnPosition >= 0){
       //This means that the column should be displayed
       shouldColumnBeDisplayed = 1;
       columnPosition = displayedColumnPosition;
     }else if(hiddenColumnPosition >= 0){
       columnPosition = hiddenColumnPosition;
     }

     if(!sortChildren && oldDataColumnPosition >= 0){
       columnPosition = oldDataColumnPosition;
     }

     const extraAttributes = {
       datacolumnposition: columnPosition,
       dataisdisplayed: shouldColumnBeDisplayed
     }

     if (isValidElement(child)) {
         const modifiedElement = cloneElement(child, extraAttributes);

         sortedNodeChildren.push(modifiedElement);
     }
 });

 if(sortChildren && sortedNodeChildren.length > 0){
   sortedNodeChildren.sort( (a,b) => {
     const firstDataColumnPosition = get(a, ["props", "datacolumnposition"]);
     const secondDataColumnPosition = get(b, ["props", "datacolumnposition"]);

     const firstSource = get(a, ["props", "source"]);
     const secondSource = get(b, ["props", "source"]);

     if(!firstSource){
       return 0;
     }

     if(!secondSource){
       return 0;
     }

     if(firstDataColumnPosition > secondDataColumnPosition){
       return 1;
     }

     if(secondDataColumnPosition > firstDataColumnPosition){
       return -1;
     }

     return 0;
   })
 }

 return sortedNodeChildren;
};

export const prepareCustomizableListContextInitialState = (
  props: CustomizableListProps
) => {

  const resource = props?.resource;
  const defaultColumns = props?.defaultColumns;
  const storage = props?.datagridStorage || LocalStorage;

  return {
    isOpen: false,
    resource,
    defaultColumns,
    storage
  };
};

export const prepareColumns = (fieldsList: any[]) => {
  const childrenArr = Children.toArray(fieldsList);
  const columnsList: DatagridFieldProps[] = [];
  childrenArr.forEach( (child) => {
    const source = get(child, ["props", "source"]);
    if(source){
      const label = get(child, ["props", "label"]);
      const isDisplayed = get(child, ["props", "dataisdisplayed"]);
      const column = {
        id: source,
        label: label,
        source: source,
        isDisplayed
      }

      columnsList.push(column);
    }
  });

  return columnsList;
}

const toggleColumnDisplay = (columnName:string, isColumnDisplayed: boolean, columns:DatagridFieldProps[] | undefined ) => {
  columns?.every(column => {
    if(column.source === columnName){
      column.isDisplayed = isColumnDisplayed;
      return false;
    }
    return true;
  });
}

export const handleToggleColumn = (columnName, state: CustomizableListState) => {

  const resource = state?.resource;
  const storage = state?.storage;
  const selection = state?.selection;
  const columns = state?.columns;
  let isColumnDisplayed = true;

  const displayedColumnKey = prepareColumnKey(columnName,1);
  const hiddenColumnKey = prepareColumnKey(columnName,0);

  let toggledSelection = selection?[...selection]:[];

  if(selection?.includes(displayedColumnKey)){
    isColumnDisplayed = false;
    toggledSelection = selection?.map(columnKey => {
      if(columnKey === displayedColumnKey){
        return hiddenColumnKey;
      }

      return columnKey;
    });
  }else if(selection?.includes(hiddenColumnKey)){
    toggledSelection = selection.map(columnKey => {
      if(columnKey === hiddenColumnKey){
        return displayedColumnKey;
      }

      return columnKey;
    });
  } else{
    toggledSelection?.push(displayedColumnKey);
  } 

  if(resource){
    storage?.set(CUSTOMIZABLE_LIST_STORAGE_KEY, resource, toggledSelection);
  }

  toggleColumnDisplay(columnName, isColumnDisplayed, columns);

  return {
    selection: toggledSelection,
    columns
  }
}

export const removeResourceStorage = (resourceName: string, storage: DatagridStorage) => {
  storage.remove(CUSTOMIZABLE_LIST_STORAGE_KEY, resourceName);
}

export const initializeDataGrid = (originalFieldsList:  ReactNode | ReactNode[], state: CustomizableListState) => {

  const selection = getInitialCustomizableListSelection(state, originalFieldsList);
  const fieldsList = recalculateFields(selection, originalFieldsList, true);
  const columns = prepareColumns(fieldsList);
  const newState = {
      ...state,
      isOpen: false,
      fieldsList,
      selection,
      columns,
  }

  return newState;
}

export const saveSelectionState = (state: CustomizableListState) => {
  const resource = state?.resource;
  const columns = state?.columns;
  const storage = state?.storage;

  if(resource && columns && storage){
    const newSelection = columns?.map(column => `${column.source}-${column.isDisplayed?1:0}`);
    storage?.set(CUSTOMIZABLE_LIST_STORAGE_KEY, resource, newSelection);
    return newSelection;
  }

  return state?.selection;
}

export const prepareListRowActions = (listRowActions, modifiedProps) => {
  let defaultListRowActions: Array<any> = [];
  if (listRowActions !== false && typeof listRowActions !== "boolean") {
    defaultListRowActions = listRowActions || [
      <DefaultCrudListRowActions
        key="defaultCrudListRowActions"
        {...modifiedProps}
      />,
    ];

    return defaultListRowActions;
  }
}