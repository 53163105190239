import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { PermissionVendorGroup, RESOURCE_NAME_VENDOR_GROUP } from "./constants";
import { VendorGroupCreate } from "./VendorGroupCreate";
import { VendorGroupEdit } from "./VendorGroupEdit";
import { VendorGroupList } from "./VendorGroupList";
import { VendorGroupShow } from "./VendorGroupShow";
const VendorGroupPermissions = {
  menu: PermissionVendorGroup.MENU_VENDOR_GROUP,
  list: PermissionVendorGroup.LIST_VENDOR_GROUPS,
  create: PermissionVendorGroup.CREATE_VENDOR_GROUP,
  update: PermissionVendorGroup.EDIT_VENDOR_GROUP,
  show: PermissionVendorGroup.SHOW_VENDOR_GROUP,
  delete: PermissionVendorGroup.DELETE_VENDOR_GROUP,
};

const vendorGroupResource = {
  name: RESOURCE_NAME_VENDOR_GROUP,
  list: VendorGroupList,
  create: VendorGroupCreate,
  edit: VendorGroupEdit,
  show: VendorGroupShow,
  icon: PeopleAltIcon,
  options: {
    label: "resources.vendorGroup.name",
    allowedPermissions: VendorGroupPermissions,
  },
};

export * from "./constants";
export { VendorGroupPermissions, vendorGroupResource };
