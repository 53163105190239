import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useCallback, useContext } from "react";
import {
  useListContext,
  usePermissions,
  useResourceDefinition,
  ConfirmProps,
  Record,
} from "react-admin";
import { hasAccess } from "../../../../../core";
import { ListRowActionButtonsContext } from "../ListRowActionButtonsContext";
import {
  SingleListRowAction,
  SingleListRowActionProps,
} from "../SingleListRowAction";
import { ListRowActionButtonsActionTypes } from "../types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabPrimary: {
      color: theme.palette.primary.main,
    },
  })
);

export const EMPTY_CONFIRM_HANDLER = (event) => {}

export interface ConfirmListViewActionButtonProps
  extends SingleListRowActionProps {
  confirm: ConfirmProps;
  onConfirmHandler: (record?: Record) => Promise<void> | void;
}

const ConfirmListViewActionButton = (props: ConfirmListViewActionButtonProps) => {
  const { resource } = useListContext();
  const { permissionKey, permissionName, record } = props;
  const {confirm, onConfirmHandler, ...rest } = props;
  const { options } = useResourceDefinition({ resource });
  const { loaded: permissionsLoaded, permissions } = usePermissions(props);

  const classes = useStyles();
  const actionClassName = props?.className || classes.fabPrimary;

  const actionPermissionName =
    !permissionName && permissionKey
      ? options?.allowedPermissions[permissionKey]
      : permissionName;

  const canAccess =
    actionPermissionName && permissionsLoaded
      ? hasAccess(permissions, actionPermissionName)
      : true;
  const { dispatch } = useContext(ListRowActionButtonsContext);

  const openConfirmDialog = useCallback(
    (clickEvent, record) => {
      const handleOnConfirm = (event) => {

        confirm?.onConfirm(event);

        if (dispatch) {
          dispatch({
            type: ListRowActionButtonsActionTypes.CLOSE_CONFIRM,
          });
        }

        if(onConfirmHandler){
          onConfirmHandler(record);
        }
      };

      const handleOnConfirmClose = (event) => {
        confirm?.onClose(event);
        
        if (dispatch) {
          dispatch({
            type: ListRowActionButtonsActionTypes.CLOSE_CONFIRM,
          });
        }
      };

      const confirmProps = {
        ...confirm,
        onConfirm: handleOnConfirm,
        onClose: handleOnConfirmClose,
      };

      if (dispatch && confirm) {
        dispatch({
          type: ListRowActionButtonsActionTypes.OPEN_CONFIRM,
          payload: {
            confirm: confirmProps,
          },
        });
      }
    },
    [confirm, onConfirmHandler, dispatch]
  );

  if (!canAccess) {
    return <div/>
  }

  return (
    <SingleListRowAction
      {...rest}
      handleClick={openConfirmDialog}
      className={actionClassName}
    />
  );
};

export { ConfirmListViewActionButton };
