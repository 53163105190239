export const getTabbedFormProps = (props) => {
    return {
      basePath: props?.basePath,
      children: props?.children,
      className: props?.className,
      classes: props?.classes,
      initialValues: props?.classes,
      margin: props?.margin,
      mutationMode: props?.mutationMode,
      record: props?.record,
      redirect: props?.redirect,
      resource: props?.resource,
      sanitizeEmptyValues: props?.sanitizeEmptyValues,
      save: props?.save,
      submitOnEnter: props?.submitOnEnter,
      syncWithLocation: props?.syncWithLocation,
      tabs: props?.tabs,
      toolbar: props?.toolbar,
      undoable: props?.undoable,
      variant: props?.variant,
      warnWhenUnsavedChanges: props?.warnWhenUnsavedChanges,
    };
  };