import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  RichTextField,
  ReferenceField,
} from "react-admin";
import { DefaultShow, UploadedFilesField } from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_ORG_STRUCTURE_TYPES } from "./constants";

const OrgStructureTypesShow = (props: ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="number" 
          label="resources.OrgStructureTypes.fields.number"
          />
        <TextField source="name" 
          label="resources.OrgStructureTypes.fields.name"
          />
        <RichTextField source="description" />
        <ReferenceField source="parentId"
          label="resources.OrgStructureTypes.fields.parentId"
          reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <UploadedFilesField canDelete={false} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { OrgStructureTypesShow };
