import { ReactElement } from "react";

import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  DateField,
  BooleanField,
  RichTextField,
  ReferenceField,
} from "react-admin";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../../lib/components";
import { DEFAULT_PER_PAGE, IdFilter, SearchFilter } from "../../../../lib/common";
import { RESOURCE_NAME_ORG_STRUCTURE_TYPES } from "./constants";
import { RESOURCE_NAME_ACCOUNT } from "../../../../lib/modules/accounts/accounts/constants";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
  </Filter>
);
const defaultColumns = ["number", "name", "description"];

const OrgStructureTypesList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="number"
          label="resources.OrgStructureTypes.fields.number"
        />
        <TextField source="name"
          label="resources.OrgStructureTypes.fields.name"
        />
        <RichTextField source="description"
          label="resources.OrgStructureTypes.fields.description" />
        <ReferenceField source="parentId"
          label="resources.OrgStructureTypes.fields.parentId"
          reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT} >
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { OrgStructureTypesList };
