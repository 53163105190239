import GroupWorkIcon from "@material-ui/icons/GroupWork";
import {
  PermissionOrgUserGroup,
  RESOURCE_NAME_ORG_USER_GROUP,
} from "./constants";
import { OrgUserGroupCreate } from "./OrgUserGroupCreate";
import { OrgUserGroupEdit } from "./OrgUserGroupEdit";
import { OrgUserGroupsList } from "./OrgUserGroupList";
import { OrgUserGroupshow } from "./OrgUserGroupShow";

const allowedPermissions = {
  menu: PermissionOrgUserGroup.MENU_ORG_USER_GROUP,
  list: PermissionOrgUserGroup.LIST_ORG_USER_GROUPS,
  create: PermissionOrgUserGroup.CREATE_ORG_USER_GROUP,
  update: PermissionOrgUserGroup.EDIT_ORG_USER_GROUP,
  show: PermissionOrgUserGroup.SHOW_ORG_USER_GROUP,
  delete: PermissionOrgUserGroup.DELETE_ORG_USER_GROUP,
};

const OrgUserGroupsResource = {
  name: RESOURCE_NAME_ORG_USER_GROUP,
  list: OrgUserGroupsList,
  create: OrgUserGroupCreate,
  edit: OrgUserGroupEdit,
  show: OrgUserGroupshow,
  icon: GroupWorkIcon,
  options: { label: "resources.OrgUserGroups.name", allowedPermissions },
};

export * from "./constants";
export { OrgUserGroupsResource };
