import GavelIcon from "@material-ui/icons/Gavel";
import { GatedMenuItemLink, GatedSubMenu, MenuProps } from "../../components/menu";
import { mediaResource } from "./media";

const UtilitiesMenu= ({
  isSidebarOpen,
  onMenuClick,
  dense,
}:MenuProps) => {
  return (
    <GatedSubMenu
      name={`resources.utilities.name`}
      icon={<GavelIcon />}
      isSidebarOpen={isSidebarOpen}
      dense={dense}
      allowedPermissionName="view-utilities-management-menu"
    >
      <GatedMenuItemLink
        to={`/${mediaResource.name}`}
        name="resources.media.name"
        leftIcon={<mediaResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={mediaResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
    </GatedSubMenu>
  );
};

export { UtilitiesMenu }