import { Identifier } from "react-admin";

export const getLocalStorageItem = (itemKey: string) => {
    const itemStr = localStorage.getItem(itemKey);
    const item = itemStr ? JSON.parse(itemStr):null;
    return item;
}

export const isMatchingAccount = (targetAccountId: Identifier) => {
    const user = getLocalStorageItem("user");
    const accountId = user?.accountId;

    return (targetAccountId === accountId)?true:false;
}