export const RESOURCE_NAME_UOM = "uom";

export enum PermissionUoms {
  UOM_MENU = "view-unit-of-measures-menu",
  LIST_UOMS = "list-uoms",
  SHOW_UOM = "show-uom",
  CREATE_UOM = "create-uom",
  EDIT_UOM = "update-uom",
  DELETE_UOM = "delete-uom",
  IMPORT_UOMS="import-uoms",
}
