import { ReactElement, useReducer, useMemo } from "react";
import { Box } from "@material-ui/core";
import {
  List,
  usePermissions,
  useResourceDefinition,
  Loading,
} from "react-admin";
import { CustomizableListProps } from "./types";
import { ListActionBreadcrumb } from "../../../navigation";
import { prepareModifiedListProps } from "../listUtils";
import {
  prepareCustomizableListContextInitialState,
  prepareListRowActions,
} from "./listUtils";
import { CustomizableListContext } from "./CustomizableListContext";
import { customizableListReducer } from "./customizableListReducer";
import { CustomizableListActions } from "./CustomizableListActions";
import { DEFAULT_PER_PAGE } from "../../../../common";
import { CustomizableSelectionDialog } from "./CustomizableSelectionDialog";
import {
  ListRowActionButtonsContext,
  listRowActionButtonsReducer,
  prepareListRowActionButtonsInitialState,
} from "../listActions";
import { DefaultPagination } from "../DefaultPagination";
import { DummyListRowComponent } from "../DummyListRowComponent";

const CustomizableList = (
  props: CustomizableListProps & { children: ReactElement }
): ReactElement => {
  const { defaultColumns, listRowActions, ...rest } = props;
  const { options, icon } = useResourceDefinition(rest);
  const { loaded, permissions } = usePermissions();
  const modifiedProps = prepareModifiedListProps(options, rest, permissions);
  const listContextInitialState =
    prepareCustomizableListContextInitialState(modifiedProps);

  const listRowActionButtonsInitialState = useMemo(() => {
    const defaultListRowActions = prepareListRowActions(
      listRowActions,
      modifiedProps
    );
    const listRowActionButtonsInitialState =
      prepareListRowActionButtonsInitialState(defaultListRowActions);
    return listRowActionButtonsInitialState;
  }, [listRowActions]);

  const [customizableListState, customizableListDispatch] = useReducer(
    customizableListReducer,
    {
      ...listContextInitialState,
    }
  );

  const [listRowActionButtonsState, listRowActionButtonsDispatch] = useReducer(
    listRowActionButtonsReducer,
    { ...listRowActionButtonsInitialState }
  );

  const listContextValue = useMemo(() => {
    return { state: customizableListState, dispatch: customizableListDispatch };
  }, [customizableListState, customizableListDispatch]);

  const listRowActionButtonsContextValue = useMemo(() => {
    return {
      state: listRowActionButtonsState,
      dispatch: listRowActionButtonsDispatch,
    };
  }, [listRowActionButtonsState, listRowActionButtonsDispatch]);

  const resourceName = options?.label;

  if (loaded) {
    return (
      <Box>
        <Box pt={1}>
          <ListActionBreadcrumb resourceLabel={resourceName} Icon={icon} />
        </Box>
        <CustomizableListContext.Provider value={listContextValue}>
          <ListRowActionButtonsContext.Provider
            value={listRowActionButtonsContextValue}
          >
            <List {...modifiedProps} />
            <CustomizableSelectionDialog />
            {listRowActions !== false &&
              typeof listRowActions !== "boolean" && (
                <DummyListRowComponent
                  listRowActions={listRowActions}
                  modifiedProps={modifiedProps}
                />
              )}
          </ListRowActionButtonsContext.Provider>
        </CustomizableListContext.Provider>
      </Box>
    );
  } else {
    return <Loading />;
  }
};

CustomizableList.defaultProps = {
  filter: {},
  perPage: DEFAULT_PER_PAGE,
  pagination: <DefaultPagination />,
  actions: <CustomizableListActions />,
};

CustomizableList.displayName = "CustomizableList";

export { CustomizableList };
