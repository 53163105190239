import { useEffect, useState } from "react";
import { TextField, useRecordContext } from "react-admin";
import { ResourceLinkedField } from "../../../../components/controls/fields/ResourceLinkedField";
import { usePermissionsCheck } from "../../../../core";
import { MEDIA_OWNER_MAP } from "../constants";

const MediaOwnerLinkField = (props) => {
  const record = useRecordContext(props);
  const mediaOwnerConfig = MEDIA_OWNER_MAP.get(record?.ownerType);

  const resourceName = mediaOwnerConfig?.resource;
  const permissionName = mediaOwnerConfig?.permission;

  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const [hasShowAccess, setHasShowAccess] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasShowAccess = hasPermissionAccess(permissionName);
      setHasShowAccess(hasShowAccess);
    }
  }, [hasPermissionAccess, permissionName, permissionsLoaded]);

  if (!resourceName) {
    return <div />;
  }

  if (hasShowAccess) {
    return (
      <ResourceLinkedField
        label="resources.media.fields.ownerType"
        source="ownerType"
        linkedResource={resourceName}
        linkedResourceIdSource="ownerId"
        resource={resourceName}
        record={record}
        link="show"
      />
    );
  } else {
    return <TextField source="ownerType" />;
  }
};

export { MediaOwnerLinkField };
