import {
  CustomizableListState,
  CustomizableListActions,
  CustomizableListActionTypes,
} from "./types";
import {
  handleToggleColumn,
  removeResourceStorage,
  initializeDataGrid,
  saveSelectionState,
} from "./listUtils";

export const customizableListReducer = (
  state: CustomizableListState,
  action: CustomizableListActions
): CustomizableListState => {
  switch (action.type) {
    case CustomizableListActionTypes.INIT_DATAGRID: {
      const originalFieldsList = action?.payload?.fieldsList;
      const newState = initializeDataGrid(originalFieldsList, state);

      return newState;
    }

    case CustomizableListActionTypes.SET_COLUMNS: {
      const columns = action?.payload?.columns;
      const newState = {
        ...state,
        columns,
      };

      return newState;
    }

    case CustomizableListActionTypes.COLUMN_TOGGLED: {
      const columnName = action?.payload?.columnName;
      const { selection, columns } = handleToggleColumn(columnName, state);
      const newState = {
        ...state,
        selection,
        columns,
      };

      return newState;
    }

    case CustomizableListActionTypes.RESET_ALL: {
      if (state?.resource && state?.storage) {
        removeResourceStorage(state?.resource, state?.storage);
      }

      const newState = initializeDataGrid(state?.fieldsList, state);

      return newState;
    }

    case CustomizableListActionTypes.SAVE_COLUMNS_STATE: {
      const selection = saveSelectionState(state);

      const newState = {
        ...state,
        selection,
      };

      return newState;
    }

    case CustomizableListActionTypes.OPEN_DIALOG: {
      const newState = {
        ...state,
        ...{ isOpen: true },
      };

      return newState;
    }

    case CustomizableListActionTypes.CLOSE_DIALOG: {
      const newState = {
        ...state,
        ...{ isOpen: false },
      };

      return newState;
    }
  }
};
