import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
    setSidebarVisibility,
    ShowProps,
    SimpleShowLayout,
  } from "react-admin";
import { FILTER_DIRECTION, PermissionBehavior } from "../../common";
import { DefaultShow } from "../../lib/components";
import { usePermissionsCheck } from "../../lib/core";
import { BidShowContent } from "./components";
import { BidCrudShowActions } from "./components/actions";

const BidShow = ( props: ShowProps) => {
    const dispatch = useDispatch();
    const { hasPermissionAccess} = usePermissionsCheck();
    const canProcure = hasPermissionAccess(PermissionBehavior.CAN_PROCURE);
    const direction = canProcure?FILTER_DIRECTION.RECEIVED:FILTER_DIRECTION.SENT;
    
    useEffect(() => {
      dispatch(setSidebarVisibility(false));
      // eslint-disable-next-line
    }, []);
    
    return (
        <DefaultShow {...props}
            actions={<BidCrudShowActions/>}
            basePath={`/bids?filter={"direction":"${direction}"}`}
        >
            <SimpleShowLayout>
                <BidShowContent/>
            </SimpleShowLayout>
        </DefaultShow>
    )
}

export { BidShow }
