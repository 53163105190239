import {
  TextInput,
  required,
  useTranslate,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { Typography } from "@material-ui/core";
import { MediaInputField } from "../../../lib/components";
import { RESOURCE_NAME_VENDOR_GROUP } from "./constants";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";

export const styles: Styles<Theme, any> = {
  number: { display: "block" },
  name: { display: "block" },
  description: { display: "block" },
  formClass: {
    width: "75%",
  },
};
const useStyles = makeStyles(styles);

const VendorGroupForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm
      variant="filled"
      className={classes.formClass}
      warnWhenUnsavedChanges
      {...rest}
    >
      <SectionTitle label="resources.vendorGroup.fieldGroups.VendorGroupDetaills" />
      <TextInput
        autoFocus
        source="number"
        label="resources.vendorGroup.fields.number"
        helperText="resources.vendorGroup.help.number"
        formClassName={classes.number}
        validate={[required()]}
        fullWidth
      />

      <TextInput
        source="name"
        label="resources.vendorGroup.fields.name"
        helperText="resources.vendorGroup.help.name"
        formClassName={classes.name}
        validate={[required()]}
        fullWidth
      />

      <RichTextInput
        source="description"
        helperText="resources.vendorGroup.help.description"
        formClassName={classes.description}
        label="resources.vendorGroup.fields.description"
      />
      <ReferenceInput
        source="parentId"
        label="resources.vendorGroup.fields.parentId"
        fullWidth
        reference={RESOURCE_NAME_VENDOR_GROUP}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
        key="parentId"
      >
        <AutocompleteInput
          optionText="displayLabel"
          resettable={true}
          allowEmpty={false}
          helperText="resources.vendorGroup.help.parentId"
        />
      </ReferenceInput>

      <MediaInputField showUploadedFiles={isEdit} />
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { VendorGroupForm };
