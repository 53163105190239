import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../lib/components/crud/DefaultCreate";
import { MediaFileSource, transformAndUploadS3Files } from "../../../lib/modules";
import { VendorForm } from "./components/VendorForm";


const VendorCreate= (props: CreateProps)=> {
 
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "vendors",
    },
  ]; return (
    <DefaultCreate {...props}
    transform={transformAndUploadS3Files(mediaFileSources)}>
        <VendorForm />
    </DefaultCreate>
  );
};

export { VendorCreate };
