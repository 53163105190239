export const DEFAULT_PER_PAGE = 25;
export const MAX_RECORDS_PER_PAGE=1000;
export const DEFAULT_DATE_TIME_OPTIONS = {
    showTodayButton: true,
    clearable: true,
}

export const APP_MAX_FILE_SIZE_BYTES = 15728640; //15MBs
export const PIC_MAX_FILE_SIZE_BYTES = 1048576; //1MB

export const SUPPORTED_IMAGE_TYPES: string[] = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/tiff",
  "image/gif",
];

export const ALLOWED_FILE_TYPES: string[] = [
  ...SUPPORTED_IMAGE_TYPES,
  "text/plain",
  "text/csv",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/bz",
  "application/bz2",
  "application/zip",
  "application/gzip",
  "application/x-tar",
  "application/x-7z-compressed",
];