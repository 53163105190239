import MediaIcon from "@material-ui/icons/Backup";
import { mediaPermissions, RESOURCE_NAME_MEDIA } from "./constants";
import { MediaList } from "./MediaList";

const mediaResource = {
  name: RESOURCE_NAME_MEDIA,
  list: MediaList,
  icon: MediaIcon,
  options: { label: "resources.media.name", allowedPermissions: mediaPermissions },
};

export * from "./constants";
export * from "./utils";
export { mediaResource };
