import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { DatagridStorage } from "./types";
//import lz from 'lzutf8';

export const CUSTOMIZABLE_LIST_STORAGE_KEY = "raCustomListColumns";

// Very basic storage helper
// values are stored in browser localStorage

const getRootValue = (storageKey) => {
  try {
    const compressedValue = window.localStorage.getItem(storageKey);
    /* const uncompressedValue = lz.decompress(compressedValue);
    const jsonValue = JSON.parse(uncompressedValue); */
    const jsonValue = compressedValue ? JSON.parse(compressedValue) : {};
    return jsonValue;
  } catch (e) {
    return undefined;
  }
};

const setRootValue = (storageKey, value) => {
  try {
    if (isEmpty(value)) {
      //console.log(`Empltying storage`)
      window.localStorage.removeItem(storageKey);
    } else {
      const stringValue = JSON.stringify(value);
      /* const compressedValue = lz.compress(stringValue); 
      window.localStorage.setItem(STORAGE_KEY, compressedValue); */
      window.localStorage.setItem(storageKey, stringValue);
    }
  } catch (e) {}
};

const LocalStorage: DatagridStorage = {
  get(storageKey, key) {
    return get(getRootValue(storageKey), key);
  },
  set(storageKey, key, value) {
    const oldValue = getRootValue(storageKey);
    //console.log(`Storage Set Value: ${JSON.stringify(value)}`)
    if (isEmpty(value)) {
      this.remove(storageKey, key);
    } else {
      setRootValue(storageKey, {
        ...oldValue,
        [key]: value,
      });
    }
  },
  remove(storageKey, key) {
    const rootValue = getRootValue(storageKey);
    if (rootValue && key in rootValue) {
      delete rootValue[key];
      setRootValue(storageKey, rootValue);
    }
  },
};

export { LocalStorage };
