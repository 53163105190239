import { TranslationMessages } from 'react-admin';
import englishMessages from 'ra-language-english';
import en from "./en.json";
import accounts from "../lib/modules/accounts/i18n/en.json";
import utilities from "../lib/modules/utilities/i18n/en.json";

import components from "../lib/components/i18n/en.json";
import datasetup from "../modules/datasetup/i18n/en.json";


const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    ...en,
    resources: {
        ...accounts.resources,
        ...datasetup.resources,
        ...utilities.resources,
        ...en.resources,
    },

    common: {
        ...en.common,
    },

    components: {
        ...components.components,
    },   
    
}

export default customEnglishMessages;