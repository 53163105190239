import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  TabbedShowLayout,
  Tab,
  usePermissions,
} from "react-admin";
import { DefaultShow } from "../../../components";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";
import { hasAccess } from "../../../core/helpers";
import { RESOURCE_NAME_ACCOUNT } from "../accounts";
import { ShowRolePermissions } from "../permissions/ShowRolePermissions";
import { PermissionRoles } from "./constants";

const RoleShow= (props:ShowProps) => {
  const { permissions } = usePermissions();

  const allowManagePermissionsFromRoleScreen = hasAccess(
    permissions,
    PermissionRoles.ACTION_MANAGE_PERMISSIONS_FROM_ROLE
  );

  const SimpleShowForm = () => (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </DefaultShow>
  );

  const TabbedForm = () => (
    <DefaultShow {...props}>
      <TabbedShowLayout>
        <Tab label="resources.roles.tabs.role">
          <TextField source="name" />
          <TextField source="description" />
          <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT}>
            <TextField source="displayLabel" />
          </ReferenceField>
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <TextField source="version" />
          <BooleanField source="isActive" />
        </Tab>
        <Tab label="resources.roles.tabs.permissions">
          <ShowRolePermissions />
        </Tab>
      </TabbedShowLayout>
    </DefaultShow>
  );

  if (allowManagePermissionsFromRoleScreen) {
    return <TabbedForm />;
  } else {
    return <SimpleShowForm />;
  }
};

export { RoleShow };
