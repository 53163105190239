import {
  ShowProps,
  TabbedShowLayout,
  Tab,
  TextField,
  ReferenceField,
  DateField,
  RichTextField,
  ReferenceArrayField,
  Datagrid,
  EmailField,
} from "react-admin";
import { DefaultShow, ResourceLinkedField } from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_USER } from "../../../../lib/modules";
import { RESOURCE_NAME_PURCHASE_GROUPS } from "../../purchase-groups";
import { RESOURCE_NAME_ORG_STRUCTURE_TYPES } from "../org-structure-types/constants";
import { RESOURCE_NAME_ORG_USER_GROUP } from "../org-user-groups";
import { RESOURCE_NAME_ORG_STRUCTURE } from "./constants";

const OrgStructureShow = (props: ShowProps) => {
  return (
    <DefaultShow {...props}>
      <TabbedShowLayout>
        <Tab label="resources.orgStructures.tabs.general">
          <TextField
            source="name"
            label="resources.orgStructures.fields.name"
          />
          <TextField
            source="number"
            label="resources.orgStructures.fields.number"
          />
          <RichTextField
            source="description"
            label="resources.businessFunction.fields.description"
          />
          <ReferenceField
            source="orgStructureTypeId"
            label="resources.orgStructures.fields.orgStructureTypeId"
            reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="parentId"
            label="resources.orgStructures.fields.parentId"
            reference={RESOURCE_NAME_ORG_STRUCTURE}
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
          <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
          <TextField source="version" />
        </Tab>
        <Tab label="resources.orgStructures.tabs.procurement">
          <ReferenceArrayField
            source="purchaseGroupIds"
            label="resources.orgStructures.fields.purchaseGroupIds"
            reference={RESOURCE_NAME_PURCHASE_GROUPS}
          >
            <Datagrid optimized>
              <ResourceLinkedField
                source="number"
                linkedResource={RESOURCE_NAME_PURCHASE_GROUPS}
                linkedResourceIdSource="id"
                resource={RESOURCE_NAME_PURCHASE_GROUPS}
                link="show"
              />
              <TextField source="name" />
            </Datagrid>
          </ReferenceArrayField>

          <ReferenceArrayField
            source="procureRequisitionsUserGroupIds"
            label="resources.orgStructures.fields.procureRequisitionsUserGroupIds"
            reference={RESOURCE_NAME_ORG_USER_GROUP}
          >
            <Datagrid optimized>
              <ResourceLinkedField
                source="number"
                label="resources.OrgUserGroups.fields.number"
                linkedResource={RESOURCE_NAME_ORG_USER_GROUP}
                linkedResourceIdSource="id"
                resource={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              />
              <TextField
                source="name"
                label="resources.OrgUserGroups.fields.name"
              />
              <ReferenceField
                source="level1ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceField
                source="level2ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceArrayField
                source="userIds"
                label="resources.OrgUserGroups.fields.userIds"
                reference={RESOURCE_NAME_USER}
              >
                <Datagrid optimized>
                  <ResourceLinkedField
                    source="fullName"
                    label="resources.users.fields.fullName"
                    linkedResource={RESOURCE_NAME_USER}
                    linkedResourceIdSource="id"
                    resource={RESOURCE_NAME_USER}
                    link="show"
                  />
                  <EmailField
                    source="email"
                    label="resources.users.fields.email"
                  />
                </Datagrid>
              </ReferenceArrayField>
            </Datagrid>
          </ReferenceArrayField>

          <ReferenceArrayField
            source="procureEnquiriesUserGroupIds"
            label="resources.orgStructures.fields.procureEnquiriesUserGroupIds"
            reference={RESOURCE_NAME_ORG_USER_GROUP}
          >
            <Datagrid optimized>
              <ResourceLinkedField
                source="number"
                label="resources.OrgUserGroups.fields.number"
                linkedResource={RESOURCE_NAME_ORG_USER_GROUP}
                linkedResourceIdSource="id"
                resource={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              />
              <TextField
                source="name"
                label="resources.OrgUserGroups.fields.name"
              />
              <ReferenceField
                source="level1ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceField
                source="level2ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceArrayField
                source="userIds"
                label="resources.OrgUserGroups.fields.userIds"
                reference={RESOURCE_NAME_USER}
              >
                <Datagrid optimized>
                  <ResourceLinkedField
                    source="fullName"
                    label="resources.users.fields.fullName"
                    linkedResource={RESOURCE_NAME_USER}
                    linkedResourceIdSource="id"
                    resource={RESOURCE_NAME_USER}
                    link="show"
                  />
                  <EmailField
                    source="email"
                    label="resources.users.fields.email"
                  />
                </Datagrid>
              </ReferenceArrayField>
            </Datagrid>
          </ReferenceArrayField>

          <ReferenceArrayField
            source="procureBidsUserGroupIds"
            label="resources.orgStructures.fields.procureBidsUserGroupIds"
            reference={RESOURCE_NAME_ORG_USER_GROUP}
          >
            <Datagrid optimized>
              <ResourceLinkedField
                source="number"
                label="resources.OrgUserGroups.fields.number"
                linkedResource={RESOURCE_NAME_ORG_USER_GROUP}
                linkedResourceIdSource="id"
                resource={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              />
              <TextField
                source="name"
                label="resources.OrgUserGroups.fields.name"
              />
              <ReferenceField
                source="level1ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceField
                source="level2ApprovalGroupId"
                label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
                reference={RESOURCE_NAME_ORG_USER_GROUP}
                link="show"
              >
                <TextField source="displayLabel" />
              </ReferenceField>
              <ReferenceArrayField
                source="userIds"
                label="resources.OrgUserGroups.fields.userIds"
                reference={RESOURCE_NAME_USER}
              >
                <Datagrid optimized>
                  <ResourceLinkedField
                    source="fullName"
                    label="resources.users.fields.fullName"
                    linkedResource={RESOURCE_NAME_USER}
                    linkedResourceIdSource="id"
                    resource={RESOURCE_NAME_USER}
                    link="show"
                  />
                  <EmailField
                    source="email"
                    label="resources.users.fields.email"
                  />
                </Datagrid>
              </ReferenceArrayField>
            </Datagrid>
          </ReferenceArrayField>
        </Tab>
      </TabbedShowLayout>
    </DefaultShow>
  );
};

export { OrgStructureShow };
