import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../components";
import { PermissionGroupForm } from "./PermissionGroupsForm";

const PermissionGroupEdit= (props:EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <PermissionGroupForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { PermissionGroupEdit };
