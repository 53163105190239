import { Box } from "@material-ui/core";
import { useTranslate } from "react-admin";

export interface StatusItemProps {
  statusLabel: string;
  statusValue: number | string;
  statusColor?: string;
  getColorFromStatus?: (status: any) => string;
  width?: string;
}

const StatusItem=({
  statusLabel,
  statusValue,
  statusColor,
  getColorFromStatus,
  width = '100%',
}:StatusItemProps) => {
  const translate = useTranslate();
  return (
    <Box 
      display="block" 
      flexDirection="row" 
      alignItems="center" 
      width={width}
      paddingLeft = "0px"
      paddingRight = "0px"
      marginLeft = "-10px"
    >
      <Box
        width={10}
        height={10}
        display="inline-block"
        borderRadius={5}
        bgcolor={statusColor ?? (getColorFromStatus ? getColorFromStatus(statusValue): "#000")}
        component="span"
        />
        &nbsp; {translate(statusLabel)} 
    </Box>
  );
};

export { StatusItem };
