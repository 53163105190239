import { useState, useEffect, useMemo, Fragment } from "react";
import { createStyles, makeStyles, Box, Theme } from "@material-ui/core";
import { useForm } from "react-final-form";
import {
  ReferenceField,
  required,
  TextInput,
  TextField,
  EmailField,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  Identifier,
  useGetList,
  useEditContext,
  ArrayInput,
  RichTextField,
  SimpleFormIterator,
  Datagrid,
} from "react-admin";
import {
  itemResource,
  RESOURCE_NAME_ITEM,
  SingleItemShowContainer,
} from "../../datasetup/items";
import { RESOURCE_NAME_UOM } from "../../datasetup/unit-of-measure";
import {
  RESOURCE_NAME_REQUISITION_DETAILS,
} from "../../requisitions";
import { RequisitionSummaryContainer } from "./RequisitionSummaryContainer";
import { RESOURCE_NAME_VENDOR, vendorResource } from "../../datasetup/vendors";
import {
  AccordionField,
  DateTimeInput,
  DefaultList,
  EmptyState,
  MediaInputField,
  SectionTitleSubtitle,
  TruncatedTextField,
} from "../../../lib/components";
import { validations } from "./validations";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import RichTextInput from "ra-input-rich-text";
import { embeddedDataGridRowStyle } from "./embeddedDataGridRowStyle";
import {
  EnquiryDetailRecord,
  RESOURCE_NAME_ENQUIRY_VENDORS,
} from "../constants";
import {
  DEFAULT_DATE_TIME_OPTIONS,
  DEFAULT_PER_PAGE,
  MAX_RECORDS_PER_PAGE,
} from "../../../lib/common";
import { CommentsAccordion, OwnerEntityTypes } from "../../comments";
import { EnquiryBulkActionButtons } from "./EnquiryBulkActionButtons";
import {
  ItemListFilters,
  VendorListFilters,
} from "./EnquiryVendorsListFilters";
import { VendorsBulkActionButtons } from "./VendorsBulkActionButtons";
import { SingleVendorShowContainer } from "../../datasetup/vendors/components";
import { RESOURCE_NAME_ENQUIRY_DETAILS } from "..";

const EnquiryEditContainer = (props) => {
  const { record } = props;
  const classes = useStyles();
  const arrayInputClasses = useArrayInputStyles();
  const form = useForm();
  const [selectedRequisitionId, setSelectedRequisitionId] = useState<
    Identifier | undefined
  >(record?.requisitionId);
  const [selectedEnquiryId, setSelectedEnquiryId] = useState<
    Identifier | undefined
  >(record?.enquiryId);

  const [enquiryDetailsMap, setEnquiryDetailsMap] = useState(new Map());
  const [requisitionEnquiryDetailIdsMap, setRequisitionEnquiryDetailIdsMap] =
    useState(new Map());

  const { loaded: enquiriesLoaded } = useEditContext();

  const [originalRequisitionEnquiryDetailIdsMap, setOriginalRequisitionEnquiryDetailIdsMap] = useState(new Map());
  const [addedEnquiryDetailsMap, setAddedEnquiryDetailsMap] = useState(new Map());
  const [removedEnquiryDetailIds, setRemovedEnquiryDetailIds] = useState<Identifier[]>([]);

  const [vendorIds, setVendorIds] = useState<Identifier[]>([]);
  const [originalVendorIds, setOriginalVendorIds] = useState<Identifier[]>([]);
  const [addedVendorIds, setAddedVendorIds] = useState<Identifier[]>([]);
  const [removedVendorIds, setRemovedVendorIds] = useState<Identifier[]>([]);

  const {
    data: existingEnquiryDetails,
    ids: existingEnquiryDetailIds,
    loaded: existingEnquiryDetailsLoaded,
    //@ts-ignore
  } = useGetList<EnquiryDetailRecord>(
    RESOURCE_NAME_ENQUIRY_DETAILS,
    { page: 1, perPage: MAX_RECORDS_PER_PAGE },
    { field: "updatedAt", order: "ASC" },
    {
      enquiryId: selectedEnquiryId,
    },
    {
      enabled: enquiriesLoaded && selectedEnquiryId ? true : false,
    }
  );

  const {
    data: allVendors,
    ids: allVendorIds,
    loaded: allVendorsLoaded,
  } = useGetList(
    RESOURCE_NAME_VENDOR,
    { page: 1, perPage: MAX_RECORDS_PER_PAGE },
    { field: "updatedAt", order: "ASC" }
  );

  const {
    data: enquiryVendors,
    ids: enquiryVendorIds,
    loaded: enquiryVendorsLoaded,
  } = useGetList(
    RESOURCE_NAME_ENQUIRY_VENDORS,
    { page: 1, perPage: MAX_RECORDS_PER_PAGE },
    { field: "updatedAt", order: "ASC" },
    {
      enquiryId: selectedEnquiryId,
    },
    {
      enabled: selectedEnquiryId && allVendorsLoaded ? true : false,
    }
  );

  const prepareExistingEnquiryDetails = () => {
    const newEnquiryDetailsMap = new Map();
    const existingRequisitionDetailIdToEnquiryDetailsIdMap = new Map();
    if (existingEnquiryDetails) {
      existingEnquiryDetailIds?.forEach((enquiryDetailId) => {
        const existingEnquiryDetailsRecord: EnquiryDetailRecord =
          existingEnquiryDetails[enquiryDetailId];
        const enquiryDetailRecord: EnquiryDetailRecord = {
          id: existingEnquiryDetailsRecord.id,
          enquiryId: existingEnquiryDetailsRecord.enquiryId,
          requisitionId: existingEnquiryDetailsRecord.requisitionId,
          requisitionDetailId: existingEnquiryDetailsRecord.requisitionDetailId,
          itemId: existingEnquiryDetailsRecord.itemId,
          quantity: existingEnquiryDetailsRecord.quantity,
          uomId: existingEnquiryDetailsRecord.uomId,
          quantity2: existingEnquiryDetailsRecord.quantity2,
          uom2Id: existingEnquiryDetailsRecord.uom2Id,
          version: existingEnquiryDetailsRecord.version,
          isNew: false,
        };
        newEnquiryDetailsMap.set(enquiryDetailRecord.id, enquiryDetailRecord);
        existingRequisitionDetailIdToEnquiryDetailsIdMap.set(
          existingEnquiryDetailsRecord.requisitionDetailId,
          enquiryDetailRecord.id
        );
      });

      setSelectedRequisitionId(record.requisitionId);
      setSelectedEnquiryId(record.id);
      setRequisitionEnquiryDetailIdsMap(
        existingRequisitionDetailIdToEnquiryDetailsIdMap
      );
      setOriginalRequisitionEnquiryDetailIdsMap(
        new Map(existingRequisitionDetailIdToEnquiryDetailsIdMap)
      )
    }
    return newEnquiryDetailsMap;
  };

  const prepareExistingVendors = () => {
    const newEnquiryVendorsMap = new Map();
    const existingEnquiriesVendorIdsList: Array<Identifier> = [];

    if (enquiryVendorIds && enquiryVendors) {
      enquiryVendorIds?.forEach((enquiryVendorId) => {
        const enquiryVendorRecord = enquiryVendors[enquiryVendorId];
        const vendorId = enquiryVendorRecord.vendorId;
        existingEnquiriesVendorIdsList.push(vendorId);
        newEnquiryVendorsMap.set(vendorId, enquiryVendorRecord);
      });

      setVendorIds(existingEnquiriesVendorIdsList);
      setOriginalVendorIds([...existingEnquiriesVendorIdsList]);

      if (enquiryVendorIds && enquiryVendorIds.length) {
        form.change("vendorIds", existingEnquiriesVendorIdsList);
      } else {
        form.change("vendorIds", null);
      }
    }

    return newEnquiryVendorsMap;
  };

  const prepareAllVendors = () => {
    const allVendorsMap = new Map();
    const enquiryVendorIdsList: Array<Identifier> = [];

    if (
      allVendorsLoaded &&
      allVendorIds &&
      allVendors &&
      enquiryVendorsLoaded
    ) {
      if (enquiryVendorIds && enquiryVendors) {
        enquiryVendorIds?.forEach((enquiryVendorId) => {
          const enquiryVendorRecord = enquiryVendors[enquiryVendorId];
          const enquiryVendorRecordVendorId: Identifier =
            enquiryVendorRecord.vendorId;

          const allVendorRecord = allVendors[enquiryVendorRecordVendorId];
          const vendorId = allVendorRecord?.id;

          const newEnquiryVendorRecord = {
            ...enquiryVendorRecord,
            id: vendorId,
            isNew: false,
            vendorAccount: {
              name: allVendorRecord?.vendorAccount?.name,
              primaryUser: {
                fullName: allVendorRecord?.vendorAccount?.primaryUser?.fullName,
                email: allVendorRecord?.vendorAccount?.primaryUser?.email,
              },
            },
          };
          allVendorsMap.set(vendorId, newEnquiryVendorRecord);
          enquiryVendorIdsList.push(enquiryVendorRecordVendorId);
        });
      }

      allVendorIds.forEach((vendorId: Identifier) => {
        if (!enquiryVendorIdsList.includes(vendorId)) {
          //Add the other vendors only if they are not present in this enquiry vendors list
          const vendorRecord = allVendors[vendorId];
          const enquiryVendorRecord = {
            id: vendorRecord.id,
            enquiryId: selectedEnquiryId,
            vendorAccountId: vendorRecord.vendorAccountId,
            version: 1,
            isNew: true,
            vendorAccount: {
              name: vendorRecord?.vendorAccount?.name,
              primaryUser: {
                fullName: vendorRecord?.vendorAccount?.primaryUser?.fullName,
                email: vendorRecord?.vendorAccount?.primaryUser?.email,
              },
            },
          };
          allVendorsMap.set(vendorRecord.id, enquiryVendorRecord);
        }
      });
    }
    return allVendorsMap;
  };

  useEffect(() => {

    if(enquiriesLoaded){
      setSelectedRequisitionId(record?.requisitionId);
      setSelectedEnquiryId(record.id);
    }
    if (enquiriesLoaded && existingEnquiryDetailsLoaded) {
      const existingEnquiryDetails = prepareExistingEnquiryDetails();
      setEnquiryDetailsMap(existingEnquiryDetails);
      const enquiryDetails = Array.from(existingEnquiryDetails.values());
      if (enquiryDetails?.length) {
        form.change("enquiryDetails", enquiryDetails);
      } else {
        form.change("enquiryDetails", null);
      }
    }
  }, [enquiriesLoaded, existingEnquiryDetailsLoaded]);

  const enquiryVendorsMap = useMemo(
    () => prepareExistingVendors(),
    // eslint-disable-next-line
    [enquiryVendorsLoaded]
  );

  const [allVendorsMap, setAllVendorsMap] = useState(prepareAllVendors());

  useEffect(() => {
    if (allVendorsLoaded && enquiryVendorsLoaded) {
      setAllVendorsMap(prepareAllVendors());
    }
    // eslint-disable-next-line
  }, [enquiryVendorsLoaded]);

  const hasRequisitionDetailsId = (id: Identifier) => {
    return requisitionEnquiryDetailIdsMap?.has(id);
  };

  const hasVendorId = (id: Identifier) => {
    return enquiryVendorsMap.has(id);
  };

  const handleAddItemsToEnquiry = (map) => {
    const newEnquiriesDetailsMap = new Map(enquiryDetailsMap);
    const newAddedEnquiryDetailsMap = new Map(addedEnquiryDetailsMap);
    let newRemoveEnquiryDetailIds = removedEnquiryDetailIds;

    map.forEach((enquiryDetail, id) => {
      if (!enquiryDetail.id) {
        enquiryDetail.id = id;
        if(!originalRequisitionEnquiryDetailIdsMap?.has(id)){
          requisitionEnquiryDetailIdsMap.set(id, id);
        }
      }
      newEnquiriesDetailsMap.set(id, enquiryDetail);

      if(!originalRequisitionEnquiryDetailIdsMap?.has(id)){
        newAddedEnquiryDetailsMap.set(id, enquiryDetail);
      }

      if(newRemoveEnquiryDetailIds?.includes(id)){
        newRemoveEnquiryDetailIds = newRemoveEnquiryDetailIds.filter((enquiryDetailId) => enquiryDetailId !== id);
      }
    });

    const enquiryDetails = Array.from(newEnquiriesDetailsMap.values());
    setEnquiryDetailsMap(newEnquiriesDetailsMap);

    setAddedEnquiryDetailsMap(newAddedEnquiryDetailsMap);
    setRemovedEnquiryDetailIds(newRemoveEnquiryDetailIds);

    if (enquiryDetails?.length) {
      form.change("enquiryDetails", enquiryDetails);
    } else {
      form.change("enquiryDetails", null);
    }

    const changedEnquiryDetails = {
      added: Array.from(newAddedEnquiryDetailsMap.values()),
      removed: newRemoveEnquiryDetailIds
    }

    form.change("changedEnquiryDetails", changedEnquiryDetails);
  };

  const handleRemoveItemsFromEnquiry = (requisitionDetailIds: Array<Identifier>) => {
    const newEnquiriesDetailsMap = new Map(enquiryDetailsMap);
    const newAddedEnquiryDetailsMap = new Map(addedEnquiryDetailsMap);
    let newRemoveEnquiryDetailIds = removedEnquiryDetailIds;

    requisitionDetailIds.forEach((requisitionDetailId) => {
      const enquiryDetailId = requisitionEnquiryDetailIdsMap?.get(requisitionDetailId);
      newEnquiriesDetailsMap?.delete(enquiryDetailId);
      requisitionEnquiryDetailIdsMap?.delete(requisitionDetailId);

      if(originalRequisitionEnquiryDetailIdsMap?.has(requisitionDetailId) && !newRemoveEnquiryDetailIds?.includes(enquiryDetailId)){
        newRemoveEnquiryDetailIds?.push(enquiryDetailId);
      }

      newAddedEnquiryDetailsMap?.delete(enquiryDetailId);
    });

    const enquiryDetails = Array.from(newEnquiriesDetailsMap.values());
    setEnquiryDetailsMap(newEnquiriesDetailsMap);

    setAddedEnquiryDetailsMap(newAddedEnquiryDetailsMap);
    setRemovedEnquiryDetailIds(newRemoveEnquiryDetailIds);


    if (enquiryDetails?.length) {
      form.change("enquiryDetails", enquiryDetails);
    } else {
      form.change("enquiryDetails", null);
    }

    const changedEnquiryDetails = {
      added: Array.from(newAddedEnquiryDetailsMap.values()),
      removed: newRemoveEnquiryDetailIds
    }

    form.change("changedEnquiryDetails", changedEnquiryDetails);
  };

  const handleAddVendorsToEnquiry = (selectedIds: Array<Identifier>) => {
    let newVendorIds: Array<Identifier> = vendorIds;
    let newRemovedVendorIds = removedVendorIds;
    let newAddedVendorIds = addedVendorIds;

    selectedIds?.forEach((id) => {
      const allVendorsRecord = allVendorsMap.get(id);

      if (!enquiryVendorsMap.has(id)) {
        const newVendorRecord = {
          ...allVendorsRecord,
          ...{
            isNew: true,
          },
        };

        enquiryVendorsMap.set(id, newVendorRecord);
        newVendorIds.push(id);

        if(!newAddedVendorIds?.includes(id) 
          && !originalVendorIds?.includes(id)){
          newAddedVendorIds.push(id)
        }
      }

      if(newRemovedVendorIds?.includes(id)){
        newRemovedVendorIds = newRemovedVendorIds?.filter((vendorId) => vendorId !== id);
      }

    });

    setVendorIds(newVendorIds);
    if (newVendorIds && newVendorIds.length) {
      form.change("vendorIds", newVendorIds);
    } else {
      form.change("vendorIds", null);
    }

    setAddedVendorIds(newAddedVendorIds);
    setRemovedVendorIds(newRemovedVendorIds);
    
    const vendorChanges = {
      added: newAddedVendorIds,
      removed: newRemovedVendorIds,
    }
    form.change("vendorChanges", vendorChanges);
  };

  const handleRemoveVendorsFromEnquiry = (selectedIds: Array<Identifier>) => {
    let newVendorIds: Identifier[] = vendorIds;
    let newAddedVendorIds = addedVendorIds;
    let newRemovedVendorIds = removedVendorIds;

    selectedIds?.forEach((id) => {
      const allVendorsRecord = allVendorsMap.get(id);
      const toggledVendorId = allVendorsRecord.vendorId;

      if (enquiryVendorsMap.has(id)) {
        enquiryVendorsMap.delete(id);
        const newRecordIndex = vendorIds.indexOf(toggledVendorId);
        newVendorIds = [
          ...newVendorIds.splice(0, newRecordIndex),
          ...newVendorIds.splice(newRecordIndex + 1),
        ];

        if(newAddedVendorIds?.includes(id)){
          newAddedVendorIds = newAddedVendorIds?.filter((vendorId) => vendorId !== id);
        }

        if(!newRemovedVendorIds?.includes(id) 
          && originalVendorIds?.includes(id)){
          newRemovedVendorIds.push(id);
        }
      }
    });
    
    setRemovedVendorIds(newRemovedVendorIds);
    setAddedVendorIds(newAddedVendorIds);

    setVendorIds(newVendorIds);
    if (newVendorIds && newVendorIds.length) {
      form.change("vendorIds", newVendorIds);
    } else {
      form.change("vendorIds", null);
    }

    const vendorChanges = {
      added: newAddedVendorIds,
      removed: newRemovedVendorIds,
    }
    form.change("vendorChanges", vendorChanges);
  };

  return (
    <>
      <RequisitionSummaryContainer />

      {selectedRequisitionId && (
        <SectionTitleSubtitle
          title="resources.enquiries.sections.enquiry.input.title"
          subTitle="resources.enquiries.sections.enquiry.input.subTitle"
        />
      )}

      {selectedRequisitionId && (
        <Box className={classes.enquiryLayout1}>
          <TextInput
            source="code"
            helperText="resources.enquiries.help.code"
            validate={[required()]}
          />
          <ReferenceInput
            label="resources.enquiries.fields.currencyId"
            source="currencyId"
            reference={RESOURCE_NAME_CURRENCIES}
            validate={[required()]}
          >
            <AutocompleteInput
              optionText="displayLabel"
              helperText="resources.enquiries.help.currencyId"
              resettable={true}
            />
          </ReferenceInput>
        </Box>
      )}

      {selectedRequisitionId && (
        <Box className={classes.enquiryLayout2}>
          <DateTimeInput
            label="resources.enquiries.fields.bidStartDate"
            source="bidStartDate"
            helperText="resources.enquiries.help.bidStartDate"
            validate={validations.bidStartDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
          />

          <DateTimeInput
            label="resources.enquiries.fields.bidEndDate"
            source="bidEndDate"
            helperText="resources.enquiries.help.bidEndDate"
            validate={validations.bidEndDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
          />

          <DateTimeInput
            label="resources.enquiries.fields.preferredDeliveryDate"
            source="preferredDeliveryDate"
            helperText="resources.enquiries.help.preferredDeliveryDate"
            validate={validations.preferredDeliveryDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
          />
        </Box>
      )}

      {selectedRequisitionId && (
        <Fragment>
          <SectionTitleSubtitle
            title="resources.enquiries.sections.items.input.title"
            subTitle="resources.enquiries.sections.items.input.subTitle"
          />
          <DefaultList
            resource={RESOURCE_NAME_REQUISITION_DETAILS}
            basePath="/"
            exporter={false}
            perPage={DEFAULT_PER_PAGE}
            title="Enquiries"
            showBreadcrumbs={false}
            sort={{ field: "sortOrder", order: "ASC" }}
            filters={<ItemListFilters />}
            filter={{
              requisitionId: selectedRequisitionId,
              openRequisitionDetailsForEnquiry: selectedEnquiryId,
            }}
            syncWithLocation={false}
            listRowActions={false}
            actions={false}
            bulkActionButtons={
              <EnquiryBulkActionButtons
                handleAddItemsToEnquiry={handleAddItemsToEnquiry}
                handleRemoveItemsFromEnquiry={handleRemoveItemsFromEnquiry}
              />
            }
            empty={
              <EmptyState
                title="resources.enquiries.sections.items.emptyState"
                subTitle={null}
                Icon={itemResource.icon}
                className={classes.emptyStateContainer}
                iconClassName={classes.emptyStateIcon}
              />
            }
            className={classes.itemsGrid}
          >
            <Datagrid
              rowStyle={embeddedDataGridRowStyle(hasRequisitionDetailsId)}
              expand={
                <SingleItemShowContainer
                  procureAccountId={record?.procureAccountId}
                />
              }
            >
              <TextField
                source="sortOrder"
                label="resources.enquiries.fields.serialNum"
              />
              <ReferenceField
                label="resources.enquiries.fields.itemId"
                source="itemId"
                reference={RESOURCE_NAME_ITEM}
                link={false}
              >
                <TruncatedTextField
                  source="displayLabel"
                  truncateLength={250}
                />
              </ReferenceField>

              <NumberField source="quantity" />

              <ReferenceField
                label="resources.enquiries.fields.uomId"
                source="uomId"
                reference={RESOURCE_NAME_UOM}
                link={false}
              >
                <TextField source="displayLabel" />
              </ReferenceField>

              {record?.showUnitPrice2 && <NumberField source="quantity2" />}

              {record?.showUnitPrice2 && (
                <ReferenceField
                  label="resources.enquiries.fields.uom2Id"
                  source="uom2Id"
                  reference={RESOURCE_NAME_UOM}
                  link={false}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
              )}

              <TruncatedTextField
                source="make"
                label="resources.enquiries.fields.make"
                truncateLength={25}
              />
              <TruncatedTextField
                source="size"
                label="resources.enquiries.fields.size"
                truncateLength={25}
              />
              <RichTextField
                source="specifications"
                label="resources.enquiries.fields.specifications"
              />
              <TruncatedTextField
                source="comments"
                label="resources.enquiries.fields.comments"
                truncateLength={25}
              />
            </Datagrid>
          </DefaultList>
        </Fragment>
      )}

      {selectedRequisitionId && (
        <Fragment>
          <SectionTitleSubtitle
            title="resources.enquiries.sections.vendors.input.title"
            subTitle="resources.enquiries.sections.vendors.input.subTitle"
          />
          <DefaultList
            resource={RESOURCE_NAME_VENDOR}
            basePath="/"
            exporter={false}
            perPage={DEFAULT_PER_PAGE}
            title="Vendors"
            showBreadcrumbs={false}
            sort={{ field: "id", order: "ASC" }}
            filters={<VendorListFilters />}
            syncWithLocation={false}
            listRowActions={false}
            actions={false}
            bulkActionButtons={
              <VendorsBulkActionButtons
                handleAddVendorsToEnquiry={handleAddVendorsToEnquiry}
                handleRemoveVendorsFromEnquiry={handleRemoveVendorsFromEnquiry}
              />
            }
            empty={
              <EmptyState
                title="resources.enquiries.sections.vendors.emptyState"
                subTitle={null}
                Icon={vendorResource.icon}
                className={classes.emptyStateContainer}
                iconClassName={classes.emptyStateIcon}
              />
            }
          >
            <Datagrid
              rowStyle={embeddedDataGridRowStyle(hasVendorId)}
              expand={<SingleVendorShowContainer />}
            >
              <TextField
                source="displayLabel"
                label="resources.enquiries.fields.vendorAccountName"
                sortable={false}
              />
              <TextField
                source="vendorAccount.primaryUser.fullName"
                label="resources.enquiries.fields.vendorPrimaryUserName"
                sortable={false}
              />
              <EmailField
                source="vendorAccount.primaryUser.email"
                label="resources.enquiries.fields.vendorPrimaryUserEmail"
                sortable={false}
              />
            </Datagrid>
          </DefaultList>
        </Fragment>
      )}

      <AccordionField
        headerlabel="resources.enquiries.sections.conditions.input.title"
        headerTooltip="resources.enquiries.sections.conditions.input.tooltip"
        className={classes.remarksAccordion}
      >
        <Box>
          <SectionTitleSubtitle title="resources.enquiries.sections.conditions.input.heading" />
          <ArrayInput source="conditions" label={false}>
            <SimpleFormIterator classes={arrayInputClasses}>
              <TextInput
                source="conditionType"
                label="resources.enquiries.fields.conditionType"
                helperText="resources.enquiries.help.conditionType"
                resettable={true}
                validate={[required()]}
              />
              <TextInput
                multiline
                fullWidth={true}
                source="condition"
                label="resources.enquiries.fields.condition"
                helperText="resources.enquiries.help.condition"
                resettable={true}
                validate={[required()]}
              />
            </SimpleFormIterator>
          </ArrayInput>
        </Box>
      </AccordionField>

      <AccordionField
        headerlabel="resources.enquiries.sections.remarks.input.title"
        headerTooltip="resources.enquiries.sections.remarks.input.tooltip"
        className={classes.remarksAccordion}
      >
        <RichTextInput
          source="remarks"
          helperText="resources.bids.help.remarks"
          label={false}
        />
      </AccordionField>

      <AccordionField
        headerlabel="resources.enquiries.sections.fileUpload.input.title"
        headerTooltip="resources.enquiries.sections.fileUpload.input.tooltip"
      >
        <Box display="flex" flexDirection="column" width="100%">
          <MediaInputField showUploadedFiles={true} />
        </Box>
      </AccordionField>

      <CommentsAccordion
        ownerEntityType={OwnerEntityTypes.ENQUIRY}
        ownerEntityId={record?.id}
        isViewOnly={false}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsGrid: {
      "& div:nth-of-type(2) .MuiPaper-root": {
        width: "95vw",
        whiteSpace: "nowrap",
        overflowX: "auto",
      },
    },
    account: { display: "block", width: 544 },

    vendor: { display: "block", width: 544 },
    arrayInput: { display: "flex", flexDirection: "column" },
    formIterator: { form: { display: "flex" } },
    arrayField: { padding: "4px" },
    remarksAccordion: {
      width: "100%",
      marginTop: 25,
    },
    enquiryLayout1: {
      width: "100%",
      display: "grid",
      paddingLeft: 15,
      gridTemplateColumns: "1fr 1fr",
      gridGap: 35,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    enquiryLayout2: {
      width: "100%",
      display: "grid",
      paddingLeft: 15,
      paddingTop: 15,
      paddingBottom: 15,
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 15,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
    emptyStateContainer: {
      width: "100%",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start"
    },
    emptyStateIcon: {
      width: "1.25em",
      height: "1.25em",
      alignSelf: "center",
    },
  })
);

const useArrayInputStyles = makeStyles((theme) => ({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridColumnGap: 50,
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "100%" },
  },
}));

export { EnquiryEditContainer };
