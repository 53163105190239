export const RESOURCE_NAME_ITEM = "items";

export enum ItemAbcIndicator {
  CRITICAL = 1,
  MEDIUM,
  LESS_CRITICAL,
}

export const ABC_INDICATOR_CHOICES = [
  { id: 1, name: "resources.items.choices.itemType.critical" },
  { id: 2, name: "resources.items.choices.itemType.medium" },
  { id: 3, name: "resources.items.choices.itemType.less_critical" },
];

export enum PermissionItems {
  ITEMS_MANAGEMENT_MENU = "view-items-management-menu",
  MENU_ITEMS = "view-items-menu",
  LIST_ITEMS = "list-items",
  SHOW_ITEM = "show-item",
  CREATE_ITEM = "create-item",
  EDIT_ITEM = "update-item",
  DELETE_ITEM = "delete-item",
  IMPORT_ITEMS = "import-items",
}
