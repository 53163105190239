import React, { ReactElement } from "react";

import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  DateField,
  BooleanField,
  SelectField,
  SelectInput,
  RichTextField,
  ReferenceField,
} from "react-admin";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../lib/components";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../lib/common";
import { CATEGORY_TYPE_CHOICES, RESOURCE_NAME_CATEGORY } from "./constants";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />

    <SelectInput
      source="categoryType"
      label="resources.category.fields.categoryType"
      choices={CATEGORY_TYPE_CHOICES}
      allowEmpty={false}
    />
  </Filter>
);
const defaultColumns = ["number", "name", "categoryType", "description"];

const CategoryList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="number" />
        <TextField source="name" />
        <SelectField source="categoryType" choices={CATEGORY_TYPE_CHOICES} />
        <RichTextField source="description" />
        <ReferenceField source="parentId" reference={RESOURCE_NAME_CATEGORY}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { CategoryList };
