import { ResourceContextProvider, ShowContextProvider, ShowProps, useShowController } from "react-admin";
import { RESOURCE_NAME_VENDOR } from "../constants";
import { VendorShowContainer } from "./VendorShowContainer";

const SingleVendorShowContainer = (props) => {
    const vendorId = props?.record?.vendorId || props?.record?.id;
    const showProps: ShowProps = {
        basePath: '/',
        hasCreate: false,
        hasShow: true,
        hasList: false,
        id: vendorId,
        resource: RESOURCE_NAME_VENDOR
    }
    const controllerProps = useShowController({...showProps}); 

    return (
        <ResourceContextProvider value={RESOURCE_NAME_VENDOR}>
            <ShowContextProvider value={controllerProps}>
              <VendorShowContainer {...props} {...controllerProps} shouldNotSyncWithLocation={true}/>
          </ShowContextProvider>
        </ResourceContextProvider>
    );
}

export { SingleVendorShowContainer }