import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../../lib/components/crud/DefaultCreate";
import { OrgStructureForm } from "./OrgStructureForm";

const OrgStructureCreate = (props: CreateProps) => {
  return (
    <DefaultCreate {...props}>
      <OrgStructureForm />
    </DefaultCreate>
  );
};

export { OrgStructureCreate };