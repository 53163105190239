import { createStyles, makeStyles, Theme } from "@material-ui/core";
import MediaIcon from "@material-ui/icons/Backup";
import { EmptyState } from "../../..";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyStateContainer: {
      width: "25%",
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start"
    },
    emptyStateIcon: {
      width: "1.25em",
      height: "1.25em",
      alignSelf: "center",
    },
  })
);

const MediaEmptyState = () => {
  const classes = useStyles();

  return (
    <EmptyState
      title="resources.media.sections.emptyState.title"
      subTitle={null}
      Icon={MediaIcon}
      className={classes.emptyStateContainer}
      iconClassName={classes.emptyStateIcon}
    />
  );
};

export { MediaEmptyState };
