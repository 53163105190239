import {useContext } from "react";
import {
  DefaultListActions,
  DefaultListActionsProps,
} from "../DefaultListActions";
import ColumnIcon from "@material-ui/icons/ViewColumn";
import {
  ListVerticalActionMenuButton,
  ListVerticalActionMenuItemButton,
} from "../../../buttons";
import { useTranslate } from "react-admin";
import { CustomizableListContext } from "./CustomizableListContext";
import { CustomizableListActionTypes } from "./types";

const CustomizableListActions= (props:DefaultListActionsProps) => {
  const translate = useTranslate();
  const {  dispatch } = useContext(CustomizableListContext);

  const handleFieldSelectionDialogOpen = () => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.OPEN_DIALOG,
      });
    }
  };

  return (
    <DefaultListActions {...props}>
      <ListVerticalActionMenuButton>
        <ListVerticalActionMenuItemButton
          leftIcon={<ColumnIcon />}
          onClick={handleFieldSelectionDialogOpen}
          primaryText={translate(
            "components.lists.customizableList.dialogLabel"
          )}
          tooltip={translate(
            "components.lists.customizableList.dialogTooltip"
          )}
        />
      </ListVerticalActionMenuButton>
      {props?.children}
    </DefaultListActions>
  );
};

CustomizableListActions.displayName = "CustomizableListActions";

export { CustomizableListActions };
