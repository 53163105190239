import { Fragment, useEffect, useState } from "react";
import RichTextInput from "ra-input-rich-text";
import {
  AutocompleteInput,
  RecordContextProvider,
  ReferenceInput,
  required,
  useGetList,
  Record,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  useGetOne,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { useForm } from "react-final-form";
import { DEFAULT_DATE_TIME_OPTIONS, MAX_RECORDS_PER_PAGE, UserFormMessage } from "../../../lib/common";
import {
  AccordionField,
  DateTimeInput,
  MediaInputField,
} from "../../../lib/components";
import { BidDetailRecord, RESOURCE_NAME_BID } from "../constants";
import { VendorBidForm } from "./VendorBidForm";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { SectionTitleSubtitle } from "../../../lib/components";
import {
  RESOURCE_NAME_ENQUIRY,
  RESOURCE_NAME_ENQUIRY_DETAILS,
} from "../../enquiries";
import { EnquirySummaryContainer } from "./EnquirySummaryContainer";
import { validations } from "./validations";
import { FILTER_DIRECTION } from "../../../common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    estimatedDeliveryDate: {
      marginTop: 25,
      marginLeft: 5,
    },
    remarksAccordion: {
      width: "100%",
      marginTop: 25,
    },
  })
);

const useArrayInputStyles = makeStyles((theme) => ({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridColumnGap: 50,
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "100%" },
  },
}));

const BidCreateContent = ({ isEdit = false, ...rest }) => {
  const classes = useStyles();
  const arrayInputClasses = useArrayInputStyles();
  const form = useForm();

  const queryParams = new URLSearchParams(useLocation().search);
  const requestEnquiryId = queryParams.get("enquiryId");

  const [selectedEnquiryId, setSelectedEnquiryId] = useState<
    string | undefined
  >();

  const [enquiry, setEnquiry] = useState<Record | undefined>();

  const { data: requestEnquiry } = useGetOne(
    RESOURCE_NAME_ENQUIRY,
    selectedEnquiryId ?? "",
    {
      enabled: selectedEnquiryId && selectedEnquiryId?.length ? true : false,
    }
  );

  useEffect(() => {
    if (requestEnquiryId) {
      form.change("requisitionId", requestEnquiryId);
      setSelectedEnquiryId(requestEnquiryId);
    }
  }, [requestEnquiryId]);

  const [bidDetails, setBidDetails] = useState<BidDetailRecord[] | undefined>();

  const { total, ids, data } = useGetList(
    RESOURCE_NAME_ENQUIRY_DETAILS,
    { perPage: MAX_RECORDS_PER_PAGE, page: 1 },
    { field: "sortOrder", order: "ASC" },
    { enquiryId: enquiry?.id },
    {
      enabled: enquiry && enquiry.id ? true : false,
      onSuccess: (response) => handleEnquiryDetailsLoaded(response?.data),
    }
  );

  const onEnquiryIdChange = (record) => {
    setEnquiry(record);
    setSelectedEnquiryId(record?.requisitionId);
    form.change("requisitionId", record?.requisitionId);
  };

  const handleEnquiryDetailsLoaded = (data) => {
    if (!data || !data.length) {
      return;
    }

    const bidDetailRecords = data?.map((existingRecord) => {
      const record: BidDetailRecord = {
        enquiryId: existingRecord.enquiryId,
        enquiryDetailId: existingRecord.id,
        requisitionId: existingRecord.requisitionId,
        requisitionDetailId: existingRecord.requisitionDetailId,
        itemId: existingRecord.itemId,
        quantity: existingRecord.quantity,
        uomId: existingRecord.uomId,
        quantity2: existingRecord.quantity2,
        uom2Id: existingRecord.uom2Id,
        sortOrder: existingRecord?.sortOrder,
        requisitionDetail: {
          make: existingRecord?.requisitionDetail?.make,
          size: existingRecord?.requisitionDetail?.size,
          specifications: existingRecord?.requisitionDetail?.specifications,
          comments: existingRecord?.requisitionDetail?.comments,
        },
      };

      return record;
    });

    setBidDetails(bidDetailRecords);
  };

  useEffect(() => {
    if (requestEnquiry) {
      form.change("enquiryId", requestEnquiry?.id);
      form.change("requisitionId", requestEnquiry?.requisitionId);
      form.change("showUnitPrice2", requestEnquiry?.showUnitPrice2);
      setEnquiry(requestEnquiry);
    }
  }, [requestEnquiry]);

  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.bids.sections.bid.create.title"
        subTitle="resources.bids.sections.bid.create.subTitle"
      />
      {!requestEnquiryId && (
        <ReferenceInput
          label="resources.bids.fields.enquiryId"
          source="enquiryId"
          reference={RESOURCE_NAME_ENQUIRY}
          filter={{
            direction: FILTER_DIRECTION.RECEIVED,
            openEnquiries: true,
          }}
          validate={[required()]}
          onSelect={onEnquiryIdChange}
          perPage={100}
          sort={{ field: "code", order: "ASC" }}
        >
          <AutocompleteInput
            optionText="displayLabel"
            helperText="resources.bids.help.enquiryId"
          />
        </ReferenceInput>
      )}

      {!enquiry && (
        <UserFormMessage
          line1="resources.bids.messages.openEnquiries.line1"
          line2="resources.bids.messages.openEnquiries.line2"
        />
      )}
      {enquiry && (
        <RecordContextProvider
          value={{
            id: enquiry.id,
            enquiryId: enquiry.id,
          }}
        >
          <EnquirySummaryContainer />
        </RecordContextProvider>
      )}
      {enquiry && bidDetails && (
        <Fragment>
          <DateTimeInput
            source="estimatedDeliveryDate"
            className={classes.estimatedDeliveryDate}
            helperText="resources.bids.help.estimatedDeliveryDate"
            validate={validations.estimatedDeliveryDate}
            options={DEFAULT_DATE_TIME_OPTIONS}
            isRequired
          />

          <SectionTitleSubtitle
            title="resources.bids.sections.items.create.title"
            subTitle="resources.bids.sections.items.create.subTitle"
          />
          <VendorBidForm
            enquiryId={enquiry.id}
            currencyId={enquiry.currencyId}
            bidDetails={bidDetails}
            source=""
            resource={RESOURCE_NAME_BID}
            showUnitPrice2={enquiry.showUnitPrice2}
          />
          <AccordionField
            headerlabel="resources.bids.sections.conditions.input.title"
            headerTooltip="resources.bids.sections.conditions.input.tooltip"
            className={classes.remarksAccordion}
          >
            <Box>
              <SectionTitleSubtitle title="resources.bids.sections.conditions.input.heading" />
              <ArrayInput source="conditions" label={false}>
                <SimpleFormIterator classes={arrayInputClasses}>
                  <TextInput
                    source="conditionType"
                    label="resources.bids.fields.conditionType"
                    helperText="resources.bids.help.conditionType"
                    resettable={true}
                    validate={[required()]}
                  />
                  <TextInput
                    multiline
                    fullWidth={true}
                    source="condition"
                    label="resources.bids.fields.condition"
                    helperText="resources.bids.help.condition"
                    resettable={true}
                    validate={[required()]}
                  />
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </AccordionField>
          <AccordionField
            headerlabel="resources.bids.sections.remarks.input.title"
            headerTooltip="resources.bids.sections.remarks.input.tooltip"
            className={classes.remarksAccordion}
          >
            <RichTextInput
              source="remarks"
              helperText="resources.bids.help.remarks"
              label={false}
            />
          </AccordionField>
          <AccordionField
            headerlabel="resources.bids.sections.fileUpload.input.title"
            headerTooltip="resources.bids.sections.fileUpload.input.tooltip"
          >
            <Box display="flex" flexDirection="column" width="100%">
              <MediaInputField showUploadedFiles={isEdit} />
            </Box>
          </AccordionField>
        </Fragment>
      )}
    </Box>
  );
};

export { BidCreateContent };
