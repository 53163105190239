import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  ReferenceArrayField,
  RichTextField,
  Datagrid,
  EmailField,
} from "react-admin";
import { DefaultShow, ResourceLinkedField } from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_USER } from "../../../../lib/modules";
import { RESOURCE_NAME_ORG_USER_GROUP } from "./constants";

const OrgUserGroupshow = (props: ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField
          source="number"
          label="resources.OrgUserGroups.fields.number"
        />
        <TextField source="name" label="resources.OrgUserGroups.fields.name" />
        <RichTextField
          source="description"
          label="resources.businessFunction.fields.description"
        />
        <ReferenceField
          source="level1ApprovalGroupId"
          label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField
          source="level2ApprovalGroupId"
          label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <ReferenceArrayField
          source="userIds"
          label="resources.OrgUserGroups.fields.userIds"
          reference={RESOURCE_NAME_USER}
        >
          <Datagrid optimized>
            <ResourceLinkedField
              source="fullName"
              label="resources.users.fields.fullName"
              linkedResource={RESOURCE_NAME_USER}
              linkedResourceIdSource="id"
              resource={RESOURCE_NAME_USER}
              link="show"
            />
            <EmailField source="email" label="resources.users.fields.email"/>
          </Datagrid>
        </ReferenceArrayField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { OrgUserGroupshow };
