import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../../lib/components";
import { MediaFileSource, transformAndUploadS3Files } from "../../../../lib/modules";
import { ItemSegmentForm } from "./ItemSegmentForm";

const ItemSegmentCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [{
    source: 'files',
    subFolder: 'itemSegments',
  }];  
  return (
      <DefaultCreate {...props}transform={transformAndUploadS3Files(mediaFileSources)}>
          <ItemSegmentForm />
      </DefaultCreate>
    );
  };
  
  export { ItemSegmentCreate };