import {
  TextInput,
  required,
  useTranslate,
  SimpleForm,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { MediaInputField } from "../../../components";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  code: { display: "block" },
  description: { display: "block" },
};

const useStyles = makeStyles(styles);

const AccountForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm {...rest} warnWhenUnsavedChanges>
      <SectionTitle label="resources.accounts.fieldGroups.accountDetails" />
      <TextInput
        autoFocus
        source="name"
        label="resources.accounts.fields.name"
        helperText="resources.accounts.help.name"
        formclassName={classes.name}
        validate={[required()]}
      />

      <TextInput
        source="code"
        label="resources.accounts.fields.code"
        helperText="resources.accounts.help.code"
        formclassName={classes.code}
        validate={[required()]}
      />
      <TextInput
        source="description"
        label="resources.accounts.fields.description"
        helperText="resources.accounts.help.description"
        formclassName={classes.description}
        resettable
        multiline
      />
      <MediaInputField showUploadedFiles={isEdit} />

    </SimpleForm>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { AccountForm };
