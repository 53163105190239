import { EditContextProvider, useEditController } from "react-admin";
import { RESOURCE_NAME_BIDS_COMPARISON } from "../../bidsComparison";
import { BidsComparisonView } from "../../bidsComparison/components/BidsComparisonView";

const EnquiryShowBidEnquiry = (props) => {
  const controllerProps = useEditController({
    ...props,
    id: props?.id,
    resource: RESOURCE_NAME_BIDS_COMPARISON,
  });

  const { loaded, record } = controllerProps;
  return (
    <EditContextProvider value={controllerProps}>
      <BidsComparisonView
        id={props?.id}
        loaded={loaded}
        excludedItemIds={record?.excluded?.items || []}
        excludedVendorAccountIds={record?.excluded?.vendorAccounts || []}
        priceComponents={record?.priceComponents}
        isReadOnly={true}
      />
    </EditContextProvider>
  );
};

export { EnquiryShowBidEnquiry };
