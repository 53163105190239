import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarVisibility,
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  Datagrid,
  NumberField,
  DateField,
  Labeled,
  ResourceContextProvider,
  List,
  useRecordContext,
  RichTextField,
} from "react-admin";
import {
  AccordionField,
  DefaultShow,
  SectionTitleSubtitle,
  TruncatedTextField,
  UploadedFilesField,
} from "../../lib/components";
import { RESOURCE_NAME_UOM } from "../datasetup/unit-of-measure";

import { RESOURCE_NAME_ITEM } from "../datasetup/items/items/constants";
import { RESOURCE_NAME_REQUISITION_DETAILS } from "./constants";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { DEFAULT_PER_PAGE } from "../../lib/common";
import { CommentsAccordion, OwnerEntityTypes } from "../comments";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../datasetup/business-functions/constants";
import {
  ApprovalStatusField,
  isMatchingAccount,
  ProcureAccountField,
  UserNameEmailField,
  RecordStatusField,
} from "../../common";
import { SingleItemShowContainer } from "../datasetup/items/items/components/SingleItemShowContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemsGrid: {
      "& div:nth-of-type(1) .MuiPaper-root": {
        width: "95vw",
        whiteSpace: "nowrap",
        overflowX: "auto",
      },
    },
  })
);

const RequisitionShow = (props: ShowProps) => {
  const requisitionId = props?.id;
  const classes = useStyles();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  const ShowDetail = (props) => {
    const record = useRecordContext(props);
    const showUnitPrice2 = record?.showUnitPrice2;
    const isProcureAccount = isMatchingAccount(record?.procureAccountId);
    const linkType = isProcureAccount ? "show" : false;

    return (
      <SimpleShowLayout {...props}>
        <SectionTitleSubtitle
          title="resources.requisitions.sections.header.show.title"
          subTitle="resources.requisitions.sections.header.show.subTitle"
        />
        <Box
          width="100%"
          pl={2}
          mb={2}
          display="grid"
          gridTemplateColumns="1fr 1fr"
        >
          <Labeled id="code" source="code">
            <TextField source="code" />
          </Labeled>
          <Labeled id="businessFunctionId" source="businessFunctionId">
            <ReferenceField
              source="businessFunctionId"
              reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
            >
              <TextField source="displayLabel" />
            </ReferenceField>
          </Labeled>
        </Box>
        <Box
          width="100%"
          pl={2}
          mb={2}
          display="grid"
          gridTemplateColumns="1fr"
        >
          <Labeled id="description" source="description">
            <RichTextField source="description" />
          </Labeled>
        </Box>
        <Box
          width="100%"
          pl={2}
          mb={2}
          display="grid"
          gridTemplateColumns="1fr 1fr"
        >
          <RecordStatusField label="common.fields.status" useLabel={true} />
          <ApprovalStatusField
            label="common.fields.approvalStatus"
            useLabel={true}
          />
          <ProcureAccountField
            label="common.fields.procureAccountId"
            useLabel={true}
          />
          <UserNameEmailField
            source="procureOwnerUserId"
            label="common.fields.procureOwnerUserId"
            useLabel={true}
          />

          <Labeled id="createdAt" source="createdAt">
            <DateField source="createdAt" />
          </Labeled>
          <Labeled id="updatedAt" source="updatedAt">
            <DateField source="updatedAt" />
          </Labeled>
        </Box>
        <SectionTitleSubtitle
          title="resources.requisitions.sections.items.show.title"
          subTitle="resources.requisitions.sections.items.show.subTitle"
        />
        <ResourceContextProvider value={RESOURCE_NAME_REQUISITION_DETAILS}>
          <List
            basePath={`/${RESOURCE_NAME_REQUISITION_DETAILS}`}
            filter={{ requisitionId }}
            perPage={DEFAULT_PER_PAGE}
            sort={{ field: "sortOrder", order: "ASC" }}
            title={"Requisition Details"}
            actions={false}
            syncWithLocation={false}
            bulkActionButtons={false}
            className={classes.itemsGrid}
          >
            <Datagrid
              optimized
              expand={
                <SingleItemShowContainer
                  procureAccountId={record?.procureAccountId}
                  {...props}
                />
              }
            >
              <TextField
                source="sortOrder"
                label="resources.requisitionDetails.fields.serialNum"
              />
              <ReferenceField
                source="itemId"
                label="resources.requisitionDetails.fields.number"
                reference={RESOURCE_NAME_ITEM}
                linkType={linkType}
              >
                <TextField source="number" />
              </ReferenceField>
              <ReferenceField
                source="itemId"
                label="resources.requisitionDetails.fields.itemId"
                reference={RESOURCE_NAME_ITEM}
                linkType={linkType}
              >
                <TextField source="name" />
              </ReferenceField>
              <NumberField
                source="quantity"
                label="resources.requisitionDetails.fields.quantity"
              />
              <ReferenceField
                source="uomId"
                reference={RESOURCE_NAME_UOM}
                label="resources.requisitionDetails.fields.uomId"
              >
                <TextField source="displayLabel" />
              </ReferenceField>

              {showUnitPrice2 && (
                <NumberField
                  source="quantity2"
                  label="resources.requisitionDetails.fields.quantity2"
                />
              )}
              {showUnitPrice2 && (
                <ReferenceField
                  source="uom2Id"
                  reference={RESOURCE_NAME_UOM}
                  label="resources.requisitionDetails.fields.uom2Id"
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
              )}
              <TruncatedTextField
                source="make"
                label="resources.requisitionDetails.fields.make"
                truncateLength={50}
              />
              <TruncatedTextField
                source="size"
                label="resources.requisitionDetails.fields.size"
                truncateLength={25}
              />
              <RichTextField
                source="specifications"
                label="resources.requisitionDetails.fields.specifications"
              />
              <TruncatedTextField
                source="comments"
                label="resources.requisitionDetails.fields.comments"
                truncateLength={100}
              />
            </Datagrid>
          </List>
        </ResourceContextProvider>
        <Box py={2}>
          <AccordionField
            headerlabel="resources.requisitions.sections.remarks.field.title"
            headerTooltip="resources.requisitions.sections.remarks.field.tooltip"
          >
            <RichTextField source="remarks" />
          </AccordionField>
          <AccordionField
            headerlabel="resources.requisitions.sections.fileUpload.input.title"
            headerTooltip="resources.requisitions.sections.fileUpload.input.tooltip"
          >
            <Box display="flex" flexDirection="column" width="100%">
              <UploadedFilesField canDelete={false} />
            </Box>
          </AccordionField>
        </Box>

        {props?.id && (
          <Box py={2}>
            <CommentsAccordion
              ownerEntityType={OwnerEntityTypes.REQUISITION}
              ownerEntityId={props.id}
              isViewOnly={false}
            />
          </Box>
        )}
      </SimpleShowLayout>
    );
  };
  return (
    <DefaultShow {...props}>
      <ShowDetail />
    </DefaultShow>
  );
};

export { RequisitionShow };
