import { cloneElement, ReactElement, useMemo } from "react";
import { SingleListRowActionProps } from "../SingleListRowAction";
import { RedirectListRowAction } from "./RedirectListRowAction";
import {
  Record,
  linkToRecord,
  useResourceDefinition,
  useListContext,
} from "react-admin";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DeleteListRowActionButton } from "./DeleteListRowActionButton";

interface DefaultCrudListRowActionsProps extends SingleListRowActionProps {
  shouldDisplayShow?: (record?: Record, extraData?: any) => Promise<boolean>;
  shouldDisplayEdit?: (record?: Record, extraData?: any) => Promise<boolean>;
  shouldDisplayDelete?: (record?: Record, extraData?: any) => Promise<boolean>;
  calculateShowRedirectPath?: (record?: Record) => string;
  calculateEditRedirectPath?: (record?: Record) => string;
  extraShowDisplayData?: any;
  extraEditDisplayData?: any;
  extraDeleteDisplayData?: any;
  hasEdit?: boolean;
  hasShow?: boolean;
}

const DefaultCrudListRowActions = (props: DefaultCrudListRowActionsProps) => {
  const { basePath, resource } = useListContext();
  const { options } = useResourceDefinition({ resource });
  const { hasEdit, hasShow } = props;
  const showPermissionName = options?.allowedPermissions?.show;
  const editPermissionName = options?.allowedPermissions?.update;
  const deletePermissionName = options?.allowedPermissions?.delete;

  const {
    shouldDisplayShow,
    shouldDisplayEdit,
    shouldDisplayDelete,
    calculateShowRedirectPath,
    calculateEditRedirectPath,
    extraShowDisplayData,
    extraEditDisplayData,
    extraDeleteDisplayData,
    open,
    ...rest
  } = props;

  const hasEditPermission = hasEdit && editPermissionName ? true: false;
  const hasShowPermission = hasShow && showPermissionName ? true: false;
  const displayDeleteButton = deletePermissionName ? true: false;
  const keyPrefix = props?.record?.id;

  const defaultActions: Array<ReactElement> = useMemo(() => {
    const defaultActions: Array<ReactElement> = [];

    const calculateDefaultEditRedirectPath = (record?: Record) => {
      const editPath = record
        ? linkToRecord(basePath || `/${resource}`, record?.id)
        : "";

      return editPath;
    };

    const calculateDefaultShowRedirectPath = (record?: Record) => {
      const showPath = record
        ? `${linkToRecord(basePath || `/${resource}`, record?.id)}/show`
        : "";

      return showPath;
    };

     if (hasEditPermission) {
       defaultActions.push(
         <RedirectListRowAction
           key={`common.actions.edit-${keyPrefix}`}
           tooltipTitle="common.actions.edit"
           icon={<EditIcon />}
           calculateRedirectPath={
             calculateEditRedirectPath ?? calculateDefaultEditRedirectPath
            }
            shouldDisplay={shouldDisplayEdit}
            permissionKey="edit"
            open={open}
            extraDisplayData={extraEditDisplayData}
            {...rest}
         />
       );
     }

    if (hasShowPermission) {
      defaultActions.push(
        <RedirectListRowAction
          key={`common.actions.view-${keyPrefix}`}
          tooltipTitle="common.actions.view"
          icon={<VisibilityIcon />}
          calculateRedirectPath={
            calculateShowRedirectPath ?? calculateDefaultShowRedirectPath
          }
          shouldDisplay={shouldDisplayShow}
          permissionKey="show"
          open={open}
          extraDisplayData={extraShowDisplayData}
          {...rest}
        />
      );
    }

     if (displayDeleteButton) {
      defaultActions.push(
        <DeleteListRowActionButton
          key={`common.actions.delete-${keyPrefix}`}
          open={open}
          shouldDisplay={shouldDisplayDelete}
          tooltipTitle="common.actions.delete"
          extraDisplayData={extraDeleteDisplayData}
          {...rest}
        />
      );
    }

    return defaultActions;
  }, [hasEditPermission, hasShowPermission, displayDeleteButton, basePath, resource, keyPrefix, calculateEditRedirectPath, shouldDisplayEdit, open, extraEditDisplayData, rest, calculateShowRedirectPath, shouldDisplayShow, extraShowDisplayData, shouldDisplayDelete, extraDeleteDisplayData]);

  return (
    <>
      {defaultActions?.map((defaultAction) => {
        return cloneElement(defaultAction, {
          record: props?.record,
        });
      })}
    </>
  )
};

DefaultCrudListRowActions.displayName = "DefaultCrudListRowActions";

DefaultCrudListRowActions.defaultProps = {
  hasShow: true,
};

export { DefaultCrudListRowActions };
