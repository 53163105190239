import { FunctionComponent, ReactNode } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import ContentAddIcon from "@material-ui/icons/Add";
import ContentCreateIcon from "@material-ui/icons/Create";
import ImageEyeIcon from "@material-ui/icons/RemoveRedEye";
import { Link as RouterLink } from 'react-router-dom';

import { useTranslate } from "react-admin";
import DefaultIcon from "@material-ui/icons/ViewList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

export enum CrudActionType {
  CREATE = "ra.action.create",
  EDIT = "ra.action.edit",
  SHOW = "ra.action.show",
}

const actionIcons = {
  [CrudActionType.CREATE]: ContentAddIcon,
  [CrudActionType.EDIT]: ContentCreateIcon,
  [CrudActionType.SHOW]: ImageEyeIcon,
};

export interface CrudActionBreadcrumbProps {
  resourceLabel: string;
  listPath: string;
  Icon: FunctionComponent<any>;
  actionType: CrudActionType | string;
  actionIcon? : ReactNode;
}

const CrudActionBreadcrumb = ({
  resourceLabel,
  listPath,
  Icon,
  actionType,
  actionIcon,
}: CrudActionBreadcrumbProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const ActionIcon = actionIcon ?? actionIcons[actionType];

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" to="/" component={RouterLink} className={classes.link}>
        <HomeIcon className={classes.icon} />
        {translate("ra.page.dashboard")}
      </Link>
      <Link color="inherit" to={listPath} component={RouterLink} className={classes.link}>
        {Icon ? (
          <Icon className={classes.icon} />
        ) : (
          <DefaultIcon className={classes.icon} />
        )}
        {resourceLabel && translate(resourceLabel, {smart_count: 2})}
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        {<ActionIcon className={classes.icon} />}
        {translate(actionType)}
      </Typography>
    </Breadcrumbs>
  );
};

export { CrudActionBreadcrumb };
