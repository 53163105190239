import { fetchUtils } from "react-admin";
import { httpClient, Options } from "../../../../core/providers/dataProvider";
import { MediaFile, MediaFileSource } from "../constants";

class S3FileUploader {
  protected s3SignUrl: string;
  protected mediaFileSource: MediaFileSource;
  constructor(mediaFileSource: MediaFileSource) {
    this.mediaFileSource = mediaFileSource;
    this.s3SignUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/media/sign`;
  }

  public async startUpload(files: any): Promise<MediaFile[]> {
    const options: Options = {
      method: "POST",
     // headers:headers,
    }

    let mediaFiles: MediaFile[] = [];
    if (Array.isArray(files)) {
      for (var i = 0; i < files.length; i++) {
        const file = files[i];
        const mediaFile = await this.uploadFile(file, options);
        if (mediaFile) {
          mediaFiles.push(mediaFile);
        }
      }
    } else {
      const mediaFile = await this.uploadFile(files, options);
        if (mediaFile) {
          mediaFiles.push(mediaFile);
        }
    }

    return mediaFiles;
  }

  protected async uploadFile(file: any, options: Options): Promise<MediaFile | null> {
    if (!file || !file.rawFile) {
      return null;
    }
    const rawFile = file.rawFile;
    const fileName = this.scrubFilename(rawFile.name);
    const fileType = rawFile.type;
    const fileSize = rawFile.size;
    const fileExtension = fileName.split(".").pop();
    const params = {
      objectName: fileName,
      contentType: rawFile.type,
      path: this.mediaFileSource.subFolder,
    };
    const body = JSON.stringify(params);
    options.body = body;

    const signUrl = `${this.s3SignUrl}`;

    const signedResponse = await httpClient(signUrl, options);
    const signedUploadUrl = signedResponse.json?.data?.presignedUrl;
    const filePath = signedResponse.json?.data?.filePath;

    const s3Headers = new Headers({
      "Content-Type": fileType,
    });

    const uploadResponse = await fetch(signedUploadUrl, {
      method: "PUT",
      headers: s3Headers,
      body: rawFile,
    });

    const mediaFile: MediaFile = {
      originalFileName: fileName,
      filePath: filePath,
      fileExtension: fileExtension,
      fileType: fileType,
      fileSize: fileSize,
    };
    return mediaFile;
  }

  protected scrubFilename(filename) {
    return filename.replace(/[^\w\d_\-\.]+/gi, "");
  }
}

export { S3FileUploader };
