import {forwardRef, useContext } from "react";
import {
  Box,
  createStyles,
  Theme,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  IconButton,
  Select,
  MenuItem,
  Tooltip,
  FormControl,
  FormHelperText,
  Slide,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ReplayIcon from "@material-ui/icons/Replay";
import { BidComparisonCalculationActionTypes, PriceComponent } from "./types";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useTranslate } from "react-admin";
import { v4 as uuidv4 } from "uuid";
import { EmptyState } from "../../../lib/components";
import EmptyPriceComponentIcon from "@material-ui/icons/MoneyOff";
import { TransitionProps } from "@material-ui/core/transitions";
import { BidComparisonCalculationContext } from "./context";

const PRICE_COMPONENT_FIELD_NAME = "priceComponents";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
    },
    title: {
      marginBottom: theme.spacing(4),
    },
    emptyStateContainer: {
      display: "flex",
      justifyContent: "center",
    },
    row: {
      display: "inline-grid",
      gridAutoFlow: "column",
      gridColumnGap: 5,
    },
    field: {
      padding: 5,
    },
    unitType: {
      padding: 5,
      justifyContent: "flex-end",
    },
    removeIcon: {
      margin: 0,
      marginLeft: 4,
      color: theme.palette.error.main,
    },
    bottomRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    icon: {
      width: "1.5em",
      height: "1.5em",
    },
  })
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManagePriceComponents= () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(BidComparisonCalculationContext);

  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<PriceComponent>({
    mode: "onChange",
    shouldUnregister: true,
    defaultValues: {
      [PRICE_COMPONENT_FIELD_NAME]: state?.bidsComparisonCalc?.priceComponents,
    },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: PRICE_COMPONENT_FIELD_NAME,
  });

  const onSubmit = (data) => {
    if (dispatch) {
      dispatch({
        type: BidComparisonCalculationActionTypes.RECALCULATE_PRICE_COMPONENTS,
        payload: {
          priceComponents: data?.priceComponents,
        },
      });
    }
  };

  const onSubmitCheckChangedData = (data) => {
    if (
      (!data?.priceComponents || !data?.priceComponents?.length) &&
      state?.priceComponents &&
      state?.priceComponents?.length
    ) {
      onSubmit(data);
    } else {
      handleClose();
    }
  };

  const translate = useTranslate();

  const handleClose = () => {
    if (dispatch) {
      dispatch({
        type: BidComparisonCalculationActionTypes.CLOSE_MANAGE_PRICE_COMPONENTS_DIALOG,
      });
    }
  };

  const addPriceComponent = () => {
    append({
      id: uuidv4(),
      unitType: 1,
    });
  };

  const removePriceComponent = (index: number) => {
    remove(index);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      keepMounted
      open={state?.isManagePriceComponentsDialogOpen ?? false}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-slide-description"
    >
      <DialogTitle id="dialog-title">
        {translate(
          "resources.bidsComparison.components.ManagePriceComponents.labels.title"
        )}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-slide-description">
          {translate(
            "resources.bidsComparison.components.ManagePriceComponents.labels.subTitle"
          )}
        </DialogContentText>
        <Box className={classes.container}>
          {!fields?.length && (
            <Box className={classes.emptyStateContainer}>
              <EmptyState
                Icon={EmptyPriceComponentIcon}
                iconClassName={classes.icon}
                title="resources.bidsComparison.components.ManagePriceComponents.messages.empty.title"
                subTitle="resources.bidsComparison.components.ManagePriceComponents.messages.empty.subTitle"
              />
            </Box>
          )}
          {fields?.length > 0 && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {fields.map((row, rowNumber: number) => {
                return (
                  <Box key={row.id} className={classes.row}>
                    <Controller
                      name={`${PRICE_COMPONENT_FIELD_NAME}.${rowNumber}.name`}
                      control={control}
                      rules={{
                        required: translate(
                          "resources.bidsComparison.components.ManagePriceComponents.validations.required"
                        ),
                      }}
                      defaultValue={row?.["name"]}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl className={classes.field}>
                          <TextField
                            label={translate(
                              "resources.bidsComparison.fields.priceComponentName"
                            )}
                            error={!!error}
                            helperText={
                              error
                                ? error.message
                                : translate(
                                    "resources.bidsComparison.help.priceComponentName"
                                  )
                            }
                            {...field}
                          />
                        </FormControl>
                      )}
                    />
                    <Controller
                      name={`${PRICE_COMPONENT_FIELD_NAME}.${rowNumber}.value`}
                      control={control}
                      rules={{
                        required: translate(
                          "resources.bidsComparison.components.ManagePriceComponents.validations.required"
                        ),
                      }}
                      defaultValue={row?.["value"]}
                      render={({ field, fieldState: { error } }) => (
                        <FormControl className={classes.field}>
                          <TextField
                            label={translate(
                              "resources.bidsComparison.fields.value"
                            )}
                            type="number"
                            error={!!error}
                            helperText={
                              error
                                ? error.message
                                : translate(
                                    "resources.bidsComparison.help.value"
                                  )
                            }
                            {...field}
                            onChange={(e) => {
                              field.onChange(parseInt(e.target.value, 10));
                            }}
                          />
                        </FormControl>
                      )}
                    />

                    <Controller
                      name={`${PRICE_COMPONENT_FIELD_NAME}.${rowNumber}.unitType`}
                      control={control}
                      defaultValue={row?.["unitType"]}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <FormControl className={classes.unitType}>
                          <Select {...field}>
                            <MenuItem value={1}>
                              {translate(
                                "resources.bidsComparison.choices.unitType.flat"
                              )}
                            </MenuItem>
                            <MenuItem value={2}>
                              {translate(
                                "resources.bidsComparison.choices.unitType.percent"
                              )}
                            </MenuItem>
                          </Select>
                          <FormHelperText>
                            {translate(
                              "resources.bidsComparison.help.unitType"
                            )}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />

                    <Tooltip
                      title={translate(
                        "resources.bidsComparison.components.ManagePriceComponents.labels.removePriceComponent"
                      )}
                    >
                      <IconButton
                        aria-label="remove"
                        className={classes.removeIcon}
                        onClick={() => removePriceComponent(rowNumber)}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}
            </form>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          size="medium"
          startIcon={<AddCircleIcon />}
          onClick={() => addPriceComponent()}
        >
          {translate(
            "resources.bidsComparison.components.ManagePriceComponents.labels.addPriceComponent"
          )}
        </Button>

        <Button
          color="primary"
          variant="contained"
          size="medium"
          startIcon={<ReplayIcon />}
          disabled={!(fields?.length > 0 && isValid)}
          onClick={() => handleSubmit(onSubmit)()}
        >
          {translate(
            "resources.bidsComparison.components.ManagePriceComponents.labels.recalculate"
          )}
        </Button>
        <Button
          color="primary"
          onClick={() => handleSubmit(onSubmitCheckChangedData)()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ManagePriceComponents };
