import { cloneElement, MouseEventHandler, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import SpeedDial from "@material-ui/lab/SpeedDial";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import EditIcon from "@material-ui/icons/Edit";
import {
  FieldProps,
  Confirm,
} from "react-admin";

import { ListRowActionButtonsContext } from "./ListRowActionButtonsContext";
import { ListRowActionButtonsActionTypes } from "./types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 10,
      transform: "translateZ(0px)",
      flexGrow: 1,
    },
    speedDial: {
      zIndex: 11,
      "& .MuiFab-sizeSmall": {
        width: 35,
        height: 35,
        "& svg": {
          "width": "0.85em",
          "height": "0.85em",
        }
      },
      "& .MuiSpeedDial-actions[role=menu]": {
        zIndex: 12,
        position: "absolute",
        display: "flex",
        right: "30px",
        flexDirection: "row",
      },
    },
    fabPrimary: {
      margin: 0,
      marginLeft: 4,
      color: theme.palette.primary.main,
    },
    fabSecondary: {
      margin: 0,
      marginLeft: 4,
      color: theme.palette.secondary.main,
    },
    fabError: {
      margin: 0,
      marginLeft: 4,
      color: theme.palette.error.main,
    },
  })
);

export interface ListRowActionsProps extends FieldProps {
  overriddenListRowActions?: Array<ReactElement>,
}

const ListRowActions = (props: ListRowActionsProps) => {
  const classes = useStyles();
  const { state, dispatch } = useContext(ListRowActionButtonsContext);
  const overriddenListRowActions = props?.overriddenListRowActions;

  const { record } = props;

  const listRowActions = useMemo(() => {
    return overriddenListRowActions ?? state?.listRowActions;
  }, [overriddenListRowActions, state?.listRowActions])

  const [isSpeedDialOpen, setSpeedDialOpen] = useState(false);

  const handleSpeedDialOpen = () => {
    if (dispatch) {
      dispatch({
        type: ListRowActionButtonsActionTypes.OPEN_SPEED_DIAL,
      });
    }
    setSpeedDialOpen(true);
  };

  const handleSpeedDialClose = () => {
    if (dispatch) {
      dispatch({
        type: ListRowActionButtonsActionTypes.CLOSE_SPEED_DIAL,
      });
    }
    setSpeedDialOpen(false);
  };

  const handleConfirmClose = (event, onConfirmClose?: MouseEventHandler) => {
    handleSpeedDialClose();
    if(onConfirmClose){
      onConfirmClose(event);
    }
    return true;
  };

  if (listRowActions?.length) {

    return (
      <div className={classes.root}>
        <SpeedDial
          ariaLabel="Actions"
          className={classes.speedDial}
          icon={<SpeedDialIcon icon={<MoreVertIcon />} />}
          onClose={handleSpeedDialClose}
          onOpen={handleSpeedDialOpen}
          open={isSpeedDialOpen}
          direction={"left"}
          openIcon={<EditIcon />}
          FabProps={{
            size: "small",
          }}
        >
          {isSpeedDialOpen && listRowActions?.map((listRowAction, index) => {
            const keyValue=`listRowActions-${index}-${record?.id}`;
            return cloneElement(listRowAction, {
              record: record,
              key: keyValue
            });
          })}
        </SpeedDial>
        {state?.isConfirmOpen && state?.confirm && (
          <Confirm
            {...state?.confirm}
            isOpen={state?.isConfirmOpen}
            onClose={(event) => handleConfirmClose(event, state?.confirm?.onClose)}
          />
        )}
      </div>
    );
  } else {
    return null;
  }
};

export { ListRowActions }
