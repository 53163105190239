//import simpleRestProvider from 'ra-data-simple-rest';
import { restDataProvider } from "./restDataProvider";
import { fetchUtils } from "ra-core";

import { cacheDataProviderProxy } from "react-admin";
import inMemoryJWTManager from "./inMemoryJWTManager";

export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

export const httpClient = (url: string, options: Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  options.credentials = "include";

  const token = inMemoryJWTManager.getToken();
  if (token) {
    return fetchUtils.fetchJson(url, options);
  } else {
    return inMemoryJWTManager.getRefreshedToken().then((gotFreshToken) => {
      return fetchUtils.fetchJson(url, options);
    });
  }
  /*  const token = localStorage.getItem('token');
    if(token){
        const requestHeaders: HeadersInit = new Headers();
        requestHeaders.set('authorization', `Bearer ${token}`);
        options.headers = requestHeaders;
    } */
};

const defaultApiUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}`;
const unCachedDataProvider = restDataProvider(defaultApiUrl, httpClient);
const dataProvider = cacheDataProviderProxy(unCachedDataProvider);

export { dataProvider };
