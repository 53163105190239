import {
  TabbedForm,
  FormTab,
  TextInput,
  PasswordInput,
  Edit,
  Toolbar,
  SaveButton,
  useTranslate,
  EmailField,
  Loading,
  useUpdate,
  useNotify,
  useRedirect,
} from "react-admin";
import validation from "./validations";
import { styles } from "./style";
import { makeStyles } from "@material-ui/core/styles";
import ProfileActions from "./ProfileActions";
import { UserPreferences } from "./UserPreferences";
import { RESOURCE_NAME_USER } from "../accounts";
import { LocalUser } from "../../core";
import { useCallback } from "react";

const useStyles = makeStyles(styles);

const UserProfileEditTitle = () => {
  const translate = useTranslate();

  return <span>{translate("resources.users.profile.editPagetitle")}</span>;
};

const ProfileEdit = (props) => {
  const localUser = new LocalUser();

  const userId = localUser?.getUserId();

  const SaveProfileButton = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const [saveProfile] = useUpdate(RESOURCE_NAME_USER, userId);

    const handleSave = useCallback(
      (values) => {
        const localUserData = localUser.readLocalUser();
        localUser.saveName(values.firstName, values.lastName);
        let user = {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          settings: localUserData?.settings,
          version: values.version,
        };

        if (values.password) {
          user["password"] = values.password;
        }

        saveProfile(
          {
            payload: {
              data: {
                ...user,
              },
            },
          },
          {
            onSuccess: () => {
              notify("resources.users.profile.notification.saved", {type: "info"});
              redirect("/my-profile");
            },
          }
        );
      },
      [saveProfile, notify, redirect]
    );
    return <SaveButton {...props} onSave={handleSave} />;
  };

  const classes = useStyles();

  const CustomToolbar = (props) => (
    <Toolbar {...props} style={{ clear: "left" }}>
      <SaveProfileButton />
    </Toolbar>
  );

  const { staticContext, ...restProps } = props;

  if (!userId) {
    return <Loading />;
  }

  return (
    <Edit
      {...restProps}
      actions={
        <ProfileActions href="/my-profile" buttonTitle="ra.action.show" />
      }
      title={<UserProfileEditTitle />}
      id={userId}
      resource={RESOURCE_NAME_USER}
      basePath="/my-profile/edit"
      className={classes.marginTop20}
    >
      <TabbedForm
        toolbar={<CustomToolbar />}
        initialValues={{ password: undefined }}
      >
        <FormTab label="resources.users.profile.tabs.generalInformation">
          <TextInput
            source="firstName"
            formClassName={classes.clearWithFloatLeft}
            validate={validation.validateFirstName}
          />
          <TextInput
            source="lastName"
            formClassName={classes.floatLeftWithMarginLeft}
            validate={validation.validateLastName}
          />
          <EmailField
            source="email"
            formClassName={classes.clearWithFloatLeft}
          />
        </FormTab>
        <FormTab
          label="resources.users.profile.tabs.changePassword"
          path="password"
        >
          <PasswordInput
            source="password"
            formClassName={classes.clearWithFloatLeft}
            autoComplete="new-password"
            validate={validation.validatePassword}
          />
          <PasswordInput
            type="password"
            source="passwordConfirmation"
            formClassName={classes.floatLeftWithMarginLeft}
            validate={validation.matchPassword}
            autoComplete="new-password"
          />
        </FormTab>
        <FormTab label="resources.users.profile.tabs.settings" path="settings">
          <UserPreferences />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export { ProfileEdit };
