import {
  PermissionOrgStructureTypes,
  RESOURCE_NAME_ORG_STRUCTURE_TYPES,
} from "./constants";
import { OrgStructureTypesCreate } from "./OrgStructureTypesCreate";
import { OrgStructureTypesEdit } from "./OrgStructureTypesEdit";
import { OrgStructureTypesList } from "./OrgStructureTypesList";
import { OrgStructureTypesShow } from "./OrgStructureTypesShow";

const OrgStructureTypesPermissions = {
  menu: PermissionOrgStructureTypes.MENU_ORG_STRUCTURE_TYPE,
  list: PermissionOrgStructureTypes.LIST_ORG_STRUCTURE_TYPES,
  create: PermissionOrgStructureTypes.CREATE_ORG_STRUCTURE_TYPE,
  update: PermissionOrgStructureTypes.EDIT_ORG_STRUCTURE_TYPE,
  show: PermissionOrgStructureTypes.SHOW_ORG_STRUCTURE_TYPE,
  delete: PermissionOrgStructureTypes.DELETE_ORG_STRUCTURE_TYPE,
};

const OrgStructureTypesResource = {
  name: RESOURCE_NAME_ORG_STRUCTURE_TYPES,
  list: OrgStructureTypesList,
  create: OrgStructureTypesCreate,
  edit: OrgStructureTypesEdit,
  show: OrgStructureTypesShow,
  options: {
    label: "resources.OrgStructureTypes.name",
    allowedPermissions: OrgStructureTypesPermissions,
  },
};

export * from "./constants";
export { OrgStructureTypesPermissions, OrgStructureTypesResource };
