import {
  Divider,
  Tabs,
  Tab,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Identifier,
  useGetList,
  useListContext,
  ListContextProvider,
  Datagrid,
  DatagridProps,
  ReferenceField,
  TextField,
  DateField,
  NumberField,
} from "react-admin";
import { RESOURCE_NAME_BID } from "../constants";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { RESOURCE_NAME_ENQUIRY } from "../../enquiries";
import { ListRowActions } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";
import { FILTER_DIRECTION, getProcEntityRecordStatusRowStyle } from "../../../common";



const tabs = [
  { id: FILTER_DIRECTION.SENT, name: FILTER_DIRECTION.SENT },
  { id: FILTER_DIRECTION.RECEIVED, name: FILTER_DIRECTION.RECEIVED },
];

const useGetTotals = (filterValues: any) => {
  const { total: totalSent } = useGetList(
    RESOURCE_NAME_BID,
    { perPage: MAX_RECORDS_PER_PAGE, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, direction: FILTER_DIRECTION.SENT }
  );
  const { total: totalReceived } = useGetList(
    RESOURCE_NAME_BID,
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, direction: FILTER_DIRECTION.RECEIVED }
  );

  return {
    sent: totalSent,
    received: totalReceived,
  };
};

interface TabbedDatagridProps extends DatagridProps { }

const BidTabbedDatagrid= (props:TabbedDatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;

  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const [received, setReceived] = useState<Identifier[]>([] as Identifier[]);
  const [sent, setSent] = useState<Identifier[]>([] as Identifier[]);

  const totals = useGetTotals(filterValues) as any;
  useEffect(() => {
    if (ids && ids !== filterValues.direction) {
      switch (filterValues.direction) {
        case FILTER_DIRECTION.SENT:
          setSent(ids);
          break;
        case FILTER_DIRECTION.RECEIVED:
          setReceived(ids);
          break;
      }
    }
  }, [ids, filterValues.direction]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, direction: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.direction}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>

      <Divider />
      {isSmall ? (
        <div>
          {filterValues.direction === FILTER_DIRECTION.SENT && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <ReferenceField
                  source="enquiryId"
                  reference={RESOURCE_NAME_ENQUIRY}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>

                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.direction === FILTER_DIRECTION.RECEIVED && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <ReferenceField source="code" reference={RESOURCE_NAME_ENQUIRY}>
                  <TextField source="displayLabel" />
                </ReferenceField>

                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      ) : (
        <div>
          {filterValues.direction === FILTER_DIRECTION.SENT && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <ReferenceField
                  source="enquiryId"
                  reference={RESOURCE_NAME_ENQUIRY}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
                <ReferenceField
                  source="userAccountId"
                  reference={RESOURCE_NAME_ACCOUNT}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>

                <DateField source="estimatedDeliveryDate" />
                <NumberField source="totalAmount" />
                <NumberField source="totalAmount2" />
                <DateField
                  source="updatedAt"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.direction === FILTER_DIRECTION.RECEIVED && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <ReferenceField
                  source="enquiryId"
                  reference={RESOURCE_NAME_ENQUIRY}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
                <ReferenceField
                  source="vendorAccountId"
                  reference={RESOURCE_NAME_ACCOUNT}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
                <DateField
                  source="estimatedDeliveryDate"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <NumberField source="totalAmount" />
                <NumberField source="totalAmount2" />
                <DateField
                  source="updatedAt"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      )}
    </Fragment>
  );
};

export { BidTabbedDatagrid };
