import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';import { PermissionRoles, RESOURCE_NAME_ROLE } from './constants';
import { RoleCreate } from './RoleCreate';
import { RoleEdit } from './RoleEdit';
import { RoleList } from './RoleList';
import { RoleShow } from './RoleShow';

const allowedPermissions = {
    menu: PermissionRoles.MENU_ROLES,
    list: PermissionRoles.LIST_ROLES,
    create: PermissionRoles.CREATE_ROLE,
    update: PermissionRoles.EDIT_ROLE,
    show: PermissionRoles.SHOW_ROLE,
    delete: PermissionRoles.DELETE_ROLE,
};
const roleResource = {
    name: RESOURCE_NAME_ROLE,
    list: RoleList,
    create:RoleCreate,
    edit: RoleEdit,
    show: RoleShow, 
    icon: AssignmentIndIcon,
    options: { label: 'resources.roles.name',allowedPermissions },

}

export * from './constants';
export { roleResource }