import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  ListProps,
  setSidebarVisibility,
  ReferenceField,
  TextField,
  DateField,
  BooleanField,
  useListParams,
} from "react-admin";
import { CustomizableDatagrid, CustomizableList } from "../../lib/components";
import { DEFAULT_PER_PAGE } from "../../lib/common";
import { EnquiryListFilters } from "./components/EnquiryListFilters";
import { EnquiryListSideFilters } from "./components/EnquiryListSideFilters";
import { RESOURCE_NAME_ACCOUNT } from "../../lib/modules";
import {
  requisitionPermissions,
  RESOURCE_NAME_REQUISITION,
} from "../requisitions";
import { COMMON_DATE_TIME_FORMAT, usePermissionsCheck } from "../../lib/core";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../datasetup/business-functions/constants";
import { EnquiryListRowActionButtons } from "./components";
import {
  ApprovalStatusField,
  FILTER_DIRECTION,
  getProcEntityRecordStatusRowStyle,
  ProcureAccountField,
  UserNameEmailField,
  RecordStatusField,
} from "../../common";
import { enquiryResource } from ".";

const EnquiryList = (props: ListProps) => {
  const dispatch = useDispatch();
  const [listParams] = useListParams({
    resource: enquiryResource.name,
    syncWithLocation: true,
  });

  const { filterValues } = listParams;
  const isDirectionSent = filterValues["direction"] === FILTER_DIRECTION.SENT;

  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  const [hasRequisitionsListAccess, setHasRequisitionsListAccess] =
    useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasRequisitionsListAccess = hasPermissionAccess(
        requisitionPermissions.list
      );
      setHasRequisitionsListAccess(hasRequisitionsListAccess);
    }
  }, [permissionsLoaded]);
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  const defaultColumns = ["code", "bidStartDate", "bidEndDate"];

  if (isDirectionSent) {
    defaultColumns.push("status");
    defaultColumns.push("procureApprovalStatus");
  }

  return (
    <CustomizableList
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={DEFAULT_PER_PAGE}
      aside={<EnquiryListSideFilters />}
      filters={<EnquiryListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid rowStyle={getProcEntityRecordStatusRowStyle}>
        <TextField source="code" />
        <ReferenceField
          source="accountId"
          sortable={false}
          reference={RESOURCE_NAME_ACCOUNT}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        {isDirectionSent && (
          <ReferenceField
            source="name"
            reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
            sortable={false}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
        )}
        {isDirectionSent && hasRequisitionsListAccess && (
          <ReferenceField
            source="requisitionId"
            reference={RESOURCE_NAME_REQUISITION}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
        )}
        <DateField source="bidStartDate" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="bidEndDate" options={COMMON_DATE_TIME_FORMAT} />
        <DateField
          source="preferredDeliveryDate"
          options={COMMON_DATE_TIME_FORMAT}
        />
        {isDirectionSent && <RecordStatusField label="common.fields.status" />}
        {isDirectionSent && (
          <ApprovalStatusField label="common.fields.approvalStatus" />
        )}
        <ProcureAccountField label={`${isDirectionSent?'common.fields.procureAccountId':'resources.enquiries.fields.customerName'}`} />
        <UserNameEmailField source="procureOwnerUserId" label={`${isDirectionSent?'common.fields.procureOwnerUserId':'resources.enquiries.fields.customerContact'}`} />
        {isDirectionSent && <BooleanField source="canLevel1Approve" />}
        {isDirectionSent && <BooleanField source="canLevel2Approve" />}
        <DateField
          source="createdAt"
          showTime
          options={COMMON_DATE_TIME_FORMAT}
        />
        <DateField
          source="updatedAt"
          showTime
          options={COMMON_DATE_TIME_FORMAT}
        />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <EnquiryListRowActionButtons />)
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { EnquiryList };
