import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
  SimpleForm,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { Typography } from "@material-ui/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts/accounts/constants";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  account: { display: "block" },
  unitType: { display: "block" },
  unitSystem: { display: "block" },
};

const useStyles = makeStyles(styles);

const UomForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm warnWhenUnsavedChanges {...rest}>
      <SectionTitle label="resources.uom.fieldGroups.unitOfMeasureDetails" />
      <TextInput
        autoFocus
        source="name"
        label="resources.uom.fields.name"
        helperText="resources.uom.help.name"
        formClassName={classes.name}
        fullWid
      />

      <TextInput
        source="unitType"
        label="resources.uom.fields.unitType"
        helperText="resources.uom.help.unitType"
        formClassName={classes.unitType}
        fullWid
      />

      <TextInput
        source="unitSystem"
        label="resources.uom.fields.unitSystem"
        helperText="resources.uom.help.unitSystem"
        formClassName={classes.unitSystem}
        validate={[required()]}
        fullWid
      />

      <RichTextInput
        source="description"
        label="resources.uom.fields.description"
        helperText="resources.uom.help.description"
        formClassName={classes.description}
      />

      <ReferenceInput
        label="resources.uom.fields.accountId"
        source="accountId"
        reference={RESOURCE_NAME_ACCOUNT}
        formClassName={classes.account}
      >
        <SelectInput
          optionText="displayLabel"
          helperText="resources.uom.help.accountId"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { UomForm };
