import { Box } from "@material-ui/core";
import { Identifier } from "ra-core";
import { FC, useEffect, useMemo, useReducer } from "react";
import { SimpleForm } from "react-admin";
import { SectionTitleSubtitle } from "../../../lib/components";
import { BidsComparisonContainer } from "./BidsComparisonContainer";
import {
  BidComparisonCalculationContext,
  bidComparisonCalculationReducer,
} from "./context";
import { CustomBidComparisonToolbar } from "./CustomBidComparisonToolbar";
import {
  BidComparisonCalculationActionTypes,
  BidComparisonState,
  PriceComponent,
} from "./types";

const listContextInitialState: BidComparisonState = {
  isLoading: true,
  isInitialized: false,
  hasData: false,
  excludedItemIds: new Set(),
  excludedVendorAccountIds: new Set(),
  bidsComparisonCalc: null,
  priceComponents: [],
  isManagePriceComponentsDialogOpen: false,
  shouldRecalculate: false,
};

interface BidsComparisonViewProps {
  id?: string;
  loaded: boolean;
  isReadOnly: boolean;
  excludedItemIds?: Identifier[];
  excludedVendorAccountIds?: Identifier[];
  priceComponents?: PriceComponent[];
}

const BidsComparisonView: FC<BidsComparisonViewProps> = ({
  id,
  loaded,
  excludedItemIds = [],
  excludedVendorAccountIds = [],
  priceComponents = [],
  ...rest
}) => {
  useEffect(() => {
    if (loaded) {
      dispatch({
        type: BidComparisonCalculationActionTypes.ENQUIRY_LOADED,
        payload: {
          excludedItemIds,
          excludedVendorAccountIds,
          priceComponents,
        },
      });
    }
  }, [loaded]);

  const [state, dispatch] = useReducer(bidComparisonCalculationReducer, {
    ...listContextInitialState,
  });

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" marginLeft={2}>
        <SectionTitleSubtitle
          title="resources.bidsComparison.sections.bid.edit.title"
          subTitle="resources.bidsComparison.sections.bid.create.subTitle"
          marginLeft={4}
        />
        <SimpleForm
          warnWhenUnsavedChanges
          toolbar={<CustomBidComparisonToolbar id={id} {...rest} />}
          margin="normal"
          variant="filled"
          redirect="edit"
        >
          <BidComparisonCalculationContext.Provider value={contextValue}>
            <BidsComparisonContainer id={id} />
          </BidComparisonCalculationContext.Provider>
        </SimpleForm>
      </Box>
    </Box>
  );
};

export { BidsComparisonView };
