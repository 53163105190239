import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import {
  SingleListRowAction,
  SingleListRowActionProps,
} from "../SingleListRowAction";
import {
  Record,
  useListContext,
  useRedirect,
  useResourceDefinition,
} from "react-admin";
import { usePermissionsCheck } from "../../../../../core";
import { ListRowActionButtonsContext } from "../ListRowActionButtonsContext";
import { ListRowActionButtonsActionTypes } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabPrimary: {
      color: theme.palette.primary.main,
    },
  })
);

export interface RedirectListRowActionProps extends SingleListRowActionProps {
  calculateRedirectPath: (record?: Record) => string;
}

const RedirectListRowAction = (props: RedirectListRowActionProps) => {
  const { calculateRedirectPath, ...rest } = props;
  const { resource } = useListContext();
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  const { permissionKey, permissionName } = props;
  const classes = useStyles();

  const redirect = useRedirect();
  const { options } = useResourceDefinition({ resource });
  const actionClassName = props?.className ?? classes.fabPrimary;
  const keyValue = `rlra-${props?.record?.id}-${props?.tooltipTitle}`;

  const [canAccess, setCanAccess] = useState(false);

  const actionPermissionName =
    !permissionName && permissionKey
      ? options?.allowedPermissions[permissionKey]
      : permissionName;

  const { dispatch } = useContext(ListRowActionButtonsContext);

  const handleRedirectAction = useCallback(
    (clickEvent, record) => {
      if (dispatch) {
        dispatch({
          type: ListRowActionButtonsActionTypes.CLOSE_SPEED_DIAL,
        });
      }
      const redirectPath = calculateRedirectPath(record);
      redirect(redirectPath);
    },
    [dispatch, calculateRedirectPath, redirect]
  );

  useEffect(() => {
    if (permissionsLoaded) {
      const canAccess = actionPermissionName
        ? hasPermissionAccess(actionPermissionName)
        : true;
      setCanAccess(canAccess);
    }
  }, [permissionsLoaded, actionPermissionName, hasPermissionAccess]);

  if (!canAccess) {
    return <Fragment key={keyValue}></Fragment>;
  }

  return (
    <SingleListRowAction
      {...rest}
      handleClick={handleRedirectAction}
      className={actionClassName}
      key={keyValue}
    />
  );
};

export { RedirectListRowAction };
