import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";
import { RESOURCE_NAME_ITEM } from "../../../modules/datasetup/items";

const ItemsListFilter = ({source}: {source: string}) => {
  return (
    <ReferenceArrayInput
      source={source}
      reference={RESOURCE_NAME_ITEM}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key={source}
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  );
};

export { ItemsListFilter };
