import { useCallback } from "react";
import { useGetIdentity, usePermissions } from "react-admin";
import { PERMISSION_MODE } from "..";

const usePermissionsCheck = () => {
  const { loaded: permissionsLoaded, permissions } = usePermissions();
  const { identity } = useGetIdentity();

  const hasPermissionAccess = useCallback(
    (
      permissionNames?: string | Array<string>,
      permissionMode: PERMISSION_MODE = PERMISSION_MODE.ANY
    ): boolean => {
      let hasResourceAccess = false;
      if (permissionNames && permissionsLoaded) {
        const permissionsToCheck = Array.isArray(permissionNames)
          ? permissionNames
          : [permissionNames];

        if (permissionMode === PERMISSION_MODE.ANY) {
          hasResourceAccess = permissionsToCheck?.some((permission) =>
            permissions.includes(permission)
          );
        } else if (permissionMode === PERMISSION_MODE.ALL) {
          hasResourceAccess = permissionsToCheck?.every((permission) =>
            permissions.includes(permission)
          );
        }
      }

      return hasResourceAccess;
    },
    [permissions, permissionsLoaded]
  );

  const hasOwnOrAccountAccess = useCallback(
    (
        accountPermissionNames,
        ownPermissionNames,
        targetAccountId,
        targetOwnerId,
    ) => {
      let hasResourceAccess = false;
      if (permissionsLoaded) {
        const doesTargetAccountIdMatch =
          targetAccountId === identity?.accountId;
        const doesTargetOwnerIdMatch = targetOwnerId === identity?.id;
        const isSystemAdmin = identity?.isSystemAdmin;
        const isSystemUser = identity?.isSystemUser;

        //First check if the current user has access to the account
        hasResourceAccess = doesTargetAccountIdMatch;

        //If the current user's account id matches with the targetAccountId
        if (hasResourceAccess) {
          //Check if the current user has account access, check if his accountId matches with the target resource's account Id
          hasResourceAccess = hasPermissionAccess(accountPermissionNames);

          //If the user does not have account access, and his target owner id matches,
          //then check if he has own resource access
          if (!hasResourceAccess && doesTargetOwnerIdMatch) {
            hasResourceAccess = hasPermissionAccess(ownPermissionNames);
          }
        }

        //Finally, if the user still does not have any access, finally check if he is an admin or a superuser
        if (!hasResourceAccess) {
          hasResourceAccess = isSystemAdmin || isSystemUser;
        }
      }

      return hasResourceAccess;
    },
    [
      hasPermissionAccess,
      identity?.accountId,
      identity?.id,
      identity?.isSystemAdmin,
      identity?.isSystemUser,
      permissionsLoaded,
    ]
  );

  return {
    hasPermissionAccess, 
    hasOwnOrAccountAccess,
    permissionsLoaded,
  };
};

export { usePermissionsCheck };
