import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  BulkActionProps,
  Button,
  useListContext,
  useUnselectAll,
  useNotify,
  Identifier,
} from "react-admin";
import AddToEnquiryIcon from "@material-ui/icons/AddShoppingCartTwoTone";
import RemoveFromEnquiryIcon from "@material-ui/icons/RemoveShoppingCartTwoTone";
import { DatagridColors } from "../constants";
import { RESOURCE_NAME_VENDOR } from "../../datasetup/vendors";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addVendorsToEnquiry: {
      color: DatagridColors.SELECTED_COLOR,
      backgroundColor: DatagridColors.SELECTED_BG_COLOR,
      padding: 8,
    },
    removeVendorsFromEnquiry: {
      color: DatagridColors.UNSELECTED_COLOR,
      backgroundColor: DatagridColors.UNSELECTED_BG_COLOR,
      padding: 8,
    },
    container: {
      display: "flex",
      gap: 10,
    },
  })
);

interface VendorsBulkActionProps extends BulkActionProps {
  handleAddVendorsToEnquiry: (ids: Array<Identifier>) => void;
  handleRemoveVendorsFromEnquiry: (ids: Array<Identifier>) => void;
}

const AddVendorsToEnquiryBulkActionButton = (props: VendorsBulkActionProps) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleAddVendorsToEnquiry, ...rest } = props;

  const addVendorsToEnquiry = () => {
    handleAddVendorsToEnquiry(selectedIds);
    unselectAll(RESOURCE_NAME_VENDOR);
    notify("resources.enquiries.messages.addVendorsToEnquiry", {
      type: "info",
      multiLine: true,
    });
  };
  return (
    <Button
      label="resources.enquiries.actions.addVendorsToEnquiry"
      className={classes.addVendorsToEnquiry}
      onClick={addVendorsToEnquiry}
    >
      <AddToEnquiryIcon />
    </Button>
  );
};

const RemoveVendorsFromEnquiryBulkActionButton = (
  props: VendorsBulkActionProps
) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleRemoveVendorsFromEnquiry, ...rest } = props;

  const removeVendorsFromEnquiry = () => {
    handleRemoveVendorsFromEnquiry(selectedIds);
    unselectAll(RESOURCE_NAME_VENDOR);
    notify("resources.enquiries.messages.removeVendorsFromEnquiry", {
      type: "info",
      multiLine: true,
    });
  };

  return (
    <Button
      label="resources.enquiries.actions.removeVendorsFromEnquiry"
      className={classes.removeVendorsFromEnquiry}
      onClick={removeVendorsFromEnquiry}
    >
      <RemoveFromEnquiryIcon />
    </Button>
  );
};

const VendorsBulkActionButtons = (props: VendorsBulkActionProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <AddVendorsToEnquiryBulkActionButton {...props} />
      <RemoveVendorsFromEnquiryBulkActionButton {...props} />
    </Box>
  );
};

export { VendorsBulkActionButtons };
