import {
  TextInput,
  PasswordInput,
  required,
  email,
  ReferenceInput,
  AutocompleteInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
  useGetIdentity,
  BooleanInput,
} from "react-admin";
import { Typography } from "@material-ui/core";

import { AnyObject } from "react-final-form";
import { Box } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { RESOURCE_NAME_ACCOUNT } from "../accounts";
import { MediaInputField, requiredIfTrue } from "../../../components";
import { RESOURCE_NAME_ROLE } from "..";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) =>
  createStyles({
    firstName: { display: "inline-block" },
    lastName: {
      display: "inline-block",
      marginLeft: 32,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    email: { display: "block" },
    password: { display: "inline-block" },
    confirm_password: {
      display: "inline-block",
      marginLeft: 32,
      [theme.breakpoints.down("xs")]: {
        marginLeft: 0,
      },
    },
    accountId: { display: "block", width: 544 },
    roleIds: { display: "block", width: 544 },
    isActive: { display: "block" },
  })
);

export const validatePasswords = ({
  password,
  confirm_password,
}: AnyObject) => {
  const errors = {} as any;

  if (password && confirm_password && password !== confirm_password) {
    errors.confirm_password = ["resources.users.validation.password_mismatch"];
  }

  return errors;
};

const matchPassword = (value, allValues) =>
  value !== allValues.password
    ? `This field must match with your password`
    : undefined;

const UserForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  const { identity } = useGetIdentity();
  const isSystemAdmin = identity?.isSystemAdmin;
  const firstNameRandomId = uuidv4();
  const lastNameRandomId = uuidv4();
  const emailRandomId = uuidv4();
  const passwordRandomId = uuidv4();
  const confirmPasswordRandomId = uuidv4();

  return (
    <>
      <SectionTitle label="resources.users.fieldGroups.userDetails" />
      <TextInput
        autoFocus
        source="firstName"
        label="resources.users.fields.firstName"
        helperText="resources.users.help.firstName"
        className={classes.firstName}
        validate={[required()]}
        InputProps={{
          autoComplete: firstNameRandomId,
          id: firstNameRandomId,
          name: firstNameRandomId,
        }}
      />
      <TextInput
        source="lastName"
        label="resources.users.fields.lastName"
        helperText="resources.users.help.lastName"
        className={classes.lastName}
        validate={[required()]}
        InputProps={{
          autoComplete: lastNameRandomId,
          id: lastNameRandomId,
          name: lastNameRandomId,
        }}
      />
      <TextInput
        type="email"
        source="email"
        label="resources.users.fields.email"
        validation={{ email: true }}
        helperText="resources.users.help.email"
        className={classes.email}
        fullWidth={false}
        validate={[required(), email()]}
        InputProps={{
          autoComplete: emailRandomId,
          id: emailRandomId,
          name: emailRandomId,
        }}
      />

      <Separator />
      <SectionTitle label="resources.users.fieldGroups.password" />
      <PasswordInput
        source="password"
        label="resources.users.fields.password"
        helperText="resources.users.help.password"
        className={classes.password}
        validate={[requiredIfTrue(!isEdit)]}
        InputProps={{
          autoComplete: "off",
          id: passwordRandomId,
          name: passwordRandomId,
          //@ts-ignore
          "data-lpignore": true,
        }}
        />
      <PasswordInput
        source="confirm_password"
        label="resources.users.fields.confirm_password"
        helperText="resources.users.help.confirm_password"
        className={classes.confirm_password}
        validate={matchPassword}
        InputProps={{
          autoComplete: confirmPasswordRandomId,
          id: confirmPasswordRandomId,
          name: confirmPasswordRandomId,
        }}
      />

      {isSystemAdmin && (
        <>
          <Separator />
          <SectionTitle label="resources.users.fieldGroups.account" />
          <ReferenceInput
            source="accountId"
            label="resources.users.fields.accountId"
            reference={RESOURCE_NAME_ACCOUNT}
            isRequired
          >
            <AutocompleteInput
              optionText="displayLabel"
              className={classes.accountId}
              isRequired
              helperText="resources.users.help.accountId"
              resettable
              validate={[required()]}
            />
          </ReferenceInput>
          <BooleanInput
            source="isPrimaryUser"
            label="resources.users.fields.isPrimaryUser"
            helperText="resources.users.help.isPrimaryUser"
          />
        </>
      )}

      <Separator />
      <SectionTitle label="resources.users.fieldGroups.roles" />

      <ReferenceArrayInput
        source="roleIds"
        label="resources.users.fields.roleIds"
        reference={RESOURCE_NAME_ROLE}
        isRequired
      >
        <AutocompleteArrayInput
          optionText="name"
          className={classes.roleIds}
          isRequired
          helperText="resources.users.help.roleIds"
        />
      </ReferenceArrayInput>

      <Separator />
      <SectionTitle label="resources.users.fieldGroups.files" />
      <MediaInputField showUploadedFiles={isEdit} />
    </>
  );
};

const Separator = () => <Box pt="1em" />;

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();
  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { UserForm };
