import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import { OrgStructureForm } from "./OrgStructureForm";

const OrgStructureEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <OrgStructureForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { OrgStructureEdit };
