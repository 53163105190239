import { makeStyles, Box, Fade, Paper } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";
import { SingleItemShowContainer } from "../../../modules/datasetup/items";

const ItemDetailsInputAccordionField = (props) => {
  const classes = useStyles();
  const summaryClassName = props?.summaryClassName;
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <Box>
      <Box className={classes.itemsAccordion}>
        {props?.record?.itemId && (
          <Box
            className={classes.expandIconContainer}
            onClick={() => handleChange()}
          >
            <ExpandMoreIcon
              className={`${classes.expandIcon} ${
                checked
                  ? classes.expandIconSelected
                  : classes.expandIconUnselected
              }`}
            />
          </Box>
        )}
        <Box className={summaryClassName}>{props?.children}</Box>
      </Box>
      {checked && props?.record?.itemId && (
        <Box>
          <Fade in={checked}>
            <Paper elevation={3} variant="outlined">
              <SingleItemShowContainer
                procureAccountId={false}
                record={props?.record}
              />
            </Paper>
          </Fade>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  itemsAccordion: {
    border: 0,
    borderRadius: 0,
    position: "unset",
    display: "flex",
  },

  expandIconContainer: {
    position: "relative",
    top: 30,
    left: -10,
    [theme.breakpoints.down("sm")]: {
      position: "unset",
      top: "unset",
      left: "unset",
      marginTop: 10,
    },
  },
  expandIcon: {
    cursor: "pointer",
    transition: "transform 0.5s",
  },
  expandIconUnselected: {
    transform: "rotate(-90deg)",
  },
  expandIconSelected: {
    transform: "rotate(0deg)",
  },
}));

export { ItemDetailsInputAccordionField };
