import { ReactElement, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import {
  Show,
  ShowProps,
  useResourceDefinition,
  useResourceContext,
} from "react-admin";

import { CrudActionBreadcrumb, CrudActionType } from "../navigation";
import { usePermissionsCheck } from "../../core";
import { DefaultShowAction } from "./actions";

const DefaultShow = (
  props: ShowProps & { children: ReactElement }
): ReactElement => {
  const { options, icon } = useResourceDefinition(props);
  const resource = useResourceContext();
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  const resourceName = options?.label;
  const {actions = <DefaultShowAction/> } = props;

  const allowedPermissions = options?.allowedPermissions;
  const listPermissionName = allowedPermissions?.list;
  const createPermissionName = allowedPermissions?.create;
  const editPermissionName = allowedPermissions?.update;
  const listPath = props?.basePath ? `${props?.basePath}` : `/${resource}`;

  const [hasList, setHasList] = useState(false);
  const [hasCreate, setHasCreate] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasList = listPermissionName
      ? hasPermissionAccess(listPermissionName)
      : false;

      const hasCreate = createPermissionName
        ? hasPermissionAccess(createPermissionName)
        : false;
      const hasEdit = editPermissionName
        ? hasPermissionAccess(editPermissionName)
        : false;

      setHasList(hasList);
      setHasCreate(hasCreate);
      setHasEdit(hasEdit);
    }
  }, [
    permissionsLoaded,
    listPermissionName,
    createPermissionName,
    editPermissionName,
    hasPermissionAccess,
  ]);

  const modifiedProps = {
    ...props,
    actions,
    hasList,
    hasCreate,
    hasEdit,
  };

  return (
    <Box>
      <Box pt={1}>
        <CrudActionBreadcrumb
          resourceLabel={resourceName}
          listPath={listPath}
          Icon={icon}
          actionType={CrudActionType.SHOW}
        />
      </Box>
      <Show {...modifiedProps} />
    </Box>
  );
};

export { DefaultShow };
