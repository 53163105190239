import GroupIcon from "@material-ui/icons/Group";
import {
  PermissionPurchaseGroups,
  RESOURCE_NAME_PURCHASE_GROUPS,
} from "./constants";
import { PurchaseGroupList } from "./PurchaseGroupList";
import { PurchaseGroupCreate } from "./PurchaseGroupCreate";
import { PurchaseGroupShow } from "./PurchaseGroupShow";
import { PurchaseGroupEdit } from "./PurchaseGroupEdit";

const PurchaseGroupPermissions = {
  menu: PermissionPurchaseGroups.MENU_PURCHASE_GROUP,
  list: PermissionPurchaseGroups.LIST_PURCHASE_GROUPS,
  create: PermissionPurchaseGroups.CREATE_PURCHASE_GROUP,
  update: PermissionPurchaseGroups.EDIT_PURCHASE_GROUP,
  show: PermissionPurchaseGroups.SHOW_PURCHASE_GROUP,
  delete: PermissionPurchaseGroups.DELETE_PURCHASE_GROUP,
};

const purchaseGroupResource = {
  name: RESOURCE_NAME_PURCHASE_GROUPS,
  list: PurchaseGroupList,
  create: PurchaseGroupCreate,
  edit: PurchaseGroupEdit,
  show: PurchaseGroupShow,
  icon: GroupIcon,
  options: {
    label: "resources.purchaseGroups.name",
    allowedPermissions: PurchaseGroupPermissions,
  },
};

export * from "./constants";
export { PurchaseGroupPermissions, purchaseGroupResource };
