import { useCallback, useContext, useEffect, useState } from "react";
import {
  useResourceDefinition,
  useDeleteWithConfirmController,
  useListContext,
} from "react-admin";
import { ListRowActionButtonsContext } from "../ListRowActionButtonsContext";
import {
  SingleListRowAction,
  SingleListRowActionProps,
} from "../SingleListRowAction";
import { usePermissionsCheck } from "../../../../../core";
import ActionDelete from "@material-ui/icons/Delete";
import { ListRowActionButtonsActionTypes } from "../types";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fabError: {
      color: theme.palette.error.main,
    },
  })
);

const DeleteListRowActionButton = (props: SingleListRowActionProps) => {
  const classes = useStyles();
  const { basePath, resource } = useListContext();
  const { record } = props;

  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const { options } = useResourceDefinition({ resource });
  const deletePermissionName = options?.allowedPermissions?.delete;

  const [canDelete, setCanDelete] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const canDelete = deletePermissionName
        ? hasPermissionAccess(deletePermissionName)
        : true;
      setCanDelete(canDelete);
    }
  }, [permissionsLoaded, deletePermissionName, hasPermissionAccess]);

  const { dispatch } = useContext(ListRowActionButtonsContext);

  const { handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      record,
      basePath,
      resource,
    });
  const openConfirmDialog = useCallback(
    (clickEvent, record) => {
      const handleOnConfirm = (event) => {
        if (dispatch) {
          dispatch({
            type: ListRowActionButtonsActionTypes.CLOSE_CONFIRM,
          });
        }
        handleDelete(event);
      };

      const handleOnConfirmClose = (event) => {
        if (dispatch) {
          dispatch({
            type: ListRowActionButtonsActionTypes.CLOSE_CONFIRM,
          });
        }
        handleDialogClose(event);
      };

      const title = "components.buttons.listRowDeleteAction.title";
      const content = "components.buttons.listRowDeleteAction.content";
      const confirmMessage = "components.buttons.listRowDeleteAction.confirm";
      const cancel = "components.buttons.listRowDeleteAction.cancel";
      const confirm = {
        title,
        content,
        onConfirm: handleOnConfirm,
        onClose: handleOnConfirmClose,
        confirmColor: "warning",
        CancelIcon: CancelTwoToneIcon,
        confirm: confirmMessage,
        cancel,
      };
      if (dispatch) {
        dispatch({
          type: ListRowActionButtonsActionTypes.OPEN_CONFIRM,
          payload: {
            confirm,
          },
        });
      }

      handleDialogOpen(clickEvent);
    },
    // eslint-disable-next-line
    [dispatch, handleDialogOpen]
  );

  if (!canDelete) {
    return null;
  }

  return (
    <SingleListRowAction
      {...props}
      icon={<ActionDelete />}
      className={classes.fabError}
      handleClick={openConfirmDialog}
    />
  );
};

export { DeleteListRowActionButton };
