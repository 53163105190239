import { required } from 'react-admin';
import { requiredIf, isSameAs } from '../../../../lib/components/validations';

const validateUsersMessage = "resources.OrgUserGroups.validations.userIds.required";
const validateLevel1ApprovalGroupRequiredIfMessage = "resources.OrgUserGroups.validations.level1ApprovalGroupId.requiredIf";
const validateLevel1ApprovalGroupSameAsMessage = "resources.OrgUserGroups.validations.level2ApprovalGroupId.isSameAs";
const validateLevel2ApprovalGroupSameAsMessage = "resources.OrgUserGroups.validations.level2ApprovalGroupId.isSameAs";

const validateLevel1ApprovalGroupName = [
    requiredIf("level2ApprovalGroupId", validateLevel1ApprovalGroupRequiredIfMessage),
    isSameAs("level2ApprovalGroupId", validateLevel1ApprovalGroupSameAsMessage),
];

const validateLevel2ApprovalGroupName = [
    isSameAs("level1ApprovalGroupId", validateLevel2ApprovalGroupSameAsMessage),
];

const validateUsers = [
    required(validateUsersMessage)
]

const validate = {
    validateLevel1ApprovalGroupName,
    validateLevel2ApprovalGroupName,
    validateUsers
}

export { validate }