import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../../lib/modules";

import { ItemGroupForm } from "./ItemGroupForm";

const ItemGroupEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "itemGroups",
    },
  ];
  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <ItemGroupForm isEdit={true}{...props} />
    </DefaultEdit>
  );
};

export { ItemGroupEdit };
