import { ReactElement } from "react";

import {
  TextField,
  ListProps,
  FilterProps,
  ReferenceField,
  DateField,
  Filter,
  RichTextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  SearchFilter,
} from "../../../../lib/common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_ACCOUNT, RESOURCE_NAME_USER } from "../../../../lib/modules";
import { RESOURCE_NAME_ORG_USER_GROUP } from "./constants";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />

  </Filter>
);
const defaultColumns = ["number", "name", "level1ApprovalGroupId", "level2ApprovalGroupId"];
const OrgUserGroupsList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField
          source="number"
          label="resources.OrgUserGroups.fields.number"
        />
        <TextField source="name" label="resources.OrgUserGroups.fields.name" />

        <RichTextField
          source="description"
          label="resources.OrgUserGroups.fields.description"
        />
        <ReferenceField
          source="level1ApprovalGroupId"
          label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField
          source="level2ApprovalGroupId"
          label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceArrayField
          source="userIds"
          label="resources.OrgUserGroups.fields.userIds"
          reference={RESOURCE_NAME_USER}
          sortable={false}
        >
          <SingleFieldList>
            <ChipField source="displayLabel" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT} >
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { OrgUserGroupsList };
