import { ReactElement, useEffect, useMemo, useState } from "react";
import {
  Record,
  useUpdate,
  useListContext,
  useNotify,
  useRefresh,
  useGetIdentity,
  Identifier,
} from "react-admin";
import {
  ConfirmListViewActionButton,
  DefaultCrudListRowActions,
  EMPTY_CONFIRM_HANDLER,
  ListRowActions,
  ListRowActionsProps,
} from "../../../../lib/components";

import PersonAddTwoToneIcon from "@material-ui/icons/PersonAddTwoTone";
import PersonAddDisabledRoundedIcon from "@material-ui/icons/PersonAddDisabledRounded";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";

import {
  FILTER_NAME_GLOBAL_VENDORS,
  PermissionVendors,
  RESOURCE_NAME_VENDOR,
  VENDOR_ACTIONS,
} from "../constants";
import { usePermissionsCheck } from "../../../../lib/core";

interface IOwnVendorActionDisplayData {
  isGlobalFilterSelected: boolean;
  userAccountId?: Identifier;
}

const VendorListRowActionButtons = (props: ListRowActionsProps) => {
  const listContext = useListContext();

  const { filterValues } = listContext;
  const isGlobalFilterSelected =
    filterValues && filterValues[FILTER_NAME_GLOBAL_VENDORS] === true
      ? true
      : false;
  const { identity } = useGetIdentity();
  const userAccountId: Identifier = identity?.accountId;
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const [hasEdit, setHasEdit] = useState(false);
  const [hasShow, setHasShow] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasEdit = hasPermissionAccess(PermissionVendors.EDIT_VENDOR);

      const hasShow = hasPermissionAccess(PermissionVendors.SHOW_VENDOR);

      setHasEdit(hasEdit);
      setHasShow(hasShow);
    }
  }, [permissionsLoaded, hasPermissionAccess]);

  // eslint-disable-next-line
  const ownVendorExtraDisplayData: IOwnVendorActionDisplayData = {
    isGlobalFilterSelected,
    userAccountId,
  };

  const notify = useNotify();
  const refresh = useRefresh();

  const [update] = useUpdate();

  const overriddenListRowActions: Array<ReactElement> = useMemo(() => {
    const handleAddAsOwnVendorAction = (record?: Record) => {
      handleVendorAction(record, VENDOR_ACTIONS.ADD_AS_OWN_VENDOR);
    };

    const handleRemoveAsOwnVendorAction = (record?: Record) => {
      handleVendorAction(record, VENDOR_ACTIONS.REMOVE_AS_OWN_VENDOR);
    };

    const handleVendorAction = (record, vendorAction: VENDOR_ACTIONS) => {
      const isAddAsOwnVendor =
        String(VENDOR_ACTIONS.ADD_AS_OWN_VENDOR) === String(vendorAction);
      const notifySuccessMessage = isAddAsOwnVendor
        ? "resources.vendors.notification.addAsOwnVendor.success"
        : "resources.vendors.notification.removeAsOwnVendor.success";
      const notifyFailureMessage = isAddAsOwnVendor
        ? "resources.vendors.notification.addAsOwnVendor.failure"
        : "resources.vendors.notification.removeAsOwnVendor.failure";
      const payload = {
        action: vendorAction,
        version: record?.version,
      };

      update(RESOURCE_NAME_VENDOR, record.id, payload, record, {
        undoable: false,
        onSuccess: () => {
          notify(notifySuccessMessage, { type: "success" });
          refresh(true);
        },
        onFailure: (error) => {
          const errorMessage = `An error occured during processing. Cannot perform the requested action.
            ${error?.message ?? ""}
            ${notifyFailureMessage}
          `;

          notify(notifyFailureMessage, {
            type: "error",
            multiLine: true,
            autoHideDuration: 10000,
            undoable: false,
            messageArgs: { message: error?.message },
          });
        },
      });
    };

    const shouldDisplayEditOrDeleteAction = async (
      record?: Record,
      extraDisplayData?: IOwnVendorActionDisplayData
    ): Promise<boolean> => {
      const userAccountId = extraDisplayData?.userAccountId;
      const primaryProcureAccountId = record?.primaryProcureAccountId;

      const isVendorOwnedByUserAccount =
        userAccountId === primaryProcureAccountId;

      const shouldShow =
        isVendorOwnedByUserAccount === undefined
          ? true
          : isVendorOwnedByUserAccount;
      return Promise.resolve(shouldShow);
    };

    const shouldDisplayAddAsOwnVendorAction = async (
      record?: Record,
      extraDisplayData?: IOwnVendorActionDisplayData
    ): Promise<boolean> => {
      const isGlobalFilterSelected = extraDisplayData?.isGlobalFilterSelected;
      const shouldShow =
        isGlobalFilterSelected === undefined ? false : isGlobalFilterSelected;
      return Promise.resolve(shouldShow);
    };

    const shouldDisplayRemoveAsOwnVendorAction = async (
      record?: Record,
      extraDisplayData?: IOwnVendorActionDisplayData
    ) => {
      const isGlobalFilterSelected = extraDisplayData?.isGlobalFilterSelected;
      const userAccountId = extraDisplayData?.userAccountId;
      const primaryProcureAccountId = record?.primaryProcureAccountId;

      const isVendorNotOwnedByUserAccount =
        userAccountId !== primaryProcureAccountId;
      const shouldShow =
        !isGlobalFilterSelected && isVendorNotOwnedByUserAccount ? true : false;
      return Promise.resolve(shouldShow);
    };

    const addAsOwnVendorConfirm = {
      title: "resources.vendors.actions.addAsOwnVendor.single.confirm.title",
      content:
        "resources.vendors.actions.addAsOwnVendor.single.confirm.content",
      confirm:
        "resources.vendors.actions.addAsOwnVendor.single.confirm.confirm",
      cancel: "resources.vendors.actions.addAsOwnVendor.single.confirm.cancel",
      confirmColor: "primary",
      CancelIcon: CancelTwoToneIcon,
      onConfirm: EMPTY_CONFIRM_HANDLER,
      onClose: EMPTY_CONFIRM_HANDLER,
    };

    const removeAsOwnVendorConfirm = {
      title: "resources.vendors.actions.removeAsOwnVendor.single.confirm.title",
      content:
        "resources.vendors.actions.removeAsOwnVendor.single.confirm.content",
      confirm:
        "resources.vendors.actions.removeAsOwnVendor.single.confirm.confirm",
      cancel:
        "resources.vendors.actions.removeAsOwnVendor.single.confirm.cancel",
      confirmColor: "primary",
      CancelIcon: CancelTwoToneIcon,
      onConfirm: EMPTY_CONFIRM_HANDLER,
      onClose: EMPTY_CONFIRM_HANDLER,
    };

    const listRowActions: Array<ReactElement> = [
      <DefaultCrudListRowActions
        key="defaultCrudListRowActions"
        shouldDisplayEdit={shouldDisplayEditOrDeleteAction}
        shouldDisplayDelete={shouldDisplayEditOrDeleteAction}
        extraEditDisplayData={ownVendorExtraDisplayData}
        extraDeleteDisplayData={ownVendorExtraDisplayData}
        hasEdit={hasEdit}
        hasShow={hasShow}
        {...props}
      />,

      <ConfirmListViewActionButton
        {...props}
        icon={<PersonAddTwoToneIcon />}
        tooltipTitle="resources.vendors.actions.addAsOwnVendor.tooltip"
        permissionName={PermissionVendors.CAN_LINK_GLOBAL_VENDORS}
        confirm={addAsOwnVendorConfirm}
        onConfirmHandler={handleAddAsOwnVendorAction}
        shouldDisplay={shouldDisplayAddAsOwnVendorAction}
        extraDisplayData={ownVendorExtraDisplayData}
      />,
      <ConfirmListViewActionButton
        {...props}
        icon={<PersonAddDisabledRoundedIcon />}
        tooltipTitle="resources.vendors.actions.removeAsOwnVendor.tooltip"
        permissionName={PermissionVendors.CAN_UNLINK_GLOBAL_VENDORS}
        confirm={removeAsOwnVendorConfirm}
        onConfirmHandler={handleRemoveAsOwnVendorAction}
        extraDisplayData={ownVendorExtraDisplayData}
        shouldDisplay={shouldDisplayRemoveAsOwnVendorAction}
      />,
    ];

    return listRowActions;
  }, [notify, ownVendorExtraDisplayData, props, refresh, update]);

  return (
    <ListRowActions
      overriddenListRowActions={overriddenListRowActions}
      {...props}
    />
  );
};

export { VendorListRowActionButtons };
