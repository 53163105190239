import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

const SectionTitleSubtitle = (props) => {
  const {title, subTitle, ...rest} = props;

  const translate = useTranslate();
  return (
    <Box paddingY={2} {...rest}>
      <Typography variant="h6" gutterBottom>
        {translate(title)}
      </Typography>
      {subTitle && (
        <Typography variant="subtitle1" gutterBottom>
          {translate(subTitle)}
        </Typography>
      )}
    </Box>
  );
};

export { SectionTitleSubtitle };
