import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import {
    MediaFileSource,
    transformAndUploadS3Files,
} from "../../../lib/modules";
import { VendorGroupForm } from "./VendorGroupForm";
const VendorGroupEdit=(props:EditProps)=>{
    const mediaFileSources: MediaFileSource[] = [
        {
            source: "files",
            subFolder: "vendor-group",
        },
    ];

    return (
        <DefaultEdit
            {...props}
            mutationMode="pessimistic"
            transform={transformAndUploadS3Files(mediaFileSources)}
        >
            <VendorGroupForm isEdit={true} {...props} />
        </DefaultEdit>
    );
};

export { VendorGroupEdit };
