import ApprovalIcon from "@material-ui/icons/VerifiedUser";
import { StatusFilterList } from "../../../lib/common/ui/filters/StatusFilterList";
import { procureApprovalStatusFiltersList } from "../../constants";

const ProcureApprovalStatusFilterList = () => (
  <StatusFilterList
    statusFieldName="procureApprovalStatus"
    label="common.fields.approvalStatus"
    icon={<ApprovalIcon />}
    statusList={procureApprovalStatusFiltersList}
    width="180px"
  />
);

export { ProcureApprovalStatusFilterList };
