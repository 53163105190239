import { FilterLiveSearch } from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import { createStyles, makeStyles, Card, CardContent } from "@material-ui/core";

import { mediaOwnerTypeFiltersList } from "../constants";
import {
  format,
  endOfYesterday,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import { FixedDateRangeFilterList, StatusFilterList } from "../../../../common";

const MediaListSideFilters = () => {
  const cardClasses = useCardStyles();
  const mediaOwnerTypeFiltersStatusList = mediaOwnerTypeFiltersList();

  return (
    <Card classes={cardClasses}>
      <CardContent>
        <FilterLiveSearch />
        <FixedDateRangeFilterList
          label="resources.media.fields.updated_since_gte"
          icon={<AccessTimeIcon />}
          gteSource="updated_since_gte"
          lteSource="updated_since_lte"
          dateRangeList={updatedSinceDatesList}
        />
        <StatusFilterList
          statusFieldName="ownerType"
          label="resources.media.fields.ownerType"
          icon={<HowToRegIcon />}
          statusList={mediaOwnerTypeFiltersStatusList}
          width="100px"
        />
      </CardContent>
    </Card>
  );
};

const useCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        order: -1,
        maxWidth: 210,
        marginRight: "1em",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const DATE_FORMAT_DAY = "yyyy-MM-dd";
const updatedSinceDatesList = [
  {
    label: "resources.media.filters.updatedSince.today",
    gte: format(endOfYesterday(), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.media.filters.updatedSince.thisWeek",
    gte: format(startOfWeek(new Date()), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.media.filters.updatedSince.thisMonth",
    lte: undefined,
    gte: format(startOfMonth(new Date()), DATE_FORMAT_DAY),
  },
  {
    label: "resources.media.filters.updatedSince.lastMonth",
    lte: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT_DAY),
    gte: format(subMonths(startOfMonth(new Date()), 1), DATE_FORMAT_DAY),
  },
  {
    label: "resources.media.filters.updatedSince.earlier",
    lte: format(endOfMonth(subMonths(new Date(), 2)), DATE_FORMAT_DAY),
    gte: undefined,
  },
];


export { MediaListSideFilters };
