import { Tooltip } from "@material-ui/core";
import { usePermissions, useTranslate } from "react-admin";
import { hasAccess } from "../../core";
import DownloadIcon from '@material-ui/icons/CloudDownloadTwoTone';

interface DownloadReportFileButtonProps {
  downloadPath: string;
  downloadPermissionName: string;
  tooltip: string;
}
const DownloadReportFileButton=({
  downloadPath,
  downloadPermissionName,
  tooltip,
}:DownloadReportFileButtonProps) => {
  const { loaded, permissions } = usePermissions();
  const translate = useTranslate();
  const hasDownloadPermission =
    loaded && hasAccess(permissions, downloadPermissionName);
  const apiUrl = process.env["REACT_APP_SERVER_ENDPOINT"];
  const downloadUrl = `${apiUrl}${downloadPath}`;
  if (hasDownloadPermission) {
    return (
      <Tooltip title={translate(tooltip)}>
        <a
          href={downloadUrl}
          className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-outlinedPrimary"
          style={{
            marginLeft: "5px",
            marginRight: "5px",
            padding: "4px 5px",
          }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon style={{ paddingRight: "5px", fontSize: "20px" }} />
          {translate("components.buttons.DownloadReportFileButton.label")}
        </a>
      </Tooltip>
    );
  }else{
    return null;
  }
};

export { DownloadReportFileButton };
