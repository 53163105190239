import { Filter, FilterProps } from "react-admin";
import { SearchFilter } from "../../../lib/common";

const CommentListFilters = (props: Omit<FilterProps, "children">) => {
  return (
    <Filter {...props}>
      <SearchFilter alwaysOn key="commentsSearchFilter"/>
    </Filter>
  );
};

export { CommentListFilters };
