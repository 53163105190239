import { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import { PermissionManagerContext } from "./PermissionManagerContext";
import { PermissionsGroup } from "./PermissionsGroup";
import Typography from "@material-ui/core/Typography";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: "10px",
    fontSize: theme.typography.pxToRem(20),
    fontFamily: theme.typography.fontFamily,
    fontWeight: (theme.typography.fontWeightRegular) as number,
  },
}));

interface PermissionsGroupContainerProps {
  headingLabel: string;
}
const PermissionsGroupContainer= ({
  headingLabel
}: PermissionsGroupContainerProps) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { state } = useContext(PermissionManagerContext);
  const allPermissionGroups = state?.allPermissionGroups;

  if (allPermissionGroups) {
    return (
      <div>
        <Typography className={classes.heading}>
          {translate(headingLabel)}
        </Typography>
        {allPermissionGroups.map((permissionGroup, key) => (
          <PermissionsGroup
            key={permissionGroup.id}
            permissionGroup={permissionGroup}
          />
        ))}
      </div>
    );
  } else {
    return <div />;
  }
};

export { PermissionsGroupContainer };
