import { ReactElement, useEffect } from "react";
import {
  TextField,
  RichTextField,
  ListProps,
  ReferenceField,
  DateField,
  BooleanField,
  setSidebarVisibility,
} from "react-admin";
import { useDispatch } from "react-redux";
import {
  CustomizableList,
  CustomizableDatagrid,
} from "../../lib/components";
import { DEFAULT_PER_PAGE } from "../../lib/common";
import { RequisitionListFilters } from "./components/RequisitionListFilters";
import { RequisitionListSideFilters } from "./components/RequisitionListSideFilters";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../datasetup/business-functions";
import { RequisitionListRowActionButtons } from "./components/RequisitionListRowActionButtons";
import { ApprovalStatusField, getProcEntityRecordStatusRowStyle, ProcureAccountField, UserNameEmailField, RecordStatusField } from "../../common";
import { COMMON_DATE_TIME_FORMAT } from "../../lib/core";

const defaultColumns = ["code", "businessFunctionId", "procureStatus", "procureApprovalStatus"];

const RequisitionList = (props: ListProps): ReactElement => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  return (
    <CustomizableList
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={DEFAULT_PER_PAGE}
      aside={<RequisitionListSideFilters />}
      filters={<RequisitionListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid rowStyle={getProcEntityRecordStatusRowStyle}>
        <TextField source="code" />
        <RecordStatusField label="common.fields.status" />
        <ApprovalStatusField label="common.fields.approvalStatus" />

        <ReferenceField
          source="businessFunctionId"
          reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <RichTextField source="description" /> 
        <ProcureAccountField label="common.fields.procureAccountId"/>
        <UserNameEmailField source="procureOwnerUserId" label="common.fields.procureOwnerUserId"/>
        <BooleanField source="isOpen" />
        <BooleanField source="canPerformAction" />
        <BooleanField source="canLevel1Approve" />
        <BooleanField source="canLevel2Approve" />
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <RequisitionListRowActionButtons />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { RequisitionList };
