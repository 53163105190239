import { ReactElement } from "react";
import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  ReferenceField,
  UrlField,
  ArrayField,
  SingleFieldList,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  DateField,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import {
  CustomizableList,
  CustomizableDatagrid,
} from "../../../lib/components";
import {
  DEFAULT_PER_PAGE,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../lib/common";
import { FILTER_NAME_GLOBAL_VENDORS } from "./constants";
import { VendorListRowActionButtons } from "./components";
import { CategoryTypes, RESOURCE_NAME_CATEGORY } from "../category";
import { RESOURCE_NAME_VENDOR_GROUP } from "../vendor-group";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="search" />
    <BooleanInput
      label="resources.vendors.filters.showGlobalVendors"
      source={FILTER_NAME_GLOBAL_VENDORS}
      alwaysOn
      key="showGlobalVendors"
    />
    <ReferenceArrayInput
      label="resources.vendors.filters.vendorCategoryId"
      source="vendorCategoryId"
      reference={RESOURCE_NAME_CATEGORY}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      filter={{ categoryType: CategoryTypes.VENDOR }}
      key="vendorCategoryId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);

const defaultColumns = [
  "vendorAccountId",
  "vendorCategoryId",
  "vendorGroupId",
  "vendorAccount.primaryUser.fullName",
  "vendorAccount.primaryUser.email",
  "city",
];

const VendorList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "vendorAccountId", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
      listRowActions={false}
    >
      <CustomizableDatagrid>
        <ReferenceField
          source="vendorAccountId"
          reference={RESOURCE_NAME_ACCOUNT}
          link={false}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <TextField
          source="vendorAccount.primaryUser.fullName"
          sortable={false}
        />
        <ReferenceField
          source="vendorCategoryId"
          reference={RESOURCE_NAME_CATEGORY}
          link={false}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField
          source="vendorGroupId"
          reference={RESOURCE_NAME_VENDOR_GROUP}
          link={false}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <TextField source="vendorAccount.primaryUser.email" sortable={false} />

        <TextField source="id" />
        <TextField source="addressLine1" />
        <TextField source="addressLine2" />
        <TextField source="pincode" />
        <TextField source="city" />
        <TextField source="state" />
        <TextField source="country" />
        <ArrayField source="phones" sortable={false}>
          <SingleFieldList>
            <TextField source="phone" />
          </SingleFieldList>
        </ArrayField>
        <UrlField source="url" />
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <VendorListRowActionButtons />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { VendorList };
