import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
export interface SecondaryActionLinkButtonProps {
  title: string;
  url: string;
}

const useStyles = makeStyles(
  (theme) => ({
    main: {
      marginTop: "15px",
      alignItems: "center",
      justifyContent: "flex-end",
      display: "flex",
      width: "100%",
    },
  }),
  { name: "RaLogin" }
);

const SecondaryActionLinkButton= ({
  title,
  url,
}:SecondaryActionLinkButtonProps) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <Button
        style={{
          color: "red",
          cursor: "pointer",
        }}
        component={Link}
        to={url}
      >
        {title}
      </Button>
    </div>
  );
};

export { SecondaryActionLinkButton };
