import { usePermissions, useTranslate, MenuItemLink } from "react-admin";
import { hasAccess } from "../../core/helpers";
import { GatedMenuItemLinkProps } from "./menu.interface";

const GatedMenuItemLink= ({
  to,
  name,
  leftIcon,
  onClick,
  allowedPermissionName,
  isSidebarOpen,
  dense,
  ...rest
}:GatedMenuItemLinkProps) => {
  const translate = useTranslate();
  const { loaded, permissions } = usePermissions();

  if (loaded && hasAccess(permissions, allowedPermissionName)) {
    return (
      //@ts-ignore
      <MenuItemLink
        to={to}
        primaryText={translate(name, {smart_count: 2})}
        leftIcon={leftIcon}
        onClick={onClick}
        sidebarIsOpen={isSidebarOpen}
        dense={dense}
        {...rest}
      />
    );
  } else {
    return null;
  }
};

export { GatedMenuItemLink };
