import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import { SystemDataForm } from "./SystemDataForm";

const SystemDataEdit= (props:EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <SystemDataForm />
    </DefaultEdit>
  );
};

export { SystemDataEdit };
