import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  SimpleForm,
  setSidebarVisibility,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import {
  DefaultEdit,
} from "../../lib/components";
import { BidEditContent } from "./components";
import { MediaFileSource, transformAndUploadS3Files } from "../../lib/modules";
import { FILTER_DIRECTION } from "../../common";
import { BidCrudEditActions } from "./components/actions";

const BidEdit = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("resources.bids.messages.edited", {type: "success"});
    redirect(`/bids?filter={"direction":"${FILTER_DIRECTION.SENT}"}`);
    refresh();
  };

  const onFailure = (error) => {
    if (error.code === 123) {
      notify(
        "Could not save changes: concurrent edition in progress",
        {type: "error"}
      );
    } else {
      notify("ra.notification.http_error", {type: "error"});
    }
    redirect(`/bids?filter={"direction":"${FILTER_DIRECTION.SENT}"}`);
  };

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
// eslint-disable-next-line
}, []);
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "bids",
    },
  ];
  return (
    <DefaultEdit
      {...props} 
      actions={<BidCrudEditActions/>}
      mutationMode="pessimistic"
      onSuccess={onSuccess} 
      onFailure={onFailure}
      transform={transformAndUploadS3Files(mediaFileSources)}
      basePath={`/bids?filter={"direction":"${FILTER_DIRECTION.SENT}"}`}
    >
      <SimpleForm
        {...props}
        mutationMode="pessimistic"
        warnWhenUnsavedChanges
        margin="normal"
        variant="filled"
      >

        <BidEditContent isEdit={true}/>
      </SimpleForm>
    </DefaultEdit>
  );
};

export { BidEdit };
