import { ReactElement, useEffect, useState } from "react";

import { Box } from "@material-ui/core";

import {
  Edit,
  EditProps,
  useResourceDefinition,
  useResourceContext,
  Loading,
} from "react-admin";

import { CrudActionBreadcrumb, CrudActionType } from "../navigation";
import { usePermissionsCheck } from "../../core";
import { DefaultEditAction } from "./actions";

const DefaultEdit = (
  props: EditProps & { children: ReactElement }
): ReactElement => {
  const { options, icon } = useResourceDefinition(props);
  const resource = useResourceContext();
  const resourceName = options?.label;
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  const allowedPermissions = options?.allowedPermissions;
  const listPermissionName = allowedPermissions?.list;
  const createPermissionName = allowedPermissions?.create;
  const showPermissionName = allowedPermissions?.show;
  const listPath = props?.basePath ? `${props?.basePath}` : `/${resource}`;

  const { actions = <DefaultEditAction /> } = props;
  const [hasList, setHasList] = useState(false);
  const [hasCreate, setHasCreate] = useState(false);
  const [hasShow, setHasShow] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasList = listPermissionName
        ? hasPermissionAccess(listPermissionName)
        : false;

      const hasCreate = createPermissionName
        ? hasPermissionAccess(createPermissionName)
        : false;
      const hasShow = showPermissionName
        ? hasPermissionAccess(showPermissionName)
        : false;

      setHasList(hasList);
      setHasCreate(hasCreate);
      setHasShow(hasShow);
    }
  }, [
    permissionsLoaded,
    listPermissionName,
    createPermissionName,
    showPermissionName,
    hasPermissionAccess,
  ]);

  const modifiedProps = {
    ...props,
    actions,
    hasList,
    hasCreate,
    hasShow,
  };

  return (
    <Box>
      <Box pt={1}>
        <CrudActionBreadcrumb
          resourceLabel={resourceName}
          listPath={listPath}
          Icon={icon}
          actionType={CrudActionType.EDIT}
        />
      </Box>
      <Edit {...modifiedProps} />
    </Box>
  );
};

export { DefaultEdit };
