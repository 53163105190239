const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let isRefreshing: Promise<Boolean> | null = null;
  let logoutEventName = "app-logout";
  const apiUrl = process.env["REACT_APP_SERVER_ENDPOINT"];
  let refreshEndpoint = `${apiUrl}/auth/refresh`;
  let refreshTimeOutId;
  const delay = 3600; //1hour

  const setLogoutEventName = (name) => (logoutEventName = name);
  const setRefreshTokenEndpoint = (endpoint) => (refreshEndpoint = endpoint);

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const refreshToken = () => {
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      delay * 1000 - 5000
    ); // Validity period of the token in seconds, minus 5 seconds
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  const waitForTokenRefresh = () => {
    if (!isRefreshing || isRefreshing === null) {
      return Promise.resolve();
    } else {
      return isRefreshing.then(() => {
        isRefreshing = null;
        return true;
      });
    }
  };

  // The method make a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
  const getRefreshedToken = () => {
    const request = new Request(refreshEndpoint, {
      method: "GET",
      headers: new Headers({ "Content-Type": "application/json" }),
      credentials: "include",
    });

    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          eraseToken();
          global.console.log("Token renewal failure");
          return { token: null };
        }
        return response.json();
      })
      .then(({ accessToken, tokenExpiry }) => {
        if (accessToken) {
          setToken(accessToken);
          return true;
        }
        eraseToken();
        return false;
      });

    return isRefreshing;
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token) => {
    inMemoryJWT = token;
    refreshToken();
    return true;
  };

  const eraseToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now().toString());
    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener("storage", (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    eraseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh,
  };
};

export default inMemoryJWTManager();
