import { ChangeEvent, useContext, useMemo, useState} from "react";
import {
  makeStyles,
  Tooltip,
  FormControl,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PermissionGroup, PermissionManagerActionTypes } from "./types";
import { PermissionsGridList } from "./PermissionsGridList";
import { PermissionManagerContext } from "./PermissionManagerContext";
import { useForm } from "react-final-form";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  permissionGroupHeading: {
    marginTop: "15px",
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 600,
    fontStyle: "italic",
  },
  summary: {
    display: "block",
  },
  checkbox: {},
  label: {},
}));

interface Props {
  permissionGroup: PermissionGroup;
}

const PermissionsGroup= ({ permissionGroup }) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(PermissionManagerContext);

  const isDisabled = false;
  const permissionGroupTooltip =
    permissionGroup?.description + (isDisabled ? " - Inactive" : "");

  const [isExpanded, setExpanded] = useState<boolean>(true);
  const isAccordionExpanded = useMemo(() => isExpanded, [isExpanded]);

  const form = useForm();

  const handleChange = (event: ChangeEvent<{}>) => {
    const eventPermissionId = event?.target['id'];
    if(eventPermissionId === permissionGroup?.id){
      setExpanded(!isExpanded);
    }
  }
  const handlePermissionGroupChecked = (
    event: ChangeEvent<{}>,
    isSelected: boolean
  ) => {
    if (dispatch) {
      event.preventDefault();
      event.stopPropagation();

      dispatch({
        type: PermissionManagerActionTypes.TOGGLE_MANY_PERMISSIONS,
        payload: { permissionGroup, isSelected },
      });

      if (!state?.isDisabled) {
        const statePermissionChanges = state?.permissionChanges;
        if (statePermissionChanges) {
          const permissionChanges = {
            added: Array.from(statePermissionChanges?.added),
            removed: Array.from(statePermissionChanges?.removed),
          };
          form.change("permissionChanges", permissionChanges);
        }
      }
    }
  };

  const ChildPermissionGroups = () => {
    if (!permissionGroup?.children) {
      return null;
    }

    return (
      <div>
        {permissionGroup?.children?.map((childPermissionGroup) => (
          <PermissionsGroup
            key={childPermissionGroup.id}
            permissionGroup={childPermissionGroup}
          />
        ))}
      </div>
    );
  };

  return (
    <Tooltip title={permissionGroupTooltip}>
      <FormControl
        className={classes.root}
        component="fieldset"
        margin="normal"
      >
        <Accordion
          className={classes.root}
          TransitionProps={{ unmountOnExit: true }}
          expanded={isAccordionExpanded}
          onChange={handleChange}
          id={permissionGroup?.id}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-label="Expand"
            id={permissionGroup?.id}
          >
            <FormControlLabel
              htmlFor={`${permissionGroup.id}_${permissionGroup.name}`}
              name={`${permissionGroup.id}_${permissionGroup.name}`}
              key={`${permissionGroup.id}_${permissionGroup.name}`}
              className={classes.label}
              value={permissionGroup.id.toString()}
              checked={
                permissionGroup?.areAllPermissionsAssigned ? true : false
              }
              disabled={state?.isDisabled}
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  id={`${permissionGroup.id}__${permissionGroup.name}`}
                  color="primary"
                  className={classes.checkbox}
                  onChange={handlePermissionGroupChecked}
                />
              }
              label={`${permissionGroup.label}`}
            />
          </AccordionSummary>
          <AccordionDetails className={classes.summary}>
            <PermissionsGridList permissions={permissionGroup.permissions} />
            <ChildPermissionGroups />
          </AccordionDetails>
        </Accordion>
      </FormControl>
    </Tooltip>
  );
};

export { PermissionsGroup };
