import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import { BusinessFunctionForm } from "./BusinessFunctionForm";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";

const BusinessFunctionEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "business-functions",
    },
  ];

  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <BusinessFunctionForm isEdit={true}{...props} />
    </DefaultEdit>
  );
};

export { BusinessFunctionEdit };
