import { ReactNode, useMemo } from "react";
import { FilterList, FilterListItem } from "react-admin";
import { StatusItem, StatusItemProps } from "./StatusItem";

export interface StatusFilterListProps {
  statusFieldName?: string;
  label: string;
  icon: ReactNode;
  statusList: StatusItemProps[];
  width?: string;
}

const StatusFilterList = ({
  statusFieldName = "status",
  label,
  icon,
  statusList,
  width,
}: StatusFilterListProps) => {

  const filterList = useMemo( () => (
    <FilterList label={label} icon={icon}>
      {
        statusList.map((statusEntry: StatusItemProps) => (
          <FilterListItem
            label={
                <StatusItem
                  statusLabel={statusEntry.statusLabel}
                  statusValue={statusEntry.statusValue}
                  statusColor={statusEntry.statusColor}
                  getColorFromStatus={statusEntry.getColorFromStatus}
                  width={statusEntry.width || width}
                />
            }
            value={{
              [statusFieldName]: statusEntry.statusValue,
            }}
          />
        ))
      }
    </FilterList>
  ), [icon, label, statusFieldName, statusList, width]);

  return filterList;
};

export { StatusFilterList };
