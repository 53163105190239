import { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import {
  ArrayInput,
  FormDataConsumer,
  Identifier,
  InputProps,
  RichTextField,
  NumberField,
  SimpleFormIterator,
  FormInput,
  ReferenceField,
  TextField,
  Labeled,
  NumberInput,
  required,
} from "react-admin";
import { BidDetailRecord } from "../constants";
import { RESOURCE_NAME_ITEM } from "../../datasetup/items";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { ItemDetailsInputAccordionField, QuantityField } from "../../../common";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import { TruncatedTextField } from "../../../lib/components";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    gap: 10,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    "& div > .MuiAccordionSummary-content": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      columnGap: 10,
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    "& .MuiAccordionSummary-content >.ra-input-itemId": {
      paddingRight: 15,
    },
    "& .MuiAccordionSummary-expandIcon": {
      transform: "rotate(-90deg)",
      position: "absolute",
      left: -40,
      "& .Mui-expanded": {
        transform: "rotate(1deg)",
      },
    },
    "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
      transform: "rotate(1deg)",
    },
  },
  itemId: {
    minWidth: 100,
   // maxWidth: 350,
    paddingRight: 15,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "unset",
    }
  },
  quantity: {
    paddingLeft: 10,
    paddingRight: 50,
    minWidth: 30,
    maxWidth: 150,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  currencyId: {
    minWidth: 30,
    maxWidth: 100,
  },
  unitPrice: {
    minWidth: 30,
    maxWidth: 100,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#6bd280',
    "& div": {
      padding: 0,
      margin: 0,
      "& .MuiFilledInput-inputMarginDense": {
        paddingTop: 5,
        paddingBottom: 5,
      },
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  amount: {
    minWidth: 50,
    maxWidth: 150,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  make: {
    minWidth: 50,
    maxWidth: 150,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  size: {
    minWidth: 30,
    maxWidth: 150,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  specifications: {
    minWidth: 80,
    maxWidth: 200,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  comments: {
    minWidth: 80,
    maxWidth: 250,
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      maxWidth: "unset",
    }
  },
  itemsAccordion: {
    border: 0,
    borderRadius: 0,
    position: "unset",
  },
  totalAmountLabel: {
    fontWeight: 500,
    justifySelf: "end",
    paddingRight: 25,
    fontStyle: "italic",
    [theme.breakpoints.down("sm")]: {
      justifySelf: "start",
      paddingBottom: 0,
    },
  },
  totalAmountContainer: {
    backgroundColor: '#6bd280',
    "& label": {
      fontWeight: 600,
      fontStyle: "italic",
      transformOrigin: "top",
    },
    "& div": {
      textAlign: "center",
    },
    "& div span": {
      fontWeight: 600,
      fontStyle: "italic",
    },
  },
  itemsArrayContainer: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    width: "90vw",
    "& li": {
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    },
  },
  totalsContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}));

interface VendorBidFormProps extends InputProps {
  enquiryId: Identifier;
  currencyId: Identifier;
  currency2Id?: Identifier;
  bidDetails: BidDetailRecord[];
  showUnitPrice2: boolean;
}

const VendorBidForm = (props: VendorBidFormProps) => {
  const [totalAmount, setTotalAmount] = useState<number | undefined>();
  const [totalAmount2, setTotalAmount2] = useState<number | undefined>();

  const {
    enquiryId,
    bidDetails,
    currencyId,
    currency2Id,
    showUnitPrice2,
    ...rest
  } = props;
  const formClasses = useStyles();
  const form = useForm();

  useEffect(() => {
    if (bidDetails) {
      form.change("bidDetails", bidDetails);
    }
    // eslint-disable-next-line
  }, [bidDetails]);

  const recalculateTotalAmounts = (bidDetails) => {
    let totalAmount = 0;
    let totalAmount2 = 0;

    for (
      let detailRowIndex = 0;
      detailRowIndex < bidDetails?.length;
      detailRowIndex++
    ) {
      const bidDetail = bidDetails[detailRowIndex];
      const quantity = bidDetail?.quantity;
      const unitPrice = bidDetail?.unitPrice;

      const quantity2 = bidDetail?.quantity2;
      const unitPrice2 = bidDetail?.unitPrice2;

      if (quantity && unitPrice) {
        totalAmount += quantity * unitPrice;
      }

      if (showUnitPrice2 && quantity2 && unitPrice2) {
        totalAmount2 += quantity2 * unitPrice2;
      }
    }

    setTotalAmount(totalAmount);
    setTotalAmount2(totalAmount2);
  };

  return (
    <Box className={formClasses.itemsArrayContainer}>
      <ArrayInput
        {...rest}
        defaultValue={bidDetails}
        isRequired={true}
        source="bidDetails"
        label={false}
        margin="dense"
        validate={[required()]}
      >
        <SimpleFormIterator
          margin="dense"
          variant="filled"
          disableAdd
          disableRemove
          disableReordering={true}
          getItemLabel={() => ""}
        >
          <FormDataConsumer>
            {({ getSource, formData, scopedFormData }) => {
              if (!getSource || !scopedFormData) {
                return <div />;
              }

              const quantity = scopedFormData?.quantity;
              const unitPrice = scopedFormData?.unitPrice;

              const quantity2 = scopedFormData?.quantity2;
              const unitPrice2 = scopedFormData?.unitPrice2;

              const amount =
                quantity && unitPrice ? quantity * unitPrice : undefined;
              const amount2 =
                showUnitPrice2 && quantity2 && unitPrice2
                  ? quantity2 * unitPrice2
                  : undefined;

              recalculateTotalAmounts(formData.bidDetails);

              return (
                <ItemDetailsInputAccordionField
                  record={scopedFormData}
                  summaryClassName={formClasses.form}
                >
                  <Labeled
                    id="sortOrder"
                    source="sortOrder"
                    label="resources.bids.fields.serialNum"
                  >
                    <TextField
                      source="sortOrder"
                      label="resources.bids.fields.serialNum"
                      record={scopedFormData}
                    />
                  </Labeled>

                  <FormInput
                    basePath={props.basePath}
                    input={
                      <ReferenceField
                        label="resources.bids.fields.itemId"
                        source="itemId"
                        reference={RESOURCE_NAME_ITEM}
                        record={scopedFormData}
                        formClassName="itemId"
                        link={false}
                      >
                        <TextField
                          source="displayLabel"
                        />
                      </ReferenceField>
                    }
                    record={scopedFormData}
                    className={formClasses.itemId}
                  />
                  <QuantityField
                    record={scopedFormData}
                    className={formClasses.quantity}
                  />
                  <Labeled
                    id="currencyId"
                    source="currencyId"
                    label="resources.bids.fields.currencyId"
                    className={formClasses.currencyId}
                  >
                    <ReferenceField
                      source="currencyId"
                      reference={RESOURCE_NAME_CURRENCIES}
                      link={false}
                      record={{
                        id: "",
                        currencyId,
                      }}
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </Labeled>
                  <Labeled
                    id="unitPrice"
                    source={getSource("unitPrice")}
                    label="resources.bids.fields.unitPrice"
                    className={formClasses.unitPrice}
                    isRequired
                  >
                    <NumberInput
                      source={getSource("unitPrice")}
                      label={false}
                      helperText="resources.bids.help.unitPrice"
                      isRequired
                      validate={[required()]}
                    />
                  </Labeled>
                  <Labeled
                    id="amount"
                    source="amount"
                    label="resources.bids.fields.amount"
                    className={formClasses.amount}
                  >
                    <NumberField
                      source="amount"
                      record={{
                        id: "",
                        amount,
                      }}
                    />
                  </Labeled>

                  {showUnitPrice2 && (
                    <QuantityField
                      record={scopedFormData}
                      source="quantity2"
                      label="resources.bids.fields.quantity2"
                      uomSource="uom2Id"
                      className={formClasses.quantity}
                    />
                  )}

                  {showUnitPrice2 && (
                    <Labeled
                      id="unitPrice2"
                      source={getSource("unitPrice2")}
                      label="resources.bids.fields.unitPrice2"
                      className={formClasses.unitPrice}
                      isRequired={quantity2 > 0 ? true : false}
                    >
                      <NumberInput
                        source={getSource("unitPrice2")}
                        label={false}
                        helperText="resources.bids.help.unitPrice2"
                        validate={quantity2 > 0 ? [required()] : []}
                      />
                    </Labeled>
                  )}

                  {showUnitPrice2 && (
                    <Labeled 
                      id="amount2" 
                      source="amount2" 
                      label="resources.bids.fields.amount2"
                      className={formClasses.amount}
                    >
                      <NumberField
                        source="amount2"
                        record={{
                          id: "",
                          amount2,
                        }}
                      />
                    </Labeled>
                  )}

                  <Labeled
                    id="make"
                    source="make"
                    label="resources.bids.fields.make"
                    className={formClasses.make}
                  >
                    <TruncatedTextField
                      source="requisitionDetail.make"
                      label="resources.bids.fields.make"
                      truncateLength={25}
                      record={scopedFormData}
                    />
                  </Labeled>
                  <Labeled
                    id="size"
                    source="size"
                    label="resources.bids.fields.size"
                    className={formClasses.size}
                  >
                    <TruncatedTextField
                      source="requisitionDetail.size"
                      label="resources.bids.fields.size"
                      truncateLength={25}
                      record={scopedFormData}
                    />
                  </Labeled>
                  <Labeled
                    id="specifications"
                    source="requisitionDetail.specifications"
                    label="resources.bids.fields.specifications"
                    className={formClasses.specifications}
                  >
                    <RichTextField
                      source="requisitionDetail.specifications"
                      label="resources.bids.fields.specifications"
                      record={scopedFormData}
                    />
                  </Labeled>
                  <Labeled
                    id="comments"
                    source="comments"
                    label="resources.bids.fields.comments"
                    className={formClasses.comments}
                  >
                    <TruncatedTextField
                      source="comments"
                      label="resources.bids.fields.comments"
                      truncateLength={25}
                      record={scopedFormData}
                    />
                  </Labeled>
                </ItemDetailsInputAccordionField>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <Box className={formClasses.totalsContainer}>
        <Typography className={formClasses.totalAmountLabel}>Totals</Typography>
        <Labeled
          id="totalAmount"
          source="totalAmount"
          label="resources.bids.fields.totalAmount"
          className={formClasses.totalAmountContainer}
        >
          <NumberField
            source="totalAmount"
            record={{
              id: "",
              totalAmount,
            }}
            label="resources.bids.fields.totalAmount"
          />
        </Labeled>

        {showUnitPrice2 && (
          <Labeled
            id="totalAmount2"
            source="totalAmount2"
            label="resources.bids.fields.totalAmount2"
            className={formClasses.totalAmountContainer}
          >
            <NumberField
              source="totalAmount2"
              record={{
                id: "",
                totalAmount2,
              }}
            />
          </Labeled>
        )}
      </Box>
    </Box>
  );
};

export { VendorBidForm };
