import { bidResource } from "../../../../modules/bids";
import {
  itemResource,
  itemsClassResource,
  itemsGroupResource,
  itemsSegmentResource,
} from "../../../../modules/datasetup/items";
import {
  vendorResource,
} from "../../../../modules/datasetup/vendors";
import {
  enquiryResource,
} from "../../../../modules/enquiries";
import {
  requisitionResource,
} from "../../../../modules/requisitions";
import { StatusItemProps } from "../../../common";
import { memoize } from "../../../components/validations/validationUtils";
import {
  accountResource,
  usersResource,
} from "../../accounts";
import { Record } from "react-admin";
import { businessFunctionResource } from "../../../../modules/datasetup/business-functions";
import { categoryResource } from "../../../../modules/datasetup/category";
import { locationResource } from "../../../../modules/datasetup/locations";
import { purchaseGroupResource } from "../../../../modules/datasetup/purchase-groups";

export const RESOURCE_NAME_MEDIA = "media";

export enum PermissionMedia {
  MEDIA_MANAGEMENT_MENU = "view-media-management-menu",
  LIST_MEDIA = "list-media",
  SHOW_MEDIA = "show-media",
  CREATE_MEDIA = "create-media",
  EDIT_MEDIA = "update-media",
  DELETE_MEDIA = "delete-media",
}

export const mediaPermissions = {
  menu: PermissionMedia.MEDIA_MANAGEMENT_MENU,
  list: PermissionMedia.LIST_MEDIA,
  create: PermissionMedia.CREATE_MEDIA,
  update: PermissionMedia.EDIT_MEDIA,
  show: PermissionMedia.SHOW_MEDIA,
  delete: PermissionMedia.DELETE_MEDIA,
};

export interface MediaFileSource {
  source: string;
  subFolder: string;
}

export interface MediaFile {
  originalFileName: string;
  filePath: string;
  fileExtension: string;
  fileSize: number;
  fileType: string;
}

export const MEDIA_OWNER_MAP = new Map([
  [
    "Accounts",
    {
      id: "Accounts",
      label: "resources.media.choices.ownerType.accounts",
      color: "#083D77",
      resource: accountResource?.name,
      permission:
        accountResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],
  [
    "Bids",
    {
      id: "Bids",
      label: "resources.media.choices.ownerType.bids",
      color: "#EE964B",
      resource: bidResource.name,
      permission: bidResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],
  [
    "Business Functions",
    {
      id: "Business Functions",
      label: "resources.media.choices.ownerType.businessFunctions",
      color: "#AC9BD7",
      resource: businessFunctionResource?.name,
      permission:
        businessFunctionResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",
    },
  ],

  [
    "Category",
    {
      id: "Category",
      label: "resources.media.choices.ownerType.category",
      color: "#B9E28C",
      resource: categoryResource?.name,
      permission:
        categoryResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],

  [
    "Enquiries",
    {
      id: "Enquiries",
      label: "resources.media.choices.ownerType.enquiries",
      color: "#A8A84D",
      resource: enquiryResource?.name,
      permission: enquiryResource?.options?.allowedPermissions.show?.toString(),
    },
  ],
  [
    "Items",
    {
      id: "Items",
      label: "resources.media.choices.ownerType.items",
      color: "#F2FF49",
      resource: itemResource?.name,
      permission: itemResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],
  [
    "ItemClass",
    {
      id: "Item Class",
      label: "resources.media.choices.ownerType.itemClass",
      color: "#723517",
      resource: itemsClassResource?.name,
      permission:
        itemsClassResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],

  [
    "ItemGroups",
    {
      id: "ItemGroups",
      label: "resources.media.choices.ownerType.itemGroup",
      color: "#D81E5B",
      resource: itemsGroupResource?.name,
      permission:
        itemsGroupResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",        
    },
  ],

  [
    "ItemSegments",
    {
      id: "ItemSegments",
      label: "resources.media.choices.ownerType.itemSegment",
      color: "#74D3AE",
      resource: itemsSegmentResource?.name,
      permission:
        itemsSegmentResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",
    },
  ],

  [
    "Location",
    {
      id: "Location",
      label: "resources.media.choices.ownerType.location",
      color: "#29BF12",
      resource: locationResource?.name,
      permission:
        locationResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],

  [
    "PurchaseGroups",
    {
      id: "Purchase Groups",
      label: "resources.media.choices.ownerType.purchaseGroupes",
      color: "#5C7457",
      resource: purchaseGroupResource?.name,
      permission:
        purchaseGroupResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",       
    },
  ],

  [
    "Requisitions",
    {
      id: "Requisitions",
      label: "resources.media.choices.ownerType.requisitions",
      color: "#E88D67",
      resource: requisitionResource?.name,
      permission:
        requisitionResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",
    },
  ],
  [
    "User",
    {
      id: "User",
      label: "resources.media.choices.ownerType.user",
      color: "#C7EF00",
      resource: usersResource?.name,
      permission: usersResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],
  [
    "vendorGroup",
    {
      id: "VendorGroup",
      label: "resources.media.choices.ownerType.vendorGroups",
      color: "#210B2C",
      resource: purchaseGroupResource?.name,
      permission:
        purchaseGroupResource?.options?.allowedPermissions?.show?.toString(),
      width: "150px",        
    },
  ],
  [
    "Vendors",
    {
      id: "Vendors",
      label: "resources.media.choices.ownerType.vendors",
      color: "#FFAEBC",
      resource: vendorResource?.name,
      permission: vendorResource?.options?.allowedPermissions?.show?.toString(),
    },
  ],
]);

export const fetchMediaOwnerTypeChoices = memoize(() => {
  const mediaOwnerTypeFiltersList:{id: string, name: string}[] = [];
  MEDIA_OWNER_MAP.forEach((value, key) => {
    const record = {
      id: value.id,
      name: value.label,
    };

    mediaOwnerTypeFiltersList.push(record);
  });

  return mediaOwnerTypeFiltersList;
});

export const mediaOwnerTypeFiltersList = memoize((): StatusItemProps[] => {
  const mediaOwnerTypeFiltersList: StatusItemProps[] = [];

  MEDIA_OWNER_MAP.forEach((value, key) => {
    const record: StatusItemProps = {
      statusLabel: value.label!,
      statusValue: value.id!,
      statusColor: value.color,
      ...(value?.width && {
        width: value.width,
      }),
    };

    mediaOwnerTypeFiltersList.push(record);
  });

  return mediaOwnerTypeFiltersList;
});

export const getRowStyle = (record: Record) => {
  const ownerType = record?.ownerType;
  const ownerTypeRecord = MEDIA_OWNER_MAP.get(ownerType);
  const mediaOwnerTypeColor = ownerTypeRecord?.color || "#FFF";
  return {
    borderLeftColor: mediaOwnerTypeColor,
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
  };
};
