export const RESOURCE_NAME_ORG_STRUCTURE_TYPES = "orgStructureTypes";

export enum PermissionOrgStructureTypes {
  MENU_ORG_STRUCTURE_TYPE = "view-org-structure-type-menu",
  LIST_ORG_STRUCTURE_TYPES = "list-org-structure-types",
  SHOW_ORG_STRUCTURE_TYPE = "show-org-structure-type",
  CREATE_ORG_STRUCTURE_TYPE = "create-org-structure-type",
  EDIT_ORG_STRUCTURE_TYPE = "update-org-structure-type",
  DELETE_ORG_STRUCTURE_TYPE = "delete-org-structure-type",
}
