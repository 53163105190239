import { IconButton, Menu, useMediaQuery, Theme } from "@material-ui/core";
import {
  Children,
  cloneElement,
  isValidElement,
  useState,
} from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const ListVerticalActionMenuButton= ({ children }) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenuItem = (menuItem) => {
    if (isValidElement(menuItem)) {
      return cloneElement(menuItem, {
        //@ts-ignore
        closeMenu: handleClose,
      });
    } else {
      return null;
    }
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          padding: isSmall?"12px":"4px 5px",
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Children.map(children, renderMenuItem)}
      </Menu>
    </div>
  );
};

export { ListVerticalActionMenuButton };
