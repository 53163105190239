import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  SaveButton,
  Toolbar,
  useNotify,
  useRedirect,
  useRefresh,
  setSidebarVisibility,
  useUpdate,
  SimpleForm,
} from "react-admin";
import { DefaultEdit } from "../../lib/components/crud";
import { EnquiryCrudEditActions, EnquiryEditContainer } from "./components";
import { RESOURCE_NAME_ENQUIRY } from "./constants";
import { MediaFileSource, transformAndUploadS3Files } from "../../lib/modules";
import { FILTER_DIRECTION } from "../../common";

const EnquiryEdit = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  return (
    <DefaultEdit
      {...props}
      actions={<EnquiryCrudEditActions/>}
      mutationMode="pessimistic"
      basePath={`/enquiries?filter={"direction":"${FILTER_DIRECTION.SENT}"}`}
    >
      <SimpleForm
        {...props}
        warnWhenUnsavedChanges
        toolbar={<CustomEnquiryToolbar {...props} />}
        mutationMode="pessimistic"
      >
        <EnquiryEditContainer />
      </SimpleForm>
    </DefaultEdit>
  );
};

const SaveEnquiryButton = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  // eslint-disable-next-line
  const [saveEnquiry, {}] = useUpdate(RESOURCE_NAME_ENQUIRY, props?.record?.id);
  const refresh = useRefresh();

  const handleSave = useCallback(
    async (values) => {
      if (!values.vendorIds || !values.enquiryDetails) {
        if (!values.vendorIds && !values.enquiryDetails) {
          notify("resources.enquiries.messages.chooseItemsAndVendors", {
            type: "error",
          });
        } else if (!values.vendorIds) {
          notify("resources.enquiries.messages.chooseVendors", {
            type: "error",
          });
        } else if (!values.enquiryDetails) {
          notify("resources.enquiries.messages.chooseItems", { type: "error" });
        }

        return false;
      }

      const mediaFileSources: MediaFileSource[] = [
        {
          source: "files",
          subFolder: "enquiries",
        },
      ];
      const modifiedValues = await transformAndUploadS3Files(mediaFileSources)(
        values
      );
      saveEnquiry(
        {
          payload: {
            data: {
              ...modifiedValues,
            },
          },
        },
        {
          onSuccess: () => {
            notify("resources.enquiries.messages.enquirySaved", {
              type: "success",
            });
            refresh(true);
            redirect(`/enquiries?filter={"direction":"${FILTER_DIRECTION.SENT}"}`);
          },
          onFailure: (error) => {
            const message = error?.message;
            notify(message, { type: "error" });
          },
        }
      );
    },
    // eslint-disable-next-line
    [saveEnquiry, notify, redirect]
  );
  return <SaveButton {...props} onSave={handleSave} />;
};

const CustomEnquiryToolbar = (props) => (
  <Toolbar {...props} style={{ clear: "left" }}>
    <SaveEnquiryButton {...props} />
  </Toolbar>
);

export { EnquiryEdit };
