import { regex } from "ra-core";
import { memoize } from "./validationUtils";

const URL_REGEX =
  /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;

export const isUrl = memoize(
  (message = "components.validations.isUrl") =>
    regex(URL_REGEX, message)
);
