import React, { ReactElement } from "react";
import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  BooleanField,
  DateField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  useGetIdentity,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT, RESOURCE_NAME_ROLE } from "../..";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../components";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";

const ListFilters = (props: Omit<FilterProps, "children">) => {
  const { identity } = useGetIdentity();
  const isSystemAdmin = identity?.isSystemAdmin;

  return (
    <Filter {...props}>
      <SearchFilter alwaysOn key="Search" />
      <IdFilter
        source="id"
        label="components.controls.filters.idFilter.label"
        key="id"
      />
      <ReferenceArrayInput
        label="resources.users.filters.roles"
        source="roleIds"
        reference={RESOURCE_NAME_ROLE}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        key="roleIds"
      >
        <AutocompleteArrayInput
          optionText="name"
          optionValue="id"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceArrayInput>
      {isSystemAdmin && (
        <ReferenceInput
          source="accountId"
          label="resources.users.fields.accountId"
          reference={RESOURCE_NAME_ACCOUNT}
          isRequired
        >
          <AutocompleteInput
            optionText="displayLabel"
            isRequired
            helperText="resources.users.help.accountId"
            resettable
          />
        </ReferenceInput>
      )}
      <BooleanInput
        source="isPrimaryUser"
        label="resources.users.fields.isPrimaryUser"
      />
    </Filter>
  );
};

const defaultColumns = ["firstName", "lastName", "email"];

const UserList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "firstName", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="email" />
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { UserList };
