import {
  CreateProps,
} from "react-admin";
import { AccountForm } from "./AccountForm";
import { DefaultCreate } from '../../../components';
import { MediaFileSource, transformAndUploadS3Files } from "../../utilities/media";

const AccountCreate= (props: CreateProps)=> {
  const mediaFileSources: MediaFileSource[] = [{
    source: 'files',
    subFolder: 'accounts',
  }];  
  return (
      <DefaultCreate {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}>
          <AccountForm />
      </DefaultCreate>
    );
  };
  
  export { AccountCreate };