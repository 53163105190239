import StraightenIcon from '@material-ui/icons/Straighten';import { ItemSegmentCreate } from './ItemSegmentCreate';

import { ItemSegmentList } from './ItemSegmentList';
import { ItemSegmentEdit } from './ItemSegmentEdit';
import { PermissionItemSegments, RESOURCE_NAME_ITEM_SEGMENTS } from './constants';
import { ItemSegmentShow } from './ItemSegmentShow';

const allowedPermissions = {
    menu: PermissionItemSegments.MENU_ITEM_SEGMENTS,
    list: PermissionItemSegments.LIST_ITEM_SEGMENTS,
    create: PermissionItemSegments.CREATE_ITEM_SEGMENT,
    update: PermissionItemSegments.EDIT_ITEM_SEGMENT,
    show: PermissionItemSegments.SHOW_ITEM_SEGMENT,
    delete: PermissionItemSegments.DELETE_ITEM_SEGMENT,
    import: PermissionItemSegments.IMPORT_ITEM_SEGMENTS,
 };

const itemsSegmentResource = {
    name: RESOURCE_NAME_ITEM_SEGMENTS,
    list: ItemSegmentList,
    create: ItemSegmentCreate,
    edit: ItemSegmentEdit,
    show: ItemSegmentShow, 
    icon: StraightenIcon,
    options: {label: 'resources.itemSegments.name', allowedPermissions },
}

export * from './constants';
export { itemsSegmentResource }