export const RESOURCE_NAME_USER = "users";
export const RESOURCE_NAME_ONLY_USER = "users/onlyUser";

export enum PermissionUsers {
  USER_MENU = "view-user-management-menu",
  USER_LIST = "list-users",
  USER_SHOW = "show-user",
  USER_CREATE = "create-user",
  USER_UPDATE = "update-user",
  USER_DELETE = "delete-user",
}

export interface IUserSettings {
  theme: string;
  locale: string;
}

export interface IUser {
  id: string;
  accountId: string;
  email: string;
  firstName: string;
  lastName: string;
  settings?: IUserSettings;
}
