import { TextField, EmailField, Labeled, Record } from "react-admin";

export interface NameEmailFieldProps {
  nameFieldSource?: string;
  emailFieldSource?: string;
  nameFieldLabel?: string;
  emailFieldLabel?: string;
  useLabel?: boolean;
  fieldLabel?: string;
  record?: Record;
}

const NameEmailField = ({
  nameFieldSource = "fullName",
  emailFieldSource = "email",
  nameFieldLabel = "common.fields.name",
  emailFieldLabel = "common.fields.email",
  useLabel = false,
  fieldLabel,
  record,
}: NameEmailFieldProps) => {
  if (useLabel) {
    return (
      <Labeled id="nameEmailField" label={fieldLabel}>
        <>
          <TextField label={nameFieldLabel} source={nameFieldSource}/>
          &nbsp;(
          <EmailField label={emailFieldLabel} source={emailFieldSource} />)
        </>
      </Labeled>
    );
  }

  return (
    <>
      <TextField label={nameFieldLabel} source={nameFieldSource} />
      &nbsp;(
      <EmailField label={emailFieldLabel} source={emailFieldSource} />)
    </>
  );
};

export { NameEmailField };
