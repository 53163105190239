import { makeStyles, useMediaQuery, Theme } from "@material-ui/core";
import GridList from '@material-ui/core/GridList';

import GridListTile from '@material-ui/core/GridListTile';
import { SinglePermission } from './SinglePermission';
import { Permission } from "./types";

const useStyles = makeStyles((theme) => ({
  heading: {
    marginTop: "-10px",
    fontSize: theme.typography.pxToRem(15),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    fontStyle: "italic",
  },
  gridList: {
    height: "auto",
    paddingLeft: 15,
  },
  gridListTile: {
    root: {
      height: "auto",
    },
  },
}));

interface Props {
  permissions: Permission[];
}

const PermissionsGridList= ({ permissions }) => {
  const classes = useStyles();
  const permissionsCount = permissions?.length;

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const isExtraLarge = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("xl")
  );

  const getGridListCols = () => {
      let gridListCols = 1;

    if (isExtraLarge) {
      gridListCols = 4;
    }else if (isLarge) {
      gridListCols = 3;
    }else if (isMedium) {
      gridListCols = 2;
    }else if (isSmall) {
      gridListCols = 2;
    }

    if(gridListCols > permissionsCount){
        gridListCols = permissionsCount;
    }
    
    return gridListCols;
  };

  if (!permissions) {
    return null;
  }

  return (
    <div>
      <GridList
        cols={getGridListCols()}
        spacing={6}
        cellHeight="auto"
        className={classes.gridList}
      >
        {permissions.map((permission) => (
          <GridListTile key={`gridList_${permission?.id}`}>
            <SinglePermission
              permission={permission}
            />
          </GridListTile>
        ))}
      </GridList>
    </div>
  );
};

export { PermissionsGridList };
