import { ReactElement, useMemo } from "react";
import {
  DefaultCrudListRowActions,
  ListRowActions,
  ListRowActionsProps,
} from "../../../lib/components";
import { Record, useListFilterContext } from "react-admin";
import { PermissionBids } from "../constants";
import { usePermissionsCheck } from "../../../lib/core";
import {
  ApprovalListRowActionButtons,
  FILTER_DIRECTION,
  PermissionBehavior,
  ProcEntityRecordStatus,
} from "../../../common";

const BidListRowActionButtons = (props: ListRowActionsProps) => {
  const context = useListFilterContext();
  const directionFilterValue = context?.filterValues?.direction;
  const isDirectionSent = directionFilterValue === FILTER_DIRECTION.SENT;

  const { hasOwnOrAccountAccess, hasPermissionAccess, permissionsLoaded } =
    usePermissionsCheck();

  const overriddenListRowActions: Array<ReactElement> = useMemo(() => {
    const keyPrefix = props?.record?.id;
    const record = props?.record;
    const isVendorUser =
    isDirectionSent && hasPermissionAccess(PermissionBehavior.CAN_SELL);
    
    const accountId = isDirectionSent
      ? record?.vendorAccountId
      : record?.procureAccountId;
    const ownerUserId = isDirectionSent
      ? record?.vendorOwnerUserId
      : record?.procureOwnerUserId;
    const hasShowBidAccess =
      permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionBids.SHOW_ANY_BID,
        PermissionBids.SHOW_OWN_BID,
        accountId,
        ownerUserId
      );

    const hasEditBidAccess =
      isVendorUser && permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionBids.EDIT_ANY_BID,
        PermissionBids.EDIT_OWN_BID,
        record?.vendorAccountId,
        record?.vendorOwnerUserId
      );

    const hasDeleteBidAccess =
      permissionsLoaded &&
      hasOwnOrAccountAccess(
        PermissionBids.DELETE_ANY_BID,
        PermissionBids.DELETE_OWN_BID,
        record?.vendorAccountId,
        record?.vendorOwnerUserId
      );

    const shouldDisplayEditAction = async (
      record?: Record
    ): Promise<boolean> => {
      const status = record?.procureStatus;
      const isApproved = status === ProcEntityRecordStatus.APPROVED;

      const shouldDisplay = hasEditBidAccess && !isApproved;
      return Promise.resolve(shouldDisplay);
    };

    const shouldDisplayDeleteAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldDisplay = hasDeleteBidAccess;

      return Promise.resolve(hasDeleteBidAccess);
    };

    const listRowActions: Array<ReactElement> = [
      <DefaultCrudListRowActions
        key={`defaultCrudListRowActions-${keyPrefix}`}
        shouldDisplayEdit={shouldDisplayEditAction}
        shouldDisplayDelete={shouldDisplayDeleteAction}
        hasEdit={hasEditBidAccess}
        hasShow={hasShowBidAccess}
        {...props}
      />,

      <ApprovalListRowActionButtons {...props} />,
    ];

    return listRowActions;
  }, [hasOwnOrAccountAccess, permissionsLoaded, props]);

  if (permissionsLoaded) {
    return (
      <ListRowActions
        overriddenListRowActions={overriddenListRowActions}
        {...props}
      />
    );
  }else{
    return null;
  }
};

export { BidListRowActionButtons };
