import { Box } from "@material-ui/core";
import { AccordionField } from "../../../lib/components";
import { CommentOwnerEntity } from "../constants";
import { CommentsList } from "./CommentsList";

import { Route } from 'react-router';
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    commentsAccordion: { width: "100%" },
  })
);


const CommentsAccordion = ({
  ownerEntityType,
  ownerEntityId,
  isViewOnly,
}: CommentsAccordionProps) => {
  const classes = useStyles();

  return (
    <AccordionField
      headerlabel="resources.requisitions.sections.comment.input.title"
      headerTooltip="resources.requisitions.sections.comment.input.tooltip"
      className={classes.commentsAccordion}
    >
      <Box mt={2} width="90vw">
        <CommentsList
          ownerEntityType={ownerEntityType}
          ownerEntityId={ownerEntityId}
          isViewOnly={isViewOnly}
        />
      </Box>
    </AccordionField>
  );
};

interface CommentsAccordionProps extends CommentOwnerEntity{
  isViewOnly: boolean
}

export { CommentsAccordion };
