export enum PERMISSION_MODE {
  ANY,
  ALL,
}

export const COMMON_DATE_FORMAT = {
  day: "numeric",
  month: "numeric",
  year: "numeric",
};

export const COMMON_DATE_TIME_FORMAT = {
  weekday: "short",
  year: "2-digit",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};

export const DATE_FORMAT_STANDARD_DAY = 'dd-MM-yyyy';
export const DATE_FORMAT_STANDARD_DATE_TIME = 'dd-MMM-yyyy HH:mm';
