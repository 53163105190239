import { Layout as AppLayout, LayoutProps, Sidebar } from "react-admin";
import { AppBar } from "./AppBar";
import { darkTheme, lightTheme } from "./themes";
import { AppMenu } from "./AppMenu";
import { LocalUser } from "../../core";

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} BackdropProps={{ invisible: true }}/>;

const Layout = (props: LayoutProps) => {
  const localUser = new LocalUser();

  const hasDarkTheme = localUser?.hasDarkTheme();
  const theme = hasDarkTheme ? darkTheme : lightTheme;

  return (
    <AppLayout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={AppMenu}
      theme={theme}
    />
  );
};

export { Layout };
