import { Pagination } from "react-admin";
import { DEFAULT_PER_PAGE } from "../../../lib/common";
import { DefaultList } from "../../../lib/components";
import { CommentOwnerEntity, RESOURCE_NAME_COMMENT } from "../constants";
import { CommentListActions } from "./CommentListActions";
import { CommentListFilters } from "./CommentListFilters";
import { CommentsIterator } from "./CommentsIterator";

const CommentsList = ({
  ownerEntityType,
  ownerEntityId,
  isViewOnly,
  ...rest
}: CommentsListProps) => {
  return (
    <DefaultList
      resource={RESOURCE_NAME_COMMENT}
      basePath="/"
      exporter={false}
      empty={false}
      perPage={DEFAULT_PER_PAGE}
      title="Comments"
      showBreadcrumbs={false}
      filters={<CommentListFilters />}
      filterDefaultValues={{
        ownerEntityType,
        ownerEntityId,
      }}
      sort={{ field: "updatedAt", order: "DESC" }}
      actions={
        <CommentListActions
          ownerEntityType={ownerEntityType}
          ownerEntityId={ownerEntityId}
          isViewOnly={isViewOnly}
        />
      }
      pagination={
        <Pagination limit={<div/>}/>
      }
      {...rest}
    >
      <CommentsIterator 
          ownerEntityType = {ownerEntityType}
          ownerEntityId = { ownerEntityId}
          isViewOnly = {isViewOnly}
      />
    </DefaultList>
  );
};

interface CommentsListProps extends CommentOwnerEntity{
  isViewOnly: boolean
}

export { CommentsList };
