import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { VendorGroupForm } from "./VendorGroupForm";
const VendorGroupCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "vendor-group",
    },
  ];

  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <VendorGroupForm />
    </DefaultCreate>
  );
};

export { VendorGroupCreate };
