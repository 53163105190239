import { ReactElement } from "react";

import {
  TextField,
  ListProps,
  ReferenceField,
  DateField,
  BooleanField,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../../lib/modules/accounts";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
  FilenameField,
  MediaEmptyState,
} from "../../../../lib/components";
import {
  DEFAULT_PER_PAGE,
} from "../../../../lib/common";
import { MediaListFilters } from "./components/MediaListFilters";
import { MediaListSideFilters } from "./components/MediaListSideFilters";
import { MediaOwnerLinkField } from "./components/MediaOwnerLinkField";
import { getRowStyle } from "./constants";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";

const defaultColumns = [
  "originalFileName",
  "fileSize",
  "ownerType",
  "fileExtension",
  "updatedAt",
];

const MediaList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "updatedAt", order: "DESC" }}
      perPage={DEFAULT_PER_PAGE}
      aside={<MediaListSideFilters />}
      filters={<MediaListFilters />}
      defaultColumns={defaultColumns}
      empty={<MediaEmptyState />}
    >
      <CustomizableDatagrid rowStyle={getRowStyle} optimized>
        <FilenameField />
        <TextField source="fileExtension" />
        <MediaOwnerLinkField source="ownerType" />
        <TextField source="fileSizeHuman" sortBy="fileSize" />
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { MediaList };
