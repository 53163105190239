import { makeStyles } from "@material-ui/core";
import {
  DateField,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../../datasetup/business-functions";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";

const useStyles = makeStyles((theme) => ({
  layout: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 25,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));

const BidSummaryContainer = () => {
  const record = useRecordContext();
  const classes = useStyles();

  return (
    <SimpleShowLayout className={classes.layout}>
      <ReferenceField
        label="resources.bids.fields.procureAccountId"
        source="procureAccountId"
        reference={RESOURCE_NAME_ACCOUNT}
        link="show"
      >
        <TextField source="displayLabel" />
      </ReferenceField>

      <ReferenceField
        label="resources.bids.fields.businessFunctionId"
        source="businessFunctionId"
        reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
        link="show"
      >
        <TextField source="displayLabel" />
      </ReferenceField>
      <ReferenceField
        label="resources.enquiries.fields.currencyId"
        source="currencyId"
        reference={RESOURCE_NAME_CURRENCIES}
        link="show"
      >
        <TextField source="displayLabel" />
      </ReferenceField>

      {record.createdAt && (
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
      )}

      {record.createdAt && (
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
      )}
    </SimpleShowLayout>
  );
};

export { BidSummaryContainer };
