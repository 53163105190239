import { useContext } from "react";
import {
  Theme,
  createStyles,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { NumberField, SelectField, useTranslate } from "react-admin";
import {
  COLOR_ODD_CELL,
  COLOR_EVEN_CELL,
  COLOR_OPTIMAL_BACKGROUND_CELL,
  COLOR_OPTIMAL_FOREGROUND_CELL,
} from "../constants";
import { TruncatedTextField } from "../../../lib/components";
import { unitTypeChoices } from "./types";
import { BidComparisonCalculationContext } from "./context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      width: "100%",
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
    tableWrapper: {
      maxHeight: 1024,
      overflowX: "auto",
    },
    table: {
      borderSpacing: 1,
    },
    firstColumn: {
      width: "30%",
    },
    vendorCell: {
      "&:nth-of-type(odd)": {
        backgroundColor: COLOR_EVEN_CELL,
      },
      "&:nth-of-type(even)": {
        backgroundColor: COLOR_ODD_CELL,
      },
    },
    oddCell: {
      backgroundColor: COLOR_ODD_CELL,
      whiteSpace: "nowrap",
    },
    evenCell: {
      backgroundColor: COLOR_EVEN_CELL,
      whiteSpace: "nowrap",
    },
    headerCell: {
      whiteSpace: "nowrap",
    },
    dataCell: {
      whiteSpace: "nowrap",
    },
    optimal: {
      whiteSpace: "nowrap",
      color: COLOR_OPTIMAL_FOREGROUND_CELL,
      backgroundColor: COLOR_OPTIMAL_BACKGROUND_CELL,
      fontWeight: 500,
    },
    subTotal: {
      fontStyle: "italic",
      fontWeight: 500,
    },
    grandTotal: {
      fontWeight: 700,
    },
    priceComponent: {
      maxWidth: 85,
    },
  })
);

// eslint-disable-next-line
const DisplayPriceComponentsTable = ({}) => {
  const classes = useStyles();
  const translate = useTranslate();

  const { state } = useContext(BidComparisonCalculationContext);

  const bidSubTotalsLabel = translate(
    "resources.bidsComparison.components.DisplayPriceComponents.labels.bidSubTotals"
  );

  const bidGrandTotalsLabel = translate(
    "resources.bidsComparison.components.DisplayPriceComponents.labels.bidGrandTotals"
  );

  const data = state?.bidsComparisonCalc;
  const showUnitPrice2 = state?.bidsComparisonCalc?.enquiry?.showUnitPrice2;
  const COLSPAN_TITLE = showUnitPrice2 ? 4 : 4;
  const COLSPAN_VENDOR = showUnitPrice2 ? 5 : 3;
  const COLSPAN_OPTIMAL_VALUES = showUnitPrice2 ? 5 : 3;

  const priceComponentOptimalRecord = {
    id: data?.enquiry?.id || 1,
    subTotal: data?.totals?.optimal?.subTotal,
    grandTotal: data?.totals?.optimal?.grandTotal,
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Paper elevation={3} variant="elevation">
        <div className={classes.tableWrapper}>
          <Table
            stickyHeader
            aria-label="Price Comparison Table"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={COLSPAN_TITLE}
                  className={classes.firstColumn}
                  align="right"
                >
                  {translate("resources.bidsComparison.fields.vendors")}
                </TableCell>
                {data?.vendorAccounts?.map((vendorAccountRecord, index) => (
                  <TableCell
                    colSpan={COLSPAN_VENDOR}
                    align="center"
                    className={classes.vendorCell}
                  >
                    {vendorAccountRecord?.name}
                  </TableCell>
                ))}
                <TableCell
                  colSpan={COLSPAN_OPTIMAL_VALUES}
                  align="center"
                  className={classes.optimal}
                >
                  {translate(
                    "resources.bidsComparison.components.bidComparisonTable.labels.optimal"
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell className={classes.priceComponent} align="left">
                  {translate(
                    "resources.bidsComparison.fields.priceComponentName"
                  )}
                </TableCell>
                <TableCell className={classes.priceComponent} align="left">
                  {translate("resources.bidsComparison.fields.unitType")}
                </TableCell>
                <TableCell className={classes.priceComponent} align="left">
                  {translate("resources.bidsComparison.fields.value")}
                </TableCell>
                {data?.vendorAccounts?.map((vendorAccountRecord, index) => {
                  const isEvenRow = index % 2 ? true : false;
                  const className = isEvenRow
                    ? classes.evenCell
                    : classes.oddCell;
                  return (
                    <>
                      <TableCell className={className} align="center">
                        {translate("resources.bidsComparison.fields.unitPrice")}
                      </TableCell>
                      <TableCell className={className} align="center">
                        {translate("resources.bidsComparison.fields.amount")}
                      </TableCell>
                      {showUnitPrice2 && (
                        <TableCell className={className} align="center">
                          {translate(
                            "resources.bidsComparison.fields.unitPrice2"
                          )}
                        </TableCell>
                      )}
                      {showUnitPrice2 && (
                        <TableCell className={className} align="center">
                          {translate("resources.bidsComparison.fields.amount2")}
                        </TableCell>
                      )}
                      <TableCell className={className} align="center">
                        {translate(
                          "resources.bidsComparison.fields.estimatedDeliveryDate"
                        )}
                      </TableCell>
                    </>
                  );
                })}
                <TableCell className={classes.optimal} align="center">
                  {translate("resources.bidsComparison.fields.unitPrice")}
                </TableCell>
                <TableCell className={classes.optimal} align="center">
                  {translate("resources.bidsComparison.fields.amount")}
                </TableCell>
                {showUnitPrice2 && (
                  <TableCell className={classes.optimal} align="center">
                    {translate("resources.bidsComparison.fields.unitPrice2")}
                  </TableCell>
                )}
                {showUnitPrice2 && (
                  <TableCell className={classes.optimal} align="center">
                    {translate("resources.bidsComparison.fields.amount2")}
                  </TableCell>
                )}
                <TableCell className={classes.optimal} align="center">
                  {translate(
                    "resources.bidsComparison.fields.estimatedDeliveryDate"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.priceComponents?.map((row, index) => {
                const record = {
                  id: row?.id ?? index,
                  name: row?.name,
                  unitType: row?.unitType,
                  value: row?.value,
                  totals: {
                    optimal: {
                      component: row?.totals?.optimal,
                    },
                  },
                };

                return (
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="left" className={classes.dataCell}>
                      <TruncatedTextField
                        truncateLength={50}
                        source="name"
                        record={record}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.dataCell}>
                      <SelectField
                        source="unitType"
                        choices={unitTypeChoices}
                        record={record}
                      />
                    </TableCell>
                    <TableCell align="left" className={classes.dataCell}>
                      <NumberField source="value" record={record} />
                    </TableCell>
                    {data?.bidIds?.map((bidId, index) => {
                      const matchingBidRows = row?.totals?.bid?.filter(
                        (bid) => bid.bidId === bidId
                      );

                      if (!matchingBidRows || matchingBidRows?.length === 0) {
                        return null;
                      }

                      const bidTotal = matchingBidRows[0];

                      const bidTotalRecord = {
                        id: bidTotal.bidId,
                        amount: bidTotal?.amount,
                        amount2: bidTotal?.amount2,
                      };

                      return (
                        <>
                          <TableCell>&nbsp;</TableCell>
                          <TableCell align="center">
                            {
                              <NumberField
                                source="amount"
                                record={bidTotalRecord}
                                className={classes.dataCell}
                              />
                            }
                          </TableCell>
                          <TableCell>&nbsp;</TableCell>
                          {showUnitPrice2 && (
                            <TableCell align="center">
                              {
                                <NumberField
                                  source="amount2"
                                  record={bidTotalRecord}
                                  className={classes.dataCell}
                                />
                              }
                            </TableCell>
                          )}
                          {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                        </>
                      );
                    })}

                    <TableCell>&nbsp;</TableCell>
                    <TableCell className={classes.optimal} align="center">
                      {
                        <NumberField
                          source="totals.optimal.component.amount"
                          record={record}
                        />
                      }
                    </TableCell>
                    <TableCell>&nbsp;</TableCell>
                    {showUnitPrice2 && (
                      <TableCell className={classes.optimal} align="center">
                        {
                          <NumberField
                            source="totals.optimal.component.amount2"
                            record={record}
                          />
                        }
                      </TableCell>
                    )}
                    {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={COLSPAN_TITLE} align="right">
                  <i>{bidSubTotalsLabel}</i>
                </TableCell>

                {data?.totals?.bid?.map((bid, index) => {
                  const record = {
                    id: bid.bidId,
                    amount: bid?.priceComponents?.subTotals?.amount,
                    amount2: bid?.priceComponents?.subTotals?.amount2,
                  };
                  return (
                    <>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell align="center">
                        {
                          <NumberField
                            source="amount"
                            record={record}
                            className={classes.subTotal}
                          />
                        }
                      </TableCell>
                      {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                      {showUnitPrice2 && (
                        <TableCell align="center">
                          {
                            <NumberField
                              source="amount2"
                              record={record}
                              className={classes.subTotal}
                            />
                          }
                        </TableCell>
                      )}
                      <TableCell>&nbsp;</TableCell>
                    </>
                  );
                })}

                <TableCell>&nbsp;</TableCell>
                <TableCell align="center">
                  {
                    <NumberField
                      source="subTotal.amount"
                      record={priceComponentOptimalRecord}
                      className={classes.subTotal}
                    />
                  }
                </TableCell>
                {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                {showUnitPrice2 && (
                  <TableCell align="center">
                    {
                      <NumberField
                        source="subTotal.amount2"
                        record={priceComponentOptimalRecord}
                        className={classes.subTotal}
                      />
                    }
                  </TableCell>
                )}
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={COLSPAN_TITLE} align="right">
                  <b>{bidGrandTotalsLabel}</b>
                </TableCell>

                {data?.totals?.bid?.map((bid, index) => {
                  const record = {
                    id: bid.bidId,
                    amount: bid?.priceComponents?.grandTotals?.amount,
                    amount2: bid?.priceComponents?.grandTotals?.amount2,
                  };
                  return (
                    <>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell align="center">
                        {
                          <NumberField
                            source="amount"
                            record={record}
                            className={classes.grandTotal}
                          />
                        }
                      </TableCell>
                      {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                      {showUnitPrice2 && (
                        <TableCell align="center">
                          {
                            <NumberField
                              source="amount2"
                              record={record}
                              className={classes.grandTotal}
                            />
                          }
                        </TableCell>
                      )}
                      <TableCell>&nbsp;</TableCell>
                    </>
                  );
                })}

                <TableCell>&nbsp;</TableCell>
                <TableCell align="center">
                  {
                    <NumberField
                      source="grandTotal.amount"
                      record={priceComponentOptimalRecord}
                      className={classes.grandTotal}
                    />
                  }
                </TableCell>
                {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                {showUnitPrice2 && (
                  <TableCell align="center">
                    {
                      <NumberField
                        source="grandTotal.amount2"
                        record={priceComponentOptimalRecord}
                        className={classes.grandTotal}
                      />
                    }
                  </TableCell>
                )}
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Paper>
    </TableContainer>
  );
};

export { DisplayPriceComponentsTable };
