import { useContext, ChangeEvent } from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import { Permission, PermissionManagerActionTypes } from "./types";
import { PermissionManagerContext } from "./PermissionManagerContext";
import { useForm } from "react-final-form";
import { EventBusyTwoTone } from "@material-ui/icons";

interface Props {
  permission: Permission;
}

const SinglePermission = ({ permission }) => {
  const isDisabled = false;
  const permissionTooltip =
    permission.description + (isDisabled ? " - Inactive" : "");

  const { state, dispatch } = useContext(PermissionManagerContext);

  const form = useForm();

  const handlePermissionChanged = (
    isSelected: boolean
  ) => {
    if (dispatch) {
      dispatch({
        type: PermissionManagerActionTypes.TOGGLE_SINGLE_PERMISSION,
        payload: { permission, isSelected },
      });

      if (!state?.isDisabled) {
        const statePermissionChanges = state?.permissionChanges;
        if (statePermissionChanges) {
          const permissionChanges = {
            added: Array.from(statePermissionChanges?.added),
            removed: Array.from(statePermissionChanges?.removed),
          };
          form.change("permissionChanges", permissionChanges);
        }
      }
    }

    return 0;
  };

  return (
    <Tooltip title={permissionTooltip}>
      <FormControl component="fieldset" margin="normal">
        <FormControlLabel
          htmlFor={`${permission.id}_${permission.name}`}
          name={`${permission.id}_${permission.name}`}
          key={`${permission.id}_${permission.name}`}
          value={permission.id}
          disabled={state?.isDisabled}
          checked={permission.isPermissionAssigned}
          onClick={(event) => event.stopPropagation()}
          label={`${permission.label}`}
          control={
            <Checkbox
              id={`${permission.id}__${permission.name}`}
              color="primary"
              onChange={(event, checked) => handlePermissionChanged(checked)}
            />
          }
        />
      </FormControl>
    </Tooltip>
  );
};

export { SinglePermission };
