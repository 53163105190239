import { maxLength, minLength, required } from "react-admin";

const validateCode = [
    required(),
    minLength(3),
    maxLength(10),
];

export const validations = {
    code: validateCode,
}