export const RESOURCE_NAME_ITEM_SEGMENTS = "itemSegments";

export enum PermissionItemSegments {
  LIST_ITEM_SEGMENTS = "list-item-segments",
  MENU_ITEM_SEGMENTS = "view-item-segments-menu",
  SHOW_ITEM_SEGMENT = "show-item-segment",
  CREATE_ITEM_SEGMENT = "create-item-segment",
  EDIT_ITEM_SEGMENT = "update-item-segment",
  DELETE_ITEM_SEGMENT = "delete-item-segment",
  IMPORT_ITEM_SEGMENTS = "import-item-segments",

}
