import React, { ReactElement } from "react";

import {
  TextField,
  ListProps,
  FilterProps,
  ReferenceField,
  DateField,
  BooleanField,
  Filter,
  RichTextField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../../lib/common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_USER } from "../../../../lib/modules/accounts";
import { RESOURCE_NAME_ACCOUNT } from "../../../../lib/modules/accounts/accounts/constants";
import { RESOURCE_NAME_PURCHASE_GROUPS } from "../../purchase-groups/constants";
import { RESOURCE_NAME_ORG_STRUCTURE_TYPES } from "../org-structure-types/constants";
import { RESOURCE_NAME_ORG_USER_GROUP } from "../org-user-groups/constants";
import { RESOURCE_NAME_ORG_STRUCTURE } from "./constants";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
    <ReferenceArrayInput
      label="resources.orgStructures.filters.parentId"
      source="parentId"
      reference={RESOURCE_NAME_ORG_STRUCTURE}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="parentId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="resources.orgStructures.filters.userId"
      source="userIds"
      reference={RESOURCE_NAME_USER}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "firstName", order: "ASC" }}
      key="userIds"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="resources.orgStructures.filters.orgStructureTypeId"
      source="orgStructureTypeId"
      reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="orgStructureTypeId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="resources.orgStructures.filters.purchaseGroupId"
      source="purchaseGroupIds"
      reference={RESOURCE_NAME_PURCHASE_GROUPS}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="purchaseGroupIds"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="name"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="resources.orgStructures.filters.orgUserGroupId"
      source="orgUserGroupsIds"
      reference={RESOURCE_NAME_ORG_USER_GROUP}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="orgUserGroupsIds"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="name"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);
const defaultColumns = ["number", "name", "parentId", "orgStructureTypeId"];
const OrgStructureList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField
          source="number"
          label="resources.orgStructures.fields.number"
        />
        <TextField source="name" label="resources.orgStructures.fields.name" />
        <ReferenceField
          source="parentId"
          label="resources.orgStructures.fields.parentId"
          reference={RESOURCE_NAME_ORG_STRUCTURE}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="orgStructureTypeId"
          label="resources.orgStructures.fields.orgStructureTypeId"
          reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <RichTextField
          source="description"
          label="resources.businessFunction.fields.description"
        />
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT} >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField source="purchaseGroupIds" sortable={false} reference={RESOURCE_NAME_PURCHASE_GROUPS} >
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { OrgStructureList };
