export const RESOURCE_NAME_CATEGORY = 'categories'



export const enum CategoryTypes {
  ITEM = 1,
  VENDOR
}

export const CATEGORY_TYPE_CHOICES = [
  { id: 1, name: 'resources.category.choices.categoryType.item' },
  { id: 2, name: 'resources.category.choices.categoryType.vendor' },
];

export enum PermissionCategory {
  MENU_CATEGORY = "view-category-menu",
  LIST_CATEGORIES = "list-categories",
  SHOW_CATEGORY = "show-category",
  CREATE_CATEGORY = "create-category",
  EDIT_CATEGORY = "update-category",
  DELETE_CATEGORY = "delete-category",
}
