import { CreateButton, EditButton, ListButton, ShowActionsProps, TopToolbar, useResourceDefinition, useShowContext } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    className,
    hasEdit,
    hasList,
    resource,
    ...rest
}: any) => rest;

const DefaultShowAction = ({ className, ...rest }: ShowActionsProps) => {
    const { basePath, record } = useShowContext(rest);
    const { hasList, hasEdit, hasCreate } = useResourceDefinition(rest);
    return (
        <TopToolbar className={className} {...sanitizeRestProps(rest)}>
            {hasList && <ListButton basePath={basePath} />}
            {hasEdit && <EditButton basePath={basePath} record={record} />}
            {hasCreate && <CreateButton basePath={basePath}/>}
        </TopToolbar>
    );
}

export { DefaultShowAction }