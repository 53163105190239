import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../components";
import { PermissionGroupForm } from "./PermissionGroupsForm";

const PermissionGroupsCreate= (props: CreateProps) => {
  return (
    <DefaultCreate {...props}>
      <PermissionGroupForm isEdit={false} />
    </DefaultCreate>
  );
};

export { PermissionGroupsCreate };
