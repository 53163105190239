import { Dispatch } from "react";
import { Identifier, NotificationType, Record } from "react-admin";

export interface BidComparisonItem {
  bidId: Identifier;
  vendorAccountId: Identifier;
  unitPrice: number;
  unitPrice2?: number;
  amount: number;
  amount2?: number;
  estimatedDeliveryDate?: Date;
  isLowestUnitPrice: boolean;
  isLowestUnitPrice2?: boolean;
  isQuickestDeliveryDate?: boolean;
}

type IDName = {
  id: Identifier;
  name: string;
};

type IDCode = {
  id: Identifier;
  code: string;
};

type Amounts = {
  amount: number;
  amount2: number;
};

export interface PriceComponent extends Partial<Record> {
  name: string;
  value: number;
  unitType: number;
  totals?: {
    bid: [
      {
        id: Identifier;
        bidId: Identifier;
        amount: number;
        amount2?: number;
      }
    ];
    optimal?: Amounts;
  };
}

export const unitTypeChoices = [
  { id: 1, name: "Flat" },
  { id: 2, name: "%" },
];

export interface BidsComparisonCalc extends Partial<Record> {
  enquiry: {
    id: Identifier;
    code: string;
    preparedBy: IDName;
    preparedDate: Date;
    showUnitPrice2: boolean;
    currency: IDName;
    requisition: {
      id: Identifier;
      code: string;
      businessFunction?: {
        id: Identifier;
        number: string;
        name: string;
      }
    }

    bidDates: {
      start: Date;
      end: Date;
      preferredDeliveryDate: Date;
    };
  };
  summary: [
    {
      header: {
        item: {
          id: Identifier;
          name: string;
          sortOrder: number;
        };
        quantity: number;
        uom: {
          id: Identifier;
          name: string;
        };
        quantity2: number;
        uom2: {
          id: Identifier;
          name: string;
        };
      };
      bids: [
        {
          bidId: Identifier;
          detail: BidComparisonItem;
        }
      ];
      optimal: {
        lowestUnitPrice: {
          price: number;
          amount: string;
        };
        lowestUnitPrice2: {
          price: number;
          amount: string;
        };
        quickestDeliveryDate: Date;
      };
    }
  ];
  bidIds: Identifier[];
  vendorAccounts: IDName[];
  totals: {
    bid: [
      {
        bidId: Identifier;
        bidAmounts: {
          totalAmount: number;
          totalAmount2: number;
          priceComponents?: {
            subTotals: Amounts;
            grandTotals: Amounts;
          };
        };
        priceComponents: {
          subTotals: Amounts;
          grandTotals: Amounts;
        };
      }
    ];
    optimal: {
      bid: Amounts;
      subTotal?: Amounts;
      grandTotal?: Amounts;
    };
  };
  excluded: {
    items?: IDName[];
    vendorAccounts?: IDName[];
  };
  priceComponents: PriceComponent[];
}

export enum BidComparisonCalculationActionTypes {
  INIT,
  LOADING_BID_CALCULATIONS,
  ENQUIRY_LOADED,
  BID_COM_DATA_POPULATE,
  ITEM_TOGGLED,
  VENDOR_ACCOUNT_TOGGLED,
  OPEN_MANAGE_PRICE_COMPONENTS_DIALOG,
  CLOSE_MANAGE_PRICE_COMPONENTS_DIALOG,
  RECALCULATE_PRICE_COMPONENTS,
}

export interface BidComparisonState {
  isLoading: boolean;
  isInitialized: boolean;
  hasData: boolean;
  excludedItemIds: Set<Identifier>;
  excludedVendorAccountIds: Set<Identifier>;
  bidsComparisonCalc: BidsComparisonCalc | null;
  priceComponents: PriceComponent[];
  isManagePriceComponentsDialogOpen: boolean;
  shouldRecalculate: boolean;
}


export type BidComparisonCalculationActions =
  |
  {
    type: BidComparisonCalculationActionTypes.INIT;
  }
  |
  {
    type: BidComparisonCalculationActionTypes.LOADING_BID_CALCULATIONS;
  }
  |
  {
    type: BidComparisonCalculationActionTypes.ENQUIRY_LOADED;
    payload: {
      excludedItemIds: Identifier[],
      excludedVendorAccountIds: Identifier[],
      priceComponents: PriceComponent[],
    }
  }
  |
  {
    type: BidComparisonCalculationActionTypes.BID_COM_DATA_POPULATE;
    payload: {
      bidsComparisonCalc: BidsComparisonCalc,
    }
  }
  |
  {
    type: BidComparisonCalculationActionTypes.ITEM_TOGGLED;
    payload: {
      itemId: Identifier;
      checked: boolean;
      notify: (message: string,
        type?: NotificationType,
        messageArgs?: any,
        undoable?: boolean,
      ) => void;
    };
  }
  |
  {
    type: BidComparisonCalculationActionTypes.VENDOR_ACCOUNT_TOGGLED;
    payload: {
      vendorAccountId: Identifier;
      checked: boolean;
      notify: (message: string,
        type?: NotificationType,
        messageArgs?: any,
        undoable?: boolean,
      ) => void;
    };
  }
  |
  {
    type: BidComparisonCalculationActionTypes.OPEN_MANAGE_PRICE_COMPONENTS_DIALOG;
  }
  |
  {
    type: BidComparisonCalculationActionTypes.CLOSE_MANAGE_PRICE_COMPONENTS_DIALOG;
  }
  |
  {
    type: BidComparisonCalculationActionTypes.RECALCULATE_PRICE_COMPONENTS,
    payload: {
      priceComponents: PriceComponent[],
    }

  }

export interface BidComparisonCalculationContextProps {
  state?: BidComparisonState;
  dispatch?: Dispatch<BidComparisonCalculationActions>;
}