import { Box } from "@material-ui/core";
import { ReactElement, useMemo, useReducer } from "react";
import {
  List,
  usePermissions,
  useResourceDefinition,
  Loading,
} from "react-admin";
import { prepareModifiedListProps } from "./listUtils";
import { ListActionBreadcrumb } from "../../navigation";
import { DefaultListProps } from "../../types";
import { DefaultListActions } from "./DefaultListActions";
import { DEFAULT_PER_PAGE } from "../../../common";
import { DefaultCrudListRowActions } from "./listActions/actions";
import {
  ListRowActionButtonsContext,
  listRowActionButtonsReducer,
  prepareListRowActionButtonsInitialState,
} from "./listActions";
import { DefaultPagination } from "./DefaultPagination";
import { prepareListRowActions } from "./CustomizableList/listUtils";
import { DummyListRowComponent } from "./DummyListRowComponent";

const DefaultList = (
  props: DefaultListProps & { children: ReactElement }
): ReactElement => {
  const { listRowActions, showBreadcrumbs, ...rest } = props;
  const { options, icon } = useResourceDefinition(props);
  const { loaded, permissions } = usePermissions();
  const modifiedProps = prepareModifiedListProps(options, rest, permissions);

  const resourceName = options?.label;
  const listRowActionButtonsInitialState = useMemo(() => {
    const defaultListRowActions = prepareListRowActions(
      listRowActions,
      modifiedProps
    );
    const listRowActionButtonsInitialState =
      prepareListRowActionButtonsInitialState(defaultListRowActions);
    return listRowActionButtonsInitialState;
  }, [listRowActions]);

  const [listRowActionButtonsState, listRowActionButtonsDispatch] = useReducer(
    listRowActionButtonsReducer,
    { ...listRowActionButtonsInitialState }
  );

  const listRowActionButtonsContextValue = useMemo(() => {
    return {
      state: listRowActionButtonsState,
      dispatch: listRowActionButtonsDispatch,
    };
  }, [listRowActionButtonsState, listRowActionButtonsDispatch]);

  if (loaded) {
    const modifiedProps = prepareModifiedListProps(options, rest, permissions);

    return (
      <Box>
        {showBreadcrumbs && (
          <Box pt={1}>
            <ListActionBreadcrumb resourceLabel={resourceName} Icon={icon} />
          </Box>
        )}
        <ListRowActionButtonsContext.Provider
          value={listRowActionButtonsContextValue}
        >
          <List {...modifiedProps} />
          {listRowActions !== false && typeof listRowActions !== "boolean" && (
            <DummyListRowComponent
              listRowActions={listRowActions}
              modifiedProps={modifiedProps}
            />
          )}
        </ListRowActionButtonsContext.Provider>
      </Box>
    );
  } else {
    return <Loading />;
  }
};

DefaultList.defaultProps = {
  actions: <DefaultListActions />,
  filter: {},
  perPage: DEFAULT_PER_PAGE,
  pagination: <DefaultPagination />,
  showBreadcrumbs: true,
};

DefaultList.displayName = "DefaultList";

export { DefaultList };
