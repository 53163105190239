import CategoryIcon from '@material-ui/icons/Category';import { ItemClassList } from './ItemClassList';
import { ItemClassEdit } from './ItemClassEdit';
import { PermissionItemClasses, RESOURCE_NAME_ITEM_CLASS } from './constants';
import { ItemClassShow } from './ItemClassShow';
import { ItemClassCreate } from './ItemClassCreate';

const allowedPermissions = {
    menu: PermissionItemClasses.MENU_ITEM_CLASSES,
    list: PermissionItemClasses.LIST_ITEM_CLASSES,
    create: PermissionItemClasses.CREATE_ITEM_CLASS,
    update: PermissionItemClasses.EDIT_ITEM_CLASS,
    show: PermissionItemClasses.SHOW_ITEM_CLASS,
    delete: PermissionItemClasses.DELETE_ITEM_CLASS,
    import: PermissionItemClasses.IMPORT_ITEM_CLASSES,
};
const itemsClassResource = {
    name: RESOURCE_NAME_ITEM_CLASS,
    list: ItemClassList,
    create: ItemClassCreate,
    edit: ItemClassEdit,
    show: ItemClassShow, 
    icon: CategoryIcon,
    options: { label: 'resources.itemClasses.name',allowedPermissions },
}

export * from './constants';
export { itemsClassResource }