import { useCallback } from "react"
import { useListContext, useTranslate, RefreshIconButtonProps } from "react-admin"
import NavigationRefresh from '@material-ui/icons/Refresh';
import { IconButton, makeStyles, Theme, Tooltip } from "@material-ui/core";



const RefetchIconButton = ({
    label  = 'ra.action.refresh',
    icon = defaultIcon,
    onClick,
    className,
    ...rest
}: RefetchButtonProps) => {
    const classes = useStyles();
    const buttonClassName = className || classes.root;
    const { refetch } = useListContext();
    const translate = useTranslate();
    const handleClick = useCallback(
        event => {
            event.preventDefault();
            refetch();
        },[refetch]
    )

    return (
        <Tooltip title={label && translate(label, { _: label })}>
            <IconButton
                aria-label={label && translate(label, { _: label })}
                className={buttonClassName}
                color="primary"
                onClick={handleClick}
                {...rest}
            >
                {icon}
            </IconButton>
        </Tooltip>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1.75),
        paddingRight: theme.spacing(1.75),
    }
}));

const defaultIcon = <NavigationRefresh />;

interface RefetchButtonProps extends RefreshIconButtonProps {

}

export { RefetchIconButton }