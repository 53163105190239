import LocationOnIcon from "@material-ui/icons/LocationOn";
import { PermissionLocations,RESOURCE_NAME_LOCATION } from "./constants";
import { LocationCreate } from "./LocationCreate";
import { LocationEdit } from "./LocationEdit";
import { LocationList } from "./LocationList";
import { LocationShow } from "./LocationShow";


const locationPermissions = {
    menu: PermissionLocations.MENU_LOCATION,
    list: PermissionLocations.LIST_LOCATIONS,
    create: PermissionLocations.CREATE_LOCATION,
    update: PermissionLocations.EDIT_LOCATION,
    show: PermissionLocations.SHOW_LOCATION,
    delete: PermissionLocations.DELETE_LOCATION,

};

const locationResource = {
    name: RESOURCE_NAME_LOCATION,
    list: LocationList,
    create: LocationCreate,
    edit: LocationEdit,
    show: LocationShow,
    icon: LocationOnIcon,
    options: { label: 'resources.location.name', allowedPermissions: locationPermissions },
}

export * from './constants'
export {locationPermissions,locationResource}
