import { useState, useMemo } from "react";
import { Card, CardContent, Box } from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NightsStayIcon from '@material-ui/icons/NightsStay';
import Button from "@material-ui/core/Button/index";
import { useTranslate, Title, Loading } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { LocalUser } from "../../core";
import { CUSTOMIZABLE_LIST_STORAGE_KEY } from "../../components/crud";

const useStyles = makeStyles((theme) => ({
  label: { width: "10em", display: "inline-block" },
  button: { margin: "1em" },
}));

const UserPreferences= () => {
  const translate = useTranslate();
  const classes = useStyles();

  const localUser = useMemo(() => new LocalUser(), []);
  const [hasDarkTheme, setDarkTheme] = useState(localUser.hasDarkTheme());

  const changeTheme = (isDarkTheme: boolean) => {
    localUser.changeLocalTheme(isDarkTheme);
    setDarkTheme(isDarkTheme);
  };

  const clearListCustomizations = () => {
    localStorage.removeItem(CUSTOMIZABLE_LIST_STORAGE_KEY);
  }

  if (!localUser) {
    return <Loading />;
  }

  return (
    <Card>
      <Title title={translate("resources.users.profile.theme.label")} />
      <CardContent>
        <Box>
          <div className={classes.label}>
            {translate("resources.users.profile.theme.label")}
          </div>
          <Button
            variant="contained"
            className={classes.button}
            color={!hasDarkTheme ? "primary" : "default"}
            startIcon={<WbSunnyIcon/>}
            onClick={() => changeTheme(false)}
          >
            {translate("resources.users.profile.theme.light")}
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            color={hasDarkTheme ? "primary" : "default"}
            startIcon={<NightsStayIcon/>}
            onClick={() => changeTheme(true)}
          >
            {translate("resources.users.profile.theme.dark")}
          </Button>
        </Box>
        <Box>
          <div className={classes.label}>
            {translate("resources.users.profile.listCustomizations.label")}
          </div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteForeverIcon/>}
            onClick={() => clearListCustomizations()}
          >
            {translate("resources.users.profile.listCustomizations.clear")}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export { UserPreferences };
