import { Box, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

const UserFormMessage = ({
  line1,
  line2,
}: {
  line1: string;
  line2?: string;
}) => {
  const translate = useTranslate();
  return (
    <Box mt={2} width="100%!important">
      <Typography variant="body1">{translate(line1)}</Typography>
      {line2 && <Typography variant="body1">{translate(line2)}</Typography>}
    </Box>
  );
};

export { UserFormMessage };
