import { hasAccess } from "../../../core/helpers";

export const prepareModifiedListProps = (options: any, otherProps, permissions: any) => {
    const allowedPermissions = options?.allowedPermissions;
    const listPermissionName = allowedPermissions?.list;
    const createPermissionName = allowedPermissions?.create;
    const editPermissionName = allowedPermissions?.update;
    const showPermissionName = allowedPermissions?.show;

    const hasList = otherProps?.hasList && hasAccess(permissions, listPermissionName);

    const hasCreate = otherProps?.hasCreate &&
      createPermissionName &&
      hasAccess(permissions, createPermissionName);

    const hasEdit = otherProps?.hasEdit &&
      editPermissionName &&
      hasAccess(permissions, editPermissionName);

    const hasShow = otherProps?.hasShow &&
      showPermissionName &&
      hasAccess(permissions, showPermissionName);

    const modifiedProps = {
      ...otherProps,
      hasList,
      hasCreate,
      hasEdit,
      hasShow,
    };
    return modifiedProps;
}