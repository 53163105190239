import { Record } from "react-admin";
import { PROC_ENTITY_RECORD_STATUS_COLORS } from "..";

const getProcEntityRecordStatusRowStyle = (record: Record) => {
  let style = {};

  if (record) {
    const recordStatusColor = PROC_ENTITY_RECORD_STATUS_COLORS[record.status];
    if(recordStatusColor){
        style = {
            borderLeftColor: recordStatusColor,
            borderLeftWidth: 5,
            borderLeftStyle: 'solid',
        }
    }
  }

  return style;
};

export { getProcEntityRecordStatusRowStyle };
