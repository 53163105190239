import { useEffect, useMemo, useRef } from "react";
import { ThemeProvider } from "@material-ui/styles";
import {
  Card,
  Avatar,
  Typography,
  AppBar,
  createTheme,
  makeStyles,
} from "@material-ui/core";
import { lightTheme } from "../../components/layout/themes";
import { Notification } from "react-admin";
import { Logo } from "../../components/layout/Logo";
import classnames from "classnames";
import LockIcon from "@material-ui/icons/Lock";

const useStyles = makeStyles(
  (theme) => ({
    main: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      height: "1px",
      alignItems: "center",
      justifyContent: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundImage:
        "radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
    },
    appbar: {
      paddingTop: 8,
    },
    card: {
      minWidth: 300,
      marginTop: "6em",
    },
    avatar: {
      margin: "2em",
      display: "flex",
      justifyContent: "center",
    },
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
    appBarTitle: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      alignSelf: "center",
    },
    spacer: {
      flex: 1,
    },
    title: {
      margin: "1em",
      display: "flex",
      justifyContent: "center",
      color: theme.palette.primary.main,
      fontSize: theme.typography.pxToRem(25),
    },
    titleMessage: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: theme.palette.grey[500],
    },
  }),
  { name: "RaLogin" }
);

export interface Props {
  title: string;
  titleMessage: string;
}

const UnauthenticatedLayout= ({
  title,
  titleMessage,
  children,
}) => {
  const theme = lightTheme;
  const backgroundImage = "/cover.jpeg";
  const classes = useStyles();
  const containerRef = useRef<HTMLDivElement>(null);
  const muiTheme = useMemo(() => createTheme(theme), [theme]);
  let backgroundImageLoaded = false;

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage();
    }
// eslint-disable-next-line
  }, [backgroundImageLoaded]);

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && null !== containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`;
      backgroundImageLoaded = true;
    }
  };

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image();
      img.onload = updateBackgroundImage;
      img.src = backgroundImage;
    }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <AppBar color="secondary" className={classes.appbar}>
        <Typography
          variant="body1"
          color="inherit"
          className={classes.appBarTitle}
          id="react-admin-title"
        >
          <Logo />
        </Typography>
        <span className={classes.spacer} />
      </AppBar>
      <div className={classnames(classes.main)} ref={containerRef}>
        <Card className={classes.card}>
          <div className={classes.avatar}>
            <Avatar className={classes.icon}>
              <LockIcon />
            </Avatar>
          </div>
          <div className={classes.title}>{title}</div>
          <Typography className={classes.titleMessage}>
            {titleMessage}
          </Typography>
          {children}
        </Card>
        <Notification />
      </div>
    </ThemeProvider>
  );
};

export { UnauthenticatedLayout };
