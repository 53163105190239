import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
  SimpleForm,
} from "react-admin";

import { Styles } from "@material-ui/styles/withStyles";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { RESOURCE_NAME_PERMISSION } from "./constants";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  label: { display: "block" },
  sortOrder: { display: "block" },
  type: { display: "block" },
  permissionGroup: { display: "block" },
};

const useStyles = makeStyles(styles);

const PermissionForm = ({ isEdit, ...rest }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles(rest);
  return (
    <SimpleForm {...rest} warnWhenUnsavedChanges>
      <SectionTitle label="resources.permissions.fieldGroups.permissionDetails" />
      <TextInput
        autoFocus
        source="name"
        label="resources.permissions.fields.name"
        helperText="resources.permissions.help.name"
        formclassName={classes.name}
        validate={[required()]}
      />
      <TextInput
        source="description"
        label="resources.permissions.fields.description"
        helperText="resources.permissions.help.description"
        formclassName={classes.description}
        resettable
        multiline
      />
      <TextInput
        source="label"
        label="resources.permissions.fields.label"
        helperText="resources.permissions.help.label"
        formclassName={classes.label}
        resettable
        multiline
      />
      <TextInput
        source="sortOrder"
        label="resources.permissions.fields.sortOrder"
        helperText="resources.permissions.help.sortOrder"
        formclassName={classes.sortOrder}
        resettable
        multiline
      />
      <TextInput
        source="type"
        label="resources.permissions.fields.type"
        helperText="resources.permissions.help.type"
        formclassName={classes.type}
        resettable
        multiline
      />
      <ReferenceInput
        label="resources.permissions.fields.permissionGroupId"
        source="permissionGroupId"
        reference={RESOURCE_NAME_PERMISSION}
        formclassName={classes.permissionGroup}
        isRequired
      >
        <SelectInput
          optionText="name"
          helperText="resources.permissions.help.permissionGroupId"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
export { PermissionForm };
