import { Box, makeStyles } from "@material-ui/core";
import React from "react";
import {
  ArrayField,
  Datagrid,
  Labeled,
  ReferenceField,
  RichTextField,
  SimpleShowLayout,
  TextField,
  useRecordContext,
} from "react-admin";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import {
  AccordionField,
  DateCountdownField,
  EmptyState,
  ResourceLinkedField,
  SectionTitleSubtitle,
  UploadedFilesField,
} from "../../../lib/components";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import { RESOURCE_NAME_ENQUIRY } from "../../enquiries";
import ConditionIcon from "@material-ui/icons/Gavel";
import { ApprovalStatusField, isMatchingAccount, RecordStatusField } from "../../../common";

const useStyles = makeStyles((theme) => ({
  enquirySummary: {
    display: "grid",
    gridGap: 25,
    paddingTop: 0,
    paddingBottom: 0,
    [theme.breakpoints.up("sm")]: { gridTemplateColumns: "1fr 1fr 1fr " },
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "1fr 1fr" },
    [theme.breakpoints.down("xs")]: { gridTemplateColumns: "1fr" },
  },
  summaryDetails: {
    flexDirection: "column",
  },
  layout: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: 25,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
    },
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr",
    },
  },
  detailRow: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    width: "100%",
    marginLeft: 20,
    gridGap: 25,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr 1fr",
      marginLeft: 25,
    },
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      marginLeft: 25,
    },
  },
  remarks: {
    gridColumn: "1 / span 3",
  },
  emptyStateContainer: {
    width: "25%",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "flex-start"
  },
  emptyStateIcon: {
    width: "1.25em",
    height: "1.25em",
    alignSelf: "center",
  },
}));

const EnquirySummaryFields = (props) => {
  const record = useRecordContext(props);
  const isProcureAccount = isMatchingAccount(record?.procureAccountId);
  const classes = useStyles();
  return (
    <>
      <SimpleShowLayout className={classes.layout} {...props}>
        <Labeled
          id="code"
          source="code"
          label="resources.enquiries.fields.code"
          resource={RESOURCE_NAME_ENQUIRY}
        >
          <ResourceLinkedField
            label="resources.enquiries.fields.code"
            source="code"
            linkedResource={RESOURCE_NAME_ENQUIRY}
            linkedResourceIdSource="id"
            resource={RESOURCE_NAME_ENQUIRY}
            record={record}
            link="show"
          />
        </Labeled>

        <Labeled id="currencyId" source="currencyId">
          <ReferenceField
            label="resources.enquiries.fields.currencyId"
            source="currencyId"
            reference={RESOURCE_NAME_CURRENCIES}
            link={false}
          >
            <TextField source="displayLabel" />
          </ReferenceField>
        </Labeled>

        {isProcureAccount && (
         <RecordStatusField label="common.fields.status" useLabel={true} />
        )}

        {isProcureAccount && (
          <ApprovalStatusField
              label="common.fields.approvalStatus"
              useLabel={true}
          />
        )}

        <Labeled id="bidStartDate" source="bidStartDate">
          <DateCountdownField
            source="bidStartDate"
            label="resources.enquiries.fields.bidStartDate"
            options={COMMON_DATE_TIME_FORMAT}
            showTime
          />
        </Labeled>
        <Labeled id="bidEndDate" source="bidEndDate">
          <DateCountdownField
            source="bidEndDate"
            label="resources.enquiries.fields.bidEndDate"
            options={COMMON_DATE_TIME_FORMAT}
            showTime
          />
        </Labeled>
        <Labeled id="preferredDeliveryDate" source="preferredDeliveryDate">
          <DateCountdownField
            source="preferredDeliveryDate"
            label="resources.enquiries.fields.preferredDeliveryDate"
            options={COMMON_DATE_TIME_FORMAT}
            showTime
          />
        </Labeled>
      </SimpleShowLayout>

      {record?.conditions && (
        <AccordionField
          headerlabel="resources.enquiries.sections.conditions.field.title"
          headerTooltip="resources.enquiries.sections.conditions.field.tooltip"
        >
          <Box>
            <SectionTitleSubtitle title="resources.enquiries.sections.conditions.field.heading" />

            <ArrayField source="conditions" fullWidth={true}>
              <Datagrid
                empty={
                  <EmptyState
                    title="resources.enquiries.sections.conditions.emptyState"
                    subTitle={null}
                    Icon={ConditionIcon}
                    className={classes.emptyStateContainer}
                    iconClassName={classes.emptyStateIcon}
                  />
                }
              >
                <TextField source="conditionType" />
                <TextField source="condition" fullWidth/>
              </Datagrid>
            </ArrayField>
          </Box>
        </AccordionField>
      )}

      {record?.remarks && (
        <AccordionField
          headerlabel="resources.enquiries.sections.remarks.field.title"
          headerTooltip="resources.enquiries.sections.remarks.field.tooltip"
        >
          <RichTextField source="remarks" />
        </AccordionField>
      )}

      <AccordionField
        headerlabel="resources.enquiries.sections.fileUpload.input.title"
        headerTooltip="resources.enquiries.sections.fileUpload.input.tooltip"
      >
        <Box display="flex" flexDirection="column" width="100%">
          <UploadedFilesField canDelete={false} />
        </Box>
      </AccordionField>
    </>
  );
};

const EnquirySummaryContainer = (props) => {
  const record = useRecordContext();
  const classes = useStyles();

  return (
    <AccordionField
      headerlabel="resources.bids.sections.summary.title"
      headerTooltip="resources.bids.sections.summary.tooltip"
      detailsClassName={classes.summaryDetails}
    >
      <ReferenceField
        label="resources.bids.fields.enquiryId"
        source="enquiryId"
        reference={RESOURCE_NAME_ENQUIRY}
        link={false}
        fullWidth={true}
      >
        <EnquirySummaryFields bidRecord={record} {...props} />
      </ReferenceField>
    </AccordionField>
  );
};

export { EnquirySummaryContainer };
