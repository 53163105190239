export const RESOURCE_ACCOUNT_CODE = "accountCode";
export const RESOURCE_NAME_PURCHASE_GROUPS = 'purchaseGroups'

export enum PermissionPurchaseGroups {
    MENU_PURCHASE_GROUP = "view-purchase-group-menu",
    LIST_PURCHASE_GROUPS = "list-purchase-groups",
    SHOW_PURCHASE_GROUP = "show-purchase-group",
    CREATE_PURCHASE_GROUP = "create-purchase-group",
    EDIT_PURCHASE_GROUP = "update-purchase-group",
    DELETE_PURCHASE_GROUP = "delete-purchase-group",
}
