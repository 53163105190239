import { Box } from "@material-ui/core";
import {
  Datagrid,
  DateField,
  List,
  Loading,
  NumberField,
  ReferenceField,
  ResourceContextProvider,
  SelectField,
  TextField,
  useRecordContext,
} from "react-admin";
import { ApprovalStatusField, FILTER_DIRECTION, RecordStatusField } from "../../../common";
import { SectionTitleSubtitle } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import {
  BidShowContent,
  RESOURCE_NAME_BID,
} from "../../bids";

const EnquiryShowBids= (props) => {
  const record = useRecordContext(props);
  const enquiryId = record?.id;

  if (!enquiryId) {
    return <Loading />
  }
  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.enquiries.sections.bids.field.title"
        subTitle="resources.enquiries.sections.bids.field.subTitle"
      />
      <ResourceContextProvider value={RESOURCE_NAME_BID}>
        <List
          basePath={`/${RESOURCE_NAME_BID}`}
          filter={{
            enquiryId,
            direction: FILTER_DIRECTION.RECEIVED
          }}
          perPage={5}
          sort={{ field: "id", order: "ASC" }}
          title="resources.enquiries.sections.bids.field.title"
          actions={false}
          syncWithLocation={false}
          bulkActionButtons={false}
        >
          <Datagrid optimized expand={<BidShowContent showEnquiryDetail={false} />}>
            <ReferenceField
              source="vendorAccountId"
              reference={RESOURCE_NAME_ACCOUNT}
            >
              <TextField source="displayLabel" />
            </ReferenceField>

            <NumberField
              source="totalAmount"
              label="resources.bids.fields.totalAmount"
            />
            <NumberField
              source="totalAmount2"
              label="resources.bids.fields.totalAmount2"
            />
            <RecordStatusField label="common.fields.status" />

            <ApprovalStatusField label="common.fields.approvalStatus" />
            <DateField
              source="updatedAt"
              options={COMMON_DATE_TIME_FORMAT}
              label="resources.bids.fields.updatedAt"
            />
          </Datagrid>
        </List>
      </ResourceContextProvider>
    </Box>
  );
};

export { EnquiryShowBids };
