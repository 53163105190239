import {
  SimpleShowLayout,
  ReferenceField,
  TextField,
  Labeled,
  usePermissions,
} from "react-admin";
import { makeStyles, createStyles } from "@material-ui/core";
import { AccordionField, ResourceLinkedField } from "../../../lib/components";
import { hasAccess } from "../../../lib/core";
import {
  businessFunctionPermissions,
  RESOURCE_NAME_BUSINESS_FUNCTIONS,
} from "../../datasetup/business-functions";
import { RESOURCE_NAME_REQUISITION } from "../../requisitions";

const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: 25,
      [theme.breakpoints.down("md")]: {
        gridTemplateColumns: "1fr 1fr",
      },
      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "1fr",
      },
    },
  })
);

const RequisitionSummaryContainer = () => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  const hasBusinessFunctionListAccess = hasAccess(
    permissions,
    businessFunctionPermissions.list
  );

  return (
    <AccordionField
      headerlabel="resources.enquiries.sections.summary.title"
      headerTooltip="resources.enquiries.sections.summary.tooltip"
    >
      <ReferenceField
        label="resources.enquiries.fields.requisitionId"
        source="requisitionId"
        reference={RESOURCE_NAME_REQUISITION}
        link={false}
        fullWidth={true}
      >
        <SimpleShowLayout className={classes.layout}>
          <ResourceLinkedField
            label="resources.enquiries.fields.code"
            source="code"
            linkedResource={RESOURCE_NAME_REQUISITION}
            linkedResourceIdSource="id"
            resource={RESOURCE_NAME_REQUISITION}
            link="show"
          />

          {hasBusinessFunctionListAccess && (
            <Labeled id="businessFunctionId" source="businessFunctionId">
              <ReferenceField
                source="businessFunctionId"
                reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
              >
                <TextField source="displayLabel" />
              </ReferenceField>
            </Labeled>
          )}

          <TextField
            label="resources.enquiries.fields.field1"
            source="field1"
          />
          <TextField
            label="resources.enquiries.fields.field2"
            source="field2"
          />
          <TextField
            label="resources.enquiries.fields.field3"
            source="field3"
          />
        </SimpleShowLayout>
      </ReferenceField>
    </AccordionField>
  );
};

export { RequisitionSummaryContainer };
