import DateRangeIcon from '@material-ui/icons/DateRange';
import { PermissionVendors, RESOURCE_NAME_VENDOR } from './constants';
import { VendorCreate } from './VendorCreate';
import { VendorEdit } from './VendorEdit';
import { VendorList } from './VendorList';
import { VendorShow } from './VendorShow';

const vendorPermissions = {
    menu: PermissionVendors.MENU_VENDOR,
    list: PermissionVendors.LIST_VENDORS,
    update: PermissionVendors.EDIT_VENDOR,
    show: PermissionVendors.SHOW_VENDOR,
    delete: PermissionVendors.DELETE_VENDOR,
    create: PermissionVendors.CREATE_VENDOR,
    import: PermissionVendors.IMPORT_VENDORS,
};

const vendorResource = {
    name: RESOURCE_NAME_VENDOR,
    list:VendorList,
    create:VendorCreate,
    edit:VendorEdit,
    show:VendorShow,
    icon: DateRangeIcon,
    options: {  label: 'resources.vendors.name', allowedPermissions: vendorPermissions },
}

export * from './constants';
export { vendorPermissions, vendorResource };