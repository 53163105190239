import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
} from "react-admin";
import { DefaultShow } from "../../../components";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";
import { RESOURCE_NAME_PERMISSION_GROUP } from "./constants";

const PermissionGroupsShow= (props:ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="label" />
        <TextField source="sortOrder" />
        <ReferenceField
          source="parentId"
          reference={RESOURCE_NAME_PERMISSION_GROUP}
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { PermissionGroupsShow };
