import { useListContext, FilterLiveSearch, FilterList, FilterListItem } from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ApproveIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import { createStyles, makeStyles, Card, CardContent } from "@material-ui/core";
import {
  FixedDateRangeFilterList,
} from "../../../lib/common";
import {
  format,
  endOfYesterday,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import { FILTER_DIRECTION, ProcEntityRecordStatusFilterList, ProcureApprovalStatusFilterList } from "../../../common";

const EnquiryListSideFilters = (props) => {
  const cardClasses = useCardStyles();
  const { filterValues } = useListContext(props);
  const isDirectionSent = filterValues?.direction === FILTER_DIRECTION.SENT;
  
  return (
    <Card classes={cardClasses}>
      <CardContent>
        <FilterLiveSearch />
        <FixedDateRangeFilterList
          label="resources.enquiries.fields.bidDate"
          icon={<AccessTimeIcon />}
          gteSource="bidDateGte"
          lteSource="bidDateLte"
          dateRangeList={bidDatesList}
        />
        {isDirectionSent && <ProcEntityRecordStatusFilterList /> }
        {isDirectionSent && <ProcureApprovalStatusFilterList /> }
        {isDirectionSent && <FilterList
          label="resources.enquiries.fields.needsUserApproval"
          icon={<ApproveIcon />}
        >
          <FilterListItem
            label="ra.boolean.true"
            value={{ needsUserApproval: true }}
          />
          <FilterListItem
            label="ra.boolean.false"
            value={{ needsUserApproval: false }}
          />
        </FilterList> }
      </CardContent>
    </Card>
  );
};

const useCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        order: -1,
        maxWidth: 210,
        marginRight: "1em",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const DATE_FORMAT_DAY = "yyyy-MM-dd";
const bidDatesList = [
  {
    label: "resources.enquiries.filters.bidDate.today",
    gte: format(endOfYesterday(), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.enquiries.filters.bidDate.thisWeek",
    gte: format(startOfWeek(new Date()), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.enquiries.filters.bidDate.thisMonth",
    lte: undefined,
    gte: format(startOfMonth(new Date()), DATE_FORMAT_DAY),
  },
  {
    label: "resources.enquiries.filters.bidDate.lastMonth",
    lte: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT_DAY),
    gte: format(subMonths(startOfMonth(new Date()), 1), DATE_FORMAT_DAY),
  },
  {
    label: "resources.enquiries.filters.bidDate.earlier",
    lte: format(endOfMonth(subMonths(new Date(), 2)), DATE_FORMAT_DAY),
    gte: undefined,
  },
];

export { EnquiryListSideFilters };
