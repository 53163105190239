import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
  SimpleForm,
  AutocompleteInput,
} from "react-admin";

import { Styles } from "@material-ui/styles/withStyles";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { RESOURCE_NAME_PERMISSION_GROUP } from "./constants";
import { isUniqueField } from "../../../components/validations";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  label: { display: "block" },
  sortOrder: { display: "block" },
  parent: { display: "block" },
};

const useStyles = makeStyles(styles);

const PermissionGroupForm = ({ isEdit, ...rest }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles(rest);
  return (
    <SimpleForm {...rest} warnWhenUnsavedChanges>
      <SectionTitle label="resources.permissionGroups.fieldGroups.permissionGroupDetails" />
      <TextInput
        autoFocus
        source="name"
        label="resources.permissionGroups.fields.name"
        helperText="resources.permissionGroups.help.name"
        formclassName={classes.name}
        validate={[required()]}
      />
      <TextInput
        source="description"
        label="resources.permissionGroups.fields.description"
        helperText="resources.permissionGroups.help.description"
        formclassName={classes.description}
        resettable
        multiline
      />
      <TextInput
        source="label"
        label="resources.permissionGroups.fields.label"
        helperText="resources.permissionGroups.help.label"
        formclassName={classes.label}
        resettable
        multiline
      />
      <TextInput
        source="sortOrder"
        label="resources.permissionGroups.fields.sortOrder"
        helperText="resources.permissionGroups.help.sortOrder"
        formclassName={classes.sortOrder}
        resettable
        multiline
      />
      <ReferenceInput
        label="resources.permissionGroups.fields.parentId"
        source="parentId"
        reference={RESOURCE_NAME_PERMISSION_GROUP}
        formclassName={classes.parent}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
      >
        <AutocompleteInput
          optionText="name"
          helperText="resources.permissionGroups.help.parentId"
        />
      </ReferenceInput>
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
export { PermissionGroupForm };
