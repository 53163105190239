import { Children, useState, useEffect, isValidElement } from "react";
import { useLocation } from "react-router-dom";

import {
  makeStyles,
  MenuItem,
  Typography,
  List,
  ListItemIcon,
  Tooltip,
  Divider,
  Collapse,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useTranslate } from "react-admin";
import { SubMenuProps } from "./menu.interface";

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5) },
  isSidebarOpen: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
  isSidebarClosed: {
    paddingLeft: 0,
    transition: "padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
  },
}));

const SubMenu= ({
  name,
  icon,
  isSidebarOpen,
  dense,
  children,
}:SubMenuProps) => {
  const translate = useTranslate();
  const translatedName = translate(name, { smart_count: 2 });
  const classes = useStyles();
  const [isMenuOpen, setMenuOpen] = useState(false);
  let location = useLocation();

  const handleToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const detectOpenedMenu = (children) => {
    Children.forEach(children, (element) => {
      if (!isValidElement(element)) return;
      //@ts-ignore
      const { to, children: grandChildren } = element.props;

      if (to) {
        const routeLocation = to?.pathname ?? to;
        const currentLocationPath = location?.pathname;
        const match = currentLocationPath?.indexOf(routeLocation) !== -1;

        if (match) {
          setMenuOpen(true);
          return true;
        }
      } else if (grandChildren) {
        const isOpenMenuDetected = detectOpenedMenu(grandChildren);
        if (isOpenMenuDetected) {
          return true;
        }
      }
    });

    return false;
  };

  useEffect(() => {
    //If this is not the home path then try to expand this and its child sub menus
    if (location?.pathname !== "/") {
      detectOpenedMenu(children);
    }
  }, []);

  const Header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {isMenuOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="body1" color="textSecondary">
        {translatedName}
      </Typography>
    </MenuItem>
  );

  return (
    <>
      {isSidebarOpen || isMenuOpen ? (
        Header
      ) : (
        <Tooltip title={translatedName} placement="right">
          {Header}
        </Tooltip>
      )}
      <Collapse in={isMenuOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            isSidebarOpen ? classes.isSidebarOpen : classes.isSidebarClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </>
  );
};

export { SubMenu };
