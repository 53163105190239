import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import { OrgUserGroupForm } from "./OrgUserGroupForm";

const OrgUserGroupEdit = (props: EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <OrgUserGroupForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { OrgUserGroupEdit };
