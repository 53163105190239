import { isValid, isBefore, isAfter } from "date-fns";
import { getMessage, memoize } from "./validationUtils";

export const isDateGreaterThan = memoize(
  (
      targetDate = new Date(),
      message = "components.validations.isDateGreaterThan"
    ) =>
    (value, values) => {
      if (!value) {
        return undefined;
      }
      const dateValue = new Date(value);
      let isValidDate = isValid(dateValue);
      isValidDate = isValidDate && !isAfter(dateValue, targetDate);

      return !isValidDate
        ? getMessage(message, undefined, value, values)
        : undefined;
    }
);

export const isDateGreaterThanOther = memoize(
  (
      targetDateField,
      message = "components.validations.isDateGreaterThanOther"
    ) =>
    (value, values) => {
      if (!value) {
        return undefined;
      }
      const dateValue = new Date(value);
      let isValidDate = isValid(dateValue);
      const targetDate = values[targetDateField];
      isValidDate = isValidDate && !isAfter(dateValue, targetDate);

      return !isValidDate
        ? getMessage(message, undefined, value, values)
        : undefined;
    }
);

export const isDateNotGreaterThanToday = memoize(
  (message = "components.validations.isDateNotGreaterThanToday") =>
    (value, values) => {
      if (!value) {
        return undefined;
      }
      const targetDate = new Date();

      return isDateGreaterThan(targetDate, message);
    }
);

export const isDateLessThan = memoize(
  (
      targetDate = new Date(),
      message = "components.validations.isDateLessThan"
    ) =>
    (value, values) => {

      if (!value) {
        return undefined;
      }

      const dateValue = new Date(value);
      let isValidDate = isValid(dateValue);
      isValidDate = isValidDate && !isBefore(dateValue, targetDate);

      return !isValidDate
        ? getMessage(message, undefined, value, values)
        : undefined;
    }
);

export const isDateLessThanOther = memoize(
  (targetDateField, message = "components.validations.isDateLessThanOther") =>
    (value, values) => {
      //Do not proceed if no date is specified
      if (!value) {
        return undefined;
      }
      const dateValue = new Date(value);
      let isValidDate = isValid(dateValue);
      const targetDate = values[targetDateField];
      isValidDate = isValidDate && !isBefore(dateValue, targetDate);

      return !isValidDate
        ? getMessage(message, undefined, value, values)
        : undefined;
    }
);

export const isDateNotLessThanToday = memoize(
  (message = "components.validations.isDateNotLessThanToday") =>
    (value, values) => {
      //Do not proceed if no date is specified
      if (!value) {
        return undefined;
      }
      const targetDate = new Date();
      const dateValue = new Date(value);
      let isValidDate = isValid(dateValue);
      isValidDate = isValidDate && !isBefore(dateValue, targetDate);

      return !isValidDate
        ? getMessage(message, undefined, value, values)
        : undefined;
    }
);
