import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from "react-admin";
import { VendorBidForm } from "./VendorBidForm";
import { EnquirySummaryContainer } from "./EnquirySummaryContainer";
import {
  AccordionField,
  DateTimeInput,
  MediaInputField,
  SectionTitleSubtitle,
} from "../../../lib/components";
import RichTextInput from "ra-input-rich-text";
import { validations } from "./validations";
import { BidSummaryContainer } from "./BidSummaryContainer";
import { Fragment } from "react";
import { CommentsAccordion, OwnerEntityTypes } from "../../comments";
import { DEFAULT_DATE_TIME_OPTIONS } from "../../../lib/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    estimatedDeliveryDate: {
      marginTop: 25,
      marginLeft: 5,
    },
    remarksAccordion: {
      width: "100%",
      marginTop: 25,
    },

    enquiryId: {
      width: "100%",
    },
  })
);

const useArrayInputStyles = makeStyles((theme) => ({
  form: {
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    gridColumnGap: 50,
    [theme.breakpoints.down("sm")]: { gridTemplateColumns: "100%" },
  },
}));

const BidEditContent = ({ isEdit = false, ...rest }) => {
  const record = useRecordContext();

  const classes = useStyles();
  const arrayInputClasses = useArrayInputStyles();

  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.bids.sections.bid.edit.title"
        subTitle="resources.bids.sections.bid.edit.subTitle"
      />

      <EnquirySummaryContainer />

      <Fragment>
        <DateTimeInput
          source="estimatedDeliveryDate"
          className={classes.estimatedDeliveryDate}
          helperText="resources.bids.help.estimatedDeliveryDate"
          validate={validations.estimatedDeliveryDate}
          options={DEFAULT_DATE_TIME_OPTIONS}
          isRequired
        />

        <BidSummaryContainer />

        <SectionTitleSubtitle
          title="resources.bids.sections.items.edit.title"
          subTitle="resources.bids.sections.items.edit.subTitle"
        />
        <VendorBidForm
          enquiryId={record?.enquiryId}
          currencyId={record?.currencyId}
          bidDetails={record?.bidDetails}
          source=""
          showUnitPrice2={record?.showUnitPrice2}
        />
        <AccordionField
          headerlabel="resources.bids.sections.conditions.input.title"
          headerTooltip="resources.bids.sections.conditions.input.tooltip"
          className={classes.remarksAccordion}
        >
          <Box>
            <SectionTitleSubtitle title="resources.bids.sections.conditions.input.heading" />
            <ArrayInput source="conditions" label={false}>
              <SimpleFormIterator classes={arrayInputClasses}>
                <TextInput
                  source="conditionType"
                  label="resources.bids.fields.conditionType"
                  helperText="resources.bids.help.conditionType"
                  resettable={true}
                  validate={[required()]}
                />
                <TextInput
                  multiline
                  fullWidth={true}
                  source="condition"
                  label="resources.bids.fields.condition"
                  helperText="resources.bids.help.condition"
                  resettable={true}
                  validate={[required()]}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </AccordionField>
        <AccordionField
          headerlabel="resources.bids.sections.remarks.input.title"
          headerTooltip="resources.bids.sections.remarks.input.tooltip"
          className={classes.remarksAccordion}
        >
          <RichTextInput
            source="remarks"
            helperText="resources.bids.help.remarks"
            label={false}
          />
        </AccordionField>
        <AccordionField
          headerlabel="resources.bids.sections.fileUpload.input.title"
          headerTooltip="resources.bids.sections.fileUpload.input.tooltip"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <MediaInputField showUploadedFiles={isEdit} />
          </Box>
        </AccordionField>

        <CommentsAccordion
          ownerEntityType={OwnerEntityTypes.BID}
          ownerEntityId={record?.id}
          isViewOnly={false}
        />
      </Fragment>
    </Box>
  );
};

export { BidEditContent };
