import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  BooleanField,
  DateField,
} from "react-admin";
import { DefaultShow } from "../../../components";
import { RESOURCE_NAME_PERMISSION } from "./constants";

const PermissionShow= (props:ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="label" />
        <TextField source="sortOrder" />
        <TextField source="type" />
        <ReferenceField
          source="permissionGroupId"
          reference={RESOURCE_NAME_PERMISSION}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="createdAt" />
        <DateField source="updatedAt" />
        <DateField source="deletedAt" />
        <TextField source="version" />
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { PermissionShow };
