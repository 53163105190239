import React, { ReactElement } from "react";
import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  DateField,
  BooleanField,
  RichTextField,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  SearchFilter,
  IdFilter,
} from "../../../common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../components";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
   </Filter>
);

const defaultColumns = ["code", "name", "description"];

const AccountList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="code" />
        <TextField source="name" />
        <RichTextField source="description" />
        <DateField source="createdAt" />
        <TextField source="id" />
        <DateField source="updatedAt" />

        <TextField source="version" />
        <BooleanField source="isActive" />

        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { AccountList };
