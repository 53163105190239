import {
  TextInput,
  required,
  useTranslate,
  SimpleForm,
  AutocompleteInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { Typography } from "@material-ui/core";
import { MAX_RECORDS_PER_PAGE } from "../../../../lib/common";
import {
  RESOURCE_NAME_ORG_USER_GROUP,
} from "./constants";
import { RESOURCE_NAME_USER } from "../../../../lib/modules";
import { validate } from "./validations";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  number: { display: "block" },
};

const useStyles = makeStyles(styles);

const OrgUserGroupForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm warnWhenUnsavedChanges {...rest}>
      <SectionTitle label="resources.OrgUserGroups.fieldGroups.OrgUserGroupsDetaills" />
      <TextInput
        autoFocus
        source="number"
        label="resources.OrgUserGroups.fields.number"
        helperText="resources.OrgUserGroups.help.number"
        formClassName={classes.number}
        fullWidth
        validate={[required()]}
      />
      <TextInput
        source="name"
        label="resources.OrgUserGroups.fields.name"
        helperText="resources.OrgUserGroups.help.name"
        formClassName={classes.name}
        fullWidth
        validate={[required()]}
      />
      <RichTextInput
        source="description"
        label="resources.OrgUserGroups.fields.description"
        formClassName={classes.description}
        fullWidth
      />
      <ReferenceInput
        label="resources.OrgUserGroups.fields.level1ApprovalGroupId"
        source="level1ApprovalGroupId"
        reference={RESOURCE_NAME_ORG_USER_GROUP}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
        validate={validate.validateLevel1ApprovalGroupName}
        fullWidth
      >
        <AutocompleteInput
          optionText="displayLabel"
          helperText="resources.OrgUserGroups.help.level1ApprovalGroupId"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceInput>
      <ReferenceInput
        source="level2ApprovalGroupId"
        label="resources.OrgUserGroups.fields.level2ApprovalGroupId"
        reference={RESOURCE_NAME_ORG_USER_GROUP}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        filter={isEdit ? { excludeIds: [rest?.id] } : {}}
        validate={validate.validateLevel2ApprovalGroupName}
        fullWidth
      >
        <AutocompleteInput
          optionText="displayLabel"
          helperText="resources.OrgUserGroups.help.level2ApprovalGroupId"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceInput>
      <ReferenceArrayInput
        source="userIds"
        label="resources.OrgUserGroups.fields.userIds"
        reference={RESOURCE_NAME_USER}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "firstName", order: "ASC" }}
        validate={validate.validateUsers}
        isRequired
        fullWidth
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          helperText="resources.OrgUserGroups.help.userIds"
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
export { OrgUserGroupForm };
