export const RESOURCE_NAME_ITEM_CLASS = "itemClasses";

export enum PermissionItemClasses {
  MENU_ITEM_CLASSES = "view-item-classes-menu",
  LIST_ITEM_CLASSES = "list-item-classes",
  SHOW_ITEM_CLASS = "show-item-class",
  CREATE_ITEM_CLASS = "create-item-class",
  EDIT_ITEM_CLASS = "update-item-class",
  DELETE_ITEM_CLASS = "delete-item-class",
  IMPORT_ITEM_CLASSES = "import-item-classes",
}
