import { LinearProgress, Loading, useListContext } from "react-admin";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";

import { SingleComment } from "./SingleComment";
import { Comment, CommentOwnerEntity, PermissionComments } from "../constants";
import { EmptyState } from "../../../lib/components";
import { CommentCreateButton } from "./CommentCreateButton";
import { usePermissionsCheck } from "../../../lib/core";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    timeline: {
      paddingInlineStart: 5,
    },
    evenBg: {
      backgroundColor: theme.palette.grey[300],
    },
    oddBg: {
      backgroundColor: theme.palette.background.default,
    },
    root: {
      //borderBottom: "1px dotted lightgrey",
      //backgroundColor: theme.palette.grey[300],
    },
    alignLeft: {
      alignItems: "flex-start",
    },
    missingOppositeContent: {
      "&:before": {
        flex: "unset",
        content: '""',
        padding: "1px 3px",
      },
    },
    before: {},
    emptyStateContainer: {
      width: "100%",
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      // display: "flex",
      // flexDirection: "column",
      // alignItems: "flex-start"
    },
    emptyStateIcon: {
      width: "1.25em",
      height: "1.25em",
      alignSelf: "center",
    },
  })
);

export const CommentsIterator = ({
  ownerEntityType,
  ownerEntityId,
  isViewOnly,
}: CommentsIteratorProps) => {
  const classes = useStyles();
  const { data, ids, total, loaded, loading } = useListContext<Comment>();
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  const [hasCreateAccess, setHasCreateAccess] = useState(false);

  useEffect(() => {
    if (permissionsLoaded) {
      const hasCreateAccess = hasPermissionAccess(
        PermissionComments.CREATE_COMMENT
      );
      setHasCreateAccess(hasCreateAccess);
    }
  }, [hasPermissionAccess, permissionsLoaded]);

  if (!loaded) {
    return <LinearProgress />;
  }

  if (loaded && !loading && (ids.length === 0 || total === 0)) {
    return (
      <EmptyState
        title="resources.comments.sections.emptyState.title"
        subTitle="resources.comments.sections.emptyState.subTitle"
        Icon={ChatBubbleIcon}
        className={classes.emptyStateContainer}
        iconClassName={classes.emptyStateIcon}
        actionButton={
          isViewOnly && !hasCreateAccess ? undefined : (
            <CommentCreateButton
              ownerEntityType={ownerEntityType}
              ownerEntityId={ownerEntityId}
            />
          )
        }
      />
    );
  }

  return (
    <Timeline align="left" className={classes.timeline}>
      {ids.map((id, index) => {
        const comment = data[id];
        const isOdd = index % 2 === 0;
        const variant = isOdd ? "default" : "outlined";
        const color = isOdd ? "primary" : "secondary";

        return (
          <TimelineItem
            classes={{
              root: classes.root,
              missingOppositeContent: classes.missingOppositeContent,
              //alignLeft: classes.alignLeft,
            }}
          >
            <TimelineSeparator>
              <TimelineDot variant={variant} color={color} />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <SingleComment
                comment={comment}
                isViewOnly={isViewOnly}
                key={`comment-${comment?.ownerEntityId}-${index}`}
              />
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export interface CommentsIteratorProps extends CommentOwnerEntity {
  isViewOnly: boolean;
}
