import {
  Datagrid,
  ReferenceManyField,
  TextField,
  DateField,
  DeleteButton,
  usePermissions,
} from "react-admin";
import { COMMON_DATE_TIME_FORMAT, hasAccess } from "../../../../core";
import { RESOURCE_NAME_MEDIA } from "../../../../modules";
import {  SectionTitleSubtitle } from "../../../ui";
import { FilenameField } from "./FilenameField";
import { MediaEmptyState } from "./MediaEmptyState";

const UploadedFilesField = ({
  title = "resources.media.sections.fieldsList.title",
  subTitle = "resources.media.sections.fieldsList.subTitle",
  canDelete = true,
  target = "ownerId",
  deletePermissionName = undefined,
  ...rest
}) => {
  const {  permissions } = usePermissions();
  const hasDeletePermission =
    canDelete &&
    (deletePermissionName
      ? hasAccess(permissions, deletePermissionName)
      : true);

  return (
    <>
      <SectionTitleSubtitle title={title} subTitle={subTitle} />
      <ReferenceManyField
        reference={RESOURCE_NAME_MEDIA}
        target={target}
        {...rest}
      >
        <Datagrid
          empty={<MediaEmptyState/>}
        >
          <FilenameField />
          <TextField
            source="fileSizeHuman"
            label="resources.media.fields.fileSizeHuman"
          />
          <DateField
            source="updatedAt"
            label="resources.media.fields.updatedAt"
            options={COMMON_DATE_TIME_FORMAT}
          />
          {hasDeletePermission && (
            <DeleteButton
              undoable={false}
              redirect={false}
              confirmTitle="resources.media.sections.deleteButton.confirmTitle"
              confirmContent="resources.media.sections.deleteButton.confirmContent"
            />
          )}
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

export { UploadedFilesField };
