import { ReactElement, useMemo } from "react";

import {
  DefaultCrudListRowActions,
  ListRowActions,
  ListRowActionsProps,
  RedirectListRowAction,
} from "../../../lib/components";
import { Record } from "react-admin";
import { enquiryResource, RESOURCE_NAME_ENQUIRY } from "../../enquiries";
import { ProcureApprovalStatus, ProcEntityRecordStatus } from "../../../common";
import { ApprovalListRowActionButtons } from "../../../common";
import { PermissionRequisitions } from "..";
import { usePermissionsCheck } from "../../../lib/core";

const RequisitionListRowActionButtons = (props: ListRowActionsProps) => {
  const { hasOwnOrAccountAccess, permissionsLoaded } = usePermissionsCheck();

  const overriddenListRowActions: Array<ReactElement> = useMemo(() => {
    const record: Record | undefined = props?.record;
    const keyPrefix = record?.id;

    const isOpen = record?.isOpen;

    const hasShowRequisitionAccess = permissionsLoaded && hasOwnOrAccountAccess(
      PermissionRequisitions.SHOW_ANY_REQUISITION,
      PermissionRequisitions.SHOW_OWN_REQUISITION,
      record?.procureAccountId,
      record?.procureOwnerUserId
    );

    const hasEditRequisitionAccess = permissionsLoaded && hasOwnOrAccountAccess(
      PermissionRequisitions.EDIT_OWN_REQUISITION,
      PermissionRequisitions.EDIT_ANY_REQUISITION,
      record?.procureAccountId,
      record?.procureOwnerUserId
    );

    const hasDeleteRequisitionAccess = permissionsLoaded && hasOwnOrAccountAccess(
      PermissionRequisitions.DELETE_OWN_REQUISITION,
      PermissionRequisitions.DELETE_ANY_REQUISITION,
      record?.procureAccountId,
      record?.procureOwnerUserId
    );

    const status = record?.procureStatus;
    const procureApprovalStatus = record?.procureApprovalStatus;
    const canPerformCreateEnquiryAction = record?.canPerformAction;

    const isStatusWaitingDraft = status === ProcEntityRecordStatus.DRAFT;
    const isStatusWaitingForApproval = status === ProcEntityRecordStatus.WAITING_FOR_APPROVAL;
    const isStatusApproved = status === ProcEntityRecordStatus.APPROVED;
    const isStatusInProgress = status === ProcEntityRecordStatus.IN_PROGRESS;
    const isStatusRejected = status === ProcEntityRecordStatus.REJECTED;
    const isStatusOnHold = status === ProcEntityRecordStatus.ON_HOLD;

    const isRecordStatusModifiable = isStatusWaitingDraft || isStatusWaitingForApproval || isStatusApproved || isStatusRejected || isStatusOnHold;

    const isApprovalStatusApproved =
      procureApprovalStatus === ProcureApprovalStatus.APPROVED;

    const shouldDisplayEditAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldShow = isStatusWaitingDraft && hasEditRequisitionAccess;

      return Promise.resolve(shouldShow);
    };

    const shouldDisplayDeleteAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldShow = isStatusWaitingDraft && hasDeleteRequisitionAccess;

      return Promise.resolve(shouldShow);
    };

    const calculateCreateEnquiryActionRedirectPath = (record?: Record) => {
      const createEnquiryPath = record
        ? `${RESOURCE_NAME_ENQUIRY}/create?requisitionId=${record.id}`
        : "";

      return createEnquiryPath;
    };

    const shouldDisplayCreateEnquiryAction = async (
      record?: Record
    ): Promise<boolean> => {
      const shouldAllowCreateEnquiryAction =
        isOpen && canPerformCreateEnquiryAction && (isStatusApproved || isStatusInProgress) && isApprovalStatusApproved;

      return Promise.resolve(shouldAllowCreateEnquiryAction);
    };

    const listRowActions: Array<ReactElement> = [

      <DefaultCrudListRowActions
        key={`defaultCrudListRowActions-${keyPrefix}`}
        shouldDisplayEdit={shouldDisplayEditAction}
        shouldDisplayDelete={shouldDisplayDeleteAction}
        hasEdit={hasEditRequisitionAccess}
        hasShow={hasShowRequisitionAccess}
        {...props}
      />,

      <ApprovalListRowActionButtons {...props} />,

      <RedirectListRowAction
        key={`create.requisition.action-${keyPrefix}`}
        tooltipTitle="resources.requisitions.actions.createEnquiry"
        icon={<enquiryResource.icon />}
        calculateRedirectPath={calculateCreateEnquiryActionRedirectPath}
        shouldDisplay={shouldDisplayCreateEnquiryAction}
        permissionName={enquiryResource?.options?.allowedPermissions?.create}
        {...props}
      />,
    ];

    return listRowActions;
  }, [hasOwnOrAccountAccess, permissionsLoaded, props]);

  return (
    <ListRowActions
      overriddenListRowActions={overriddenListRowActions}
      {...props}
    />
  );
};

export { RequisitionListRowActionButtons };
