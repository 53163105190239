import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../../lib/components";
import { OrgStructureTypesForm } from "./OrgStructureTypesForm";

const OrgStructureTypesCreate = (props: CreateProps) => {

  return (
    <DefaultCreate
      {...props}
    >
      <OrgStructureTypesForm />
    </DefaultCreate>
  );
};

export { OrgStructureTypesCreate };
