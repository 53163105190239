import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ArrayField,
  Datagrid,
  DateField,
  BooleanField,
} from "react-admin";
import { RESOURCE_NAME_USER } from "..";
import { DefaultShow, UploadedFilesField } from "../../../components";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";

const AccountShow = (props: ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="code" />
        <ArrayField source={RESOURCE_NAME_USER}>
          <Datagrid optimized>
            <TextField source="fullName" />
            <TextField source="email" />
          </Datagrid>
        </ArrayField>

        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <UploadedFilesField canDelete={false} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { AccountShow };
