import ClearIcon from "@material-ui/icons/Clear";
import {
  useTranslate,
  Toolbar,
  SaveButton,
} from "react-admin";

import { Button, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(1),
  },
  cancel: {
    marginLeft: theme.spacing(1),
  },
}));

const CommentToolbar = (props) => {
  const translate = useTranslate();
  const classes = useStyles();

  const { onCancel, ...rest } = props;
  return (
    <Toolbar
      alwaysEnableSaveButton={false}
      className={classes.toolbar}
      {...rest}
    >
      <SaveButton label="resources.comments.actions.save" />
      <Button
        size="small"
        variant="outlined"
        color="secondary"
        startIcon={<ClearIcon />}
        className={classes.cancel}
        onClick={onCancel}
      >
        {translate("resources.comments.actions.cancel")}
      </Button>
    </Toolbar>
  );
};


export { CommentToolbar }