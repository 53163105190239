import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../../lib/components";
import { MediaFileSource, transformAndUploadS3Files } from "../../../../lib/modules";
import { ItemGroupForm } from "./ItemGroupForm";

const ItemGroupCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [{
    source: 'files',
    subFolder: 'itemGroups',
  }];  
  return (
      <DefaultCreate {...props}transform={transformAndUploadS3Files(mediaFileSources)}>
          <ItemGroupForm />
      </DefaultCreate>
    );
  };
  
  export { ItemGroupCreate };