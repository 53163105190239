import { useState } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

import { useTranslate } from "ra-core";
import Typography from "@material-ui/core/Typography";
import { SecondaryActionLinkButton } from "../../components";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";

const useStyles = makeStyles((theme) => ({
  form: {
    textAlign: "center",
    padding: "0 1em 1em 1em",
  },
  input: {
    marginTop: "1em",
  },
  actions: {
    padding: "0 1em 1em 1em",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    margin: 0,
    marginTop: "20px",
  },
  success: {
    fontSize: "20px",
    fontWeight: 400,
    color: "green",
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
}));

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <div className={classes.form}>
      <Typography className={classes.success}>
        If you have given a valid email which exists in our system,
        <br />
        you will receive instructions to reset your password.
        <br />
        Please check your registered email.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to={"/login"}
      >
        Login
      </Button>
    </div>
  );
};

const InputForm = ({ handleSubmit, isSubmitting }) => {
  const classes = useStyles();
  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.form}>
        <div className={classes.input}>
          <Field
            autoFocus
            name="email"
            // @ts-ignore
            component={renderInput}
            label="Email"
            disabled={isSubmitting}
          />
        </div>
      </div>
      <CardActions className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          type="submit"
          color="primary"
          disabled={isSubmitting}
          fullWidth
        >
          {isSubmitting && <CircularProgress size={25} thickness={2} />}
          Reset My Password
        </Button>

        <SecondaryActionLinkButton title="Back To Login" url="/login" />
      </CardActions>
      {/* <Notification /> */}
    </form>
  );
};

const renderInput = ({
  meta: { touched, error } = { touched: false, error: undefined },
  input,
  ...rest
}) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...rest}
    fullWidth
  />
);

const ForgotPasswordPage= () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const translate = useTranslate();
  const [isCompleted, setCompleted] = useState(false);

  const doSubmit = async (values, form) => {
    setSubmitting(true);
    const requestUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/auth/forgot-password/initiate`;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: values?.email }),
    };

    fetch(requestUrl, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setSubmitting(true);
        },
        (error) => {
          setSubmitting(false);
        }
      );

    if (values.email) {
      setCompleted(true);
    }
  };

  const validate = (values) => {
    let errors = {};

    if (!values?.email) {
      errors["email"] = translate("ra.validation.required");
    }

    return errors;
  };

  if (isCompleted) {
    return (
      <UnauthenticatedLayout
        title="Forgot Password"
        titleMessage="Password Reset Process Initiated"
      >
        <SuccessMessage />
      </UnauthenticatedLayout>
    );
  } else {
    return (
      <UnauthenticatedLayout
        title="Forgot Password"
        titleMessage="Enter your registered email address"
      >
        <Form
          onSubmit={doSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <InputForm
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        />
      </UnauthenticatedLayout>
    );
  }
};

ForgotPasswordPage.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

export { ForgotPasswordPage };
