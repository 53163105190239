import {
  ShowProps,
} from "react-admin";
import { DefaultShow } from "../../../lib/components";
import { VendorShowContainer } from "./components";

const VendorShow=(props:ShowProps,...rest) => {
  return (
    <DefaultShow {...props}>
      <VendorShowContainer/>
    </DefaultShow>
  );
};

export { VendorShow };
