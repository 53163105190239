import {
  PermissionManagerActions,
  PermissionManagerState,
  PermissionManagerActionTypes,
} from "./types";
import {
  handlePermissionGroupToggle,
  handlePermissionToggle,
  preparePermissionGroups,
  recalculateSelection,
} from "./utils";

export const permissionsManagerReducer = (
  state: PermissionManagerState,
  action: PermissionManagerActions
): PermissionManagerState => {
  switch (action.type) {
    case PermissionManagerActionTypes.SET_ALL_PERMISSION_GROUPS: {
      const allpermissionGroups = action?.payload?.allpermissionGroups;
      const allPermissionGroupIds = action?.payload?.allPermissionGroupIds;
      if (allpermissionGroups && allPermissionGroupIds) {
        const allPermissionGroups = preparePermissionGroups(
          allpermissionGroups,
          allPermissionGroupIds,
          state.rolePermissionIds
        );
        const newState = {
          ...state,
          allPermissionGroups,
        };

        return newState;
      } else {
        return state;
      }
    }

    case PermissionManagerActionTypes.TOGGLE_SINGLE_PERMISSION: {
      const permission = action?.payload?.permission;
      const isSelected = action?.payload?.isSelected;
      const permissionChanges = handlePermissionToggle(
        permission,
        state.permissionChanges,
        isSelected
      );
      const allPermissionGroups = state?.allPermissionGroups;
      recalculateSelection(allPermissionGroups);
      const newState = {
        ...state,
        allPermissionGroups,
        permissionChanges,
      };

      return newState;
    }

    case PermissionManagerActionTypes.TOGGLE_MANY_PERMISSIONS: {
      const permissionGroup = action?.payload?.permissionGroup;
      const isSelected = action?.payload?.isSelected;
      const permissionChanges = handlePermissionGroupToggle(
        permissionGroup,
        state.permissionChanges,
        isSelected
      );
      const allPermissionGroups = state?.allPermissionGroups;
      recalculateSelection(allPermissionGroups);
      const newState = {
        ...state,
        allPermissionGroups,
        permissionChanges,
      };

      return newState;
    }
  }
};
