import { ReduxState, Menu, MenuProps, DashboardMenuItem } from "react-admin";
import { useSelector } from "react-redux";
import { useMediaQuery, Theme, Box } from "@material-ui/core";
import { AccountsMenu } from "../../modules/accounts/AccountsMenu";
import { DatasetupMenu } from "../../../modules/datasetup/DatasetupMenu";
import { UtilitiesMenu } from "../../modules";
import { ProcurementMenu } from "../../../common/layout/menu/ProcurementMenu";
import { SalesMenu } from "../../../common/layout/menu/SalesMenu";

const AppMenu= (props:MenuProps) => {
  const { onMenuClick, logout, dense = false } = props;
  const isSidebarOpen = useSelector(
    (state: ReduxState) => state.admin.ui.sidebarOpen
  );
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <Menu {...props}>
      <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={isSidebarOpen} />

      <ProcurementMenu
        onMenuClick={onMenuClick}
        dense={dense}
        isSidebarOpen={isSidebarOpen}
      />
      <SalesMenu
        onMenuClick={onMenuClick}
        dense={dense}
        isSidebarOpen={isSidebarOpen}
      />
      <DatasetupMenu
        onMenuClick={onMenuClick}
        dense={dense}
        isSidebarOpen={isSidebarOpen}
      />
      <AccountsMenu
        onMenuClick={onMenuClick}
        dense={dense}
        isSidebarOpen={isSidebarOpen}
      />
      <UtilitiesMenu
        onMenuClick={onMenuClick}
        dense={dense}
        isSidebarOpen={isSidebarOpen}
      />
      {isXSmall && logout}
    </Menu>
  );
};

export { AppMenu };
