import { Loading, useGetIdentity } from "react-admin";
import {Card, CardHeader, } from "@material-ui/core";


const DefaultDashboardCard= ({children}) => {
    const { loaded, identity } = useGetIdentity();
    const fullName = identity?.fullName;

    if(!loaded){
        return <Loading/>
    }
    
    return (
        <Card variant="outlined">
            <CardHeader title={`Welcome ${fullName}`} subheader="More dashboard data soon. Till then, use the menus." />

        </Card>
    )
}

export { DefaultDashboardCard }