import { getMessage, memoize, isEmpty } from "./validationUtils";

export const requiredIf = memoize(
  (otherFieldName, message = "ra.validation.required") =>
    Object.assign((value, values) => {
      const doesConditionPass =
        isEmpty(value) && !isEmpty(values[otherFieldName]);
      const conditionalMessage = doesConditionPass
        ? getMessage(message, undefined, value, values)
        : undefined;

      return conditionalMessage;
    })
);

export const requiredIfTrue = memoize(
  (trueCondition: boolean, message = "ra.validation.required") =>
    Object.assign((value, values) => {
      const doesConditionPass =
        isEmpty(value) && trueCondition;
      const conditionalMessage = doesConditionPass
        ? getMessage(message, undefined, value, values)
        : undefined;

      return conditionalMessage;
    })
);

export const isSameAs = memoize(
  (otherFieldName, message = "ra.validation.required") =>
    Object.assign((value, values) => {
      const doesConditionPass =
        !isEmpty(value) &&
        !isEmpty(values[otherFieldName]) &&
        value === values[otherFieldName];
      const conditionalMessage = doesConditionPass
        ? getMessage(message, undefined, value, values)
        : undefined;

      return conditionalMessage;
    })
);

export const isNotSameAs = memoize(
    (otherFieldName, message = "ra.validation.required") =>
      Object.assign((value, values) => {
        const doesConditionPass =
          !isEmpty(value) &&
          !isEmpty(values[otherFieldName]) &&
          value !== values[otherFieldName];
        const conditionalMessage = doesConditionPass
          ? getMessage(message, undefined, value, values)
          : undefined;
  
        return conditionalMessage;
      })
  );
