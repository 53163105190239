import IsOpenIcon from '@material-ui/icons/NoEncryptionTwoTone';
import { FilterList, FilterListItem, FilterLiveSearch } from "react-admin";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ApproveIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { createStyles, makeStyles, Card, CardContent } from "@material-ui/core";
import {
  FixedDateRangeFilterList,
} from "../../../lib/common";
import {
  format,
  endOfYesterday,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";
import { ProcEntityRecordStatusFilterList, ProcureApprovalStatusFilterList } from '../../../common';

const RequisitionListSideFilters = () => {
  const cardClasses = useCardStyles();

  return (
    <Card classes={cardClasses}>
      <CardContent>
        <FilterLiveSearch />
        <FixedDateRangeFilterList
          label="resources.requisitions.fields.updated_since_gte"
          icon={<AccessTimeIcon />}
          gteSource="updated_since_gte"
          lteSource="updated_since_lte"
          dateRangeList={updatedSinceDatesList}
        />
        <FilterList
          label="resources.requisitions.fields.isOpen"
          icon={<IsOpenIcon />}
        >
          <FilterListItem
            label="ra.boolean.true"
            value={{ isOpen: true }}
          />
          <FilterListItem
            label="ra.boolean.false"
            value={{ isOpen: false }}
          />
        </FilterList>
        <FilterList
          label="resources.requisitions.fields.needsUserApproval"
          icon={<ApproveIcon />}
        >
          <FilterListItem
            label="ra.boolean.true"
            value={{ needsUserApproval: true }}
          />
          <FilterListItem
            label="ra.boolean.false"
            value={{ needsUserApproval: false }}
          />
        </FilterList>
        <FilterList
          label="resources.requisitions.fields.canPerformAction"
          icon={<ContactSupportIcon />}
        >
          <FilterListItem
            label="ra.boolean.true"
            value={{ canPerformAction: true }}
          />
          <FilterListItem
            label="ra.boolean.false"
            value={{ canPerformAction: false }}
          />
        </FilterList>        
        <ProcEntityRecordStatusFilterList />
        <ProcureApprovalStatusFilterList />
      </CardContent>
    </Card>
  );
};

const useCardStyles = makeStyles((theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("sm")]: {
        order: -1,
        maxWidth: 210,
        marginRight: "1em",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

const DATE_FORMAT_DAY = "yyyy-MM-dd";
const updatedSinceDatesList = [
  {
    label: "resources.requisitions.filters.updatedSince.today",
    gte: format(endOfYesterday(), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.requisitions.filters.updatedSince.thisWeek",
    gte: format(startOfWeek(new Date()), DATE_FORMAT_DAY),
    lte: undefined,
  },
  {
    label: "resources.requisitions.filters.updatedSince.thisMonth",
    lte: undefined,
    gte: format(startOfMonth(new Date()), DATE_FORMAT_DAY),
  },
  {
    label: "resources.requisitions.filters.updatedSince.lastMonth",
    lte: format(endOfMonth(subMonths(new Date(), 1)), DATE_FORMAT_DAY),
    gte: format(subMonths(startOfMonth(new Date()), 1), DATE_FORMAT_DAY),
  },
  {
    label: "resources.requisitions.filters.updatedSince.earlier",
    lte: format(endOfMonth(subMonths(new Date(), 2)), DATE_FORMAT_DAY),
    gte: undefined,
  },
];

export { RequisitionListSideFilters };
