import { CreateButton, ListButton, ShowActionsProps, ShowButton, TopToolbar, useResourceDefinition, useShowContext } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    className,
    hasEdit,
    hasList,
    resource,
    ...rest
}: any) => rest;

const DefaultEditAction = ({ className, ...rest }: ShowActionsProps) => {
    const { basePath, record } = useShowContext(rest);
    const { hasList, hasShow, hasCreate } = useResourceDefinition(rest);
    return (
        <TopToolbar className={className} {...sanitizeRestProps(rest)}>
            {hasList && <ListButton basePath={basePath} />}
            {hasShow && <ShowButton basePath={basePath} record={record} />}
            {hasCreate && <CreateButton basePath={basePath}/>}
        </TopToolbar>
    );
}

export { DefaultEditAction }