import { cloneElement, useMemo,  ReactElement } from "react";
import PropTypes from "prop-types";
import {
  sanitizeListRestProps,
  Identifier,
  SortPayload,
  Exporter,
  useListContext,
  useResourceContext,
  useResourceDefinition,
} from "ra-core";
import { ToolbarProps } from "@material-ui/core";

import { TopToolbar, CreateButton, ExportButton } from "react-admin";

const DefaultListActions= (props:DefaultListActionsProps) => {
  const { className, exporter, filters, ...rest } = props;
  const {
    currentSort,
    displayedFilters,
    filterValues,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext(props);
  const resource = useResourceContext(rest);
  const { hasCreate } = useResourceDefinition(rest);
  return useMemo(
    () => (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters &&
          cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button",
          })}
        {hasCreate && <CreateButton basePath={basePath} />}
        {exporter !== false && (
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filterValues={filterValues}
          />
        )}
        {props?.children}
      </TopToolbar>
    ),
    [resource, displayedFilters, filterValues, selectedIds, filters, total] // eslint-disable-line react-hooks/exhaustive-deps
  );
};

DefaultListActions.propTypes = {
  basePath: PropTypes.string,
  className: PropTypes.string,
  currentSort: PropTypes.any,
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  hasCreate: PropTypes.bool,
  resource: PropTypes.string,
  onUnselectItems: PropTypes.func.isRequired,
  selectedIds: PropTypes.arrayOf(PropTypes.any),
  showFilter: PropTypes.func,
  total: PropTypes.number,
};

DefaultListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
};

export interface DefaultListActionsProps extends ToolbarProps {
  currentSort?: SortPayload;
  className?: string;
  resource?: string;
  filters?: ReactElement<any>;
  displayedFilters?: any;
  exporter?: Exporter | boolean;
  filterValues?: any;
  permanentFilter?: any;
  hasCreate?: boolean;
  basePath?: string;
  selectedIds?: Identifier[];
  onUnselectItems?: () => void;
  showFilter?: (filterName: string, defaultValue: any) => void;
  total?: number;
}

DefaultListActions.displayName = "DefaultListActions";

export { DefaultListActions };
