export const RESOURCE_ACCOUNT_CODE = "accountCode";
export const RESOURCE_NAME_LOCATION = 'location'

export enum PermissionLocations {
  MENU_LOCATION = "view-locations-menu",
  LIST_LOCATIONS = "list-locations",
  SHOW_LOCATION = "show-location",
  CREATE_LOCATION = "create-location",
  EDIT_LOCATION = "update-location",
  DELETE_LOCATION = "delete-location",
}
