import { ReactElement } from "react";

import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  ReferenceField,
  DateField,
  BooleanField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
  RichTextField,
  NumberField,
  SelectField,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  MAX_RECORDS_PER_PAGE,
  IdFilter,
  SearchFilter,
} from "../../../../lib/common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../../lib/components";
import { RESOURCE_NAME_LOCATION } from "../../locations/constants";
import { RESOURCE_NAME_ITEM_SEGMENTS } from "../itemsSegment";
import { ABC_INDICATOR_CHOICES, RESOURCE_NAME_ITEM } from "./constants";
import {
  CategoryTypes,
  RESOURCE_NAME_CATEGORY,
} from "../../category/constants";
import { RESOURCE_NAME_ITEM_GROUPS } from "../itemsGroup";
import { RESOURCE_NAME_UOM } from "../../unit-of-measure";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn={true} key="search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
    <ReferenceArrayInput
      label="resources.items.filters.itemSegment"
      source="itemSegmentId"
      reference={RESOURCE_NAME_ITEM_SEGMENTS}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="itemSegmentId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="resources.items.filters.itemCategoryId"
      source="itemCategoryId"
      reference={RESOURCE_NAME_CATEGORY}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "number", order: "ASC" }}
      filter={{ categoryType: CategoryTypes.ITEM }}
      key="itemCategoryId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
    <ReferenceArrayInput
      label="resources.items.filters.itemGroupId"
      source="itemGroupId"
      reference={RESOURCE_NAME_ITEM_GROUPS}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "number", order: "ASC" }}
      key="itemGroupId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

    <ReferenceArrayInput
      label="resources.items.filters.storageLocation"
      source="storageLocationId"
      reference={RESOURCE_NAME_LOCATION}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="storageLocationId"
      isRequired
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>

  </Filter>
);

const defaultColumns = [
  "number",
  "name",
  "parentId",
  "itemSegmentId",
  "itemCategoryId",
  "itemGroupId",
];
const ItemList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
      {...props}
    >
      <CustomizableDatagrid optimized>
        <TextField source="number" />
        <TextField source="name" />
        <RichTextField source="description" />
        <ReferenceField source="parentId" reference={RESOURCE_NAME_ITEM}>
          <TextField source="name" />
        </ReferenceField>

        <ReferenceField
          source="itemCategoryId"
          reference={RESOURCE_NAME_CATEGORY}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField
          source="itemSegmentId"
          reference={RESOURCE_NAME_ITEM_SEGMENTS}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <ReferenceField
          source="itemGroupId"
          reference={RESOURCE_NAME_ITEM_GROUPS}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField
          source="storageLocationId"
          reference={RESOURCE_NAME_LOCATION}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        <ReferenceField
          source="uomId"
          reference={RESOURCE_NAME_UOM}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <SelectField source="abcIndicator" choices={ABC_INDICATOR_CHOICES} />
        <RichTextField source="specifications" sortable={false} />
        <NumberField source="size" />
        <NumberField source="weight" />
        <ReferenceField
          source="weightUomId"
          reference={RESOURCE_NAME_UOM}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <NumberField source="volume" />
        <ReferenceField
          source="volumeUomId"
          reference={RESOURCE_NAME_UOM}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <NumberField source="safetyStock" />
        <ReferenceField
          source="safetyStockUomId"
          reference={RESOURCE_NAME_UOM}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <NumberField source="moq" />
        <ReferenceField
          source="moqUomId"
          reference={RESOURCE_NAME_UOM}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <NumberField source="leadTimeInDays" />

        <TextField source="accountId" />
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { ItemList };
