import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../components";
import { PermissionForm } from "./PermissionForm";

const PermissionEdit= (props:EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <PermissionForm isEdit={true} />
    </DefaultEdit>
  );
};

export { PermissionEdit };
