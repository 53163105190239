import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../../lib/modules";

import { ItemClassForm } from "./ItemClassForm";

const ItemClassEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "itemClass",
    },
  ];
  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <ItemClassForm isEdit={true}{...props} />
    </DefaultEdit>
  );
};

export { ItemClassEdit };
