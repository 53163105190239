import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';import {  PermissionPermissions, RESOURCE_NAME_PERMISSION, } from './constants';
import { PermissionEdit } from './PermissionEdit';
import { PermissionList } from './PermissionList';
import { PermissionShow } from './PermissionShow';

const allowedPermissions = {
    menu: PermissionPermissions.MENU_PERMISSION,
    list: PermissionPermissions.LIST_PERMISSIONS,
    create: PermissionPermissions.CREATE_PERMISSION,
    update: PermissionPermissions.EDIT_PERMISSION,
    show: PermissionPermissions.SHOW_PERMISSION,
    delete: PermissionPermissions.DELETE_PERMISSION,
};

const permissionResource = {
    name: RESOURCE_NAME_PERMISSION,
    list: PermissionList,
    edit: PermissionEdit,
    show: PermissionShow, 
    icon: AccessibilityNewIcon,
    options: { label: 'resources.permissions.name',allowedPermissions },
}

export * from './constants';
export * from './permissionsmanager';

export { permissionResource }