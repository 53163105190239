import SpeakerGroupIcon from '@material-ui/icons/SpeakerGroup';import { ItemGroupCreate } from './ItemGroupCreate';

import { ItemGroupList } from './ItemGroupList';
import { ItemGroupEdit } from './ItemGroupEdit';
import { PermissionItemGroups, RESOURCE_NAME_ITEM_GROUPS } from './constants';
import { ItemGroupShow } from './ItemGroupShow';
const allowedPermissions = {
    menu: PermissionItemGroups.MENU_ITEM_GROUPS,
    list: PermissionItemGroups.LIST_ITEM_GROUPS,
    create: PermissionItemGroups.CREATE_ITEM_GROUP,
    update: PermissionItemGroups.EDIT_ITEM_GROUP,
    show: PermissionItemGroups.SHOW_ITEM_GROUP,
    delete: PermissionItemGroups.DELETE_ITEM_GROUP,
    import: PermissionItemGroups.IMPORT_ITEM_GROUPS,
};

const itemsGroupResource = {
    name: RESOURCE_NAME_ITEM_GROUPS,
    list: ItemGroupList,
    create: ItemGroupCreate,
    edit: ItemGroupEdit,
    show: ItemGroupShow, 
    icon: SpeakerGroupIcon,
    options: { label: 'resources.itemGroups.name', allowedPermissions },
}

export * from './constants';
export { itemsGroupResource }