import { FC } from "react";
import { CreateButton, EditButton, ListButton, ShowActionsProps, ShowButton, TopToolbar, useResourceDefinition, useShowContext } from "react-admin";
import { FILTER_DIRECTION, PermissionBehavior, ProcEntityRecordStatus } from "../../../../common";
import { usePermissionsCheck } from "../../../../lib/core";

const sanitizeRestProps = ({
    basePath,
    className,
    hasEdit,
    hasList,
    resource,
    ...rest
}: any) => rest;

const EnquiryCrudEditActions:FC<ShowActionsProps> = ({ className, ...rest }) => {
    const { record } = useShowContext(rest);

    const { hasList, hasShow, hasCreate } = useResourceDefinition(rest);
    const { hasPermissionAccess} = usePermissionsCheck();
    const canProcure = hasPermissionAccess(PermissionBehavior.CAN_PROCURE);
    const direction = canProcure?FILTER_DIRECTION.SENT:FILTER_DIRECTION.RECEIVED;

    return (
        <TopToolbar className={className} {...sanitizeRestProps(rest)}>
            {hasList && <ListButton basePath={`/enquiries?filter={"direction":"${direction}"}`}/>}
            {hasShow && <ShowButton basePath="/enquiries" record={record} />}
            {hasCreate && <CreateButton basePath="/enquiries"/>}
        </TopToolbar>
    )
}

export { EnquiryCrudEditActions }