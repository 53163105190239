import {
  TextInput,
  required,
  ReferenceInput,
  useTranslate,
  AutocompleteInput,
  Identifier,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TabbedForm,
  FormTab,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { RESOURCE_NAME_ORG_STRUCTURE } from "./constants";
import { Typography } from "@material-ui/core";
import { RESOURCE_NAME_ORG_STRUCTURE_TYPES } from "../org-structure-types/constants";
import { useState } from "react";
import { getTabbedFormProps, MAX_RECORDS_PER_PAGE } from "../../../../lib/common";
import { RESOURCE_NAME_PURCHASE_GROUPS } from "../../purchase-groups";
import { RESOURCE_NAME_ORG_USER_GROUP } from "../org-user-groups";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  parent: { display: "block" },
  number: { display: "block" },
};

const useStyles = makeStyles(styles);

const OrgStructureForm = ({ isEdit = false, ...rest }) => {
  const classes = useStyles(rest);
  const record = rest?.record;
  const [orgStructureTypeId, setOrgStructureTypeId] = useState<Identifier>(
    record?.orgStructureTypeId
  );
  return (
    <TabbedForm 
      {...getTabbedFormProps(rest)}
      warnWhenUnsavedChanges
    >
      <FormTab label="resources.orgStructures.tabs.general">
        <SectionTitle label="resources.orgStructures.fieldGroups.orgStructureDetails" />
        <TextInput
          autoFocus
          source="name"
          label="resources.orgStructures.fields.name"
          helperText="resources.orgStructures.help.name"
          formClassName={classes.name}
          validate={[required()]}
        />
        <TextInput
          source="number"
          label="resources.orgStructures.fields.number"
          helperText="resources.orgStructures.help.number"
          formClassName={classes.number}
          validate={[required()]}
        />
        <RichTextInput
          source="description"
          label="resources.businessFunction.fields.description"
          formClassName={classes.description}
        />
        <ReferenceInput
          label="resources.orgStructures.fields.orgStructureTypeId"
          source="orgStructureTypeId"
          isRequired
          validate={[required()]}
          reference={RESOURCE_NAME_ORG_STRUCTURE_TYPES}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          onChange={(value) => setOrgStructureTypeId(value)}
        >
          <AutocompleteInput
            optionText="name"
            helperText="resources.orgStructures.help.orgStructureTypeId"
            resettable={true}
            allowEmpty={false}
          />
        </ReferenceInput>

        <ReferenceInput
          label="resources.orgStructures.fields.parentId"
          source="parentId"
          reference={RESOURCE_NAME_ORG_STRUCTURE}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          filter={{ orgStructureTypeId: orgStructureTypeId }}
        >
          <AutocompleteInput
            optionText="name"
            helperText="resources.orgStructures.help.parentId"
            resettable={true}
            allowEmpty={true}
          />
        </ReferenceInput>
      </FormTab>

      <FormTab label="resources.orgStructures.tabs.procurement">
        <ReferenceArrayInput
          source="purchaseGroupIds"
          label="resources.orgStructures.fields.purchaseGroupIds"
          reference={RESOURCE_NAME_PURCHASE_GROUPS}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            helperText="resources.orgStructures.help.purchaseGroupIds"
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="procureRequisitionsUserGroupIds"
          label="resources.orgStructures.fields.procureRequisitionsUserGroupIds"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            helperText="resources.orgStructures.help.procureRequisitionsUserGroupIds"
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="procureEnquiriesUserGroupIds"
          label="resources.orgStructures.fields.procureEnquiriesUserGroupIds"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            helperText="resources.orgStructures.help.procureEnquiriesUserGroupIds"
          />
        </ReferenceArrayInput>

        <ReferenceArrayInput
          source="procureBidsUserGroupIds"
          label="resources.orgStructures.fields.procureBidsUserGroupIds"
          reference={RESOURCE_NAME_ORG_USER_GROUP}
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "name", order: "ASC" }}
          fullWidth
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            helperText="resources.orgStructures.help.procureBidsUserGroupIds"
          />
        </ReferenceArrayInput>
      </FormTab>
    </TabbedForm>
  );
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
export { OrgStructureForm };
