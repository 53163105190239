import { SpeedDialAction, SpeedDialActionProps } from "@material-ui/lab";
import { Fragment, useEffect, useState } from "react";
import { Record, useTranslate } from "react-admin";

export interface SingleListRowActionProps extends SpeedDialActionProps {
  record?: Record;
  handleClick?: (clickEvent, record?: Record) => void;
  shouldDisplay?: (record?: Record, extraData?: any) => Promise<boolean>;
  permissionKey?: string;
  permissionName?: string;
  extraDisplayData?: any;
}

const SingleListRowAction = (props: SingleListRowActionProps) => {
  const {
    record,
    handleClick,
    shouldDisplay,
    extraDisplayData,
    permissionKey,
    permissionName,
    ...rest
  } = props;
  const translate = useTranslate();
  const translatedTooltip =
    props?.tooltipTitle &&
    typeof props?.tooltipTitle === "string"
      ? translate(props?.tooltipTitle)
      : props?.tooltipTitle;
  const [shouldShowAction, setShouldShowAction] = useState(true);
  const keyValue = `slra-${record?.id}-${props?.tooltipTitle}`;

  useEffect(() => {
    async function getShouldDisplay() {
      let shouldDisplayAction = true;

      if (shouldDisplay && record) {
        shouldDisplayAction = await shouldDisplay(record, extraDisplayData);
      }
      setShouldShowAction(shouldDisplayAction);
    }

    getShouldDisplay();
  }, [extraDisplayData, record, shouldDisplay]);

  if (!shouldShowAction) {
    return <Fragment key={keyValue}></Fragment>
  }

  return (
    <SpeedDialAction
      {...rest}
      key={keyValue}
      onClick={(clickEvent) => handleClick && handleClick(clickEvent, record)}
      tooltipTitle={translatedTooltip}
    />
  );
};

SingleListRowAction.defaultProps = {
  className: "fabPrimary",
  tooltipPlacement: "bottom-start",
  extraDisplayData: null,
};

export { SingleListRowAction };
