import { ResourceProps } from "ra-core";
import { matchPath } from "react-router";

export const hasAccess = (permissions, ...askedPermissions) =>
  askedPermissions.every((permission) => permissions?.includes(permission));

export const hasAnyAccess = (permissions, ...askedPermissions) =>
  askedPermissions.some((permission) => permissions?.includes(permission));

export const checkResourcePermissions = (
  { options, name, list, create, edit, show, ...otherProps }: ResourceProps,
  permissions
) => {
  const allowedPermissions = options ? options["allowedPermissions"] : null;

  let gatedProp: ResourceProps = { ...otherProps, name };

  if (permissions && allowedPermissions) {
    const hasList =
      allowedPermissions?.list &&
      list &&
      hasAccess(permissions, allowedPermissions?.list);

    const hasCreate =
      allowedPermissions.create &&
      create &&
      hasAccess(permissions, allowedPermissions?.create);

    const hasEdit =
      allowedPermissions.edit &&
      edit &&
      hasAccess(permissions, allowedPermissions?.edit);

    const hasShow =
      allowedPermissions.show &&
      show &&
      hasAccess(permissions, allowedPermissions?.show);

    if (hasList) {
      gatedProp.list = list;
    }

    if (hasCreate) {
      gatedProp.create = create;
    }

    if (hasEdit) {
      gatedProp.edit = edit;
    }

    if (hasShow) {
      gatedProp.show = show;
    }
  } else {
    gatedProp = {
      name,
      list,
      edit,
      create,
      show,
      options,
      ...otherProps,
    };
  }

  return gatedProp;
};

export const isPublicUrl = () => {
  const isMatchingResetPath = !!matchPath(
    window?.location?.hash,
    "#/auth/reset-password/"
  );

  const currentLocation = window?.location?.hash;

  const publicUrlPathsList = ["#/auth/forgot-password"];

  return isMatchingResetPath ? true: publicUrlPathsList.includes(currentLocation);
};
