import { Identifier, RecordMap } from "react-admin";
import {
  Record,
  PermissionManagerState,
  PermissionGroup,
  Permission,
  PermissionChanges,
} from "./types";

export const prepareInitialState = (
  record: Record | undefined,
  isDisabled: boolean
): PermissionManagerState => {
  const roleId: string | undefined = record?.id?.toString();
  const rolePermissions = record?.permissions;
  const rolePermissionIds = rolePermissions?.map(
    (rolePermission) => rolePermission?.id
  );

  const addedSet = new Set<string>();
  const removedSet = new Set<string>();
  const permissionChanges = {
    added: addedSet,
    removed: removedSet,
  };
  return {
    roleId: roleId,
    isDisabled: isDisabled,
    rolePermissionIds,
    permissionChanges,
  };
};

const groupAllPermissionGroups = (
  permissionGroups: PermissionGroup[] | undefined
) => {
  if (!permissionGroups?.length) {
    return permissionGroups;
  }

  let permissionGroupsIdToIndexMap: { [key: string]: number } = {};
  const roots: PermissionGroup[] = [];
  let i;

  for (i = 0; i < permissionGroups?.length; i += 1) {
    const permissionGroup = permissionGroups[i];
    const permissionGroupId = permissionGroup.id;
    permissionGroupsIdToIndexMap[permissionGroupId] = i; // initialize the map
    permissionGroups[i].children = []; // initialize the children
  }
  for (i = 0; i < permissionGroups?.length; i += 1) {
    const permissionGroup = permissionGroups[i];
    if (permissionGroup.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      const parentIndexPosition =
        permissionGroupsIdToIndexMap[permissionGroup.parentId];
      permissionGroups[parentIndexPosition]?.children?.push(permissionGroup);
    } else {
      roots.push(permissionGroup);
    }
  }
  return roots;
};

export const preparePermissionGroups = (
  allpermissionGroups: RecordMap<Record>,
  allPermissionGroupIds: Identifier[],
  rolePermissionIds?: string[]
): PermissionGroup[] | undefined => {
  const permissionGroups = allPermissionGroupIds?.map((permissionGroupId) => {
    const record = allpermissionGroups[permissionGroupId];
    const id: string = record.id?.toString();
    const parentId: string = record.parentId;
    const name: string = record.name;
    const description: string = record.description;
    const label: string = record.label;
    const sortOrder: number = record.sortOrder;
    const version: number = record.version;

    let areAllPermissionsAssigned: boolean = record?.permissions?.length?true:false;

    const permissions = record?.permissions?.map((permission) => {
      const id: string = permission.id;
      const permissionGroupId: string = permission.permissionGroupId;
      const name: string = permission.name;
      const description: string = permission.description;
      const label: string = permission.label;
      const sortOrder: number = permission.sortOrder;
      const version: number = record.version;
      const isPermissionAssigned = rolePermissionIds
        ? rolePermissionIds.includes(id)
        : false;

      if (!isPermissionAssigned) {
        areAllPermissionsAssigned = false;
      }
      const permissionRecord: Permission = {
        id: id,
        permissionGroupId: permissionGroupId,
        name: name,
        description: description,
        label: label,
        sortOrder: sortOrder,
        version: version,
        isPermissionAssigned: isPermissionAssigned,
      };
      return permissionRecord;
    });

    const permissionGroup: PermissionGroup = {
      id: id,
      parentId: parentId,
      name: name,
      description: description,
      label: label,
      sortOrder: sortOrder,
      version: version,
      areAllPermissionsAssigned: areAllPermissionsAssigned,
      permissions: permissions,
    };

    return permissionGroup;
  });

  const sortedUnorderedPermissionGroupsWithPermissions = permissionGroups?.sort(
    (a, b) => {
      const aParentPermissiondGroupId = a && a.parentId ? a.parentId : null;
      const bParentPermissiondGroupId = b && b.parentId ? b.parentId : null;

      if (!aParentPermissiondGroupId && bParentPermissiondGroupId) {
        return 1;
      } else {
        return 0;
      }
    }
  );

  const unsortedPermissionGroupsWithPermissions = groupAllPermissionGroups(
    sortedUnorderedPermissionGroupsWithPermissions
  );

  const permissionGroupsWithPermissions = unsortedPermissionGroupsWithPermissions?.sort(
    (a, b) => {
      return a.sortOrder - b.sortOrder;
    }
  );
  return permissionGroupsWithPermissions;
};

export const handlePermissionToggle = (
  permission: Permission,
  permissionChanges: PermissionChanges,
  isSelected: boolean
): PermissionChanges => {
  const permissionId = permission.id;
  permission.isPermissionAssigned = isSelected;
  let added = permissionChanges.added;
  let removed = permissionChanges.removed;
  if (isSelected) {
    if (!removed.has(permissionId)) {
      added = added.add(permissionId);
    } else {
      removed.delete(permissionId);
    }
  } else {
    if (!added.has(permissionId)) {
      removed = removed.add(permissionId);
    } else {
      added.delete(permissionId);
    }
  }

  return {
    added,
    removed,
  };
};

const toggleAndcollectPermissionIds = (
  isSelected: boolean,
  permissions?: Permission[]
) => {
  return permissions?.map((permission) => {
    permission.isPermissionAssigned = isSelected;
    return permission?.id;
  });
};

export const handlePermissionGroupToggle = (
  permissionGroup: PermissionGroup,
  permissionChanges: PermissionChanges,
  isSelected: boolean
): PermissionChanges => {
  const permissionIds = toggleAndcollectPermissionIds(
    isSelected,
    permissionGroup?.permissions
  );
  const childPermissionIds = permissionGroup?.children?.flatMap(
    (permissionGroup) =>
      toggleAndcollectPermissionIds(isSelected, permissionGroup?.permissions)
  );
  const allPermissionIds = [
    ...(permissionIds || []),
    ...(childPermissionIds || []),
  ];
  let added = permissionChanges.added;
  let removed = permissionChanges.removed;
  allPermissionIds.forEach((permissionId) => {
    if (permissionId) {
      if (isSelected) {
        if (!removed.has(permissionId)) {
          added = added.add(permissionId);
        } else {
          removed.delete(permissionId);
        }
      } else {
        if (!added.has(permissionId)) {
          removed = removed.add(permissionId);
        } else {
          added.delete(permissionId);
        }
      }
    }
  });
  return permissionChanges;
};

const recalculatePermissionGroupSelection = (
  permissionGroup?: PermissionGroup
): boolean => {
  let areAllPermissionsAssigned: boolean = true;
  permissionGroup?.permissions?.forEach((permission) => {
    if (!permission.isPermissionAssigned) {
      areAllPermissionsAssigned = false;
    }
  });

  permissionGroup?.children?.forEach((permissionGroup) => {
    const recalculatedSelection = recalculatePermissionGroupSelection(
      permissionGroup
    );

    if (!recalculatedSelection) {
      areAllPermissionsAssigned = false;
    }
  });

  if (permissionGroup) {
    permissionGroup.areAllPermissionsAssigned = areAllPermissionsAssigned;
  }

  return areAllPermissionsAssigned;
};

export const recalculateSelection = (
  allPermissionGroups?: PermissionGroup[]
) => {
  let areAllPermissionsAssigned = true;

  allPermissionGroups?.forEach((permissionGroup) => {
    const isPermissionGroupPermissionAssigned = recalculatePermissionGroupSelection(
      permissionGroup
    );
    if (!isPermissionGroupPermissionAssigned) {
      areAllPermissionsAssigned = false;
    }
  });

  return areAllPermissionsAssigned;
};
