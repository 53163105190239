import React, { ReactElement } from "react";

import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  ReferenceField,
  DateField,
  BooleanField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../components";
import { COMMON_DATE_TIME_FORMAT } from "../../../core";
import { RESOURCE_NAME_PERMISSION_GROUP } from "../permission-groups";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
    <ReferenceArrayInput
      label="resources.permissions.filters.permissionGroup"
      source="permissionGroupId"
      reference={RESOURCE_NAME_PERMISSION_GROUP}
      perPage={MAX_RECORDS_PER_PAGE}
      sort={{ field: "name", order: "ASC" }}
      key="permissionGroupId"
    >
      <AutocompleteArrayInput
        optionText="displayLabel"
        optionValue="id"
        resettable={true}
        allowEmpty={false}
      />
    </ReferenceArrayInput>
  </Filter>
);
const defaultColumns = ["name", "label", "description"];
const PermissionList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="label" />
        <TextField source="sortOrder" />
        <TextField source="type" />
        <ReferenceField
          source="permissionGroupId"
          reference={RESOURCE_NAME_PERMISSION_GROUP}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />

        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { PermissionList };
