export const RESOURCE_NAME_SYSTEM_DATA="systemData";

export enum PermissionSystemData {
    MENU_DATA_SETUP_MODULE = 'view-datasetup-management-menu',
    MENU_SYSTEM_DATA = 'view-system-data-management-menu',
    LIST_SYSTEM_DATA = 'list-system-data',
    SHOW_SYSTEM_DATA = 'show-system-data',
    CREATE_SYSTEM_DATA = 'create-system-data',
    EDIT_SYSTEM_DATA = 'update-system-data',
    DELETE_SYSTEM_DATA = 'delete-system-data',
  }
  