import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { LocationForm } from "./LocationForm";

const LocationCreate= (props: CreateProps)=> {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "locations",
    },
  ];

  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <LocationForm />
    </DefaultCreate>
  );
};

export { LocationCreate };
