import { useEffect, useReducer, useMemo } from "react";
import { Loading, useEditContext, useGetList, useLoading } from "react-admin";
import { RESOURCE_NAME_PERMISSION_GROUP } from "../permission-groups";
import { PermissionManagerActionTypes } from "./permissionsmanager/types";
import { permissionsManagerReducer } from "./permissionsmanager/permissionsManagerReducer";
import { prepareInitialState } from "./permissionsmanager/utils";
import { PermissionManagerContext } from "./permissionsmanager/PermissionManagerContext";
import { PermissionsGroupContainer } from "./permissionsmanager/PermissionsGroupContainer";
import { useForm } from "react-final-form";
import { MAX_RECORDS_PER_PAGE } from "../../../common";

const ModifyRolePermissions = () => {
  const { record } = useEditContext();
  const {
    data: allpermissionGroups,
    ids: allPermissionGroupIds,
    loading: arePermissionGroupsLoading,
  } = useGetList(
    RESOURCE_NAME_PERMISSION_GROUP,
    { page: 1, perPage: MAX_RECORDS_PER_PAGE },
    { field: "sortOrder", order: "ASC" },
    {}
  );

  const isLoading = useLoading();

  const permissionManagerState = prepareInitialState(record, false);

  const [state, dispatch] = useReducer(permissionsManagerReducer, {
    ...permissionManagerState,
  });

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  const form = useForm();

  form.registerField(
    "permissionChanges",
    (fieldState) => {
    },
    {
      touched: true,
      value: true,
    },
    { data: true }
  );

  useEffect(() => {
    if (!arePermissionGroupsLoading && allpermissionGroups && dispatch) {
      dispatch({
        type: PermissionManagerActionTypes.SET_ALL_PERMISSION_GROUPS,
        payload: { allpermissionGroups, allPermissionGroupIds },
      });
    }
  }, [arePermissionGroupsLoading, allpermissionGroups, allPermissionGroupIds]);

  if (isLoading) {
    return <Loading/>
  } else {
    return (
      <PermissionManagerContext.Provider value={contextValue}>
        <PermissionsGroupContainer headingLabel="resources.roles.permissionTabHeading.manage" />
      </PermissionManagerContext.Provider>
    );
  }
};

export { ModifyRolePermissions };
