import { createStyles, Theme } from "@material-ui/core/styles";

const styles = (theme: Theme) => 
createStyles({
  clearWithFloatLeft: {
    float: "left",
    clear: "left",
  },
  floatLeftWithMarginLeft: {
    float: "left",
    marginLeft: 32,
  },
  marginTop20: {
    marginTop: "25px",
  },
  actionButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "8px 0 14px 8px",
  },
  inlineGridWith50PercentWidth: {
    [theme.breakpoints.up("xs")]: {
      display: "inline-grid",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
});

export { styles };
