import { ReactElement, useContext, useEffect } from "react";
import { prepareListRowActions } from "./CustomizableList/listUtils";
import { ListRowActionButtonsActionTypes, ListRowActionButtonsContext } from "./listActions";

const DummyListRowComponent = (props) => {
    const { dispatch } = useContext(ListRowActionButtonsContext);
    const { listRowActions, modifiedProps } = props;
    useEffect(() => {
      if (dispatch) {
        const defaultListRowActions = prepareListRowActions(
          listRowActions,
          modifiedProps
        );
  
        let newListRowActions: Array<ReactElement> | undefined = undefined;
        if (defaultListRowActions && Array.isArray(defaultListRowActions)) {
          newListRowActions = Array.from(defaultListRowActions);
        }
  
        dispatch({
          type: ListRowActionButtonsActionTypes.SET_LIST_ROW_ACTIONS,
          payload: {
            //@ts-ignore
            listRowActions: newListRowActions,
          },
        });
      }
    }, []);
    return null;
  }

  export { DummyListRowComponent }