export const RESOURCE_NAME_ROLE = "roles";

export enum PermissionRoles {
  MENU_ROLES = 'view-role-management-menu',
  LIST_ROLES = 'list-roles',
  SHOW_ROLE = 'show-role',
  CREATE_ROLE = 'create-role',
  EDIT_ROLE = 'update-role',
  DELETE_ROLE = 'delete-role',
  ACTION_MANAGE_PERMISSIONS_FROM_ROLE = 'role-manage-permission-action',
}
