import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { BusinessFunctionForm } from "./BusinessFunctionForm";

const BusinessFunctionCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "business-functions",
    },
  ];

  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <BusinessFunctionForm />
    </DefaultCreate>
  );
};

export { BusinessFunctionCreate };
