
import { LoginForm } from "react-admin";
import { UnauthenticatedLayout } from "./UnauthenticatedLayout";
import { SecondaryActionLinkButton } from "../../components";

/**
 * A standalone login page, to serve as authentication gate to the admin
 *
 * Expects the user to enter a login and a password, which will be checked
 * by the `authProvider.login()` method. Redirects to the root page (/)
 * upon success, otherwise displays an authentication error message.
 *
 * Copy and adapt this component to implement your own login logic
 * (e.g. to authenticate via email or facebook or anything else).
 *
 * @example
 *     import MyLoginPage from './MyLoginPage';
 *     const App = () => (
 *         <Admin loginPage={MyLoginPage} authProvider={authProvider} disableTelemetry>
 *             ...
 *        </Admin>
 *     );
 */



const LoginPage= () => {
  return (
    <UnauthenticatedLayout
      title="Login"
      titleMessage="Please login with your credentials"
    >
      <>
        <LoginForm />
        <SecondaryActionLinkButton
          title="Forgot Password ?"
          url="/auth/forgot-password"
        />
      </>
    </UnauthenticatedLayout>
  );
};

export { LoginPage };
