import { Dispatch, MouseEvent, ReactElement } from "react";
import { ConfirmProps } from "react-admin";

export interface ListRowActionButtonsState {
  listRowActions?: Array<ReactElement>;
  isSpeedDialOpen: boolean;
  isConfirmOpen: boolean;
  confirm?: ConfirmProps;
}

export enum ListRowActionButtonsActionTypes {
  INIT,
  OPEN_SPEED_DIAL,
  CLOSE_SPEED_DIAL,
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  HANDLE_CONFIRM_ACTION,
  SET_LIST_ROW_ACTIONS,
}

export type ListRowActionButtonsActions =
  | {
      type: ListRowActionButtonsActionTypes.INIT;
    }    
  | {
      type: ListRowActionButtonsActionTypes.OPEN_SPEED_DIAL;
    }
  | {
      type: ListRowActionButtonsActionTypes.CLOSE_SPEED_DIAL;
    }
  | {
      type: ListRowActionButtonsActionTypes.OPEN_CONFIRM;
      payload: {
        confirm: ConfirmProps;
      };
    }
  | {
      type: ListRowActionButtonsActionTypes.CLOSE_CONFIRM;
    }
  | {
      type: ListRowActionButtonsActionTypes.HANDLE_CONFIRM_ACTION;
      payload: {
        event: MouseEvent,
      }
    }
  | {
      type: ListRowActionButtonsActionTypes.SET_LIST_ROW_ACTIONS;
      payload: {
        listRowActions: Array<ReactElement>;
      };
    };

export interface ListRowActionButtonsContextProps {
  state?: ListRowActionButtonsState;
  dispatch?: Dispatch<ListRowActionButtonsActions>;
}
