import { FileField, FileFieldProps, FileInput, FileInputProps, InputProps } from "react-admin";
import { DropzoneOptions } from "react-dropzone";
import { FileInputPlaceholder } from "..";
import { UploadedFilesField } from "../..";
import { SectionTitleSubtitle } from "../../..";
import { APP_MAX_FILE_SIZE_BYTES, ALLOWED_FILE_TYPES } from "../../../../common";

interface FileInputOptions extends DropzoneOptions {
  inputProps?: any;
  onRemove?: Function;
}

export interface MediaInputFieldProps
  extends Partial<FileInputProps>,
    Partial<InputProps<FileInputOptions>> {
    showUploadedFiles?: boolean;
    showSectionTitle?: boolean;
    sectionTitle?: string;
    sectionSubtitle?: string;
    fileFieldOptions?: FileFieldProps,
}

const DEFAULT_FILE_FIELD_OPTIONS: FileFieldProps = {
  src: "src",
  title: "title",
}

const MediaInputField = (props: MediaInputFieldProps) => {
  const { 
      showUploadedFiles = false, 
      showSectionTitle = true, 
      sectionTitle = "components.controls.media.fieldGroups.attachments",
      sectionSubtitle,
      source = "files",
      label="components.controls.media.fields.files",
      helperText="components.controls.media.help.files",
      multiple=true,
      placeholder = <FileInputPlaceholder />,
      //@ts-ignore
      accept=ALLOWED_FILE_TYPES,
      maxSize=APP_MAX_FILE_SIZE_BYTES,
      fileFieldOptions = DEFAULT_FILE_FIELD_OPTIONS,
      ...rest 
   } = props;

  return (
    <>
      {
        showSectionTitle && <SectionTitleSubtitle 
          title={sectionTitle} subTitle={sectionSubtitle}
          />
      }
      <FileInput
        source={source}
        label={label}
        helperText={helperText}
        multiple={multiple}
        placeholder={placeholder}
        maxSize={maxSize}
        //@ts-ignore
        accept={accept}
        {...rest}
      >
        <FileField {...fileFieldOptions}/>
      </FileInput>
      {showUploadedFiles && <UploadedFilesField />}
    </>
  );
};

export { MediaInputField };
