import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  RichTextField,
  ReferenceArrayField,
  Datagrid,
} from "react-admin";
import {
  DefaultShow,
  ResourceLinkedField,
  UploadedFilesField,
} from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_PURCHASE_GROUPS } from "../../purchase-groups";
import { RESOURCE_NAME_ITEM_GROUPS } from "./constants";

const ItemGroupShow= (props:ShowProps) => {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="number" label="resources.itemGroups.fields.number" />
        <TextField source="name" label="resources.itemGroups.fields.name" />
        <RichTextField
          source="description"
          label="resources.itemGroups.fields.description"
        />
        <ReferenceField
          source="parentId"
          label="resources.itemGroups.fields.parentId"
          reference={RESOURCE_NAME_ITEM_GROUPS}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceArrayField
          source="purchaseGroupIds"
          label="resources.itemGroups.fields.purchaseGroupIds"
          reference={RESOURCE_NAME_PURCHASE_GROUPS}
        >
          <Datagrid optimized>
            <ResourceLinkedField
              source="number"
              linkedResource={RESOURCE_NAME_PURCHASE_GROUPS}
              linkedResourceIdSource="id"
              resource={RESOURCE_NAME_PURCHASE_GROUPS}
              link="show"
            />
            <TextField source="name" />
          </Datagrid>
        </ReferenceArrayField>
        <DateField
          source="createdAt"
          label="resources.itemGroups.fields.createdAt"
          options={COMMON_DATE_TIME_FORMAT}
        />
        <DateField
          source="updatedAt"
          label="resources.itemGroups.fields.updatedAt"
          options={COMMON_DATE_TIME_FORMAT}
        />
        <UploadedFilesField canDelete={false} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { ItemGroupShow };
