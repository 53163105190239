import {
  Box,
  createStyles,
  Typography,
  FormControl,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { useTranslate } from "ra-core";
import { Record } from "react-admin";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: '400px',
      height: '225px',
    },
    subTitle2: {
      paddingTop: 15
    },
    comment: {
      margin: theme.spacing(1),
      width: '95%'
    },
  })
);

const ApprovalActionCommentsForm = ({
  confirmMessage,
  commentSubtext,
  commentLabel,
  commentHelpText,
  record,
}: ApprovalActionCommentsFormProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  const storeComment = (event) => {
    if(record){
      record.comment = event.target.value;
    }
  }

  return (
    <Box className={classes.container}>
      <Typography variant="subtitle1" color="secondary" gutterBottom={true}>
        {translate(confirmMessage)}
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" className={classes.subTitle2}>
        {translate(commentSubtext)}
      </Typography>
            <FormControl className={classes.comment}>
              <TextField
                multiline
                rows={3}
                variant="filled"
                onChange={storeComment}
                label={translate(commentLabel)}
                helperText={translate(commentHelpText)}
              />
            </FormControl>
    </Box>
  );
};

interface ApprovalActionCommentsFormProps {
  confirmMessage: string;
  commentSubtext: string;
  commentLabel: string;
  commentHelpText: string;
  record?: Record;
}

export { ApprovalActionCommentsForm };
