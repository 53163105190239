import { AuthProvider } from "react-admin";
import { isPublicUrl } from "../helpers/auth";
import inMemoryJWTManager from "./inMemoryJWTManager";

const apiUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}`;
const clearLocalAuthInfo = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("app-logout");
};

const restAuthProvider: AuthProvider = {
  // authentication
  login: async ({ username, password }) => {
    const request = new Request(`${apiUrl}/auth/login`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    const response = await fetch(request);
    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }
    const { user, accessToken } = await response.json();
    const { refreshValidityTimeStamp, ...restUser } = user;
    localStorage.setItem("user", JSON.stringify(restUser));
    inMemoryJWTManager.setToken(accessToken);
  },
  checkError: (error) => {
    const status = error?.status;
    console.log(`error status: ${status}`);
    if (status === 401 || status === 403) {
      inMemoryJWTManager.eraseToken();
      clearLocalAuthInfo();
      return Promise.reject({
        redirectTo: "/login",
        message: "common.login.unauthorized",
      });
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  checkAuth: async () => {
    if (isPublicUrl()) {
      return Promise.resolve();
    }

    return inMemoryJWTManager.waitForTokenRefresh().then(() => {
      Promise.resolve();
      /* return inMemoryJWTManager.getToken()
        ? Promise.resolve()
        : Promise.reject(); */
    });
  },
  logout: async () => {
    const request = new Request(`${apiUrl}/auth/logout`, {
      method: "POST",
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    try {
      const response = await fetch(request);
      clearLocalAuthInfo();
      inMemoryJWTManager.eraseToken();
      return Promise.resolve("/login");
    } catch (error) {
      //throw new Error("Logout Error");
      //Ignore
    }
  },
  getIdentity: async () => {
    if (isPublicUrl()) {
      return Promise.resolve({ id: "", fullName: "" });
    }

    try {
      const localUserStr = localStorage.getItem("user");
      if (localUserStr) {
        const localUser = JSON.parse(localUserStr);
        const id = localUser?.id;
        const firstName = localUser?.firstName;
        const lastName = localUser?.lastName;
        const fullName = localUser.fullName ?? `${firstName} ${lastName}`;
        const accountId = localUser?.accountId;
        const isSystemAdmin = localUser?.isSystemAdmin;
        const isSystemUser = localUser?.isSystemUser;
        const isActive = localUser?.isActive;

        return Promise.resolve({
          id,
          fullName,
          firstName,
          lastName,
          accountId,
          isSystemAdmin,
          isSystemUser,
          isActive,
        });
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // authorization
  getPermissions: async (params) => {
    if (isPublicUrl()) {
      return Promise.resolve();
    }

    await inMemoryJWTManager.waitForTokenRefresh();

    /* if (!inMemoryJWTManager.getToken()) {
      Promise.reject();
    } */

    const userStr = localStorage.getItem("user");
    if (userStr) {
      const user = JSON.parse(userStr);
      const permissionNames = user.permissionNames;
      return permissionNames
        ? Promise.resolve(permissionNames)
        : Promise.reject();
    }

    return Promise.reject();
  },
};

export { restAuthProvider };
