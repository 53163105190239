import { Identifier } from "react-admin";
import { getMessage, memoize } from "./validationUtils";

const serverUrl = process.env.REACT_APP_SERVER_ENDPOINT;

const checkIsFieldUnique = async (
  resourceName: string,
  field: string,
  value: string,
  values: any,
  performOwnIdCheck: boolean = false,
  message: string = "components.validations.isUniqueField"
) => {
  const uniqueCheckUrl = `${serverUrl}/${resourceName}/isUnique`;

  if (value) {
    let params: any = {
      field,
      value,
    };
    if(performOwnIdCheck && values?.id){
      const ownId: Identifier = values?.id;
      params = {
        ...params,
        ownId,
      }
    }
    const request = new Request(uniqueCheckUrl, {
      method: "POST",
      body: JSON.stringify(params),
      credentials: "include",
      headers: new Headers({ "Content-Type": "application/json" }),
    });

    const response = await fetch(request);
    const isRequestSuccess = response.status === 201;
    const { isUnique } = await response.json();

    return !isUnique && isRequestSuccess
      ? getMessage(message, undefined, value, values)
      : undefined;
  } else {
    return undefined;
  }
}

const isUniqueField = memoize(
  (
      resourceName: string,
      field: string,
      performOwnIdCheck: boolean = false,
      message: string = "components.validations.isUniqueField"
    ) =>
    async (value, values) => {
      return checkIsFieldUnique(resourceName, field, value, values, performOwnIdCheck, message);
    }
);

export { checkIsFieldUnique, isUniqueField }
