import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import { CurrencyForm } from "./CurrencyForm";

const CurrencyCreate= (props: CreateProps) => {
    return (
      <DefaultCreate {...props}>
          <CurrencyForm isEdit={true}/>
      </DefaultCreate>
    );
  };
  
  export { CurrencyCreate };