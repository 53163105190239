import {
  PermissionBidsComparison,
  RESOURCE_NAME_BIDS_COMPARISON,
} from "./constants";
import BidsCompareIcon from "@material-ui/icons/Compare";
import { BidsComparisonEdit } from "./BidsComparisonEdit";

const allowedPermissions = {
  menu: PermissionBidsComparison.LIST_BIDS_COMPARISONS,
 // list: PermissionBidsComparison.LIST_BIDS_COMPARISONS,
 // create: PermissionBidsComparison.CREATE_BIDS_COMPARISON,
  update: PermissionBidsComparison.EDIT_BIDS_COMPARISON,
  show: PermissionBidsComparison.SHOW_BIDS_COMPARISON,
//  delete: PermissionBidsComparison.DELETE_BIDS_COMPARISON,
};

const bidsComparisonResource = {
  name: RESOURCE_NAME_BIDS_COMPARISON,
  edit: BidsComparisonEdit,
  icon: BidsCompareIcon,
  options: { label: "resources.bidsComparison.name", allowedPermissions },
};

export * from "./constants";
export { bidsComparisonResource };
