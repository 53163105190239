import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../../lib/components";
import { MediaFileSource, transformAndUploadS3Files } from "../../../../lib/modules";
import { ItemClassForm } from "./ItemClassForm";

const ItemClassCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [{
    source: 'files',
    subFolder: 'itemClass',
  }];  
  return (
      <DefaultCreate {...props} transform={transformAndUploadS3Files(mediaFileSources)}>
          <ItemClassForm />
      </DefaultCreate>
    );
  };
  
  export { ItemClassCreate };