export const RESOURCE_NAME_VENDOR = "vendors";

export const FILTER_NAME_GLOBAL_VENDORS = "showGlobalVendors";

export enum PermissionVendors {
  MENU_VENDOR = "view-vendors-menu",
  LIST_VENDORS = "list-vendors",
  SHOW_VENDOR = "show-vendor",
  CREATE_VENDOR = "create-vendor",
  EDIT_VENDOR = "update-vendor",
  DELETE_VENDOR = "delete-vendor",
  IMPORT_VENDORS = "import-vendors",
  CAN_LINK_GLOBAL_VENDORS = "can-link-global-vendors",
  CAN_UNLINK_GLOBAL_VENDORS = "can-unlink-global-vendors"
}

export const CATEGORY_TYPE_CHOICES = [
  { id: 1, name: 'resources.category.choices.categoryType.item' },
  { id: 2, name: 'resources.category.choices.categoryType.vendor' },
];
export enum VENDOR_ACTIONS {
  ADD_AS_OWN_VENDOR = "addAsGlobalVendor",
  REMOVE_AS_OWN_VENDOR = "removeAsOwnVendor",
}