import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import { UomForm } from "./UomForm";

const UomCreate= (props: CreateProps) => {
    return (
      <DefaultCreate {...props}>
          < UomForm/>
      </DefaultCreate>
    );
  };
  
  export { UomCreate };