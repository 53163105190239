import { CreateProps } from "react-admin";
import { DefaultCreate } from "../../../lib/components";
import { CategoryForm } from "./CategoryForm";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";

const CategoryCreate= (props: CreateProps)=> {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "category",
    },
  ];

  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <CategoryForm />
    </DefaultCreate>
  );
};

export { CategoryCreate };
