export const RESOURCE_NAME_ORG_USER_GROUP = "orgUserGroups";

export enum PermissionOrgUserGroup {
  MENU_ORG_USER_GROUP = "view-org-user-group-menu",
  LIST_ORG_USER_GROUPS = "list-org-user-groups",
  SHOW_ORG_USER_GROUP = "show-org-user-group",
  CREATE_ORG_USER_GROUP = "create-org-user-group",
  EDIT_ORG_USER_GROUP = "update-org-user-group",
  DELETE_ORG_USER_GROUP = "delete-org-user-group",
}
export enum OrgUserGroupType {
  REQUISITION = 1,
  ENQUIRY,
  BID,
}