import { LinkToType, ReferenceField, Record } from "react-admin";
import { usePermissionsCheck } from "../../../lib/core";
import {
  RESOURCE_NAME_ONLY_USER,
  RESOURCE_NAME_USER,
  usersResource,
} from "../../../lib/modules/accounts";
import { NameEmailField } from "./NameEmailField";

const UserNameEmailField = ({
  source,
  label,
  useLabel = false,
  record
}: {
  source: string;
  label: string;
  useLabel?: boolean;
  record?: Record;
}) => {
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();
  let showLink: LinkToType = false;

  if (
    permissionsLoaded &&
    hasPermissionAccess(usersResource?.options?.allowedPermissions?.show)
  ) {
    showLink = (record, reference) => {
      return `/${RESOURCE_NAME_USER}/${record[source]}/show`;
    };
  }

  return (
    <ReferenceField
      source={source}
      reference={RESOURCE_NAME_ONLY_USER}
      link={showLink}
      record={record}
    >
      <NameEmailField
        nameFieldSource="fullName"
        emailFieldSource="email"
        useLabel={useLabel}
        fieldLabel={label}
      />
    </ReferenceField>
  );
};

export { UserNameEmailField };
