import {  ReactNode } from "react";
import { FilterList, FilterListItem } from "react-admin";

export interface FixedDateRangeFilterListProps {
  label: string;
  icon: ReactNode;
  gteSource: string;
  lteSource: string;
  dateRangeList: {
    label: string;
    gte: string | undefined;
    lte: string | undefined;
  }[];
}

const FixedDateRangeFilterList= ({
  label,
  icon,
  gteSource,
  lteSource,
  dateRangeList,
}:FixedDateRangeFilterListProps) => {
  return (
    <FilterList label={label} icon={icon}>
      {dateRangeList?.map((dateRange) => (
        <FilterListItem
          key={dateRange.label}
          label={dateRange.label}
          value={{
            [gteSource]: dateRange.gte,
            [lteSource]: dateRange.lte,
          }}
        />
      ))}
    </FilterList>
  );
};

export { FixedDateRangeFilterList };
