import { Labeled, SelectField } from "react-admin";
import { PROCURE_APPROVAL_STATUS_CHOICES } from "../../constants";

const ApprovalStatusField = ({
  label = "common.fields.approvalStatus",
  useLabel = false,
}: {
  label?: string;
  useLabel?: boolean;
}) => {
  if (useLabel) {
    return (
      <Labeled
        id="procureApprovalStatus"
        source="procureApprovalStatus"
        label="common.fields.approvalStatus"
      >
        <SelectField
        label={label}
        source="procureApprovalStatus"
        choices={PROCURE_APPROVAL_STATUS_CHOICES}
      />
      </Labeled>
    );
  } else {
    return (
      <SelectField
        label={label}
        source="procureApprovalStatus"
        choices={PROCURE_APPROVAL_STATUS_CHOICES}
      />
    );
  }
};

export { ApprovalStatusField };
