import { Labeled, SelectField } from "react-admin";
import { PROC_ENTITY_RECORD_STATUS_CHOICES } from "../../constants";

const RecordStatusField = ({
  label = "common.fields.status",
  useLabel = false,
}: {
  label?: string;
  useLabel?: boolean;
}) => {
  if (useLabel) {
    return (
      <Labeled id="procureStatus" source="procureStatus" label="common.fields.status">
        <SelectField
          label={label}
          source="procureStatus"
          choices={PROC_ENTITY_RECORD_STATUS_CHOICES}
        />
      </Labeled>
    );
  } else {
    return (
      <SelectField
        label={label}
        source="procureStatus"
        choices={PROC_ENTITY_RECORD_STATUS_CHOICES}
      />
    );
  }
};

export { RecordStatusField };
