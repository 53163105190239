import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  setSidebarVisibility,
  ListProps,
  ReferenceField,
  TextField,
  DateField,
  NumberField,
  useListParams,
  BooleanField,
} from "react-admin";
import { BidListFilters } from "./components";
import {
  CustomizableDatagrid,
  CustomizableList,
} from "../../lib/components";
import { BidListSideFilters } from "./components/BidListSideFilters";
import { DEFAULT_PER_PAGE } from "../../lib/common";
import { RESOURCE_NAME_ENQUIRY } from "../enquiries";
import { COMMON_DATE_TIME_FORMAT } from "../../lib/core";
import { BidListRowActionButtons } from "./components/BidListRowActionButtons";
import { ApprovalStatusField, CustomerNameField, FILTER_DIRECTION, getProcEntityRecordStatusRowStyle, RecordStatusField, VendorNameField } from "../../common";
import { bidResource } from ".";

const BidList= (props:ListProps) => {
  const dispatch = useDispatch();
  const [listParams] = useListParams({
    resource: bidResource.name,
    syncWithLocation: true,
  });

  const { filterValues } = listParams;
  const isDirectionReceived = filterValues["direction"] === FILTER_DIRECTION.RECEIVED;
  
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  let defaultColumns: Array<string> = [];

  if(isDirectionReceived){
    defaultColumns.push("vendorAccountId");
    defaultColumns.push("status");
    defaultColumns.push("procureApprovalStatus");
  }else{
    defaultColumns.push("procureAccountId");
  }
  defaultColumns = defaultColumns.concat(["enquiryId", "estimatedDeliveryDate", "updatedAt"]);

  return (
    <CustomizableList
      {...props}
      filterDefaultValues={{ direction: FILTER_DIRECTION.SENT }}
      sort={{ field: "id", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      aside={<BidListSideFilters />}
      filters={<BidListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid rowStyle={getProcEntityRecordStatusRowStyle} optimized>
        {!isDirectionReceived && <CustomerNameField
          useLabel={false}
          source = "procureAccountId"
          label = "common.fields.customerName"
        />}
        {isDirectionReceived && <VendorNameField 
          useLabel={false} 
          source = "vendorAccountId"
          label = "common.fields.vendorName"
       />}

        <ReferenceField source="enquiryId" reference={RESOURCE_NAME_ENQUIRY}>
          <TextField source="displayLabel" />
        </ReferenceField>

        <DateField
          source="estimatedDeliveryDate"
          showTime
          options={COMMON_DATE_TIME_FORMAT}
        />
        <NumberField source="totalAmount" />
        <NumberField source="totalAmount2" />
        { isDirectionReceived && <RecordStatusField label="common.fields.status" />}
        { isDirectionReceived && <ApprovalStatusField label="common.fields.approvalStatus" /> }
        { isDirectionReceived && <BooleanField source = "canLevel1Approve"/> }
        { isDirectionReceived && <BooleanField source = "canLevel2Approve"/> }
        <DateField 
          source="createdAt" 
          showTime
          options={COMMON_DATE_TIME_FORMAT}
        />
        <DateField
          source="updatedAt"
          showTime
          options={COMMON_DATE_TIME_FORMAT}
        />
        <BidListRowActionButtons />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

export { BidList };
