import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  BooleanInput,
} from "react-admin";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts/accounts/constants";
import { isUniqueField } from "../../../lib/components/validations";
import { RESOURCE_NAME_CURRENCIES } from "./constants";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  symbol: { display: "block" },
  description: { display: "block" },
  account: { display: "block" },
};

const useStyles = makeStyles(styles);

const CurrencyForm = ({ isEdit, ...rest }) => {
  const classes = useStyles(rest);
  return (
    <SimpleForm warnWhenUnsavedChanges {...rest} variant="filled">
      <TextInput
        autoFocus
        source="name"
        label="resources.currencies.fields.name"
        helperText="resources.currencies.help.name"
        formClassName={classes.name}
        validate={[required(),
        isUniqueField(
          RESOURCE_NAME_CURRENCIES,
          "name",
          isEdit,
          "resources.currencies.validations.isUniqueField"
        )
        ]}

      />
      <TextInput
        source="symbol"
        label="resources.currencies.fields.symbol"
        helperText="resources.currencies.help.symbol"
        formClassName={classes.symbol}
        validate={[required()]}
      />
      <TextInput
        source="description"
        label="resources.currencies.fields.description"
        helperText="resources.currencies.help.description"
        formClassName={classes.description}
        resettable
        multiline
      />
      <ReferenceInput
        label="resources.currencies.fields.accountId"
        source="accountId"
        reference={RESOURCE_NAME_ACCOUNT}
        formClassName={classes.account}
      >
        <SelectInput
          optionText="displayLabel"
          helperText="resources.currencies.help.accountId"
        />
      </ReferenceInput>

    </SimpleForm>
  );
};
export { CurrencyForm };
