import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

import { UserList } from './UserList';
import { UserCreate } from './UserCreate';
import { UserEdit } from './UserEdit';
import { UserShow } from './UserShow';
import { RESOURCE_NAME_USER, PermissionUsers } from './constants';

const allowedPermissions = {
    menu: PermissionUsers.USER_MENU,
    list: PermissionUsers.USER_LIST,
    create: PermissionUsers.USER_CREATE,
    update: PermissionUsers.USER_UPDATE,
    show: PermissionUsers.USER_SHOW,
    delete: PermissionUsers.USER_DELETE,
};

const usersResource = {
    name: RESOURCE_NAME_USER,
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    show: UserShow,
    icon: PeopleAltIcon,
    options: { 
        label: 'resources.users.name',
        allowedPermissions 
    },
}

export * from './constants';
export { usersResource };