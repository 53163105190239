import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useFormStyles = makeStyles((theme) =>
  createStyles({
    generalTab: {
      display: "grid",
      gap: 10,
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
      "itemDetailsLabel itemDetailsLabel"
      "number number"
      "name name"
      "description description"
      "parentId itemSegmentId"
      "itemCategoryId itemGroupId"
      "uomId size"
      "specifications specifications"
      "weight weightUomId"
      "volume volumeUomId"
    `,
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
      "itemDetailsLabel"
      "number"
      "name"
      "description"
      "parentId"
      "itemSegmentId"
      "itemCategoryId"
      "itemGroupId"
      "uomId"
      "size"
      "specifications"
      "weight"
      "weightUomId"
      "volume"
      "volumeUomId"
    `,
      },
      "& .ra-input-itemDetailsLabel": {
        gridArea: "itemDetailsLabel",
      },
      "& .ra-input-number": {
        gridArea: "number",
      },

      "& .ra-input-name": {
        gridArea: "name",
      },
      "& .ra-input-description": {
        gridArea: "description",
      },
      "& .ra-input-parentId": {
        gridArea: "parentId",
      },
      "& .ra-input-itemSegmentId": {
        gridArea: "itemSegmentId",
      },
      "& .ra-input-itemCategoryId": {
        gridArea: "itemCategoryId",
      },
      "& .ra-input-itemGroupId": {
        gridArea: "itemGroupId",
      },

      "& .ra-input-uomId": {
        gridArea: "uomId",
      },
      "& .ra-input-specifications": {
        gridArea: "specifications",
      },
      "& .ra-input-size": {
        gridArea: "size",
      },
      "& .ra-input-weight": {
        gridArea: "weight",
      },
      "& .ra-input-weightUomId": {
        gridArea: "weightUomId",
      },
      "& .ra-input-volume": {
        gridArea: "volume",
      },
      "& .ra-input-volumeUomId": {
        gridArea: "volumeUomId",
      },
    },
    procurementTab: {
      display: "grid",
      gap: 10,
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
        "procurementDetailsLabel procurementDetailsLabel"
        "purchaseGroupId ."
        "leadTimeInDays storageLocationId"
        "abcIndicator ."
        "moq moqUomId"
        "safetyStock safetyStockUomId"
        "description createdAt"
        "updatedAt version"
      `,
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
        "procurementDetailsLabel"
        "purchaseGroupId"
        "leadTimeInDays"
        "storageLocationId"
        "abcIndicator"
        "moq"
        "moqUomId
        "safetyStock"
        "safetyStockUomId"
        `,
      },
      "& .ra-input-procurementDetailsLabel": {
        gridArea: "procurementDetailsLabel",
      },
      "& .ra-input-purchaseGroupId": {
        gridArea: "purchaseGroupId",
      },
      "& .ra-input-leadTimeInDays": {
        gridArea: "leadTimeInDays",
      },

      "& .ra-input-storageLocationId": {
        gridArea: "storageLocationId",
      },

      "& .ra-input-abcIndicator": {
        gridArea: "abcIndicator",
      },

      "& .ra-input-moq": {
        gridArea: "moq",
      },

      "& .ra-input-moqUomId": {
        gridArea: "moqUomId",
      },

      "& .ra-input-safetyStock": {
        gridArea: "safetyStock",
      },

      "& .ra-input-safetyStockUomId": {
        gridArea: "safetyStockUomId",
      },
      "& .ra-input-description": {
        gridArea: "description",
      },
      "& .ra-input-createdAt": {
        gridArea: "createdAt",
      },
      "& .ra-input-updatedAt": {
        gridArea: "updatedAt",
      },
      "& .ra-input-version": {
        gridArea: "version",
      },
    },
  })
);
