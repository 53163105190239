import { lightGreen, indigo, grey, orange } from "@material-ui/core/colors";

export const RESOURCE_NAME_BIDS_COMPARISON = "bids-comparison";

export const COLOR_ODD_CELL = indigo[100];
export const COLOR_EVEN_CELL = grey[300];
export const COLOR_HIGHLIGHT_CELL = lightGreen[500];
export const COLOR_OPTIMAL_BACKGROUND_CELL = orange[200];
export const COLOR_OPTIMAL_FOREGROUND_CELL = lightGreen[800];

export enum PermissionBidsComparison {
  LIST_BIDS_COMPARISONS = "list-bids-comparisons",
  SHOW_BIDS_COMPARISON = "show-bids-comparison",
  CREATE_BIDS_COMPARISON = "create-bids-comparison",
  EDIT_BIDS_COMPARISON = "update-bids-comparison",
  DELETE_BIDS_COMPARISON = "delete-bids-comparison",
}
