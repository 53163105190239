import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import { UomForm } from "./UomForm";

const UomEdit=(props:EditProps) => {
  return (
    <DefaultEdit {...props} mutationMode="pessimistic">
      <UomForm />
    </DefaultEdit>
  );
};

export { UomEdit };
