import {
  ConfirmListViewActionButton,
  EMPTY_CONFIRM_HANDLER,
  ListRowActionsProps,
} from "../../../lib/components";

import SubmitIcon from "@material-ui/icons/SendTwoTone";
import ApproveIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import RejectIcon from "@material-ui/icons/ThumbDownTwoTone";
import OnHoldIcon from "@material-ui/icons/PauseCircleFilledTwoTone";
import CancelIcon from "@material-ui/icons/CancelTwoTone";
import ReopenIcon from "@material-ui/icons/ReplyAllTwoTone";

import { Fragment } from "react";
import {
  ApprovalActionCommentsForm,
  APPROVAL_ACTIONS,
  APPROVAL_ACTION_STATUS_COLORS,
  ProcEntityRecordStatus,
  ProcureApprovalStatus,
} from "../..";
import { Record, useNotify, useRefresh, useUpdate } from "react-admin";

interface ApprovalListRowActionButtonsProps extends ListRowActionsProps {}

const ApprovalListRowActionButtons = (
  props: ApprovalListRowActionButtonsProps
) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const [update] = useUpdate();

  const record: Record | undefined = props?.record;
  const resource = props?.resource;
  const translationResourceName = resource;

  const status = record?.procureStatus;
  const procureApprovalStatus = record?.procureApprovalStatus;
  const canUserAccess = record?.canAccess;
  const canUserLevel1Approve = record?.canLevel1Approve;
  const canUserLevel2Approve = record?.canLevel2Approve;

  const isStatusDraft = status === ProcEntityRecordStatus.DRAFT;

  const isStatusWaitingForApproval =
    status === ProcEntityRecordStatus.WAITING_FOR_APPROVAL;

  const isStatusInProgress = status === ProcEntityRecordStatus.IN_PROGRESS;

  const isApprovalStatusPendingSubmission =
    procureApprovalStatus === ProcureApprovalStatus.PENDING_SUBMISSION;

  const isApprovalStatusLevel1ApprovalPending =
    procureApprovalStatus === ProcureApprovalStatus.LEVEL_1_APPROVAL_PENDING;

  const isApprovalStatusLevel2ApprovalPending =
    procureApprovalStatus === ProcureApprovalStatus.LEVEL_2_APPROVAL_PENDING;

  const isApprovalStatusApprovalPending =
    isApprovalStatusLevel1ApprovalPending ||
    isApprovalStatusLevel2ApprovalPending;

  const canUserApprove = isApprovalStatusLevel1ApprovalPending
    ? canUserLevel1Approve
    : isApprovalStatusLevel2ApprovalPending
    ? canUserLevel2Approve
    : false;

  const canUserApproveAnyLevel = canUserLevel1Approve || canUserLevel2Approve;

  const isRecordStatusOnHold =
    status === ProcEntityRecordStatus.ON_HOLD &&
    procureApprovalStatus === ProcureApprovalStatus.ON_HOLD;
  const isRecordStatusRejected =
    status === ProcEntityRecordStatus.REJECTED &&
    procureApprovalStatus === ProcureApprovalStatus.REJECTED;

  //Should Show Handlers
  const shouldDisplaySubmitAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow =
      canUserAccess && isStatusDraft && isApprovalStatusPendingSubmission;

    return Promise.resolve(shouldShow);
  };

  const shouldDisplayApproveAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow =
      canUserApprove &&
      isStatusWaitingForApproval &&
      isApprovalStatusApprovalPending;

    return Promise.resolve(shouldShow);
  };

  const shouldDisplayRejectAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow =
      canUserApprove &&
      isStatusWaitingForApproval &&
      isApprovalStatusApprovalPending;

    return Promise.resolve(shouldShow);
  };

  const shouldDisplayOnHoldAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow =
      canUserApprove &&
      isStatusWaitingForApproval &&
      isApprovalStatusApprovalPending;

    return Promise.resolve(shouldShow);
  };

  const shouldDisplayCancelAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow =
      canUserApproveAnyLevel &&
      (isStatusInProgress || isRecordStatusOnHold || isRecordStatusRejected);

    return Promise.resolve(shouldShow);
  };

  const shouldDisplayReopenAction = async (
    record?: Record
  ): Promise<boolean> => {
    const shouldShow = canUserApproveAnyLevel && isRecordStatusOnHold;

    return Promise.resolve(shouldShow);
  };

  //Action Handlers

  const handleSubmitAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.SUBMIT,
      `resources.${translationResourceName}.notification.submit.success`,
      `resources.${translationResourceName}.notification.submit.failure`,
      true
    );
  };

  const handleApproveAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.APPROVE,
      `resources.${translationResourceName}.notification.approve.success`,
      `resources.${translationResourceName}.notification.approve.failure`
    );
  };

  const handleRejectAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.REJECT,
      `resources.${translationResourceName}.notification.reject.success`,
      `resources.${translationResourceName}.notification.reject.failure`,
      true
    );
  };

  const handleOnHoldAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.ON_HOLD,
      `resources.${translationResourceName}.notification.onHold.success`,
      `resources.${translationResourceName}.notification.onHold.failure`,
      true
    );
  };

  const handleCancelAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.CANCEL,
      `resources.${translationResourceName}.notification.cancel.success`,
      `resources.${translationResourceName}.notification.cancel.failure`,
      true
    );
  };

  const handleReopenAction = (record?: Record) => {
    changeStatus(
      record,
      APPROVAL_ACTIONS.REOPEN,
      `resources.${translationResourceName}.notification.reopen.success`,
      `resources.${translationResourceName}.notification.reopen.failure`,
      true
    );
  };

  const changeStatus = (
    record,
    action: APPROVAL_ACTIONS,
    notifySuccessMessage: string,
    notifyFailureMessage: string,
    hasComment?: boolean
  ) => {
    const payload = {
      action,
      version: record?.version,
      ...(hasComment &&
        record &&
        record?.comment && {
          comment: record.comment,
        }),
    };

    update(resource, record.id, payload, record, {
      undoable: false,
      onSuccess: () => {
        notify(notifySuccessMessage, {type: "success"});
        refresh(true);
      },
      onFailure: () => {
        notify(notifyFailureMessage, {type: "error"});
      },
    });
  };

  return (
    <Fragment>
      <ConfirmListViewActionButton
        {...props}
        icon={
          <SubmitIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.SUBMIT]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.submit.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.submit.single.confirm.title`,
          content: (
            <ApprovalActionCommentsForm
              confirmMessage={`resources.${translationResourceName}.actions.submit.single.confirm.content`}
              commentSubtext={`resources.${translationResourceName}.actions.submit.comment.subText`}
              commentLabel={`resources.${translationResourceName}.actions.submit.comment.label`}
              commentHelpText={`resources.${translationResourceName}.actions.submit.comment.helpText`}
              record={record}
            />
          ),
          confirm: `resources.${translationResourceName}.actions.submit.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.submit.single.confirm.cancel`,
          confirmColor: "primary",
          ConfirmIcon: SubmitIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleSubmitAction}
        shouldDisplay={shouldDisplaySubmitAction}
      />

      <ConfirmListViewActionButton
        {...props}
        icon={
          <ApproveIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.APPROVE]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.approve.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.approve.single.confirm.title`,
          content: `resources.${translationResourceName}.actions.approve.single.confirm.content`,
          confirm: `resources.${translationResourceName}.actions.approve.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.approve.single.confirm.cancel`,
          confirmColor: "primary",
          ConfirmIcon: ApproveIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleApproveAction}
        shouldDisplay={shouldDisplayApproveAction}
      />

      <ConfirmListViewActionButton
        {...props}
        icon={
          <RejectIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.REJECT]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.reject.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.reject.single.confirm.title`,
          content: (
            <ApprovalActionCommentsForm
              confirmMessage={`resources.${translationResourceName}.actions.reject.single.confirm.content`}
              commentSubtext={`resources.${translationResourceName}.actions.reject.comment.subText`}
              commentLabel={`resources.${translationResourceName}.actions.reject.comment.label`}
              commentHelpText={`resources.${translationResourceName}.actions.reject.comment.helpText`}
              record={record}
            />
          ),
          confirm: `resources.${translationResourceName}.actions.reject.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.reject.single.confirm.cancel`,
          confirmColor: "warning",
          ConfirmIcon: RejectIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleRejectAction}
        shouldDisplay={shouldDisplayRejectAction}
      />

      <ConfirmListViewActionButton
        {...props}
        icon={
          <OnHoldIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.ON_HOLD]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.onHold.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.onHold.single.confirm.title`,
          content: (
            <ApprovalActionCommentsForm
              confirmMessage={`resources.${translationResourceName}.actions.onHold.single.confirm.content`}
              commentSubtext={`resources.${translationResourceName}.actions.onHold.comment.subText`}
              commentLabel={`resources.${translationResourceName}.actions.onHold.comment.label`}
              commentHelpText={`resources.${translationResourceName}.actions.onHold.comment.helpText`}
              record={record}
            />
          ),
          confirm: `resources.${translationResourceName}.actions.onHold.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.onHold.single.confirm.cancel`,
          confirmColor: "warning",
          ConfirmIcon: OnHoldIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleOnHoldAction}
        shouldDisplay={shouldDisplayOnHoldAction}
      />

      <ConfirmListViewActionButton
        {...props}
        icon={
          <ReopenIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.REOPEN]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.reopen.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.reopen.single.confirm.title`,
          content: (
            <ApprovalActionCommentsForm
              confirmMessage={`resources.${translationResourceName}.actions.reopen.single.confirm.content`}
              commentSubtext={`resources.${translationResourceName}.actions.reopen.comment.subText`}
              commentLabel={`resources.${translationResourceName}.actions.reopen.comment.label`}
              commentHelpText={`resources.${translationResourceName}.actions.reopen.comment.helpText`}
              record={record}
            />
          ),
          confirm: `resources.${translationResourceName}.actions.reopen.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.reopen.single.confirm.cancel`,
          confirmColor: "primary",
          ConfirmIcon: ReopenIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleReopenAction}
        shouldDisplay={shouldDisplayReopenAction}
      />

      <ConfirmListViewActionButton
        {...props}
        icon={
          <CancelIcon
            htmlColor={APPROVAL_ACTION_STATUS_COLORS[APPROVAL_ACTIONS.CANCEL]}
          />
        }
        tooltipTitle={`resources.${translationResourceName}.actions.cancel.single.tooltip`}
        confirm={{
          title: `resources.${translationResourceName}.actions.cancel.single.confirm.title`,
          content: (
            <ApprovalActionCommentsForm
              confirmMessage={`resources.${translationResourceName}.actions.cancel.single.confirm.content`}
              commentSubtext={`resources.${translationResourceName}.actions.cancel.comment.subText`}
              commentLabel={`resources.${translationResourceName}.actions.cancel.comment.label`}
              commentHelpText={`resources.${translationResourceName}.actions.cancel.comment.helpText`}
              record={record}
            />
          ),
          confirm: `resources.${translationResourceName}.actions.cancel.single.confirm.confirm`,
          cancel: `resources.${translationResourceName}.actions.cancel.single.confirm.cancel`,
          confirmColor: "warning",
          ConfirmIcon: CancelIcon,
          onConfirm: EMPTY_CONFIRM_HANDLER,
          onClose: EMPTY_CONFIRM_HANDLER,
        }}
        onConfirmHandler={handleCancelAction}
        shouldDisplay={shouldDisplayCancelAction}
      />
    </Fragment>
  );
};

export { ApprovalListRowActionButtons };
