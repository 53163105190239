import { Identifier, RecordMap } from "react-admin";
import { Dispatch } from "react";

export interface Record {
  id: Identifier;
  [key: string]: any;
}

export interface Permission {
  id: string;
  permissionGroupId: string;
  name: string;
  description?: string;
  label?: string;
  sortOrder: number;
  version: number;
  isPermissionAssigned: boolean;
}

export interface PermissionGroup {
  id: string;
  parentId: string;
  name: string;
  description?: string;
  label?: string;
  sortOrder: number;
  version: number;
  areAllPermissionsAssigned: boolean;
  permissions: Permission[];
  children?: PermissionGroup[];
}

export interface PermissionChanges {
  added: Set<string>,
  removed: Set<string>
}

export interface PermissionManagerState {
  roleId?: string;
  isDisabled: boolean;
  allPermissions?: Permission[];
  rolePermissionIds?: string[];
  allPermissionGroups?: PermissionGroup[];
  permissionChanges: PermissionChanges;
}

export enum PermissionManagerActionTypes {
  SET_ALL_PERMISSION_GROUPS,
  TOGGLE_SINGLE_PERMISSION,
  TOGGLE_MANY_PERMISSIONS,
}

export type PermissionManagerActions =
  | {
      type: PermissionManagerActionTypes.SET_ALL_PERMISSION_GROUPS;
      payload: {
        allpermissionGroups: RecordMap<Record>;
        allPermissionGroupIds: Identifier[];
      };
    }
  | {
      type: PermissionManagerActionTypes.TOGGLE_SINGLE_PERMISSION;
      payload: {
        permission: Permission;
        isSelected: boolean;
      };
    }
  | {
      type: PermissionManagerActionTypes.TOGGLE_MANY_PERMISSIONS;
      payload: {
        permissionGroup: PermissionGroup;
        isSelected: boolean;
      };
    };    

export interface PermissionManagerContextProps {
  state?: PermissionManagerState;
  dispatch?: Dispatch<PermissionManagerActions>;
}
