import { FC, Fragment, useContext } from "react";
import {
  makeStyles,
  Paper,
  Theme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  Tooltip,
  Checkbox,
  Box,
} from "@material-ui/core";
import CompareIcon from "@material-ui/icons/Compare";
import { format } from "date-fns";
import { ClassNameMap } from "@material-ui/styles";
import {
  useTranslate,
  NumberField,
  Identifier,
  SelectInput,
  useNotify,
} from "react-admin";
import { DATE_FORMAT_STANDARD_DATE_TIME } from "../../../lib/core";
import {
  COLOR_ODD_CELL,
  COLOR_EVEN_CELL,
  COLOR_HIGHLIGHT_CELL,
  COLOR_OPTIMAL_BACKGROUND_CELL,
  COLOR_OPTIMAL_FOREGROUND_CELL,
} from "../constants";
import { TruncatedTextField } from "../../../lib/components/controls/fields/TruncatedTextField";
import { EmptyState, SectionTitleSubtitle } from "../../../lib/components";
import { BidComparisonCalculationActionTypes } from "./types";
import { BidComparisonCalculationContext } from "./context";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      width: "100%",
      marginLeft: theme.spacing(2),
    },
    tableWrapper: {
      maxHeight: 1024,
      overflowX: "auto",
    },
    table: {
      borderSpacing: 1,
    },
    firstColumn: {
      width: "30%",
    },
    vendorCell: {
      "&:nth-of-type(odd)": {
        backgroundColor: COLOR_EVEN_CELL,
      },
      "&:nth-of-type(even)": {
        backgroundColor: COLOR_ODD_CELL,
      },
    },
    oddCell: {
      backgroundColor: COLOR_ODD_CELL,
      whiteSpace: "nowrap",
    },
    evenCell: {
      backgroundColor: COLOR_EVEN_CELL,
      whiteSpace: "nowrap",
    },
    headerCell: {
      whiteSpace: "nowrap",
    },
    dataCell: {
      whiteSpace: "nowrap",
    },
    item: {
      maxWidth: 85,
    },
    normalValue: {},
    bestValue: {
      backgroundColor: COLOR_HIGHLIGHT_CELL,
      cursor: "pointer",
    },
    optimal: {
      whiteSpace: "nowrap",
      color: COLOR_OPTIMAL_FOREGROUND_CELL,
      backgroundColor: COLOR_OPTIMAL_BACKGROUND_CELL,
      fontWeight: 500,
    },
    total: {
      fontWeight: 500,
    },
    excludedDataContainer: {
      display: "flex",
      flexDirection: "column",
      width: "25vw",
      paddingTop: 5,
      paddingBottom: 50,
      marginLeft: theme.spacing(4),
    },
    excludedDataSelect: {
      paddingLeft: 5,
      paddingRight: 25,
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  })
);

const ValueCell: FC<{
  value: string;
  classes: ClassNameMap<string>;
  bestValue?: boolean;
  tooltip?: string;
  isEvenRow?: boolean;
}> = ({ value, classes, bestValue, tooltip, isEvenRow }) => {
  const valueClassName = bestValue ? classes.bestValue : classes.normalValue;
  const colorClassName = isEvenRow ? classes.evenCell : classes.oddCell;
  const className = `${valueClassName} ${colorClassName}`;

  if (bestValue && tooltip) {
    return (
      <TableCell align="center" className={className}>
        <Tooltip title={tooltip}>
          <div>{value}</div>
        </Tooltip>
      </TableCell>
    );
  } else {
    return (
      <TableCell align="center" className={className}>
        {value}
      </TableCell>
    );
  }
};

const nf = new Intl.NumberFormat();

const BidsComparisonTable = () => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { state, dispatch } = useContext(BidComparisonCalculationContext);

  const data = state?.bidsComparisonCalc;
  const showUnitPrice2 = state?.bidsComparisonCalc?.enquiry?.showUnitPrice2;
  const COLSPAN_TITLE = showUnitPrice2 ? 4 : 3;
  const COLSPAN_VENDOR = showUnitPrice2 ? 5 : 3;
  const COLSPAN_OPTIMAL_VALUES = showUnitPrice2 ? 5 : 3;

  const isLowestUnitPriceLabel = translate(
    "resources.bidsComparison.components.bidComparisonTable.tooltip.isLowestUnitPriceLabel"
  );
  const isLowestUnitPrice2Label = translate(
    "resources.bidsComparison.components.bidComparisonTable.tooltip.isLowestUnitPrice2Label"
  );

  const isQuickestDeliveryDateLabel = translate(
    "resources.bidsComparison.components.bidComparisonTable.tooltip.isQuickestDeliveryDateLabel"
  );

  const bidTotalsLabel = translate(
    "resources.bidsComparison.components.bidComparisonTable.labels.bidTotals"
  );

  const excludeItemTooltip = translate(
    "resources.bidsComparison.components.bidComparisonTable.tooltip.excludeItem"
  );
  const excludeVendorAccountTooltip = translate(
    "resources.bidsComparison.components.bidComparisonTable.tooltip.excludeVendorAccount"
  );

  const itemToggled = (itemId: Identifier, checked: boolean) => {
    if (dispatch) {
      dispatch({
        type: BidComparisonCalculationActionTypes.ITEM_TOGGLED,
        payload: {
          itemId,
          checked,
          notify,
        },
      });
    }
  };

  const vendorAccountToggled = (
    vendorAccountId: Identifier,
    checked: boolean
  ) => {
    if (dispatch) {
      dispatch({
        type: BidComparisonCalculationActionTypes.VENDOR_ACCOUNT_TOGGLED,
        payload: {
          vendorAccountId,
          checked,
          notify,
        },
      });
    }
  };

  if (!data?.summary?.length) {
    return (
      <EmptyState
        Icon={CompareIcon}
        title="resources.bidsComparison.components.bidComparisonTable.messages.empty.title"
        subTitle="resources.bidsComparison.components.bidComparisonTable.messages.empty.subTitle"
      />
    );
  }

  return (
    <Box>
      <SectionTitleSubtitle
        title="resources.bidsComparison.sections.bidComparisons.title"
        subTitle="resources.bidsComparison.sections.bidComparisons.subTitle"
      />
      <Box className="excludedDataContainer">
        {data?.excluded?.items && data.excluded.items.length > 0 && (
          <SelectInput
            source="items"
            allowEmpty={true}
            choices={data?.excluded?.items}
            record={{
              id: "",
              items: data?.excluded?.items,
            }}
            helperText="resources.bidsComparison.help.excludedItemId"
            className={classes.excludedDataSelect}
            onChange={(event) => itemToggled(event?.target?.value, false)}
          />
        )}

        {data?.excluded?.vendorAccounts &&
          data.excluded.vendorAccounts.length > 0 && (
            <SelectInput
              source="vendorAccounts"
              allowEmpty={true}
              choices={data?.excluded?.vendorAccounts}
              record={{
                id: "",
                vendorAccounts: data?.excluded?.vendorAccounts,
              }}
              helperText="resources.bidsComparison.help.excludedVendorAccountId"
              className={classes.excludedDataSelect}
              clearAlwaysVisible={true}
              onChange={(event) =>
                vendorAccountToggled(event?.target?.value, false)
              }
            />
          )}
      </Box>
      <TableContainer className={classes.tableContainer}>
        <Paper elevation={3} variant="elevation">
          <div className={classes.tableWrapper}>
            <Table
              stickyHeader
              aria-label="Bids Comparison Table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    colSpan={COLSPAN_TITLE}
                    align="right"
                    className={classes.firstColumn}
                  >
                    {translate("resources.bidsComparison.fields.vendors")}
                  </TableCell>
                  {data?.vendorAccounts?.map((vendorAccountRecord, index) => (
                    <TableCell
                      key={`vendor-${vendorAccountRecord?.id}`}
                      colSpan={COLSPAN_VENDOR}
                      align="center"
                      className={classes.vendorCell}
                    >
                      <Tooltip title={excludeVendorAccountTooltip}>
                        <div>
                          <Checkbox
                            onChange={(event, checked) =>
                              vendorAccountToggled(
                                vendorAccountRecord?.id,
                                checked
                              )
                            }
                            key={`vendor-${vendorAccountRecord?.id}`}
                          />
                          {vendorAccountRecord?.name}
                        </div>
                      </Tooltip>
                    </TableCell>
                  ))}
                  <TableCell
                    colSpan={COLSPAN_OPTIMAL_VALUES}
                    align="center"
                    className={classes.optimal}
                  >
                    {translate(
                      "resources.bidsComparison.components.bidComparisonTable.labels.optimal"
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell className={classes.item} align="left">
                    {translate("resources.bidsComparison.fields.item")}
                  </TableCell>
                  <TableCell className={classes.headerCell} align="center">
                    {translate("resources.bidsComparison.fields.quantity")}
                  </TableCell>
                  {showUnitPrice2 && (
                    <TableCell className={classes.headerCell} align="center">
                      {translate("resources.bidsComparison.fields.quantity2")}
                    </TableCell>
                  )}
                  {data?.vendorAccounts?.map((vendorAccountRecord, index) => {
                    const isEvenRow = index % 2 ? true : false;
                    const className = isEvenRow
                      ? classes.evenCell
                      : classes.oddCell;
                    return (
                      <Fragment key={`vendorHeader-${vendorAccountRecord?.id}`}>
                        <TableCell className={className} align="center">
                          {translate(
                            "resources.bidsComparison.fields.unitPrice"
                          )}
                        </TableCell>
                        <TableCell className={className} align="center">
                          {translate("resources.bidsComparison.fields.amount")}
                        </TableCell>
                        {showUnitPrice2 && (
                          <TableCell className={className} align="center">
                            {translate(
                              "resources.bidsComparison.fields.unitPrice2"
                            )}
                          </TableCell>
                        )}
                        {showUnitPrice2 && (
                          <TableCell className={className} align="center">
                            {translate(
                              "resources.bidsComparison.fields.amount2"
                            )}
                          </TableCell>
                        )}
                        <TableCell className={className} align="center">
                          {translate(
                            "resources.bidsComparison.fields.estimatedDeliveryDate"
                          )}
                        </TableCell>
                      </Fragment>
                    );
                  })}
                  <TableCell className={classes.optimal} align="center">
                    {translate("resources.bidsComparison.fields.unitPrice")}
                  </TableCell>
                  <TableCell className={classes.optimal} align="center">
                    {translate("resources.bidsComparison.fields.amount")}
                  </TableCell>
                  {showUnitPrice2 && (
                    <TableCell className={classes.optimal} align="center">
                      {translate("resources.bidsComparison.fields.unitPrice2")}
                    </TableCell>
                  )}
                  {showUnitPrice2 && (
                    <TableCell className={classes.optimal} align="center">
                      {translate("resources.bidsComparison.fields.amount2")}
                    </TableCell>
                  )}
                  <TableCell className={classes.optimal} align="center">
                    {translate(
                      "resources.bidsComparison.fields.estimatedDeliveryDate"
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.summary?.map((row, index) => {
                  const record = {
                    id: row?.header?.item?.id,
                    header: row?.header,
                    optimal: row?.optimal,
                  };
                  return (
                    <TableRow key={`itemRow-${record?.id}`}>
                      <TableCell align="center">{record?.header?.item?.sortOrder}</TableCell>
                      <TableCell align="left" className={classes.dataCell}>
                        <Tooltip title={excludeItemTooltip}>
                          <div>
                            <Checkbox
                              onChange={(event, checked) =>
                                itemToggled(record?.id, checked)
                              }
                            />
                            <TruncatedTextField
                              truncateLength={50}
                              source="header.item.name"
                              record={record}
                            />
                          </div>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={classes.dataCell} align="center">
                        <NumberField source="header.quantity" record={record} />
                        &nbsp; {row?.header?.uom?.name}
                      </TableCell>
                      {showUnitPrice2 && (
                        <TableCell className={classes.dataCell} align="center">
                          <NumberField
                            source="header.quantity2"
                            record={record}
                          />
                          &nbsp; {row?.header?.uom2?.name}
                        </TableCell>
                      )}
                      {data?.bidIds?.map((bidId, index) => {
                        const matchingBidRows = row?.bids?.filter(
                          (bid) => bid.bidId === bidId
                        );
                        if (matchingBidRows?.length) {
                          const bid = matchingBidRows[0];
                          const detail = bid?.detail;
                          const isEvenRow = index % 2 ? true : false;
                          return (
                            <Fragment key={`itemsData-${bid?.bidId}`}>
                              <ValueCell
                                value={nf.format(detail.unitPrice)}
                                bestValue={detail.isLowestUnitPrice}
                                classes={classes}
                                tooltip={isLowestUnitPriceLabel}
                                isEvenRow={isEvenRow}
                              />
                              <ValueCell
                                value={nf.format(detail.amount)}
                                classes={classes}
                                isEvenRow={isEvenRow}
                              />
                              {showUnitPrice2 && (
                                <ValueCell
                                  value={
                                    detail.unitPrice2
                                      ? nf.format(detail.unitPrice2)
                                      : ""
                                  }
                                  bestValue={detail.isLowestUnitPrice2}
                                  classes={classes}
                                  tooltip={isLowestUnitPrice2Label}
                                  isEvenRow={isEvenRow}
                                />
                              )}
                              {showUnitPrice2 && (
                                <ValueCell
                                  value={
                                    detail.amount2
                                      ? nf.format(detail.amount2)
                                      : ""
                                  }
                                  classes={classes}
                                  isEvenRow={isEvenRow}
                                />
                              )}

                              <ValueCell
                                value={
                                  detail.estimatedDeliveryDate
                                    ? format(
                                        new Date(detail.estimatedDeliveryDate),
                                        DATE_FORMAT_STANDARD_DATE_TIME
                                      )
                                    : ""
                                }
                                bestValue={detail.isQuickestDeliveryDate}
                                classes={classes}
                                tooltip={isQuickestDeliveryDateLabel}
                                isEvenRow={isEvenRow}
                              />
                            </Fragment>
                          );
                        } else {
                          return null;
                        }
                      })}
                      <TableCell className={classes.optimal} align="center">
                        <NumberField
                          source="optimal.lowestUnitPrice.price"
                          record={record}
                        />
                      </TableCell>
                      <TableCell className={classes.optimal} align="center">
                        <NumberField
                          source="optimal.lowestUnitPrice.amount"
                          record={record}
                        />
                      </TableCell>
                      {showUnitPrice2 && (
                        <TableCell className={classes.optimal} align="center">
                          <NumberField
                            source="optimal.lowestUnitPrice2.price"
                            record={record}
                          />
                        </TableCell>
                      )}
                      {showUnitPrice2 && (
                        <TableCell className={classes.optimal} align="center">
                          <NumberField
                            source="optimal.lowestUnitPrice2.amount"
                            record={record}
                          />
                        </TableCell>
                      )}
                      <TableCell className={classes.optimal} align="center">
                        {row?.optimal?.quickestDeliveryDate
                          ? format(
                              new Date(row?.optimal?.quickestDeliveryDate),
                              DATE_FORMAT_STANDARD_DATE_TIME
                            )
                          : ""}
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell colSpan={COLSPAN_TITLE} align="right">
                    <b>{bidTotalsLabel}</b>
                  </TableCell>
                  {data?.bidIds?.map((bidId, index) => {
                    const matchingBidRows = data?.totals?.bid?.filter(
                      (bid) => bid.bidId === bidId
                    );
                    if (matchingBidRows?.length === 0) {
                      return null;
                    }

                    const bid = matchingBidRows[0];
                    const record = {
                      id: bid.bidId,
                      totalAmount: bid?.bidAmounts?.totalAmount,
                      totalAmount2: bid?.bidAmounts?.totalAmount2,
                    };

                    return (
                      <Fragment key={`bid-totals-${bid?.bidId}`}>
                        <TableCell>&nbsp;</TableCell>
                        <TableCell align="center">
                          {
                            <NumberField
                              source="totalAmount"
                              record={record}
                              className={classes.total}
                            />
                          }
                        </TableCell>
                        <TableCell>&nbsp;</TableCell>
                        {showUnitPrice2 && (
                          <TableCell align="center">
                            {
                              <NumberField
                                source="totalAmount2"
                                record={record}
                                className={classes.total}
                              />
                            }
                          </TableCell>
                        )}
                        <TableCell>&nbsp;</TableCell>
                      </Fragment>
                    );
                  })}

                  {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                  <TableCell align="center" className={classes.optimal}>
                    {
                      <NumberField
                        source="amount"
                        record={{
                          id: data.enquiry.id,
                          amount: data?.totals?.optimal?.bid?.amount,
                        }}
                        className={classes.total}
                      />
                    }
                  </TableCell>
                  {showUnitPrice2 && <TableCell>&nbsp;</TableCell>}
                  {showUnitPrice2 && (
                    <TableCell align="center" className={classes.optimal}>
                      {
                        <NumberField
                          source="amount2"
                          record={{
                            id: data.enquiry.id,
                            amount2: data?.totals?.optimal?.bid?.amount2,
                          }}
                          className={classes.total}
                        />
                      }
                    </TableCell>
                  )}
                  <TableCell>&nbsp;</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </Paper>
      </TableContainer>
    </Box>
  );
};

export { BidsComparisonTable };
