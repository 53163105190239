import { HttpError, Record, useNotify } from "react-admin";
import { MediaFile, MediaFileSource } from "../constants";
import { S3FileUploader } from "./S3FileUploader";

export const transformAndUploadS3Files =
  (mediaFileSources: MediaFileSource[]) =>
  async (record: Record): Promise<Record> => {
    let modifiedRecord = record;
    try {
      for await (const mediaFileSource of mediaFileSources) {
        const { source } = mediaFileSource;
        const { [source]: files } = record;
        if (files) {
          const s3FileUploader = new S3FileUploader(mediaFileSource);
          const mediaFiles: MediaFile[] = await s3FileUploader.startUpload(
            files
          );
          modifiedRecord[source] = mediaFiles;
        }
      }
    } catch (error) {
      //@ts-ignore
      const errorMessage = error?.message?.join("\n") ?? "unknown";
      //@ts-ignore
      const errorStatus = error?.status || 400;
      //@ts-ignore
      const errorbody = error?.body;
      return Promise.reject(new HttpError(errorMessage, errorStatus, errorbody));
      const notificationMessage = "Some files were not uploaded, please check and try to re-upload those files again."
    }
    return modifiedRecord;
  };
