import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ContentCreate from '@material-ui/icons/Create';
//import Button from '@material-ui/core/Button';
import { Button, useTranslate } from 'react-admin';
import { styles } from './style';

const useStyles = makeStyles(styles);

interface ProfileActionsProps {
  href: string;
  buttonTitle: string
}

const stopPropagation = e => e.stopPropagation();

const ProfileActions= ({ href, buttonTitle }:ProfileActionsProps) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <div className={classes.actionButton}>
      <Button 
        component={Link}
        color="primary" 
        variant="contained"
        onClick={stopPropagation}
        to={href} 
        label={translate(buttonTitle)}
      >
        <ContentCreate />
      </Button>
    </div>
  );
};

export default ProfileActions;
