import { Children, cloneElement, useContext, useEffect } from "react";
import { Datagrid, DatagridProps, Loading } from "react-admin";
import get from "lodash/get";
import { CustomizableListContext } from "./CustomizableListContext";
import { CustomizableListActionTypes } from "./types";

const CustomizableDatagrid= (props:DatagridProps) => {
  const { state, dispatch } = useContext(CustomizableListContext);
  const children = props?.children;
  const fieldsList = state?.fieldsList;

  useEffect(() => {
    if (dispatch) {
      dispatch({
        type: CustomizableListActionTypes.INIT_DATAGRID,
        payload: { fieldsList: children },
      });
    }
  }, [children, dispatch]);

  const renderChild = (child) => {
    const key = get(child, ["props", "source"]);
    const dataisdisplayed = get(child, ["props", "dataisdisplayed"]);
    if (!key || dataisdisplayed) {
      return cloneElement(child, {});
    }

    return null;
  };

  if (fieldsList) {
    return (
      <Datagrid {...props}>{Children.map(fieldsList, renderChild)}</Datagrid>    );
  } else {
    return <Loading/>
  }
};

CustomizableDatagrid.displayName = "CustomizableDatagrid";

export { CustomizableDatagrid };
