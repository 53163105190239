export const RESOURCE_NAME_PERMISSION_GROUP = "permissionGroups";

export enum PermissionPermissionGroups {
  MENU_PERMISSION_GROUP = "view-permission-groups-management-menu",
  LIST_PERMISSION_GROUPS = "list-permission-groups",
  SHOW_PERMISSION_GROUP = "show-permission-group",
  CREATE_PERMISSION_GROUP = "create-permission-group",
  EDIT_PERMISSION_GROUP = "update-permission-group",
  DELETE_PERMISSION_GROUP = "delete-permission-group",
}
