import {
  GatedSubMenu,
  GatedMenuItemLink,
  MenuProps,
} from "../../components/menu";
import { accountResource } from "./accounts";
import { permissionGroupResource } from "./permission-groups";
import { permissionResource } from "./permissions";
import { roleResource } from "./roles";
import { usersResource } from "./users";

const AccountsMenu= ({ isSidebarOpen, onMenuClick, dense }:MenuProps) => {
  return (
    <GatedSubMenu
      name={`resources.accounts.name`}
      icon={<accountResource.icon />}
      isSidebarOpen={isSidebarOpen}
      dense={dense}
      allowedPermissionName="view-access-management-menu"
    >
      <GatedMenuItemLink
        to={`/${accountResource.name}`}
        name="resources.accounts.name"
        leftIcon={<accountResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={accountResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${permissionResource.name}`}
        name="resources.permissions.name"
        leftIcon={<permissionResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          permissionResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${permissionGroupResource.name}`}
        name="resources.permissionGroups.name"
        leftIcon={<permissionGroupResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={
          permissionGroupResource.options.allowedPermissions.menu
        }
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${roleResource.name}`}
        name="resources.roles.name"
        leftIcon={<roleResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={roleResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />

      <GatedMenuItemLink
        to={`/${usersResource.name}`}
        name="resources.users.name"
        leftIcon={<usersResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={usersResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
    </GatedSubMenu>
  );
};

export { AccountsMenu };
