import { Identifier, Record } from "react-admin";

export const RESOURCE_NAME_BID = "bids";

export enum PermissionBids {
   MENU_BIDS ='view-bids-menu', 
   LIST_BIDS = 'list-bids',
   SHOW_OWN_BID = 'show-own-bid',
   SHOW_ANY_BID = 'show-any-bid',
   CREATE_BID = 'create-bid',
   EDIT_ANY_BID = 'update-any-bid',
   EDIT_OWN_BID = 'update-own-bid',
   DELETE_OWN_BID = 'delete-own-bid',
   DELETE_ANY_BID = 'delete-any-bid',
   IMPORT_BIDS = 'import-bids',
}

export interface BidRecord extends Partial<Record> {
  enquiryId: Identifier;
  userAccountId: Identifier;
  vendorAccountId: Identifier;
  vendorId: Identifier;
  estimatedDeliveryDate: Date;
  currencyId: string;
  currency2Id?: string;
  remarks?: string;
  status: number;
  bidDetails: BidDetailRecord[];
}

export interface BidDetailRecord extends Partial<Record> {
  requisitionId: Identifier;
  requisitionDetailId: Identifier;
  enquiryId: Identifier;
  enquiryDetailId: Identifier;
  bidId?: Identifier;
  itemId: Identifier;
  quantity: number;
  uomId: Identifier;
  unitPrice?: number;
  currencyId?: Identifier;
  quantity2?: number;
  uom2Id?: Identifier;
  unitPrice2?: number;
  currency2Id?: Identifier;
  sortOrder?: number;
  make?: string;
  size?: string;
  specifications?: string;
  comments?: string;
}

