import NoteIcon from '@material-ui/icons/Note';
import { ItemCreate } from './ItemCreate';

import { ItemList } from './ItemList';
import { ItemEdit } from './ItemEdit';
import { PermissionItems, RESOURCE_NAME_ITEM } from './constants';
import { ItemShow } from './ItemShow';

const itemsPermissions = {
    menu: PermissionItems.MENU_ITEMS,
    list: PermissionItems.LIST_ITEMS,
    create: PermissionItems.CREATE_ITEM,
    update: PermissionItems.EDIT_ITEM,
    show: PermissionItems.SHOW_ITEM,
    delete: PermissionItems.DELETE_ITEM,
    import: PermissionItems.IMPORT_ITEMS,
};
const itemResource = {
    name: RESOURCE_NAME_ITEM,
    list: ItemList,
    create: ItemCreate,
    edit: ItemEdit,
    show: ItemShow, 
    icon: NoteIcon,
    options: {label: 'resources.items.name', allowedPermissions: itemsPermissions },
}

export * from './constants';
export * from './components';
export { itemsPermissions, itemResource }