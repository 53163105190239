import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { PermissionEnquiries, RESOURCE_NAME_ENQUIRY } from './constants';
import { EnquiryCreate } from './EnquiryCreate';
import { EnquiryEdit } from './EnquiryEdit';
import { EnquiryList } from './EnquiryList';
import { EnquiryShow } from './EnquiryShow';


const enquiriesPermissions = {
    menu: PermissionEnquiries.MENU_ENQUIRIES,
    list: PermissionEnquiries.LIST_ENQUIRIES,
    create: PermissionEnquiries.CREATE_ENQUIRY,
    update: [
        PermissionEnquiries.EDIT_OWN_ENQUIRY,
        PermissionEnquiries.EDIT_ANY_ENQUIRY,
    ],
    show: [
        PermissionEnquiries.SHOW_OWN_ENQUIRY,
        PermissionEnquiries.SHOW_ANY_ENQUIRY
    ],
    delete: [
        PermissionEnquiries.DELETE_OWN_ENQUIRY,
        PermissionEnquiries.DELETE_ANY_ENQUIRY,
    ],
    import: PermissionEnquiries.IMPORT_ENQUIRIES,
};
const enquiryResource = {
    name: RESOURCE_NAME_ENQUIRY,
    list: EnquiryList,
    create: EnquiryCreate,
    edit: EnquiryEdit,
    show: EnquiryShow, 
    icon: ContactSupportIcon,
    options: {label: 'resources.enquiries.name', allowedPermissions: enquiriesPermissions },
}

export * from './constants';
export { enquiriesPermissions, enquiryResource }