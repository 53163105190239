import {
  TextInput,
  required,
  ReferenceInput,
  AutocompleteInput,
  TabbedForm,
  FormTab,
  number,
  RadioButtonGroupInput,
  Create,
  NumberInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";

import { ABC_INDICATOR_CHOICES, RESOURCE_NAME_ITEM } from "./constants";
import { RESOURCE_NAME_ITEM_SEGMENTS } from "../itemsSegment";
import {
  MediaInputField,
  SectionTitleSubtitle,
} from "../../../../lib/components";
import {
  getTabbedFormProps,
  MAX_RECORDS_PER_PAGE,
} from "../../../../lib/common";
import { RESOURCE_NAME_LOCATION } from "../../locations/constants";
import { CategoryTypes, RESOURCE_NAME_CATEGORY } from "../../category";
import { RESOURCE_NAME_UOM } from "../../unit-of-measure";
import { useFormStyles } from "./styles";
import { RESOURCE_NAME_ITEM_GROUPS } from "../itemsGroup/constants";

const validateNumber = [number()];

const ItemForm = ({ isEdit, ...rest }) => {
  const classes = useFormStyles();

  return (
    <Create {...rest}>
      <TabbedForm
        {...getTabbedFormProps(rest)}
        warnWhenUnsavedChanges
        {...rest}
      >
        <FormTab
          label="resources.items.tabs.general"
          contentClassName={classes.generalTab}
        >
          <SectionTitleSubtitle
            title="resources.items.fieldGroups.itemDetails"
            formClassName="ra-input-itemDetailsLabel"
          />

          <TextInput
            autoFocus
            source="number"
            label="resources.items.fields.number"
            helperText="resources.items.help.number"
            fullWidth
            isRequired
            validate={[required()]}
          />

          <TextInput
            source="name"
            label="resources.items.fields.name"
            helperText="resources.items.help.name"
            fullWidth
            isRequired
            validate={[required()]}
          />

          <RichTextInput
            source="description"
            label="resources.items.fields.description"
            helperText="resources.items.help.description"
          />

          <ReferenceInput
            source="parentId"
            label="resources.items.fields.parentId"
            fullWidth
            reference={RESOURCE_NAME_ITEM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            filter={isEdit ? { excludeIds: [rest?.id] } : {}}
            key="parentId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.parentId"
            />
          </ReferenceInput>

          <ReferenceInput
            source="itemSegmentId"
            label="resources.items.fields.itemSegmentId"
            fullWidth
            reference={RESOURCE_NAME_ITEM_SEGMENTS}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="itemSegmentId"
          >
            <AutocompleteInput
              optionText="name"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.itemSegmentId"
            />
          </ReferenceInput>

          <ReferenceInput
            label="resources.items.fields.itemCategoryId"
            source="itemCategoryId"
            reference={RESOURCE_NAME_CATEGORY}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            filter={{ categoryType: CategoryTypes.ITEM }}
            key="itemCategoryId"
            fullWidth
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.itemCategoryId"
            />
          </ReferenceInput>
          <ReferenceInput
            label="resources.items.fields.itemGroupId"
            source="itemGroupId"
            reference={RESOURCE_NAME_ITEM_GROUPS}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "number", order: "ASC" }}
            key="itemGroupId"
            fullWidth
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.itemGroupId"
            />
          </ReferenceInput>

          <ReferenceInput
            source="uomId"
            label="resources.items.fields.uomId"
            reference={RESOURCE_NAME_UOM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="uomId"
            fullWidth
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.uomId"
            />
          </ReferenceInput>

          <RichTextInput
            source="specifications"
            label="resources.items.fields.specifications"
            helperText="resources.items.help.specifications"
          />

          <TextInput
            source="size"
            label="resources.items.fields.size"
            helperText="resources.items.help.size"
            validate={validateNumber}
            fullWidth
          />

          <NumberInput
            source="weight"
            label="resources.items.fields.weight"
            helperText="resources.items.help.weight"
            fullWidth
          />
          <ReferenceInput
            source="weightUomId"
            label="resources.items.fields.weightUomId"
            reference={RESOURCE_NAME_UOM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="weightuomId"
            fullWidth
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.weightUomId"
            />
          </ReferenceInput>

          <NumberInput
            source="volume"
            label="resources.items.fields.volume"
            helperText="resources.items.help.volume"
            validate={validateNumber}
            fullWidth
          />
          <ReferenceInput
            source="volumeUomId"
            label="resources.items.fields.volumeUomId"
            fullWidth
            reference={RESOURCE_NAME_UOM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="volumeuomId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.volumeUomId"
            />
          </ReferenceInput>
        </FormTab>
        <FormTab
          label="resources.items.tabs.procurement"
          contentClassName={classes.procurementTab}
        >
          <SectionTitleSubtitle
            title="resources.items.fieldGroups.procurementDetails"
            formClassName="ra-input-procurementDetailsLabel"
          />
          <NumberInput
            source="leadTimeInDays"
            label="resources.items.fields.leadTimeInDays"
            helperText="resources.items.help.leadTimeInDays"
            validate={validateNumber}
            fullWidth
          />
          <ReferenceInput
            source="storageLocationId"
            label="resources.items.fields.storageLocation"
            reference={RESOURCE_NAME_LOCATION}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "number", order: "ASC" }}
            key="storageLocationId"
            fullWidth
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={true}
              helperText="resources.items.help.storageLocation"
            />
          </ReferenceInput>

          <RadioButtonGroupInput
            source="abcIndicator"
            label="resources.items.fields.abcIndicator"
            choices={ABC_INDICATOR_CHOICES}
          />

          <NumberInput
            source="moq"
            label="resources.items.fields.moq"
            helperText="resources.items.help.moq"
            validate={validateNumber}
            fullWidth
          />
          <ReferenceInput
            source="moqUomId"
            label="resources.items.fields.moqUomId"
            fullWidth
            reference={RESOURCE_NAME_UOM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="moquomId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.moqUomId"
            />
          </ReferenceInput>

          <NumberInput
            source="safetyStock"
            label="resources.items.fields.safetyStock"
            helperText="resources.items.help.safetyStock"
            validate={validateNumber}
            fullWidth
          />

          <ReferenceInput
            source="safetyStockUomId"
            label="resources.items.fields.safetyStockUomId"
            fullWidth
            reference={RESOURCE_NAME_UOM}
            perPage={MAX_RECORDS_PER_PAGE}
            sort={{ field: "name", order: "ASC" }}
            key="safetyStockUomId"
          >
            <AutocompleteInput
              optionText="displayLabel"
              resettable={true}
              allowEmpty={false}
              helperText="resources.items.help.sfUomId"
            />
          </ReferenceInput>
        </FormTab>

        <FormTab label="resources.items.tabs.attachments">
          <MediaInputField showUploadedFiles={isEdit} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export { ItemForm };
