import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../../lib/components/crud/DefaultCreate";
import { OrgUserGroupForm } from "./OrgUserGroupForm";

const OrgUserGroupCreate = (props: CreateProps) => {
  return (
    <DefaultCreate {...props}>
      < OrgUserGroupForm />
    </DefaultCreate>
  );
};

export { OrgUserGroupCreate };