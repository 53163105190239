import { StatusItemProps } from "../lib/common/ui/filters/StatusItem";

//Filters

export enum FILTER_DIRECTION {
  SENT = 'sent',
  RECEIVED = 'received',
}

export const FILTER_NAME_DIRECTION = 'direction';

export enum PermissionBehavior {
  CAN_SELL = "can-sell",
  CAN_PROCURE = "can-procure",
}

//Status
export const enum ProcEntityRecordStatus {
  DRAFT = 1,
  WAITING_FOR_APPROVAL,
  APPROVED,
  REJECTED,
  IN_PROGRESS,
  COMPLETED,
  ON_HOLD,
  CANCELLED,
}

export const enum ProcureApprovalStatus {
  PENDING_SUBMISSION = 1,
  LEVEL_1_APPROVAL_PENDING,
  LEVEL_2_APPROVAL_PENDING,
  APPROVED,
  REJECTED,
  ON_HOLD,
}

export enum APPROVAL_ACTIONS {
  SUBMIT = "submit",
  APPROVE = "approve",
  REJECT = "reject",
  ON_HOLD = "onHold",
  CANCEL = "cancel",
  REOPEN = "reopen",
}

export const PROC_ENTITY_RECORD_STATUS_CHOICES = [
  { id: ProcEntityRecordStatus.DRAFT, name: "common.choices.procEntityRecordStatus.draft" },
  { id: ProcEntityRecordStatus.WAITING_FOR_APPROVAL, name: "common.choices.procEntityRecordStatus.waitingForApproval" },
  { id: ProcEntityRecordStatus.APPROVED, name: "common.choices.procEntityRecordStatus.approved" },
  { id: ProcEntityRecordStatus.REJECTED, name: "common.choices.procEntityRecordStatus.rejected" },
  { id: ProcEntityRecordStatus.IN_PROGRESS, name: "common.choices.procEntityRecordStatus.inProgress" },
  { id: ProcEntityRecordStatus.COMPLETED, name: "common.choices.procEntityRecordStatus.completed" },
  { id: ProcEntityRecordStatus.ON_HOLD, name: "common.choices.procEntityRecordStatus.onHold" },
  { id: ProcEntityRecordStatus.CANCELLED, name: "common.choices.procEntityRecordStatus.cancelled" },
];

export const PROCURE_APPROVAL_STATUS_CHOICES = [
  { id: ProcureApprovalStatus.PENDING_SUBMISSION, name: "common.choices.procureApprovalStatus.pendingSubmission" },
  { id: ProcureApprovalStatus.LEVEL_1_APPROVAL_PENDING, name: "common.choices.procureApprovalStatus.level1ApprovalPending" },
  { id: ProcureApprovalStatus.LEVEL_2_APPROVAL_PENDING, name: "common.choices.procureApprovalStatus.level2ApprovalPending" },
  { id: ProcureApprovalStatus.APPROVED, name: "common.choices.procureApprovalStatus.approved" },
  { id: ProcureApprovalStatus.REJECTED, name: "common.choices.procureApprovalStatus.rejected" },
  { id: ProcureApprovalStatus.ON_HOLD, name: "common.choices.procureApprovalStatus.onHold" },
];

export const PROC_ENTITY_RECORD_STATUS_COLORS = {
  [ProcEntityRecordStatus.DRAFT]: "#484041",
  [ProcEntityRecordStatus.WAITING_FOR_APPROVAL]: "#F95738",
  [ProcEntityRecordStatus.APPROVED]: "#F4D35E",
  [ProcEntityRecordStatus.REJECTED]: "#A8A84D",
  [ProcEntityRecordStatus.IN_PROGRESS]: "#EE964B",
  [ProcEntityRecordStatus.COMPLETED]: "#083D77",
  [ProcEntityRecordStatus.ON_HOLD]: "#533E2D",
  [ProcEntityRecordStatus.CANCELLED]: "#A491D3",
};

export const PROCURE_APPROVAL_STATUS_COLORS = {
  [ProcureApprovalStatus.PENDING_SUBMISSION]: "#484041",
  [ProcureApprovalStatus.LEVEL_1_APPROVAL_PENDING]: "#250902",
  [ProcureApprovalStatus.LEVEL_2_APPROVAL_PENDING]: "#998FC7",
  [ProcureApprovalStatus.APPROVED]: "#E03616",
  [ProcureApprovalStatus.REJECTED]: "#1B998B",
  [ProcureApprovalStatus.ON_HOLD]: "#390099",
};

export const APPROVAL_ACTION_STATUS_COLORS = {
  [APPROVAL_ACTIONS.SUBMIT]: "#484041",
  [APPROVAL_ACTIONS.APPROVE]: "#2a9d8f",
  [APPROVAL_ACTIONS.REJECT]: "#d62828",
  [APPROVAL_ACTIONS.ON_HOLD]: "#f4a261",
  [APPROVAL_ACTIONS.CANCEL]: "#f04419",
  [APPROVAL_ACTIONS.REOPEN]: "#2176ff",
};

export const procEntityRecordFiltersList: StatusItemProps[] = [
  {
    statusLabel: "common.choices.procEntityRecordStatus.draft",
    statusValue: ProcEntityRecordStatus.DRAFT,
    statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.DRAFT],
  },
    {
      statusLabel: "common.choices.procEntityRecordStatus.waitingForApproval",
      statusValue: ProcEntityRecordStatus.WAITING_FOR_APPROVAL,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.WAITING_FOR_APPROVAL],
    },
    {
      statusLabel: "common.choices.procEntityRecordStatus.approved",
      statusValue: ProcEntityRecordStatus.APPROVED,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.APPROVED],
    },
    {
      statusLabel: "common.choices.procEntityRecordStatus.inProgress",
      statusValue: ProcEntityRecordStatus.REJECTED,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.REJECTED],
    },
    {
      statusLabel: "common.choices.procEntityRecordStatus.completed",
      statusValue: ProcEntityRecordStatus.IN_PROGRESS,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.IN_PROGRESS],
    },
    {
      statusLabel: "common.choices.procEntityRecordStatus.onHold",
      statusValue: ProcEntityRecordStatus.COMPLETED,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.COMPLETED],
    },
    {
      statusLabel: "common.choices.procEntityRecordStatus.cancelled",
      statusValue: ProcEntityRecordStatus.CANCELLED,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcEntityRecordStatus.CANCELLED],
    },
];

export const procureApprovalStatusFiltersList: StatusItemProps[] = [
  {
    statusLabel: "common.choices.procureApprovalStatus.pendingSubmission",
    statusValue: ProcureApprovalStatus.PENDING_SUBMISSION,
    statusColor: PROCURE_APPROVAL_STATUS_COLORS[ProcureApprovalStatus.PENDING_SUBMISSION],
  },
    {
      statusLabel: "common.choices.procureApprovalStatus.level1ApprovalPending",
      statusValue: ProcureApprovalStatus.LEVEL_1_APPROVAL_PENDING,
      statusColor: PROCURE_APPROVAL_STATUS_COLORS[ProcureApprovalStatus.LEVEL_1_APPROVAL_PENDING],
    },
    {
      statusLabel: "common.choices.procureApprovalStatus.level2ApprovalPending",
      statusValue: ProcureApprovalStatus.LEVEL_2_APPROVAL_PENDING,
      statusColor: PROCURE_APPROVAL_STATUS_COLORS[ProcureApprovalStatus.LEVEL_2_APPROVAL_PENDING],
    },
    {
      statusLabel: "common.choices.procureApprovalStatus.approved",
      statusValue: ProcureApprovalStatus.APPROVED,
      statusColor: PROCURE_APPROVAL_STATUS_COLORS[ProcureApprovalStatus.APPROVED],
    },
    {
      statusLabel: "common.choices.procureApprovalStatus.rejected",
      statusValue: ProcureApprovalStatus.REJECTED,
      statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcureApprovalStatus.REJECTED],
    },
    {
        statusLabel: "common.choices.procureApprovalStatus.onHold",
        statusValue: ProcureApprovalStatus.ON_HOLD,
        statusColor: PROC_ENTITY_RECORD_STATUS_COLORS[ProcureApprovalStatus.ON_HOLD],
      }
];

export const FILTER_NAME_ONLY_USER = 'onlyUser';