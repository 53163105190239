import React, { ReactElement } from "react";

import {
  TextField,
  ListProps,
  Filter,
  FilterProps,
  ReferenceField,
  DateField,
  BooleanField,
  RichTextField,
} from "react-admin";
import {
  DEFAULT_PER_PAGE,
  IdFilter,
  SearchFilter,
} from "../../../../lib/common";
import {
  CustomizableList,
  CustomizableDatagrid,
  ListRowActions,
} from "../../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../../lib/core";
import { RESOURCE_NAME_ACCOUNT } from "../../../../lib/modules/accounts/accounts/constants";
import { RESOURCE_NAME_ITEM_CLASS } from "./constants";

const ListFilters = (props: Omit<FilterProps, "children">) => (
  <Filter {...props}>
    <SearchFilter alwaysOn key="Search" />
    <IdFilter
      source="id"
      label="components.controls.filters.idFilter.label"
      key="id"
    />
  </Filter>
);

const defaultColumns = ["name", "number", "description", "parentId"];
const ItemClassList = (props: ListProps): ReactElement => {
  return (
    <CustomizableList
      {...props}
      sort={{ field: "name", order: "ASC" }}
      perPage={DEFAULT_PER_PAGE}
      filters={<ListFilters />}
      defaultColumns={defaultColumns}
    >
      <CustomizableDatagrid optimized>
        <TextField source="number" />
        <TextField source="name" />
        <RichTextField source="description" />
        <ReferenceField source="parentId" reference={RESOURCE_NAME_ITEM_CLASS}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT} >
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="id" />
        <TextField source="version" />
        <BooleanField source="isActive" />
        <ListRowActions />
      </CustomizableDatagrid>
    </CustomizableList>
  );
};

// eslint-disable-next-line
export { ItemClassList as ItemClassList };
