import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import { LocationForm } from "./LocationForm";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";

const LocationEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "locations",
    },
  ];

  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <LocationForm isEdit={true}{...props} />
    </DefaultEdit>
  );
};

export { LocationEdit };
