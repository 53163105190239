import { ReactElement, ReactNode, useCallback } from "react";
import { MenuItem, MenuItemProps, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { cloneElement } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
  active: {
    color: theme.palette.text.primary,
  },
  icon: { minWidth: theme.spacing(5) },
}));

interface Props {
  tooltip: string;
  leftIcon?: ReactElement;
  primaryText?: ReactNode;
  closeMenu?: () => void;
}

type ListVerticalActionMenuItemButtonProps = Props &
  MenuItemProps<"li", { button?: true }>;

const ListVerticalActionMenuItemButton=
  (props:ListVerticalActionMenuItemButtonProps) => {
    const classes = useStyles(props);
    const { leftIcon, primaryText, tooltip, closeMenu, onClick, ...rest } =
      props;

    const handleMenuTap = useCallback(
      (e) => {
        closeMenu && closeMenu();
        onClick && onClick(e);
      },
      [onClick, closeMenu]
    );

    return (
      <Tooltip title={tooltip!} placement="right">
        <MenuItem {...rest} onClick={handleMenuTap}>
          {leftIcon && (
            <ListItemIcon className={classes.icon}>
              {cloneElement(leftIcon, {
                titleAccess: primaryText,
              })}
            </ListItemIcon>
          )}
          {primaryText}
        </MenuItem>
      </Tooltip>
    );
  };

export { ListVerticalActionMenuItemButton };
