import {
  BidComparisonState,
  BidComparisonCalculationActions,
  BidComparisonCalculationActionTypes,
} from "../types";

export const bidComparisonCalculationReducer = (
  newState: BidComparisonState,
  action: BidComparisonCalculationActions
): BidComparisonState => {
  switch (action.type) {
    case BidComparisonCalculationActionTypes.INIT: {
      return newState;
    }

    case BidComparisonCalculationActionTypes.LOADING_BID_CALCULATIONS: {
      return {
        ...newState,
        isLoading: true,
        shouldRecalculate: false,
      };
    }

    case BidComparisonCalculationActionTypes.ENQUIRY_LOADED: {
      const excludedItemIds = new Set(action.payload.excludedItemIds);
      const excludedVendorAccountIds = new Set(action.payload.excludedVendorAccountIds);
      const priceComponents = action.payload.priceComponents;

      return {
        ...newState,
        excludedItemIds,
        excludedVendorAccountIds,
        priceComponents,
        isLoading: true,
        shouldRecalculate: true,
      };
    }

    case BidComparisonCalculationActionTypes.BID_COM_DATA_POPULATE: {
        const bidsComparisonCalc = action.payload.bidsComparisonCalc;
        const hasData = bidsComparisonCalc?.summary?.length > 0;
        
        return {
            ...newState,
            bidsComparisonCalc,
            hasData,
            isLoading: false,
            shouldRecalculate: false,
        }
    }

    case BidComparisonCalculationActionTypes.ITEM_TOGGLED: {
      const itemId = action.payload.itemId;
      const checked = action.payload.checked;
      const notify = action.payload.notify;
      let newExcludedItemIds = newState.excludedItemIds;
      const bidsComparisonCalc = newState.bidsComparisonCalc;

      if (checked) {
        const isLastRecordChecked = bidsComparisonCalc?.summary?.length === 1;
        if (isLastRecordChecked) {
          notify(
            "resources.bidsComparison.messages.lastItemChecked",
            "warning",
          );
        } else {
          newExcludedItemIds = newExcludedItemIds.add(itemId);
        }
      } else {
        newExcludedItemIds.delete(itemId);
      }
      const excludedItemIds = new Set(newExcludedItemIds);

      return {
        ...newState,
        excludedItemIds,
        shouldRecalculate: true,
      };
    }

    case BidComparisonCalculationActionTypes.VENDOR_ACCOUNT_TOGGLED: {
      const vendorAccountId = action.payload.vendorAccountId;
      const checked = action.payload.checked;
      const notify = action.payload.notify;
      let newExcludedVendorAccountIds = newState.excludedVendorAccountIds;
      const bidsComparisonCalc = newState.bidsComparisonCalc;

      if (checked) {
        const isLastRecordChecked =
          bidsComparisonCalc?.vendorAccounts?.length === 1;
        if (isLastRecordChecked) {
          notify(
            "resources.bidsComparison.messages.lastVendorAccountChecked",
            "warning"
          );
        } else {
          newExcludedVendorAccountIds =
            newExcludedVendorAccountIds.add(vendorAccountId);
        }
      } else {
        newExcludedVendorAccountIds.delete(vendorAccountId);
      }

      const excludedVendorAccountIds = new Set(newExcludedVendorAccountIds);

      return {
        ...newState,
        excludedVendorAccountIds,
        shouldRecalculate: true,
      };
    }

    case BidComparisonCalculationActionTypes.OPEN_MANAGE_PRICE_COMPONENTS_DIALOG: {
        return {
            ...newState,
            isManagePriceComponentsDialogOpen: true,
        }
    }

    case BidComparisonCalculationActionTypes.CLOSE_MANAGE_PRICE_COMPONENTS_DIALOG: {
        return {
            ...newState,
            isManagePriceComponentsDialogOpen: false,
        }
    }

    case BidComparisonCalculationActionTypes.RECALCULATE_PRICE_COMPONENTS: {
        const priceComponents = action.payload.priceComponents;

        return {
            ...newState,
            priceComponents,
            isManagePriceComponentsDialogOpen: false,
            shouldRecalculate: true,
        }
    }
  }


  return newState;
};
