import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  SimpleForm,
  setSidebarVisibility,
  useNotify,
  useRefresh,
  useRedirect,
} from "react-admin";
import { DefaultCreate } from "../../lib/components";
import { BidCreateContent } from "./components";
import { MediaFileSource, transformAndUploadS3Files } from "../../lib/modules";
import { FILTER_DIRECTION } from "../../common";

const BidCreate = (props) => {
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
    // eslint-disable-next-line
  }, []);

  const onSuccess = () => {
    notify("resources.bids.messages.edited", {type: "success"});
    redirect(`/bids?filter={"direction":"${FILTER_DIRECTION.SENT}"}`);
    refresh();
  };

  const onFailure = (error) => {
    if (error.code === 123) {
      notify("Could not save changes: concurrent edition in progress", {type: "error"});
    } else {
      const errorMessage = `An error occured and your changes were not saved. \n ${error?.message || "ra.notification.http_error"}`;
      notify(errorMessage, {type: "error", multiLine: true});
    }
    //redirect("list", props.basePath);
  };
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "bids",
    },
  ];
  return (
    <DefaultCreate
      {...props}
      transform={transformAndUploadS3Files(mediaFileSources)}
      onSuccess={onSuccess}
      onFailure={onFailure}
      basePath={`/bids?filter={"direction":"${FILTER_DIRECTION.SENT}"}`}
    >
      <SimpleForm
        {...props}
        mutationMode="pessimistic"
        warnWhenUnsavedChanges
        margin="normal"
        variant="filled"
      >
        <BidCreateContent />
      </SimpleForm>
    </DefaultCreate>
  );
};

export { BidCreate };
