import { createContext } from "react";
import { PermissionManagerContextProps } from "./types";

const PermissionManagerContext = createContext<PermissionManagerContextProps>(
  {}
);

PermissionManagerContext.displayName = "PermissionManagerContext";

export { PermissionManagerContext };
