import { FunctionComponent } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import { useTranslate } from "react-admin";
import DefaultIcon from '@material-ui/icons/ViewList';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(0.5),
      width: 20,
      height: 20,
    },
  })
);

export interface ListActionBreadcrumbProps {
  resourceLabel: string;
  Icon: FunctionComponent<any>;
}

const ListActionBreadcrumb = ({ resourceLabel, Icon }: ListActionBreadcrumbProps) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link
        color="inherit"
        to="/"
        className={classes.link}
        component={RouterLink}
      >
        <HomeIcon className={classes.icon} />
        {translate("ra.page.dashboard")}
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        {Icon?<Icon className={classes.icon} />:<DefaultIcon className={classes.icon} />}
        {resourceLabel && translate(resourceLabel, {smart_count: 2})}
      </Typography>
    </Breadcrumbs>
  );
};

export { ListActionBreadcrumb };
