export const RESOURCE_NAME_ORG_STRUCTURE = "orgStructures";

export enum PermissionOrgStructure {
  ORG_STRUCTURE_MANAGEMENT_MENU = "view-org-structure-management-menu",
  MENU_ORG_STRUCTURE = "view-org-structure-menu",
  LIST_ORG_STRUCTURE = "list-org-structures",
  SHOW_ORG_STRUCTURE = "show-org-structure",
  CREATE_ORG_STRUCTURE = "create-org-structure",
  EDIT_ORG_STRUCTURE = "update-org-structure",
  DELETE_ORG_STRUCTURE = "delete-org-structure",
  IMPORT_ORG_STRUCTURES = "import-org-structures",
}

export enum ORG_STRUCTURE_USER_GROUP_TYPE {
  REQUISITIONS = 1,
  ENQUIRIES,
  BIDS,
}