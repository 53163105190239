export const RESOURCE_ACCOUNT_CODE = "accountCode";
export const RESOURCE_NAME_BUSINESS_FUNCTIONS = 'businessFunctions'

export enum PermissionBusinessFunctions {
  MENU_BUSINESS_FUNCTION = "view-business-functions-menu",
  LIST_BUSINESS_FUNCTIONS = "list-business-functions",
  SHOW_BUSINESS_FUNCTION = "show-business-function",
  CREATE_BUSINESS_FUNCTION = "create-business-function",
  EDIT_BUSINESS_FUNCTION = "update-business-function",
  DELETE_BUSINESS_FUNCTION = "delete-business-function",
}
