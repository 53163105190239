export const RESOURCE_NAME_ACCOUNT = "accounts";
export const RESOURCE_NAME_CODE = "code";

export enum PermissionAccounts {
    ACCOUNT_MANAGEMENT_MENU ='view-access-management-menu',
    MENU_ACCOUNT = 'view-account-management-menu',
    LIST_ACCOUNTS = 'list-accounts',
    SHOW_ACCOUNT = 'show-account',
    CREATE_ACCOUNT = 'create-account',
    EDIT_ACCOUNT = 'update-account',
    DELETE_ACCOUNT = 'delete-account',
}

export const accountsPermissions = {
    menu: PermissionAccounts.MENU_ACCOUNT,
    list: PermissionAccounts.LIST_ACCOUNTS,
    create: PermissionAccounts.CREATE_ACCOUNT,
    update: PermissionAccounts.EDIT_ACCOUNT,
    show: PermissionAccounts.SHOW_ACCOUNT,
    delete: PermissionAccounts.DELETE_ACCOUNT,
};