import SalesIcon from '@material-ui/icons/DateRange';
import { SystemDataList } from './SystemDataList';
import { SystemDataEdit } from './SystemDataEdit';
import { SystemDataShow } from './SystemDataShow';
import { PermissionSystemData, RESOURCE_NAME_SYSTEM_DATA } from './constants';

const allowedPermissions = {
    menu: PermissionSystemData.MENU_SYSTEM_DATA,
    list: PermissionSystemData.LIST_SYSTEM_DATA,
    create: PermissionSystemData.CREATE_SYSTEM_DATA,
    update: PermissionSystemData.EDIT_SYSTEM_DATA,
    show: PermissionSystemData.SHOW_SYSTEM_DATA,
    delete: PermissionSystemData.DELETE_SYSTEM_DATA,
};
const systemDataResource = {
    name: RESOURCE_NAME_SYSTEM_DATA,
    list: SystemDataList,
    edit: SystemDataEdit,
    show: SystemDataShow,
    icon: SalesIcon,
    options: {  label: 'resources.systemData.name', allowedPermissions },
}

export * from './constants';
export { systemDataResource };