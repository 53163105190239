import {
  ArrayField,
  EmailField,
  ReferenceField,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  UrlField,
  useTranslate,
} from "react-admin";
import { Box, Divider, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
  SectionTitleSubtitle,
  UploadedFilesField,
} from "../../../../lib/components";
import { RESOURCE_NAME_VENDOR_GROUP } from "../../vendor-group";
import { RESOURCE_NAME_CATEGORY } from "../../category/constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    layout: {
      display: "grid",
      gap: 10,
      gridTemplateColumns: "1fr 1fr",
      gridTemplateAreas: `
            "vendorDetailsLabel vendorDetailsLabel"
            "vendorAccountCode vendorAccountName"
            "vendorAccountPrimaryUserFullName vendorAccountPrimaryUserEmail"
            "vendorCategoryId vendorGroupId"
            "dividerAddressDetails dividerAddressDetails"
            "addressDetailsLabel addressDetailsLabel"
            "addressLine1 addressLine2"
            "pincode city"
            "state country"
            "dividerContactInformation dividerContactInformation"
            "contactInformation contactInformation"
            "phones phones"
            "url url"
            "dividerAttachments dividerAttachments"
            "attachments attachments"
          `,
      [theme.breakpoints.down("sm")]: {
        gridTemplateColumns: "1fr",
        gridTemplateAreas: `
                "vendorDetailsLabel"
                "vendorAccountCode"
                "vendorAccountName"
                "vendorAccountPrimaryUserFullName"
                "vendorAccountPrimaryUserEmail"
                "vendorCategoryId"
                "vendorGroupId"
                "dividerAddressDetails"
                "addressDetailsLabel"
                "addressLine1"
                "addressLine2"
                "pincode"
                "city"
                "state"
                "country"
                "dividerContactInformation"
                "contactInformation"
                "phones"
                "url"
                "dividerAttachments"
                "attachments"
            `,
      },
      "& .ra-field-vendorDetailsLabel": {
        gridArea: "vendorDetailsLabel",
      },
      "& .ra-field-vendorAccount.code": {
        gridArea: "vendorAccountCode",
      },
      "& .ra-field-vendorCategoryId": {
        gridArea: "vendorCategoryId",
      },
      "& .ra-field-vendorGroupId": {
        gridArea: "vendorGroupId",
      },
      "& .ra-field-vendorAccount.name": {
        gridArea: "vendorAccountName",
      },
      "& .ra-field-vendorAccount.primaryUser.fullName": {
        gridArea: "vendorAccountPrimaryUserFullName",
      },
      "& .ra-field-vendorAccount.primaryUser.email": {
        gridArea: "vendorAccountPrimaryUserEmail",
      },
      "& .ra-divider-addressDetails": {
        gridArea: "dividerAddressDetails",
      },
      "& .ra-field-addressDetailsLabel": {
        gridArea: "addressDetailsLabel",
      },
      "& .ra-field-addressLine1": {
        gridArea: "addressLine1",
      },
      "& .ra-field-addressLine2": {
        gridArea: "addressLine2",
      },
      "& .ra-field-pincode": {
        gridArea: "pincode",
      },
      "& .ra-field-city": {
        gridArea: "city",
      },
      "& .ra-field-state": {
        gridArea: "state",
      },
      "& .ra-field-country": {
        gridArea: "country",
      },
      "& .ra-divider-contactInformation": {
        gridArea: "dividerContactInformation",
      },
      "& .ra-field-contactInformation": {
        gridArea: "contactInformation",
      },
      "& .ra-field-phones": {
        gridArea: "phones",
      },
      "& .ra-field-url": {
        gridArea: "url",
      },
      "& .ra-divider-attachments": {
        gridArea: "dividerAttachments",
      },
      "& .ra-field-attachments": {
        gridArea: "attachments",
      },
    },
    phonesList: {
      display: "grid",
      gap: 10,
      gridTemplateColumns: "1fr",
    },
    section: {
        paddingTop: 16,
        paddingLeft: 'unset',
    }
  })
);

const VendorShowContainer = (props) => {
  const classes = useStyles(props);

  return (
    <SimpleShowLayout className={classes.layout}>
      <SectionTitleSubtitle
        title="resources.vendors.fieldGroups.vendorDetails"
        className={`ra-field-vendorDetailsLabel ${classes.section}`}
      />
      <TextField
        label="resources.vendors.fields.vendorAccountCode"
        source="vendorAccount.code"
      />

      <TextField
        label="resources.vendors.fields.vendorAccountId"
        source="vendorAccount.name"
      />

      <TextField
        source="vendorAccount.primaryUser.fullName"
        label="resources.vendors.fields.vendorAccountFullName"
      />
      <EmailField
        source="vendorAccount.primaryUser.email"
        label="resources.vendors.fields.vendorAccountEmail"
      />

      <ReferenceField
        source="vendorCategoryId"
        label="resources.vendors.fields.vendorCategoryId"
        reference={RESOURCE_NAME_CATEGORY}
        link={false}
        key="vendorCategoryId"
      >
        <TextField source="displayLabel" />
      </ReferenceField>

      <ReferenceField
        source="vendorGroupId"
        label="resources.vendors.fields.vendorGroupId"
        reference={RESOURCE_NAME_VENDOR_GROUP}
        link={false}
        key="vendorGroupId"
      >
        <TextField source="displayLabel" />
      </ReferenceField>

      <Divider className="ra-divider-addressDetails" />
      <SectionTitleSubtitle
        title="resources.vendors.fieldGroups.addressDetails"
        className={`ra-field-addressDetailsLabel ${classes.section}`}
      />
      <TextField source="addressLine1" />
      <TextField source="addressLine2" />
      <TextField source="pincode" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="country" />

      <Divider className="ra-divider-contactInformation" />

      <SectionTitleSubtitle
        title="resources.vendors.fieldGroups.contactInformation"
        className={`ra-field-contactInformation ${classes.section}`}
      />

      <ArrayField source="phones">
        <SingleFieldList className={classes.phonesList} linkType={false}>
          <TextField source="phone" />
        </SingleFieldList>
      </ArrayField>
      <UrlField source="url" target="_blank" rel="noopener" />

      <Divider className="ra-divider-attachments" />

      <UploadedFilesField canDelete={false} className="ra-field-attachments" />
    </SimpleShowLayout>
  );
};

export { VendorShowContainer };
