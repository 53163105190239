import BusinessIcon from "@material-ui/icons/Business";
import {
  PermissionOrgStructure,
  RESOURCE_NAME_ORG_STRUCTURE,
} from "./constants";
import { OrgStructureCreate } from "./OrgStructureCreate";
import { OrgStructureEdit } from "./OrgStructureEdit";
import { OrgStructureList } from "./OrgStructureList";
import { OrgStructureShow } from "./OrgStructureShow";

const allowedPermissions = {
  menu: PermissionOrgStructure.MENU_ORG_STRUCTURE,
  list: PermissionOrgStructure.LIST_ORG_STRUCTURE,
  create: PermissionOrgStructure.CREATE_ORG_STRUCTURE,
  update: PermissionOrgStructure.EDIT_ORG_STRUCTURE,
  show: PermissionOrgStructure.SHOW_ORG_STRUCTURE,
  delete: PermissionOrgStructure.DELETE_ORG_STRUCTURE,
  import: PermissionOrgStructure.IMPORT_ORG_STRUCTURES,
};
const orgStructureResource = {
  name: RESOURCE_NAME_ORG_STRUCTURE,
  list: OrgStructureList,
  create: OrgStructureCreate,
  edit: OrgStructureEdit,
  show: OrgStructureShow,
  icon: BusinessIcon,
  options: { label: "resources.orgStructures.name", allowedPermissions },
};

export * from "./constants";
export { orgStructureResource };
