import { AutocompleteArrayInput, ReferenceArrayInput } from "react-admin";
import { MAX_RECORDS_PER_PAGE } from "../../../lib/common";
import { RESOURCE_NAME_BUSINESS_FUNCTIONS } from "../../../modules/datasetup/business-functions";

const BusinessFunctionsListFilter = ({source}: {source: string}) => {
  return (
    <ReferenceArrayInput
        source="businessFunctionId"
        reference={RESOURCE_NAME_BUSINESS_FUNCTIONS}
        perPage={MAX_RECORDS_PER_PAGE}
        sort={{ field: "name", order: "ASC" }}
        key="businessFunctionId"
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          resettable={true}
          allowEmpty={false}
        />
      </ReferenceArrayInput>
  );
};

export { BusinessFunctionsListFilter };
