import { memo } from "react";
import { TextFieldProps } from "ra-ui-materialui";
import { sanitizeFieldRestProps } from "react-admin";
import Typography from '@material-ui/core/Typography';



const LabelField=(props:LabelFieldProps)=>memo<LabelFieldProps>(props => {
    const { className, value, emptyText, ...rest } = props;

    return (
        <Typography
            component="span"
            variant="body2"
            className={className}
            {...sanitizeFieldRestProps(rest)}
        >
            {value || emptyText}
        </Typography>
    )
});

LabelField.displayName = 'LabelField';

LabelField.defaultProps = {
    addLabel: true,
}

export interface LabelFieldProps extends TextFieldProps {
    value?: string | number | undefined;
}

export { LabelField }