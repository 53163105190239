import { makeStyles, Typography } from "@material-ui/core";
import { useTranslate } from "react-admin";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    textAlign: "center",
    borderStyle: "dashed",
    borderWidth: "1px",
    borderRadius: "2%",
    color: "rgba(0, 0, 0, 0.54)",
  },
  note: {
    fontSize: "small",
    fontStyle: "italic",
  },
}));

export interface FileInputPlaceholderProps {
  fileDescription?: string;
  maxFileSize?: string;
}

const FileInputPlaceholder= ({
  fileDescription = "components.controls.media.placeholder.labels.fileDescription",
  maxFileSize = "components.controls.media.placeholder.labels.maxFileSize",
}:FileInputPlaceholderProps) => {
    const classes = useStyles();
    const translate = useTranslate();
    const fileDescriptionLabel = translate(fileDescription);
    const maxFileSizeLabel = translate(maxFileSize);
    const line1 = translate("components.controls.media.placeholder.content.line1", {fileDescriptionLabel});
    const line2 = translate("components.controls.media.placeholder.content.line2");
    const line3 = translate("components.controls.media.placeholder.content.line3", {fileDescriptionLabel, maxFileSizeLabel});
    
    return (
        <Typography
          variant="body1"
          color="inherit"
          className={classes.placeholder}
          id="react-admin-title"
        >
          <p>{line1}</p>
          <p>{line2}</p>
          <p className={classes.note}>
            {line3}
          </p>
        </Typography>
      );
};

export { FileInputPlaceholder };
