import { forwardRef } from "react";
import {
  AppBar as RaAppBar,
  UserMenu,
  MenuItemLink,
  useTranslate,
  useGetIdentity,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import UserIcon from "@material-ui/icons/PermIdentity";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";

import { Logo } from "./Logo";
import ts from "typescript";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    spacer: {
      flex: 1,
    },
    smallAvatar: {
      fontSize: "0.75rem !important",
      fontWeight: 600,
      width: theme.spacing(3),
      height: theme.spacing(3),
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const ConfigurationMenu = forwardRef<any, any>((props, ref,) => {
  const translate = useTranslate();
  return (
    //@ts-ignore
    <MenuItemLink
      ref={ref}
      to="/my-profile"
      primaryText={translate("resources.menus.profile")}
      leftIcon={<UserIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => {
  const classes = useStyles();
  const { identity } = useGetIdentity();
  const avatarText = `${identity?.firstName?.charAt(0)}${identity?.lastName?.charAt(0)}`
  return (
    <UserMenu
      {...props}
      icon={<Avatar className={classes.smallAvatar}>{avatarText}</Avatar>}
    >
      <ConfigurationMenu />
    </UserMenu>
  );
};

const AppBar = (props: any) => {
  const classes = useStyles();
  return (
    <RaAppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <Logo />
      <span className={classes.spacer} />
    </RaAppBar>
  );
};

export { AppBar };
