import { Identifier, Record } from "react-admin";

export const RESOURCE_NAME_ENQUIRY = "enquiries";
export const RESOURCE_NAME_ENQUIRY_DETAILS = "enquiry/detail";
export const RESOURCE_NAME_ENQUIRY_VENDORS = "enquiry/vendor";

export enum PermissionEnquiries {
    MENU_ENQUIRIES ='view-enquiries-management-menu', 
    LIST_ENQUIRIES = 'list-enquiries',
    SHOW_OWN_ENQUIRY = 'show-own-enquiry',
    SHOW_ANY_ENQUIRY = 'show-any-enquiry',
    CREATE_ENQUIRY = 'create-enquiry',
    EDIT_OWN_ENQUIRY = 'update-own-enquiry',
    EDIT_ANY_ENQUIRY = 'update-any-enquiry',
    DELETE_OWN_ENQUIRY = 'delete-own-enquiry',
    DELETE_ANY_ENQUIRY = 'delete-any-enquiry',
    IMPORT_ENQUIRIES = 'import-enquiries',
}


export enum DatagridColors {
  SELECTED_COLOR = "#56776d",
  SELECTED_BG_COLOR = "#B3D0C7",
  UNSELECTED_COLOR = "#ec4702",
  UNSELECTED_BG_COLOR = "#FBC8B2",
}

export interface EnquiryRecord extends Partial<Record> {
  accountId: Identifier;
  requisitionId: Identifier;
  name: string;
  currencyId: string;
  bidStartDate: Date;
  bidEndDate: Date;
  preferredDeliveryDate?: Date;
  remarks?: string;
  status: number;
  version: number;
  enquiryDetails: EnquiryDetailRecord[];
}

export interface EnquiryDetailRecord extends Partial<Record> {
  enquiryId: Identifier;
  requisitionId: Identifier;
  requisitionDetailId: Identifier;
  itemId: Identifier;
  quantity: number;
  uomId: Identifier;
  quantity2?: number;
  uom2Id?: string;
  version?: number;
  isNew?: boolean;
}
