import {
  GatedMenuItemLink,
  GatedSubMenu,
  MenuProps,
} from "../../../lib/components";
import { bidResource } from "../../../modules/bids";
import {
  enquiryResource,
} from "../../../modules/enquiries";
import SalesIcon from "@material-ui/icons/MonetizationOn";
import { FILTER_DIRECTION } from "../../constants";

const SalesMenu= ({ isSidebarOpen, onMenuClick, dense }:MenuProps) => {
  return (
    <GatedSubMenu
      name={`resources.dataSetup.salesMenu`}
      icon={<SalesIcon />}
      isSidebarOpen={isSidebarOpen}
      dense={dense}
      allowedPermissionName="view-sales-management-menu"
    >
      <GatedMenuItemLink
        to={{
          pathname: `/${enquiryResource.name}`,
          search: `filter=${JSON.stringify({
            direction: FILTER_DIRECTION.RECEIVED,
          })}`,
        }}
        name="resources.enquiries.name"
        leftIcon={<enquiryResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={enquiryResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
      <GatedMenuItemLink
        to={{
          pathname: `/${bidResource.name}`,
          search: `filter=${JSON.stringify({
            direction: FILTER_DIRECTION.SENT,
          })}`,
        }}
        name="resources.bids.name"
        leftIcon={<bidResource.icon />}
        onClick={() => onMenuClick}
        allowedPermissionName={bidResource.options.allowedPermissions.menu}
        isSidebarOpen={isSidebarOpen}
        dense={dense}
      />
    </GatedSubMenu>
  );
};

export { SalesMenu };
