import { ListButton, ShowActionsProps, TopToolbar, useResourceDefinition, useShowContext } from 'react-admin';

const sanitizeRestProps = ({
    basePath,
    className,
    hasEdit,
    hasList,
    resource,
    ...rest
}: any) => rest;

const DefaultCreateAction = ({ className, ...rest }: ShowActionsProps) => {
    const { basePath } = useShowContext(rest);
    const { hasList } = useResourceDefinition(rest);
    return (
        <TopToolbar className={className} {...sanitizeRestProps(rest)}>
            {hasList && <ListButton basePath={basePath} />}
        </TopToolbar>
    );
}

export { DefaultCreateAction }