import { TextFieldProps } from "ra-ui-materialui";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import get from "lodash/get";

const TruncatedTextField= (props:TruncatedTextFieldProps) => {
  const classes = useStyles();
  const { record, source, truncateLength, showTooltip } = props;
  const fullText = record && source ? get(record, source) : null;
  const truncateText =
    fullText && fullText.length > (truncateLength || 50)
      ? `${fullText.substring(0, truncateLength)}...`
      : fullText;
  if (showTooltip) {
    return (
      <Tooltip
        title={fullText}
        arrow
        classes={{ tooltip: classes.customWidth }}
      >
        <span className={classes.text}>{truncateText}</span>
      </Tooltip>
    );
  } else {
    return <span>{truncateText}</span>;
  }
};

const useStyles = makeStyles((theme) => ({
  customWidth: {
    maxWidth: 500,
  },
  text: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

export interface TruncatedTextFieldProps extends TextFieldProps {
  truncateLength?: number;
  showTooltip?: boolean;
}

TruncatedTextField.defaultProps = {
  truncateLength: 5,
  showTooltip: true,
};

export { TruncatedTextField };
