import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  useMediaQuery,
  Divider,
  Tabs,
  Tab,
  Theme,
} from "@material-ui/core";

import {
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  useListContext,
  Identifier,
  useGetList,
  ListContextProvider,
  Datagrid,
  DatagridProps,
  SelectField,
  usePermissions,
} from "react-admin";
import { RESOURCE_NAME_REQUISITION } from "../../requisitions/constants";
import {
  RESOURCE_NAME_ENQUIRY,
} from "../constants";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules";
import { ListRowActions } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT, hasAccess } from "../../../lib/core";
import { DEFAULT_PER_PAGE } from "../../../lib/common";
import { requisitionPermissions } from "../../requisitions";
import { FILTER_DIRECTION, getProcEntityRecordStatusRowStyle, PROC_ENTITY_RECORD_STATUS_CHOICES } from "../../../common";

const tabs = [
  { id: FILTER_DIRECTION.SENT, name: FILTER_DIRECTION.SENT },
  { id: FILTER_DIRECTION.RECEIVED, name: FILTER_DIRECTION.RECEIVED },
];

const useGetTotals = (filterValues: any) => {
  const { total: totalSent } = useGetList(
    RESOURCE_NAME_ENQUIRY,
    { page: 1, perPage: DEFAULT_PER_PAGE },
    { field: "updatedAt", order: "DESC" },
    { ...filterValues, direction: FILTER_DIRECTION.SENT }
  );
  const { total: totalReceived } = useGetList(
    RESOURCE_NAME_ENQUIRY,
    { page: 1, perPage: DEFAULT_PER_PAGE },
    { field: "updatedAt", order: "DESC" },
    { ...filterValues, direction: FILTER_DIRECTION.RECEIVED }
  );

  return {
    sent: totalSent,
    received: totalReceived,
  };
};

interface TabbedDatagridProps extends DatagridProps {}

const EnquiryTabbedDatagrid= (props:TabbedDatagridProps) => {
  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;


  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));

  const [received, setReceived] = useState<Identifier[]>([] as Identifier[]);
  const [sent, setSent] = useState<Identifier[]>([] as Identifier[]);

  const totals = useGetTotals(filterValues) as any;

  const { loading: permissionsLoading, permissions } = usePermissions();
  const hasRequisitionsListAccess =
    !permissionsLoading && hasAccess(permissions, requisitionPermissions.list);

  useEffect(() => {
    if (ids && ids !== filterValues.direction) {
      switch (filterValues.direction) {
        case FILTER_DIRECTION.SENT:
          setSent(ids);
          break;
        case FILTER_DIRECTION.RECEIVED:
          setReceived(ids);
          break;
      }
    }
  }, [ids, filterValues.direction]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<{}>, value: any) => {
      setFilters &&
        setFilters({ ...filterValues, direction: value }, displayedFilters);
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.direction}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      {isSmall ? (
        <div>
          {filterValues.direction === FILTER_DIRECTION.SENT && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <TextField source="name" />

                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.direction === FILTER_DIRECTION.RECEIVED && (
            <ListContextProvider value={{ ...listContext, ids: received }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <TextField source="name" />

                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      ) : (
        <div>
          {filterValues.direction === FILTER_DIRECTION.SENT && (
            <ListContextProvider value={{ ...listContext, ids: sent }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <TextField source="code" />
                <ReferenceField
                  source="accountId"
                  reference={RESOURCE_NAME_ACCOUNT}
                >
                  <TextField source="displayLabel" />
                </ReferenceField>
                {hasRequisitionsListAccess && (
                  <ReferenceField
                    source="requisitionId"
                    reference={RESOURCE_NAME_REQUISITION}
                  >
                    <TextField source="displayLabel" />
                  </ReferenceField>
                )}
                <DateField
                  source="bidStartDate"
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <DateField
                  source="bidEndDate"
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <DateField
                  source="preferredDeliveryDate"
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <SelectField 
                  label="common.fields.approvalStatus"
                  source="status" 
                  choices={PROC_ENTITY_RECORD_STATUS_CHOICES} 
                />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <TextField source="id" />
                <TextField source="version" />
                <BooleanField source="isActive" />
                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
          {filterValues.direction === FILTER_DIRECTION.RECEIVED && (
            <ListContextProvider value={{ ...listContext, ids: received }}>
              <Datagrid {...props} rowStyle={getProcEntityRecordStatusRowStyle} optimized>
                <TextField source="name" />
                <ReferenceField
                  source="accountId"
                  reference={RESOURCE_NAME_ACCOUNT}
                >
                  <TextField source="name" />
                </ReferenceField>
                <DateField
                  source="bidStartDate"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <DateField
                  source="bidEndDate"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <DateField
                  source="preferredDeliveryDate"
                  showTime
                  options={COMMON_DATE_TIME_FORMAT}
                />
                <SelectField 
                  label="common.fields.status"
                  source="status" 
                  choices={PROC_ENTITY_RECORD_STATUS_CHOICES} 
                />
                <DateField source="createdAt" />
                <DateField source="updatedAt" />
                <TextField source="id" />
                <TextField source="version" />
                <BooleanField source="isActive" />
                <ListRowActions />
              </Datagrid>
            </ListContextProvider>
          )}
        </div>
      )}
    </Fragment>
  );
};

export { EnquiryTabbedDatagrid };
