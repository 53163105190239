import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  DateField,
  BooleanField,
  RichTextField,
} from "react-admin";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts";
import { DefaultShow } from "../../../lib/components";
import { COMMON_DATE_TIME_FORMAT } from "../../../lib/core";

const UomShow=(props:ShowProps)=> {
  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField source="name" />
        <TextField source="unitType" />
        <TextField source="unitSystem" />
        <RichTextField source="description" />
        <ReferenceField source="accountId" reference={RESOURCE_NAME_ACCOUNT}>
          <TextField source="displayLabel" />
        </ReferenceField>
        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />
        <TextField source="version" />
        <BooleanField source="isActive" />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { UomShow };
