import lodashMemoize from "lodash/memoize";

// If we define validation functions directly in JSX, it will
// result in a new function at every render, and then trigger infinite re-render.
// Hence, we memoize every built-in validator to prevent a "Maximum call stack" error.
export const memoize = (fn) => lodashMemoize(fn, (...args) => JSON.stringify(args));

export const getMessage = (message, messageArgs, value, values) =>
  typeof message === "function"
    ? message({
        args: messageArgs,
        value,
        values,
      })
    : messageArgs
    ? {
        message,
        args: messageArgs,
      }
    : message;

export const isEmpty = (value: any) =>
    typeof value === 'undefined' ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);    