import {
  ShowProps,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EmailField,
} from "react-admin";
import { RESOURCE_NAME_ROLE, roleResource } from "..";
import { UploadedFilesField } from "../../../components";
import { DefaultShow } from "../../../components/crud/DefaultShow";
import { COMMON_DATE_TIME_FORMAT, usePermissionsCheck } from "../../../core";
import { RESOURCE_NAME_ACCOUNT } from "../accounts";

const UserShow = (props: ShowProps) => {
  const { hasPermissionAccess, permissionsLoaded } = usePermissionsCheck();

  let showRoles: boolean = false;

  if (permissionsLoaded && hasPermissionAccess(roleResource?.options?.allowedPermissions?.list)) {
    showRoles = true;
  }

  return (
    <DefaultShow {...props}>
      <SimpleShowLayout>
        <TextField
          label="resources.users.fields.firstName"
          source="firstName"
        />
        <TextField label="resources.users.fields.lastName" source="lastName" />
        <EmailField label="resources.users.fields.email" source="email" />
        <ReferenceField
          label="resources.users.fields.accountId"
          source="accountId"
          reference={RESOURCE_NAME_ACCOUNT}
        >
          <TextField source="displayLabel" />
        </ReferenceField>

        {showRoles && (
          <ReferenceArrayField
            label="resources.users.fields.roleIds"
            source="roleIds"
            reference={RESOURCE_NAME_ROLE}
          >
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}

        <DateField source="createdAt" options={COMMON_DATE_TIME_FORMAT} />
        <DateField source="updatedAt" options={COMMON_DATE_TIME_FORMAT} />

        <BooleanField
          source="isPrimaryUser"
          label="resources.users.fields.isPrimaryUser"
        />
        <BooleanField source="isActive" />
        <UploadedFilesField canDelete={false} />
      </SimpleShowLayout>
    </DefaultShow>
  );
};

export { UserShow };
