export const RESOURCE_NAME_PERMISSION = "permissions";

export enum PermissionPermissions {
  MENU_PERMISSION = 'view-permissions-management-menu',
  LIST_PERMISSIONS = 'list-permissions',
  SHOW_PERMISSION = 'show-permission',
  CREATE_PERMISSION = 'create-permission',
  EDIT_PERMISSION = 'update-permission',
  DELETE_PERMISSION = 'delete-permission',
}
