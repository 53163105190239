import {
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import {
  AutocompleteArrayInput,
  Filter,
  FilterProps,
  ReferenceArrayInput,
  SelectArrayInput,
  useListContext,
  usePermissions,
  BooleanInput,
} from "react-admin";
import {
  IdFilter,
  MAX_RECORDS_PER_PAGE,
  SearchFilter,
} from "../../../lib/common";
import {
  requisitionPermissions,
  RESOURCE_NAME_REQUISITION,
} from "../../requisitions";
import { RESOURCE_NAME_CURRENCIES } from "../../datasetup/currencies";
import { hasAccess } from "../../../lib/core";
import { DateInput } from "../../../lib/components";
import {
  BusinessFunctionsListFilter,
  FILTER_DIRECTION,
  ItemsListFilter,
  ProcureOwnerUserListFilter,
  PROCURE_APPROVAL_STATUS_CHOICES,
  PROC_ENTITY_RECORD_STATUS_CHOICES,
} from "../../../common";

const EnquiryListFilters = (props: Omit<FilterProps, "children">) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down("sm"));
  const { permissions } = usePermissions();
  const hasRequisitionsListAccess = hasAccess(
    permissions,
    requisitionPermissions.list
  );
  const { filterValues } = useListContext(props);
  const isDirectionSent = filterValues?.direction === FILTER_DIRECTION.SENT;
  const classes = useStyles();

  return (
    <Filter {...props}>
      {isSmall && <SearchFilter alwaysOn />}
      {isSmall && isDirectionSent && (
        <SelectArrayInput
          label="common.fields.status"
          source="procureStatus"
          choices={PROC_ENTITY_RECORD_STATUS_CHOICES}
          allowEmpty={false}
          resettable={true}
        />
      )}
      {isSmall && <DateInput source="bidDateLte" />}
      {isSmall && <DateInput source="bidDateGte" />}

      {isSmall && isDirectionSent && (
        <SelectArrayInput
          source="procureApprovalStatus"
          label="common.fields.approvalStatus"
          choices={PROCURE_APPROVAL_STATUS_CHOICES}
          allowEmpty={false}
          resettable={true}
          className={classes.approvalStatus}
          fullWidth
          key="procureApprovalStatus"
        />
      )}

      {isDirectionSent && hasRequisitionsListAccess && (
        <ReferenceArrayInput
          source="requisitionId"
          reference={RESOURCE_NAME_REQUISITION}
          key="requisitionId"
          perPage={MAX_RECORDS_PER_PAGE}
          sort={{ field: "code", order: "ASC" }}
        >
          <AutocompleteArrayInput
            optionText="displayLabel"
            allowEmpty={false}
            resettable={true}
          />
        </ReferenceArrayInput>
      )}
      {isDirectionSent && (
        <BusinessFunctionsListFilter source="businessFunctionId" />
      )}
      {isDirectionSent && <ItemsListFilter source="itemId" />}
      {isSmall && isDirectionSent && (
        <BooleanInput source="needsUserApproval" />
      )}
      <ReferenceArrayInput
        source="currencyId"
        reference={RESOURCE_NAME_CURRENCIES}
      >
        <AutocompleteArrayInput
          optionText="displayLabel"
          allowEmpty={false}
          resettable={true}
        />
      </ReferenceArrayInput>
      <IdFilter
        source="id"
        label="components.controls.filters.idFilter.label"
        key="id"
      />

      {isDirectionSent && (
        <ProcureOwnerUserListFilter
          source="procureOwnerUserId"
          label="common.fields.procureOwnerUserId"
        />
      )}
    </Filter>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    approvalStatus: {
      width: 175,
    },
  })
);
export { EnquiryListFilters };
