import { Labeled, NumberField, ReferenceField, TextField } from "react-admin";
import { RESOURCE_NAME_CURRENCIES } from "../../../modules/datasetup/currencies";

const AmountField = ({
  amountSource,
  currencySoure,
  label,
  useLabel = false,
}: {
  amountSource: string;
  currencySoure: string;
  label: string;
  useLabel?: boolean;
}) => {
  if (useLabel) {
    return (
      <Labeled id={label} source={amountSource} label={label}>
        <>
          <NumberField source={amountSource} />
          <ReferenceField
            source={currencySoure}
            reference={RESOURCE_NAME_CURRENCIES}
            link={false}
            fullWidth={false}
          >
            <TextField source="name" style={{marginLeft: 5}} />
          </ReferenceField>
        </>
      </Labeled>
    );
  } else {
    return (
      <>
        <NumberField source={amountSource} />
        <ReferenceField
          label="resources.enquiries.fields.currencyId"
          source={currencySoure}
          reference={RESOURCE_NAME_CURRENCIES}
          link="show"
          fullWidth={true}
        >
          <TextField source="displayLabel" />
        </ReferenceField>
      </>
    );
  }
};

export { AmountField };
