import {
  TextInput,
  required,
  useTranslate,
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { Typography } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Styles } from "@material-ui/styles/withStyles";
import { RESOURCE_NAME_ACCOUNT } from "../../../lib/modules/accounts/accounts/constants";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  type: { display: "block" },
  value: { display: "block" },
  sortOrder: { display: "block" },
  account: { display: "block" },
};

const useStyles = makeStyles(styles);

const SystemDataForm=(props) => {
  const classes = useStyles(props);
  return (
    <SimpleForm warnWhenUnsavedChanges {...props}>
      <SectionTitle label="resources.systemData.fieldGroups.systemDataDetails" />
      <TextInput
        autoFocus
        source="type"
        label="resources.systemData.fields.type"
        helperText="resources.systemData.help.type"
        className={classes.type}
        validate={[required()]}
      />
      <TextInput
        autoFocus
        source="name"
        label="resources.systemData.fields.name"
        helperText="resources.systemData.help.name"
        className={classes.name}
        validate={[required()]}
      />
      <TextInput
        autoFocus
        source="value"
        label="resources.systemData.fields.value"
        helperText="resources.systemData.help.value"
        className={classes.value}
        validate={[required()]}
      />

      <RichTextInput
        source="description"
        label="resources.systemData.fields.description"
        helperText="resources.systemData.help.description"
        formClassName={classes.description}
      />

      <TextInput
        source="description"
        label="resources.systemData.fields.description"
        helperText="resources.systemData.help.description"
        className={classes.description}
        resettable
        multiline
      />
      <TextInput
        autoFocus
        source="sortOrder"
        label="resources.systemData.fields.sortOrder"
        helperText="resources.systemData.help.sortOrder"
        className={classes.value}
        validate={[required()]}
      />
      <ReferenceInput
        source="accountId"
        label="resources.systemData.fields.accountId"
        reference={RESOURCE_NAME_ACCOUNT}
      >
        <AutocompleteInput
          optionText="displayLabel"
          helperText="resources.systemData.help.accountId"
          className={classes.account}
          isRequired
        />
      </ReferenceInput>
    </SimpleForm>
  );
};

const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};

export { SystemDataForm };
