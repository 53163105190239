import { DatagridProps } from "ra-ui-materialui";
import { Dispatch, ReactNode } from "react";
import { DefaultListProps } from "../../../types";

export interface CustomizableListProps extends DefaultListProps {
  defaultColumns: string[];
  datagridStorage?: DatagridStorage;
  selectionTooltip?: string;
  selectionLabel?: string;
}

export interface CustomizableListState {
  isOpen: boolean;
  defaultColumns?: string[];
  storage?: DatagridStorage;

  columns?: DatagridFieldProps[];
  fieldsList?: ReactNode | ReactNode[];

  selection?: string[];

  resource?: string;
}

export enum CustomizableListActionTypes {
  INIT_DATAGRID,
  SET_COLUMNS,
  COLUMN_TOGGLED,
  RESET_ALL,
  SAVE_COLUMNS_STATE,
  OPEN_DIALOG,
  CLOSE_DIALOG,
}

export type CustomizableListActions =
  | {
      type: CustomizableListActionTypes.INIT_DATAGRID;
      payload: {
        fieldsList: ReactNode | ReactNode[];
      };
    }
  | {
      type: CustomizableListActionTypes.SET_COLUMNS;
      payload: {
        columns: DatagridFieldProps[];
      };
    }
  | {
      type: CustomizableListActionTypes.COLUMN_TOGGLED;
      payload: {
        columnName: string;
      };
    }
  | {
      type: CustomizableListActionTypes.RESET_ALL;
    }
  | {
      type: CustomizableListActionTypes.SAVE_COLUMNS_STATE;
    }
  | {
    type: CustomizableListActionTypes.OPEN_DIALOG;
  }
    | {
    type: CustomizableListActionTypes.CLOSE_DIALOG;
  };

export interface CustomizableListContextProps {
  state?: CustomizableListState;
  dispatch?: Dispatch<CustomizableListActions>;
}

export interface CustomizableSelectionDialogProps {
  //isOpen: boolean;
  //onClose: () => void;
  selectionText?: string;
  selectionLabel?: string;
}

export interface DatagridFieldProps {
  id: string;
  label: string;
  source: string;
  isDisplayed: boolean;
}

export interface CustomizableListDialogButtonProps {
  selectionTooltip?: string;
}

export interface CustomizableDatagridProps extends DatagridProps {
  defaultColumns: string[];
  storage?: DatagridStorage;
  selectionTooltip?: string;
  selectionLabel?: string;
  selectionText?: string;
}

export interface DatagridStorage {
  get(storageKey: string, key: string);
  set(storageKey: string, key: string, value?: any);
  remove(storageKey: string, key: string);
}
