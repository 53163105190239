import {
  CreateProps,
} from "react-admin";
import { DefaultCreate } from "../../../components";
import { RoleForm } from "./RoleForm";

const RoleCreate = (props: CreateProps)=> {
    return (
      <DefaultCreate {...props}>
          < RoleForm isEdit={false}/>
      </DefaultCreate>
    );
  };
  
  export { RoleCreate };