import { Box } from "@material-ui/core";
import {
  ResourceContextProvider,
  ShowContextProvider,
  ShowProps,
  useShowController,
} from "react-admin";
import { ItemShowContainer } from ".";
import { SectionTitleSubtitle } from "../../../../../lib/components";
import { RESOURCE_NAME_ITEM } from "../constants";

const SingleItemShowContainer = (props) => {
  const itemId = props?.record?.itemId ?? props?.record?.id;

  const showProps: ShowProps = {
    basePath: "/",
    hasCreate: false,
    hasShow: true,
    hasList: false,
    id: itemId,
    resource: RESOURCE_NAME_ITEM,
  };
  const controllerProps = useShowController({ ...showProps });

  return (
    <ResourceContextProvider value={RESOURCE_NAME_ITEM}>
      <ShowContextProvider value={controllerProps}>
        <Box>
          <SectionTitleSubtitle title="resources.items.fieldGroups.itemDetails" />
          <ItemShowContainer
            {...props}
            {...controllerProps}
            shouldNotSyncWithLocation={true}
            procureAccountId={props?.procureAccountId}
          />
        </Box>
      </ShowContextProvider>
    </ResourceContextProvider>
  );
};

export { SingleItemShowContainer };
