import { Fragment } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  BulkActionProps,
  Button,
  useListContext,
  useUnselectAll,
  useNotify,
  Identifier,
} from "react-admin";
import AddToEnquiryIcon from "@material-ui/icons/AddShoppingCartTwoTone";
import RemoveFromEnquiryIcon from "@material-ui/icons/RemoveShoppingCartTwoTone";
import { DatagridColors } from "../constants";
import { RESOURCE_NAME_REQUISITION_DETAILS } from "../../requisitions/constants";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addItemsToEnquiry: {
      color: DatagridColors.SELECTED_COLOR,
      backgroundColor: DatagridColors.SELECTED_BG_COLOR,
      padding: 8,
    },
    removeItemsFromEnquiry: {
      color: DatagridColors.UNSELECTED_COLOR,
      backgroundColor: DatagridColors.UNSELECTED_BG_COLOR,
      padding: 8,
    },
    container: {
      display: "flex",
      gap: 10,
    },
  })
);

interface EnquiryBulkActionProps extends BulkActionProps {
  handleAddItemsToEnquiry: (map) => void;
  handleRemoveItemsFromEnquiry: (ids: Array<Identifier>) => void;
}

const AddItemsToEnquiryBulkActionButton = (props: EnquiryBulkActionProps) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleAddItemsToEnquiry, ...rest } = props;
  const addItemsToEnquiry = () => {
    const itemsToAddMap = new Map();
    selectedIds?.forEach((selectedId) => {
      const record = data[selectedId];
      const enquiryId = record?.enquiryId;
      const isNew = enquiryId ? false : true;
      const enquiryDetail = {
        requisitionId: record.requisitionId,
        requisitionDetailId: record.id,
        itemId: record.itemId,
        enquiryId,
        quantity: record.quantity,
        uomId: record.uomId,
        quantity2: record.quantity2,
        uom2Id: record.uom2Id,
        sortOrder: record?.sortOrder,
        //version: record.version,
        isNew,
      };
      itemsToAddMap.set(selectedId, enquiryDetail);
    });

    if (itemsToAddMap?.size) {
      handleAddItemsToEnquiry(itemsToAddMap);
    }

    unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
    notify("resources.enquiries.messages.addItemsToEnquiry", {
      type: "info",
      multiLine: true,
    });
  };
  return (
    <Button
      label="resources.enquiries.actions.addItemsToEnquiry"
      className={classes.addItemsToEnquiry}
      onClick={addItemsToEnquiry}
    >
      <AddToEnquiryIcon />
    </Button>
  );
};

const RemoveItemsFromEnquiryBulkActionButton = (
  props: EnquiryBulkActionProps
) => {
  const classes = useStyles();
  const { data, ids, selectedIds } = useListContext();
  const unselectAll = useUnselectAll();
  const notify = useNotify();
  const { handleRemoveItemsFromEnquiry, ...rest } = props;

  const removeItemsFromEnquiry = () => {
    handleRemoveItemsFromEnquiry(selectedIds);
    unselectAll(RESOURCE_NAME_REQUISITION_DETAILS);
    notify("resources.enquiries.messages.removeItemsFromEnquiry", {
      type: "info",
      multiLine: true,
    });
  };

  return (
    <Button
      label="resources.enquiries.actions.removeItemsFromEnquiry"
      className={classes.removeItemsFromEnquiry}
      onClick={removeItemsFromEnquiry}
    >
      <RemoveFromEnquiryIcon />
    </Button>
  );
};

const EnquiryBulkActionButtons = (props: EnquiryBulkActionProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <AddItemsToEnquiryBulkActionButton {...props} />
      <RemoveItemsFromEnquiryBulkActionButton {...props} />
    </Box>
  );
};

export { EnquiryBulkActionButtons };
