import { required, TextInput } from "react-admin";

const IdFilter= (props:{ source?: string; label?:string; alwaysOn?: boolean }) => {
  const { source = "id", label="components.controls.filters.idFilter.label" } = props;

  return (
    <TextInput
      source={source}
      label={label}
      alwaysOn={false}
      validate={[required()]}
    />
  );
};

export { IdFilter };
