import PeopleIcon from '@material-ui/icons/People';import { PermissionPermissionGroups, RESOURCE_NAME_PERMISSION_GROUP } from './constants';
import {  PermissionGroupsCreate } from './PermissionGroupsCreate';
import { PermissionGroupEdit } from './PermissionGroupsEdit';
import { PermissionGroupsList } from './PermissionGroupsList';
import { PermissionGroupsShow } from './PermissionGroupsShow';

const allowedPermissions = {
    menu: PermissionPermissionGroups.MENU_PERMISSION_GROUP,
    list: PermissionPermissionGroups.LIST_PERMISSION_GROUPS,
    create: PermissionPermissionGroups.CREATE_PERMISSION_GROUP,
    update: PermissionPermissionGroups.EDIT_PERMISSION_GROUP,
    show: PermissionPermissionGroups.SHOW_PERMISSION_GROUP,
    delete: PermissionPermissionGroups.DELETE_PERMISSION_GROUP,
};
const permissionGroupResource = {
    name: RESOURCE_NAME_PERMISSION_GROUP,
    list: PermissionGroupsList,
    create: PermissionGroupsCreate,
    edit: PermissionGroupEdit,
    show: PermissionGroupsShow, 
    icon: PeopleIcon,
    options: {   label: 'resources.permissionGroups.name', allowedPermissions },

}

export * from './constants';
export { permissionGroupResource }