import {
  TextInput,
  required,
  ReferenceInput,
  SelectInput,
  useTranslate,
  SimpleForm,
  TabbedForm,
  FormTab,
  usePermissions,
  useGetIdentity,
  useRecordContext,
} from "react-admin";

import { Styles } from "@material-ui/styles/withStyles";
import { makeStyles, Theme, Typography } from "@material-ui/core";
import { RESOURCE_NAME_ACCOUNT } from "../accounts/constants";
import { ModifyRolePermissions } from "../permissions";
import { ShowRolePermissions } from "../permissions/ShowRolePermissions";
import { PermissionRoles } from "./constants";
import { hasAccess } from "../../../core/helpers";
import { getTabbedFormProps } from "../../../common";
import { useEffect, useState } from "react";

export const styles: Styles<Theme, any> = {
  name: { display: "block" },
  description: { display: "block" },
  isActive: { display: "block" },
  account: { display: "block" },
};

const useStyles = makeStyles(styles);

const RoleForm = ({ isEdit, ...rest }) => {
  const { loaded, permissions } = usePermissions();
  const { identity } = useGetIdentity();
  const isSystemAdmin = identity?.isSystemAdmin;
  const record = useRecordContext(rest);
  const [canModifyPermissions, setCanModifyPermissions] =
    useState<boolean>(false);
  useEffect(() => {
    const isBootStrapped = record?.isBootstrapped;
    let canUserModifyPermissions = false;
    if (isBootStrapped && isSystemAdmin) {
      canUserModifyPermissions = true;
    } else if (!isBootStrapped) {
      canUserModifyPermissions = true;
    }

    setCanModifyPermissions(canUserModifyPermissions);
  }, [record, isSystemAdmin]);

  const allowManagePermissionsFromRoleScreen = hasAccess(
    permissions,
    PermissionRoles.ACTION_MANAGE_PERMISSIONS_FROM_ROLE
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles(rest);
  if (allowManagePermissionsFromRoleScreen) {
    return (
      <TabbedForm
        {...getTabbedFormProps(rest)}
        warnWhenUnsavedChanges
        {...rest}
      >
        <FormTab label="resources.roles.tabs.role">
          <SectionTitle label="resources.roles.fieldGroups.roleDetails" />
          <TextInput
            autoFocus
            source="name"
            label="resources.roles.fields.name"
            helperText="resources.roles.help.name"
            formclassName={classes.name}
            fullWidth
            validate={[required()]}
          />
          <TextInput
            source="description"
            label="resources.roles.fields.description"
            helperText="resources.roles.help.description"
            formclassName={classes.description}
            resettable
            multiline
            fullWidth
          />
          {isSystemAdmin && (
            <ReferenceInput
              label="resources.roles.fields.accountId"
              source="accountId"
              reference={RESOURCE_NAME_ACCOUNT}
              formclassName={classes.account}
              fullWidth
            >
              <SelectInput
                optionText="displayLabel"
                helperText="resources.roles.help.accountId"
              />
            </ReferenceInput>
          )}
        </FormTab>
        <FormTab label="resources.roles.tabs.permissions">
          {canModifyPermissions && <ModifyRolePermissions />}
          {!canModifyPermissions && <ShowRolePermissions />}
        </FormTab>
      </TabbedForm>
    );
  } else {
    return (
      <SimpleForm warnWhenUnsavedChanges>
        <SectionTitle label="resources.roles.fieldGroups.roleDetails" />
        <TextInput
          autoFocus
          source="name"
          label="resources.roles.fields.name"
          helperText="resources.roles.help.name"
          formclassName={classes.name}
          validate={[required()]}
        />
        <TextInput
          source="description"
          label="resources.roles.fields.description"
          helperText="resources.roles.help.description"
          formclassName={classes.description}
          resettable
          multiline
        />
        <ReferenceInput
          label="resources.roles.fields.accountId"
          source="accountId"
          reference={RESOURCE_NAME_ACCOUNT}
          formclassName={classes.account}
        >
          <SelectInput
            optionText="displayLabel"
            helperText="resources.roles.help.accountId"
          />
        </ReferenceInput>
      </SimpleForm>
    );
  }
};
const SectionTitle = ({ label }: { label: string }) => {
  const translate = useTranslate();

  return (
    <Typography variant="h6" gutterBottom>
      {translate(label)}
    </Typography>
  );
};
export { RoleForm };
