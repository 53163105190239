import {
  CreateProps, SimpleForm,
} from "react-admin";
import { DefaultCreate } from "../../lib/components";
import { MediaFileSource, transformAndUploadS3Files } from "../../lib/modules";
import { RequisitionForm } from "./RequisitionForm";

const RequisitionCreate= (props: CreateProps) => {
  const mediaFileSources: MediaFileSource[] = [{
    source: 'files',
    subFolder: 'requisitions',
  }];  
  return (
      <DefaultCreate 
        {...props}
        transform={transformAndUploadS3Files(mediaFileSources)}
      >
         <SimpleForm warnWhenUnsavedChanges>
          < RequisitionForm {...props}/>
         </SimpleForm>
      </DefaultCreate>
    );
  };
  
  export { RequisitionCreate };