import { required } from "react-admin";
import { isDateNotLessThanToday, isDateLessThanOther } from "../../../lib/components/validations/dateValidations";

const code = [required()];

const bidStartDate = [
    required(),
    //isDateNotLessThanToday()
];

const bidEndDate = [
    required(),
    isDateNotLessThanToday(),
    isDateLessThanOther("bidStartDate", "resources.enquiries.validations.bidEndDateGreaterThanStartDate"),
];

const preferredDeliveryDate = [
    isDateNotLessThanToday(),
    isDateLessThanOther("bidEndDate", "resources.enquiries.validations.preferredDeliveryDateGreaterThanBidEndDate"),
];

export const validations = {
    code,
    bidStartDate,
    bidEndDate,
    preferredDeliveryDate,
}