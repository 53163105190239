import { EditProps } from "react-admin";
import { DefaultEdit } from "../../../lib/components";
import {
  MediaFileSource,
  transformAndUploadS3Files,
} from "../../../lib/modules";
import { PurchaseGroupForm } from "./PurchaseGroupForm";

const PurchaseGroupEdit= (props:EditProps) => {
  const mediaFileSources: MediaFileSource[] = [
    {
      source: "files",
      subFolder: "purchase-groups",
    },
  ];

  return (
    <DefaultEdit
      {...props}
      mutationMode="pessimistic"
      transform={transformAndUploadS3Files(mediaFileSources)}
    >
      <PurchaseGroupForm isEdit={true} {...props} />
    </DefaultEdit>
  );
};

export { PurchaseGroupEdit };
