import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import { PermissionUoms, RESOURCE_NAME_UOM } from './constants';
import { UomCreate } from './UomCreate';
import { UomEdit } from './UomEdit';
import { UomList } from './UomList';
import { UomShow } from './UomShow';

const allowedPermissions = {
    menu: PermissionUoms.UOM_MENU,
    list: PermissionUoms.LIST_UOMS,
    create: PermissionUoms.CREATE_UOM,
    update: PermissionUoms.EDIT_UOM,
    show: PermissionUoms.SHOW_UOM,
    delete: PermissionUoms.DELETE_UOM,
    import: PermissionUoms.IMPORT_UOMS,
};

const uomResource = {
    name: RESOURCE_NAME_UOM,
    list: UomList,
    create: UomCreate,
    edit: UomEdit,
    show: UomShow, 
    icon: DonutLargeIcon,
    options: { label: 'resources.uom.name',allowedPermissions },
}

export * from './constants';
export { uomResource }